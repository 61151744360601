import "./EditLocationPopup.css";

import {
  ValidatorFunction,
  useFormControl,
} from "../../../lib/components/form/Form";
import { useCallback, useEffect, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { LocationService } from "../../../services/LocationServices";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { UpdateLocationRequestDTO } from "../../../models/api/location/UpdateLocationRequestDTO";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";

var locationService = new LocationService();

interface IProps {
  locationId: number;
  onCompleted: () => void;
}



export function EditLocationPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const createNotification = useCreateNotification();

  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  useEffect(() => {
    getLocationById();
  }, []);

  const getLocationById = useCallback(() => {
    if (props.locationId) {
      locationService
        .getLocationById(props.locationId + "")
        .then((response) => {
          nameFormControl.setValue(response.name);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [props.locationId, nameFormControl.value, openPopup]);

  const editLocation = useCallback(() => {
    if (!props.locationId || !AllValid(nameFormControl.validate())) return;


    setOnClickSubmit(true);
    let request: UpdateLocationRequestDTO = {
      locationId: props.locationId,
      locationName: trimString(nameFormControl.value),
    };

    locationService
      .editLocation(request)
      .then((_) => {
        setOnClickSubmit(false);
        props.onCompleted();
        closePopup();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate(
              "GLOBALADMIN.LOCATIONS.POPUP.EditLocationSuccess"
            )}
          ></Notification>
        );
      })
      .catch((error) => {
        setOnClickSubmit(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [
    props.locationId,
    nameFormControl.value,
    nameFormControl.validate,
    props.onCompleted,
    setOnClickSubmit,
    closePopup,
    openPopup,
    createNotification,
  ]);

  return (
    <PopupContainer className="popup-edit-location">
      {onClickSubmit ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("GLOBALADMIN.LOCATIONS.POPUP.EditLocation")}
      />

      <PopupContent isLoading={isLoading}>
        <FormContainer>
          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("GLOBALADMIN.LOCATIONS.INFO.Name")}
          />
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Edit"),
              type: "primary",
              onClick: editLocation,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
