import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { CorrelateLabSamplesPopup } from "../../popups/CorrelateLabSamplesPopup";
import { CreateSampleRequestDTO } from "../../../models/api/CreateSampleRequestDTO";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { LabOperator } from "../../../../admin/employees/models/LabOperator";
import { LabOperatorService } from "../../../../admin/employees/LabOperatorService";
import { LabSampleService } from "../../../services/LabSampleService";
import { Material } from "../../../../../models/domain/materials/Material";
import { MaterialsService } from "../../../../../services/MaterialsService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { Permission } from "../../../../../models/api/gate/Permission";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { SampleTypeEnum } from "../../../models/domain/SampleTypeEnum";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { WarningPopup } from "../../../../../lib/components/popup/WarningPopup";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import {
  useFormControl,
} from "../../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useNavigate } from "react-router-dom";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { AnalysisRequestSamplesToCorrelateFilterRequestDTO } from "../../../models/api/AnalysisRequestSamplesToCorrelateFilterRequestDTO";

const labelSelector = (item: Material) => item.name + " - " + item.description;
const idSelector = (item: Material) => item.id;

var labOperatorService = new LabOperatorService();

const employeeLabelSelector = (item: LabOperator) => item.employeeCode + " - " + item.name;
const employeeIdSelector = (item: LabOperator) => item.labOperatorId;


var materialService = new MaterialsService();
var labSampleService = new LabSampleService();

export interface ICloneSampleProps {
  cloneId?: string | undefined;
}


const topPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS
];


export function AddSampleFibersPopup(props: ICloneSampleProps) {
  const openPopup = usePopup();
  const navigate = useNavigate();
  const closePopup = useClosePopup();
  const createNotification = useCreateNotification();
  const locationID = useGlobalLocation();

  const [materials, setMaterials] = useState<Material[]>([]);
  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
  const [isContentLoading, setIsContentLoading] = useState<boolean>(true);

  const [getLabOperatorCall, getLabOperatorCallIsLoading] = useServiceCallPro2(labOperatorService, labOperatorService.getLabOperators);

  const { isLoadingPermissions: isLoadingCorrelationPermission, hasPermission: hasCorrelationPermissions } = useHasPermissions(topPermissions);
  const [getPossibleCorrelationsCall, getPossibleCorrelationsCallIsLoading] = useServiceCallPro2(labSampleService, labSampleService.getPossibleSampleCorrelations);

  const [employees, setEmployees] = useState<LabOperator[]>([]);

  const employeeFormControl = useFormControl<LabOperator>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });


  const materialFormControl = useFormControl<Material>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const supplierFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const batchFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const waybillFormControl = useFormControl<string>({ isDisabled: false });

  const observationsFormControl = useFormControl<string>({ isDisabled: false });


  useEffect(() => {
    if (!locationID) return;
    getLabOperatorCall("" + locationID, { filterByIsActive: true })
      .then((r) => { setEmployees(r.operators) })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, []);




  useEffect(() => {

    if (!locationID) {
      openPopup(<ErrorPopup>{translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}</ErrorPopup>);
      return;
    }

    materialService.getActivesMaterials(locationID, { filterByIsActive: true })
      .then((data) => {
        setMaterials(data.materials);
        if (!props.cloneId)
          setIsContentLoading(false);
      })
      .catch((error) => {
        setIsContentLoading(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })
  }, [locationID]);





  useEffect(() => {

    if (!props.cloneId || materials.length < 1) return;

    setIsContentLoading(true);
    labSampleService.getLabSampleById(`${locationID}`, `${props.cloneId}`).then((labSample) => {

      materialFormControl.setValue(materials.find(m => m.id === labSample?.sample.materialId))
      supplierFormControl.setValue(labSample?.sample.supplier);
      waybillFormControl.setValue(labSample?.sample.waybill);
      observationsFormControl.setValue(labSample?.sample.observations);
      batchFormControl.setValue(labSample.sample.batch);

      setIsContentLoading(false);
    }).catch(error => {
      if (!error) return;
      openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
    });

  }, [props.cloneId, materials]);





  const handleOnClickCreate = useCallback(() => {

    if (!locationID) {
      openPopup(<ErrorPopup>{translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}</ErrorPopup>);
      return;
    }

    if (!AllValid(materialFormControl.validate(), supplierFormControl.validate(), employeeFormControl.validate(), batchFormControl.validate())) return;

    setOnClickSubmit(true);

    var request: CreateSampleRequestDTO = {
      materialId: materialFormControl.value?.id || -1,
      supplier: trimString(supplierFormControl.value),
      waybill: trimString(waybillFormControl.value),
      observations: trimString(observationsFormControl.value),
      labSampleType: SampleTypeEnum.FIBERS,
      labOperatorId: employeeFormControl.value?.labOperatorId || -1,
      batch: trimString(batchFormControl.value)
    };


    labSampleService.addLabSample(locationID, request)
      .then((data) => {

        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("SAMPLES.POPUPS.CreateSampleSuccess")}
          ></Notification>
        );


        if (hasCorrelationPermissions && !isLoadingCorrelationPermission && data.sampleId) {

          var requestDto: AnalysisRequestSamplesToCorrelateFilterRequestDTO = {
            batch: data.batch,
            waybill: data.waybill,
            material: data.sapNumber ? data.sapNumber : data.othersMaterialDescription,
            filterWithoutCorrelation: true
          };

          getPossibleCorrelationsCall(`${locationID}`, "" + data.sampleId, requestDto, undefined)
            .then((res) => {
              setOnClickSubmit(false);
              if (res.samples && res.samples.length > 0) {
                openPopup(<WarningPopup
                  stopClosePopupOnConfirmClicked
                  onDismissClicked={() => { closePopup(); navigate("/samples/delivered"); }}
                  onConfirmClicked={(ev) => {

                    openPopup(<CorrelateLabSamplesPopup
                      navigateToViewSamples
                      batch={data.batch}
                      labSampleID={"" + data.sampleId}
                      locationID={"" + locationID}
                      waybill={data.waybill}
                      materialReference={data.sapNumber ?
                        "" + data.sapNumber :
                        (data.materialDescription ? data.materialDescription : data.othersMaterialDescription)}
                      onCompletedOperations={() => { navigate("/samples/delivered"); }}
                    />)
                  }
                  }
                  dismissText={translate("COMMON.No")}
                  confirmText={translate("COMMON.Yes")}
                >
                  {translate("SAMPLES.POPUPS.PossibleCorrelationFoundText")}
                </WarningPopup >
                );
              }
              else {
                setOnClickSubmit(false);
                closePopup();
                navigate("/samples/delivered");
              }

            })
            .catch((error) => {
              setOnClickSubmit(false);
              openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
        }
        else {
          setOnClickSubmit(false);
          closePopup();
          navigate("/samples/delivered");
        }
      })
      .catch((error) => {
        setOnClickSubmit(false);
        closePopup();
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });


  }, [isLoadingCorrelationPermission, hasCorrelationPermissions, supplierFormControl.validate, materialFormControl.validate, materialFormControl.value, batchFormControl.validate,
    supplierFormControl.value, waybillFormControl.value, observationsFormControl.value,
    employeeFormControl.validate, employeeFormControl.value,
    locationID, setOnClickSubmit, closePopup, openPopup, createNotification, navigate, translate, AllValid]);





  return (
    <PopupContainer className="popup-fibers">
      {onClickSubmit ? <FullScreenLoader /> : null}
      <PopupHeader navigateToOnCloseIcon="/samples/new"
        title={`${translate("SAMPLES.DeliverNewSample")}`} subtitle={`${translate("SAMPLES.INFOANDFORM.Type")}: ` + `${translate("SAMPLES.TYPES.Fibers")}`}
      />
      <PopupContent isLoading={isContentLoading || getLabOperatorCallIsLoading}>
        <PopupContent>
          <FormContainer>
            <FormFieldSelectSingle
              label={translate("SAMPLES.INFOANDFORM.Employee")}
              placeholder={translate(
                "SAMPLES.INFOANDFORM.EmployeePlaceholder"
              )}
              disableFuzzySearch
              options={employees}
              labelSelector={employeeLabelSelector}
              idSelector={employeeIdSelector}
              formControl={employeeFormControl}
              isClearSelectionAvailable={false}
            />
            <FormFieldSelectSingle
              label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
              placeholder={translate(
                "SAMPLES.INFOANDFORM.SAPMaterialPlaceholder"
              )}
              disableFuzzySearch
              options={materials}
              labelSelector={labelSelector}
              idSelector={idSelector}
              formControl={materialFormControl}
              isClearSelectionAvailable={false}
            />
            <FormFieldTextInput
              formControl={batchFormControl}
              label={translate("SAMPLES.INFOANDFORM.Batch")}
              placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
            />
            <FormFieldTextInput
              formControl={supplierFormControl}
              label={translate("SAMPLES.INFOANDFORM.Supplier")}
              placeholder={translate("SAMPLES.INFOANDFORM.SupplierPlaceholder")}
            />
            <FormFieldTextInput
              formControl={waybillFormControl}
              label={translate("SAMPLES.INFOANDFORM.Waybill") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate("SAMPLES.INFOANDFORM.WaybillPlaceholder")}
            />
            <FormFieldTextArea
              formControl={observationsFormControl}
              label={translate("SAMPLES.INFOANDFORM.Observations") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate(
                "SAMPLES.INFOANDFORM.ObservationsPlaceholder"
              )}
            />
          </FormContainer>
          <Spacer mode={"vertical"} px="30"></Spacer>
          <PopupActionButtons
            buttons={[
              {
                text: translate("COMMON.Cancel"),
                type: "tertiary",
                onClick: () => {
                  /*                 navigate("..", { relative: "path" }); */
                  navigate("/samples/new")
                  closePopup();
                },
              },
              {
                text: translate("COMMON.Deliver"),
                type: "primary",
                onClick: handleOnClickCreate,
              },
            ]}
          />
        </PopupContent>
      </PopupContent>
    </PopupContainer >
  );
}
