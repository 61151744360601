import "./DeliverSamplesTypePage.css";

import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { AddSampleAccessoriesPopup } from "./popups/AddSampleAccessoriesPopup";
import { AddSampleFibersPopup } from "./popups/AddSampleFibersPopup";
import { AddSampleOthersPopup } from "./popups/AddSampleOthersPopup";
import { AddSamplePackagePopup } from "./popups/AddSamplePackagePopup";
import { AddSampleProducedPopup } from "./popups/AddSampleProducedPopup";
import { AddSampleProductionPopup } from "./popups/AddSampleProductionPopup";
import { AddSampleThreadsPopup } from "./popups/AddSampleThreadsPopup";
import { LargeButton } from "../../../../lib/components/buttons/LargeButton";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";

/***** ICONS ******/

export function DeliverSamplesTypePage() {
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const navigate = useNavigate();

  const {sampleType, cloneId} = useParams();

  useEffect(() => {    
    if(!sampleType) {
      closePopup();
    } else {
      switch (sampleType.toLowerCase()) {
        case 'production': openPopup(<AddSampleProductionPopup cloneId={cloneId}/>); break;
        case 'fibers': openPopup(<AddSampleFibersPopup cloneId={cloneId}/>); break;
        case 'produced': openPopup(<AddSampleProducedPopup cloneId={cloneId}/>); break;
        case 'accessories': openPopup(<AddSampleAccessoriesPopup cloneId={cloneId}/>); break;
        case 'threads': openPopup(<AddSampleThreadsPopup cloneId={cloneId}/>); break;
        case 'others': openPopup(<AddSampleOthersPopup cloneId={cloneId}/>); break;
        case 'package': openPopup(<AddSamplePackagePopup cloneId={cloneId}/>); break;
        default: navigate("../"); break;
      }
    }

  }, [sampleType, cloneId])

  return (
    <PageLayout
      tabTitle={`laMP - ${translate("SAMPLES.DeliverSample")}`}
      pageTitle={translate("SAMPLES.DeliverSample")}
      className="deliver-samples-type"
      showBreadcrumb
    >
      <div className="deliver-samples-type-actions">
        <LargeButton
          text={translate("SAMPLES.TYPES.Production")}
          type="primary"
          onClick={() => {navigate("/samples/new/production")}}
        />
        <LargeButton
          text={translate("SAMPLES.TYPES.Fibers")}
          type="primary"
          onClick={() => {navigate("/samples/new/fibers")}}
        />
        <LargeButton
          text={translate("SAMPLES.TYPES.Produced")}
          type="primary"
          onClick={() => {navigate("/samples/new/produced")}}
        />
        <LargeButton
          text={translate("SAMPLES.TYPES.Accessories")}
          type="primary"
          onClick={() => {navigate("/samples/new/accessories")}}
        />
        <LargeButton
          text={translate("SAMPLES.TYPES.Threads")}
          type="primary"
          onClick={() => {navigate("/samples/new/threads")}}
        />
        <LargeButton
          text={translate("SAMPLES.TYPES.Others")}
          type="primary"
          onClick={() => {navigate("/samples/new/others")}}
        />
        <LargeButton
          text={translate("SAMPLES.TYPES.Package")}
          type="primary"
          onClick={() => {navigate("/samples/new/package")}}
        />
      </div>
    </PageLayout>
  );
}
