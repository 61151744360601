import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { CreateLocationRequestDTO } from "../models/api/location/CreateLocationRequestDTO";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";
import { LocationDTO } from "../models/api/location/LocationDTO";
import { LocationsResponseDTO } from "../models/api/location/LocationsResponseDTO";
import { UpdateLocationRequestDTO } from "../models/api/location/UpdateLocationRequestDTO";
import { Employee } from "../features/samples/models/domain/Employee";
import { UserDTO } from "../features/samples/models/api/UserDTO";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};



export class LocationService {

  activateLocation(locationId: string): Promise<void> {

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/EnableLocation`),
    })
      .then((_) => {
      })
      .catch((error) => {
        throw error;
      });
  }

  getLocations(): Promise<LocationsResponseDTO> {
    return HttpClient.sessionRequest<LocationsResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getLocationById(locationId: string): Promise<LocationDTO> {
    return HttpClient.sessionRequest<LocationDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  disableLocation(locationId: number): Promise<string> {
    return HttpClient.sessionRequest<string>({
      method: "DELETE",
      url: Route(`/api/v1/Locations/${locationId}`),
    })
      .then((response) => {
        return response.status.toString()
      })
      .catch((error) => {
        throw error;
      });
  }

  addLocation(locations: CreateLocationRequestDTO): Promise<LocationDTO> {
    return HttpClient.sessionRequest<LocationDTO>({
      method: "POST",
      url: Route(`/api/v1/Locations`),
      data: locations
    })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error;
      });
  }

  editLocation(location: UpdateLocationRequestDTO): Promise<LocationDTO> {
    return HttpClient.sessionRequest<LocationDTO>({
      method: "PUT",
      url: Route(`/api/v1/Locations/${location.locationId}`),
      data: location
    })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error;
      });
  }

  selectLocation(locationId: string | number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Me/Location/${locationId}`)
    })
      .then((_) => {
      })
      .catch((error) => {
        throw error;
      });
  }

  getSelectedLocation(): Promise<LocationDTO> {
    return HttpClient.sessionRequest<LocationDTO>({
      method: "GET",
      url: Route(`/api/v1/Me/Location`),
    })
      .then((response) => {

        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  }



}
