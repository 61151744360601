import "./CardButton.css";

import { useCallback, useMemo } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/big-btn-arrow.svg";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface ICardButtonProps {
  text: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function CardButton(props: ICardButtonProps) {
  const buttonCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("card-button")
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className, props.isDisabled]);

  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      if (props.isDisabled) return;
      props.onClick && props.onClick(ev);
    },
    [props.onClick, props.isDisabled]
  );

  return (
    <div className={buttonCss} onClick={handleClicked}>
      <div className="text">{props.text}</div>
      <div className="arrow">
        <Arrow />
      </div>
      {props.icon ? <div className="card-button-icon">{props.icon}</div> : null}
    </div>
  );
}
