import { AnalysisRequestStatus } from "./AnalysisRequestStatus";
import { AnalysisRequestType } from "./AnalysisRequestType";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum AnalysisRequestStatusEnum {
    DRAFT = 3,
    REGISTERED = 1,
    CANCELED = 2,
    UNDERANALYSIS = 4,
    READYTOCLOSE = 6,
    DONE = 5,

}

export const AnalysisRequestStatusTranslationTerms: { [key: string]: string } = {
    3: "REQUESTS.STATUS.Draft",
    1: "REQUESTS.STATUS.Registed",
    2: "REQUESTS.STATUS.Canceled",
    4: "REQUESTS.STATUS.UnderAnalysis",
    6: "REQUESTS.STATUS.ReadyToClose",
    5: "REQUESTS.STATUS.Finished",

}

export const RequestStatusAsEnum: { [key: number]: AnalysisRequestStatusEnum } = {
    3: AnalysisRequestStatusEnum.DRAFT,
    1: AnalysisRequestStatusEnum.REGISTERED,
    2: AnalysisRequestStatusEnum.CANCELED,
    4: AnalysisRequestStatusEnum.UNDERANALYSIS,
    6: AnalysisRequestStatusEnum.READYTOCLOSE,
    5: AnalysisRequestStatusEnum.DONE,

};

export function GetAnalysisRequestStatus() {

    const analysisRequestStatusArray: AnalysisRequestStatus[] = Object.values(AnalysisRequestStatusEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedAnalysisRequestStatus: AnalysisRequestType = {
                id: "" + enumValue,
                description: translate(AnalysisRequestStatusTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedAnalysisRequestStatus;
        });

    return analysisRequestStatusArray;
}