import { Attachment } from "../pages/request-details-page/AddAttachments";
import { AttachmentsResponseDTO } from "../models/api/AttachmentsResponseDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { SignURLRequestDTO } from "../models/api/SignURLRequestDTO";
import { SignURLResponseDTO } from "../models/api/SignURLResponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class RequestAttachmentsService {


    uploadRequestAttachment(locationId: string, requestId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file, file.name);

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Files`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getRequestsAttachments(locationId: string, requestId: string): Promise<Attachment[]> {
        return HttpClient.sessionRequest<AttachmentsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Files`)
        })
            .then((response) => {
                var mappedAttachments = response.data.files.map((ob) => { 
                    var attachment: Attachment = {
                        id: ob.id,
                        name: ob.name,
                        creatorName: ob.creatorName,
                        creatorEmail: ob.creatorEmail
                    }
                    return attachment;
                });
                return mappedAttachments;
            })
            .catch((error) => {
                throw error;
            });
    }



    getUrlDownloadAttachment(locationId: string, requestId: string, fileId: string): Promise<string> {
        var requestDTO: SignURLRequestDTO = {
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Files/${fileId}`)
        }

        return HttpClient.sessionRequest<SignURLResponseDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Files/SignURL`),
            data: requestDTO
        })
            .then((res) => {
                return res.data.url;
            })
            .catch((error) => {
                throw error;
            });
    }

    deleteRequestAttachment(locationId: string, requestId: string, fileId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Files/${fileId}`)
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    
    sendNotificationReportCompleted(locationId: string, requestId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Files/SendAlert`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


}