import "./EditMaterialPopup.css";

import {
  ValidatorFunction,
  useFormControl,
} from "../../../lib/components/form/Form";
import { useCallback, useEffect, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { MaterialsService } from "../../../services/MaterialsService";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";
import { Checkbox } from "../../../lib/components/checkbox/Checkbox";



export interface IEditMaterialProps {
  locationId: string;
  materialId: number;
  onChangesMade: () => void;
}

var materialService = new MaterialsService();

export function EditMaterialPopup(props: IEditMaterialProps) {
  const createNotification = useCreateNotification();
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /****************************
   * FORM CONTROL
   *****************************/
  const plantFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });
  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });
  const descriptionFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });
  const [isSubjectToC04, setIsSubjectToC04] = useState<boolean>(false);


  useEffect(() => {
    if (props.locationId && props.materialId) {
      materialService
        .getById(props.locationId, props.materialId)
        .then((data) => {
          plantFormControl.setValue(trimString(data.plant));
          nameFormControl.setValue(trimString(data.name));
          descriptionFormControl.setValue(trimString(data.description));
          setIsSubjectToC04(data.isSubjectToC04);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, []);



  const onClickEditMaterial = useCallback(() => {
    if (!props.locationId || !props.materialId) return;


    if (!AllValid(nameFormControl.validate(), plantFormControl.validate(), descriptionFormControl.validate())) return;

    setOnClickSubmit(true);
    materialService
      .updateMaterial(
        props.locationId,
        props.materialId,
        trimString(plantFormControl.value),
        trimString(nameFormControl.value),
        trimString(descriptionFormControl.value), isSubjectToC04
      )
      .then(() => {
        setOnClickSubmit(false);
        props.onChangesMade();
        closePopup();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate(
              "GLOBALADMIN.MATERIALS.POPUP.EditMaterialSuccess"
            )}
          ></Notification>
        );
      })
      .catch((error) => {
        setOnClickSubmit(false);
        if (!error) return;
        openPopup(
          <ErrorPopup>{error.response.data.message}</ErrorPopup>,
          false
        );
      });

  }, [
    props.locationId,
    props.materialId,
    isSubjectToC04,
    plantFormControl.value,
    nameFormControl.value,
    descriptionFormControl.value,
    plantFormControl.validate,
    nameFormControl.validate,
    descriptionFormControl.validate,
    props.onChangesMade,
    createNotification,
    setOnClickSubmit,
    closePopup,
    usePopup,
    openPopup,
  ]);



  return (
    <PopupContainer className="popup-edit-material">
      {onClickSubmit ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("GLOBALADMIN.MATERIALS.POPUP.EditMaterial")}
      />
      <PopupContent isLoading={isLoading}>
        <FormContainer>
          <FormFieldTextInput
            formControl={plantFormControl}
            label={translate("GLOBALADMIN.MATERIALS.INFO.Plant")}
            placeholder="Ex: 4500"
          />
          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("GLOBALADMIN.MATERIALS.INFO.Code")}
            placeholder="Ex: L07950-3-0-100"
          />
          <FormFieldTextInput
            formControl={descriptionFormControl}
            label={translate("GLOBALADMIN.MATERIALS.INFO.Description")}
            placeholder="Ex: DF Seam wire 6x6m 1,14x1,12 PEEK"
          />
          <div className="small-copy separator">{translate("GLOBALADMIN.MATERIALS.INFO.Control04")}</div>
          <Checkbox
            text={translate("GLOBALADMIN.MATERIALS.INFO.SubjectToC04")}
            isChecked={isSubjectToC04}
            onClick={() => { setIsSubjectToC04(!isSubjectToC04) }}
          />
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Edit"),
              type: "primary",
              onClick: onClickEditMaterial
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
