import "./EditRequestForm.css";

import { DeliverTypeEnum, GetDeliverTypeArray } from "../../../../models/domain/DeliverTypeEnum";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { EditRequestForm } from "../../../../models/domain/EditRequestForm";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../../lib/components/form/section/FormSection";
import React from "react";
import { RequestDetails } from "../../../../models/domain/RequestDetails";
import { SelectOption } from "../../../../models/domain/SelectOption";
import { hasOneElementSelected } from "../../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { validateWaybillFormIfCourierShippingMethod } from "../../../create-request-page/CreateRequestPage";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { AnalysisRequestReasonType } from "../../../../models/domain/AnalysisRequestReasonType";
import { validateAnalysisRequestReasonRequiredIfOthers } from "../../../../validators/validateAnalysisRequestReasonRequiredIfOthers";

const labelDeliverTypeSelector = (item: SelectOption) => item.description;
const idDeliverTypeSelector = (item: SelectOption) => item.id;

const labelAnalysisReasonTypeSelector = (item: AnalysisRequestReasonType) => item.reasonTypeName;
const idAnalysisReasonTypeSelector = (item: AnalysisRequestReasonType) => item.id;


export interface IGenericEditRequestFormsProps {
  info: RequestDetails;
  analysisReasonTypes: AnalysisRequestReasonType[];
}

export const EditRequestARForm = forwardRef(function EditRequestARForm(
  props: IGenericEditRequestFormsProps,
  ref: React.ForwardedRef<EditRequestForm>
) {

  const deliverRequestTypes = useMemo(() => { return GetDeliverTypeArray(); }, [GetDeliverTypeArray]);

  const requestNameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const companyFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const industryFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const customerCommentsFormControl = useFormControl<string>({
    isDisabled: false,
  });

  const analysisReasonTypeFormControl = useFormControl<AnalysisRequestReasonType>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false
  });

  const analysisReasonFormControl = useFormControl<string>({
    validators: [validateAnalysisRequestReasonRequiredIfOthers(analysisReasonTypeFormControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });


  const deliverTypeRequestFormControl = useFormControl<SelectOption>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const waybillFormControl = useFormControl<string>({
    validators: [validateWaybillFormIfCourierShippingMethod(deliverTypeRequestFormControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const ketoProjectFormControl = useFormControl<string>({
    isDisabled: false,
  });





  useImperativeHandle(ref, () => {
    return {
      validate() {
        return AllValid(requestNameFormControl.validate(),
          companyFormControl.validate(), industryFormControl.validate(), analysisReasonFormControl.validate(),
          waybillFormControl.validate(), deliverTypeRequestFormControl.validate(), customerCommentsFormControl.validate(), analysisReasonTypeFormControl.validate());
      },
      value() {
        return {
          name: requestNameFormControl.value,
          company: companyFormControl.value,
          industry: industryFormControl.value,
          customerComments: customerCommentsFormControl.value,
          analysisReason: analysisReasonFormControl.value,
          ketoProject: ketoProjectFormControl.value,

          analysisRequestReasonTypeId: analysisReasonTypeFormControl.value?.id ?? -1,
          
          shippingByCourier: deliverTypeRequestFormControl.value?.id === DeliverTypeEnum.Courier.toString() ? true : false,

          waybill: deliverTypeRequestFormControl.value?.id === `${DeliverTypeEnum.Personally}` ?
            undefined : waybillFormControl.value,
        }
      }
    }
  }, [requestNameFormControl.validate, companyFormControl.validate, industryFormControl.validate,
  analysisReasonFormControl.validate, waybillFormControl.validate, deliverTypeRequestFormControl.validate, customerCommentsFormControl.validate,
  requestNameFormControl.value, companyFormControl.value, industryFormControl.value, ketoProjectFormControl.value,
  waybillFormControl.value, deliverTypeRequestFormControl.value, customerCommentsFormControl.value, analysisReasonFormControl.validate, analysisReasonTypeFormControl.value]);







  useEffect(() => {

    if (!props.info || !props.analysisReasonTypes || props.analysisReasonTypes.length < 1) return;
    var foundReasonType = props.analysisReasonTypes.find((t) => t.id === props.info.analysisRequestReasonTypeId);
    analysisReasonTypeFormControl.setValue(foundReasonType);

  }, [props.info, props.analysisReasonTypes]);



  useEffect(() => {
    if (!props.info) return;

    requestNameFormControl.setValue(props.info.name);
    companyFormControl.setValue(props.info.company);
    industryFormControl.setValue(props.info.industry);
    waybillFormControl.setValue(props.info.waybill);
    customerCommentsFormControl.setValue(props.info.customerComments);
    analysisReasonFormControl.setValue(props.info.analysisReason);
    waybillFormControl.setValue(props.info.waybill);
    ketoProjectFormControl.setValue(props.info.ketoProject)

    if (props.info.shippingByCourier)
      deliverTypeRequestFormControl.setValue(deliverRequestTypes.find((f) => f.id === (DeliverTypeEnum.Courier).toString()));
    else
      deliverTypeRequestFormControl.setValue(deliverRequestTypes.find((f) => f.id === (DeliverTypeEnum.Personally).toString()))

  }, [props.info]);


  useEffect(() => {
    if (!deliverTypeRequestFormControl.value) {
      waybillFormControl.setIsDisabled(true); return;
    }

    if (deliverTypeRequestFormControl.value.id === (DeliverTypeEnum.Personally).toString() || !deliverTypeRequestFormControl.value)
      waybillFormControl.setIsDisabled(true);
    else waybillFormControl.setIsDisabled(false);

  }, [deliverTypeRequestFormControl.value]);



  return (
    <>
      <div className="edit-request-details">
        <div className="subtitle">{translate("REQUESTS.RequestData")}</div>
        <FormSection>
          <FormFieldTextInput
            formControl={requestNameFormControl}
            label={translate("REQUESTS.INFOANDFORM.Name")}
            placeholder={translate("REQUESTS.INFOANDFORM.NamePlaceholder")}
          />
        </FormSection>

        <FormSection title={translate("REQUESTS.INFOANDFORM.Client")}>
          <FormFieldTextInput
            formControl={companyFormControl}
            label={translate("REQUESTS.INFOANDFORM.Company")}
            placeholder={translate("REQUESTS.INFOANDFORM.CompanyPlaceholder")}
          />
          <FormFieldTextInput
            formControl={industryFormControl}
            label={translate("REQUESTS.INFOANDFORM.Industry")}
            placeholder={translate("REQUESTS.INFOANDFORM.IndustryPlaceholder")}
          />
        </FormSection>

        <FormSection title={translate("REQUESTS.OtherData")}>
          <FormFieldTextArea
            formControl={ketoProjectFormControl}
            label={translate("REQUESTS.INFOANDFORM.KetoProject") + " (" + translate("COMMON.Optional") + ")"}
            placeholder={translate("REQUESTS.INFOANDFORM.KetoProjectPlaceholder")}

          />
          <FormFieldTextArea
            formControl={customerCommentsFormControl}
            label={
              translate("REQUESTS.INFOANDFORM.Comments") +
              " (" +
              translate("COMMON.Optional") +
              ")"
            }
            placeholder={translate("REQUESTS.INFOANDFORM.CommentsPlaceholder")}
          />
        </FormSection>
      </div>
      <div className="edit-request-ar-transport">
        <div className="edit-request-ar">
          <div className="subtitle">
            {translate("REQUESTS.INFOANDFORM.AnalysisReason")}
          </div>
          <FormFieldSelectSingle
            label={translate("REQUESTS.ANALYSISREASONTYPE.AnalysisReasonType")}
            placeholder={translate("COMMON.FORM.VALIDATIONS.SelectOneOption")}
            options={props.analysisReasonTypes || []}
            labelSelector={labelAnalysisReasonTypeSelector}
            idSelector={idAnalysisReasonTypeSelector}
            formControl={analysisReasonTypeFormControl}
            isClearSelectionAvailable={false}
          />
          <FormFieldTextArea
            formControl={analysisReasonFormControl}
            label={translate("COMMON.Description")}
            placeholder={translate(
              "REQUESTS.INFOANDFORM.AnalysisReasonPlaceholder"
            )}
          />
        </div>

        <div className="edit-request-transport">
          <div className="subtitle">
            {translate("REQUESTS.INFOANDFORM.Shipping")}
          </div>
          <FormFieldSelectSingle
            label={translate("REQUESTS.INFOANDFORM.ShippingMethod")}
            placeholder={translate("COMMON.FORM.VALIDATIONS.SelectOneOption")}
            options={deliverRequestTypes || []}
            labelSelector={labelDeliverTypeSelector}
            idSelector={idDeliverTypeSelector}
            formControl={deliverTypeRequestFormControl}
            isClearSelectionAvailable={false}
          />
          <FormFieldTextInput
            className="waybill"
            formControl={waybillFormControl}
            label={translate("REQUESTS.INFOANDFORM.Waybill")}
            placeholder={translate("REQUESTS.INFOANDFORM.WaybillPlaceholder")}
          />
        </div>
      </div>
    </>
  );
});
