import "./AddLabTestPopup.css";

import { GetMeasurementEnumAsArray, MeasurementAsEnum, MeasurementEnum } from "./models/MeasurementEnum";
import { GetUnitsEnumAsArray, UnitEnum, UnitEnumAsEnum } from "./models/UnitEnum";
import { regexIntegerOrDecimals, validateRegexOrEmpty, validateRegexOrEmptyV2 } from "../../../lib/validators/ValidateRegex";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, } from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../lib/components/checkbox/Checkbox";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { LabTestTypeDetails } from "../../../models/domain/lab-tests/LabTestTypeDetails";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { SelectOption } from "../../requests/models/domain/SelectOption";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { TestTypesService } from "./services/TestTypesService";
import { TestsService } from "./services/TestsService";
import { hasOneElementSelected } from "../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { useFormControl } from "../../../lib/components/form/Form";
import { validateStringIfConditional } from "../../requests/validators/ValidateIfConditional";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";

function replaceCommaByPoint(value: string | undefined) {
  if (!value) return null;
  if (value?.includes(','))
    return trimString(value.replace(',', '.'));
  return trimString(value);
}

export interface IAddLabTestProps {
  locationId: string;
  onChangesMade: () => void;
}

var testsService = new TestsService();
var testTypesService = new TestTypesService();

const labelTestTypeSelector = (item: LabTestTypeDetails) => item.labTestTypeName;
const idTestTypeSelector = (item: LabTestTypeDetails) => item.id;

const labelMeasurementTypeSelector = (item: SelectOption) => item.description;
const idMeasurementTypeSelector = (item: SelectOption) => item.id;

const labelUnitsTypeSelector = (item: SelectOption) => item.description;
const idUnitsSelector = (item: SelectOption) => item.id;


export function AddLabTestPopup(props: IAddLabTestProps) {
  const createNotification = useCreateNotification();
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
  const [isForDestroyingBarOrFilter, setIsForDestroyingBarOrFilterFormControl] = useState<boolean>(false);
  const [isUsed, setIsUsed] = useState<boolean>(false);
  const [testTypes, setTestTypes] = useState<LabTestTypeDetails[]>([]);

  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);

  const measurementTypes = useMemo(() => {
    return GetMeasurementEnumAsArray();
  }, [GetMeasurementEnumAsArray]);

  const unitMeasurementTypes = useMemo(() => {
    return GetUnitsEnumAsArray();
  }, [GetUnitsEnumAsArray]);

  const labTestTypeFormControl = useFormControl<LabTestTypeDetails>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const nameENFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const namePTFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const multiplierFormControl = useFormControl<string>({
    validators: [validateRegexOrEmpty(regexIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });

  const rehearsalTimeFormControl = useFormControl<string>({
    validators: [validateRegexOrEmpty(regexIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });

  const workedHoursFormControl = useFormControl<string>({
    validators: [validateRegexOrEmpty(regexIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });

  const sampleSizeFormControl = useFormControl<string>({
  });

  const costFormControl = useFormControl<string>({
    validators: [validateRegexOrEmpty(regexIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });

  const usedCostFormControl = useFormControl<string>({
    validators: [validateRegexOrEmpty(regexIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });

  const internalProcedureFormControl = useFormControl<string>({});

  const standardFormControl = useFormControl<string>({});

  const measurementTypeFormControl = useFormControl<SelectOption>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const unitsFormControl = useFormControl<SelectOption>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const sampleLengthFormControl = useFormControl<string>({
    validators: [validateRegexOrEmptyV2(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });

  const sampleWidthFormControl = useFormControl<string>({
    validators: [validateStringIfConditional(measurementTypeFormControl.value?.id === (MeasurementEnum.AREA).toString()),
    validateRegexOrEmpty(regexIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
    enableAutoValidate: true,
  });



  useEffect(() => {
    if (measurementTypeFormControl.value?.id === (MeasurementEnum.AREA).toString())
      sampleWidthFormControl.setIsDisabled(false);
    else {
      sampleWidthFormControl.setIsDisabled(true);
      sampleWidthFormControl.validate();
    }
  }, [measurementTypeFormControl?.value?.id]);



  useEffect(() => {

    if (!props.locationId) return;

    setIsLoadingContent(true);

    testTypesService.getActiveLabTestTypesByLocationId(props.locationId, { filterByIsActive: true })
      .then((response) => {
        setTestTypes(response.testTypes);
        setIsLoadingContent(false);
      })
      .catch((error) => {
        setIsLoadingContent(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, []);



  const onClickAddLabTest = useCallback(() => {
    if (!props.locationId) return;

    if (!AllValid(labTestTypeFormControl.validate(), nameENFormControl.validate(), namePTFormControl.validate(), multiplierFormControl.validate(),
      rehearsalTimeFormControl.validate(), workedHoursFormControl.validate(), sampleSizeFormControl.validate(), sampleLengthFormControl.validate(),
      costFormControl.validate(), usedCostFormControl.validate(), internalProcedureFormControl.validate(), standardFormControl.validate(),
      measurementTypeFormControl.validate(), unitsFormControl.validate(), sampleWidthFormControl.validate())) {
      return;
    }
    setOnClickSubmit(true);


    testsService.
      addLabTest(props.locationId, {
        labTestTypeId: labTestTypeFormControl.value?.id,
        nameEn: trimString(nameENFormControl.value),
        namePt: trimString(namePTFormControl.value),
        multiplier: parseFloat(trimString(multiplierFormControl.value)),
        rehearsalTime: replaceCommaByPoint(rehearsalTimeFormControl.value),
        workedHours: replaceCommaByPoint(workedHoursFormControl.value),
        sampleSize: trimString(sampleSizeFormControl.value),
        isForDestroyingBarOrFilter: isForDestroyingBarOrFilter,
        cost: replaceCommaByPoint(costFormControl.value),
        usedCost: replaceCommaByPoint(usedCostFormControl.value),
        internalProcedure: trimString(internalProcedureFormControl.value),
        standard: trimString(standardFormControl.value),
        isUsed: isUsed,
        measurement: measurementTypeFormControl.value?.id ? MeasurementAsEnum[measurementTypeFormControl.value.id] : MeasurementEnum.AREA,
        length: replaceCommaByPoint(sampleLengthFormControl.value) || "",
        width: replaceCommaByPoint(sampleWidthFormControl.value),
        unit: unitsFormControl.value?.id ? UnitEnumAsEnum[unitsFormControl.value.id] : UnitEnum.METERS,
      })
      .then((_) => {
        props.onChangesMade();
        setOnClickSubmit(false);
        closePopup();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate(
              "GLOBALADMIN.TESTS.POPUP.CreateTestSuccess"
            )}
          ></Notification>
        );
      })
      .catch((error) => {
        if (!error) return;
        setOnClickSubmit(false);
        openPopup(
          <ErrorPopup>{error.response.data.message}</ErrorPopup>,
          false
        );
      });
  }, [measurementTypeFormControl.value,
  sampleLengthFormControl.value,
  sampleWidthFormControl.value,
  labTestTypeFormControl.value,
  unitsFormControl.value,
  nameENFormControl.value,
  namePTFormControl.value,
  multiplierFormControl.value,
  rehearsalTimeFormControl.value,
  workedHoursFormControl.value,
  sampleSizeFormControl.value,
    isForDestroyingBarOrFilter,
  costFormControl.value,
  usedCostFormControl.value,
  internalProcedureFormControl.value,
  standardFormControl.value,
    isUsed,
  labTestTypeFormControl.validate,
  nameENFormControl.validate,
  namePTFormControl.validate,
  multiplierFormControl.validate,
  rehearsalTimeFormControl.validate,
  workedHoursFormControl.validate,
  sampleSizeFormControl.validate,
  costFormControl.validate,
  usedCostFormControl.validate,
  internalProcedureFormControl.validate,
  standardFormControl.validate,
  measurementTypeFormControl.validate,
  unitsFormControl.validate,
  sampleLengthFormControl.validate,
  sampleWidthFormControl.validate,
    createNotification,
    closePopup,
    openPopup,
    setOnClickSubmit,
  props.onChangesMade,
  ]);

  return (
    <PopupContainer className="popup-add-test">
      {onClickSubmit ? <FullScreenLoader /> : null}

      <PopupHeader title={translate("GLOBALADMIN.TESTS.POPUP.CreateTest")} />
      <PopupContent isLoading={isLoadingContent} className="create-test">
        <FormContainer>
          <FormSection title={translate("REQUESTS.INFOANDFORM.Description")}>
            <FormFieldSelectSingle
              label={translate("GLOBALADMIN.TESTS.INFO.Type")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.Type")}
              options={testTypes || []}
              labelSelector={labelTestTypeSelector}
              idSelector={idTestTypeSelector}
              formControl={labTestTypeFormControl}
              isClearSelectionAvailable={false}
            />
            <FormFieldTextInput
              formControl={nameENFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.Name") + " (en-EN)"}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.NamePlaceholder")}
            />
            <FormFieldTextInput
              formControl={namePTFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.Name") + " (pt-PT)"}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.NamePlaceholder")}
            />
          </FormSection>
          <FormSection title={translate("COMMON.FORM.Details")}>
            <FormFieldTextInput
              formControl={rehearsalTimeFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.RehearsalTime")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.RehearsalTimePlaceholder")}
            />
            <FormFieldTextInput
              formControl={workedHoursFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.WorkedHours")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.WorkedHoursPlaceholder")}
            />
            <FormFieldTextInput
              formControl={multiplierFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.Multiplier")}
            />
          </FormSection>
          <FormSection title={translate("GLOBALADMIN.TESTS.INFO.SampleSize")}>
            <FormFieldSelectSingle
              label={translate("GLOBALADMIN.TESTS.INFO.MeasurementType")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.MeasurementPlaceholder")}
              options={measurementTypes || []}
              labelSelector={labelMeasurementTypeSelector}
              idSelector={idMeasurementTypeSelector}
              formControl={measurementTypeFormControl}
              isClearSelectionAvailable={false}
            />
            <FormFieldTextInput
              formControl={sampleLengthFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.Length")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.LengthPlaceholder")}
            />
            <FormFieldTextInput
              formControl={sampleWidthFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.Width")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.WidthPlaceholder")}
            />
            <FormFieldSelectSingle
              label={translate("GLOBALADMIN.TESTS.INFO.Units")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.UnitsPlaceholder")}
              options={unitMeasurementTypes || []}
              labelSelector={labelUnitsTypeSelector}
              idSelector={idUnitsSelector}
              formControl={unitsFormControl}
              isClearSelectionAvailable={false}
            />
          </FormSection>
          <FormSection title={translate("GLOBALADMIN.TESTS.INFO.Procedure")}>
            <FormFieldTextInput
              formControl={internalProcedureFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.InternalProcedure")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.InternalProcedurePlaceholder")}
            />
            <FormFieldTextInput
              formControl={standardFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.Standard")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.StandardPlaceholder")}
            />
          </FormSection>
          <FormSection title={translate("REQUESTS.INFOANDFORM.Cost")}>
            <FormFieldTextInput
              formControl={costFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.Cost")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.CostPlaceholder")}
            />
            <FormFieldTextInput
              formControl={usedCostFormControl}
              label={translate("GLOBALADMIN.TESTS.INFO.UsedCost")}
              placeholder={translate("GLOBALADMIN.TESTS.INFO.UsedCostPlaceholder")}
            />
          </FormSection>
          <FormSection title={translate("REQUESTS.INFOANDFORM.MaterialStatus")}>
            <Checkbox
              text={translate("GLOBALADMIN.TESTS.INFO.DestroyingBarOrFilterPlaceholder")}
              isChecked={isForDestroyingBarOrFilter}
              onClick={() => setIsForDestroyingBarOrFilterFormControl(!isForDestroyingBarOrFilter)}
            />
            <Checkbox
              text={translate("GLOBALADMIN.TESTS.INFO.Used")}
              isChecked={isUsed}
              onClick={() => setIsUsed(!isUsed)}
            />
          </FormSection>
          <PopupActionButtons
            className="create-test-btns"
            buttons={[
              {
                text: translate("COMMON.Cancel"),
                type: "tertiary",
                onClick: () => closePopup(),
              },
              {
                text: translate("COMMON.Create"),
                type: "primary",
                onClick: onClickAddLabTest
              },
            ]}
          />
        </FormContainer>
      </PopupContent>
    </PopupContainer>
  );
}
