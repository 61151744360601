import "./MainContainer.css";

import { useEffect, useMemo } from "react";
import {
  useSetSideBarButtons,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AdminIcon } from "../../../lib/assets/icons/administracao.svg";
import { ReactComponent as AmostrasIcon } from "../../../lib/assets/icons/amostras.svg";
import { ReactComponent as PedidosIcon } from "../../../lib/assets/icons/pedidos.svg";
import { ReactComponent as PowerBiDarkIcon } from "../../../lib/assets/icons/powerbi-dark.svg";
import { ReactComponent as PowerBiSearchIcon } from "../../../lib/assets/icons/magnifying-glass.svg";
import { Sidebar } from "../../../lib/components/side-bar/Sidebar";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { Permission } from "../../../models/api/gate/Permission";

interface IMainContainerProps {
  children: React.ReactNode;
}



const adminPermission = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS
];



export function MainContainer(props: IMainContainerProps) {
  const windowResize = useWindowResize();
  const setSideBarButtons = useSetSideBarButtons();


  const { isLoadingPermissions: loadingAdminPermissions, hasPermission: hasAdminPermissions } = useHasPermissions(adminPermission);



  const sideBarButtons = useMemo(() => [
    {
      icon: <AmostrasIcon />,
      navigateTo: "samples",
      name: translate("SAMPLES.Samples"),
    },
    {
      icon: <PedidosIcon />,
      navigateTo: "requests",
      name: translate("REQUESTS.Requests")
    },
    {
      icon: <PowerBiDarkIcon />,
      navigateTo: "https://app.powerbi.com/reportEmbed?reportId=fc4559ea-765a-468b-9d1b-49176059f52f&autoAuth=true&ctid=65f8dbd7-eb30-4ddc-88b3-f1f6fbea6ba2",
      name: "",
      isHidden: loadingAdminPermissions || !hasAdminPermissions,
      isTargetNewTab: true
    },
    {
      icon: <PowerBiSearchIcon />,
      navigateTo: "https://app.powerbi.com/reportEmbed?reportId=0c3fe700-cb41-4922-834f-dd8b91a9afb8&autoAuth=true&ctid=65f8dbd7-eb30-4ddc-88b3-f1f6fbea6ba2",
      name: "",
      isHidden: loadingAdminPermissions || !hasAdminPermissions,
      isTargetNewTab: true
    },
    {
      icon: <AdminIcon />,
      navigateTo: "settings/locations",
      name: translate("GLOBALADMIN.GlobalAdmin"),
      isBottomAligned: true
    },
  ], [loadingAdminPermissions, hasAdminPermissions]);





  useEffect(() => {
    setSideBarButtons(sideBarButtons);
  }, [setSideBarButtons]);

  const renderSideBar = useMemo(() => {
    if (windowResize > 768) return <Sidebar buttons={sideBarButtons} />;
    else return null;
  }, [windowResize, sideBarButtons]);

  return (
    <div className="main-content-container">
      {renderSideBar}
      {props.children}
    </div>
  );
}
