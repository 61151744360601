import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { CreateSampleRequestDTO } from "../../../models/api/CreateSampleRequestDTO";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { LabSampleService } from "../../../services/LabSampleService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { SampleTypeEnum } from "../../../models/domain/SampleTypeEnum";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../../lib/infrastructure/location/LocationServices";
import { useNavigate } from "react-router-dom";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { SelectOption } from "../../../../requests/models/domain/SelectOption";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { GetPackageAreaTypesArray, PackageAreaTypesAsEnum, PackageAreaTypesEnum } from "../../../models/domain/PackageAreaTypesEnum";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { LabOperator } from "../../../../admin/employees/models/LabOperator";
import { LabOperatorService } from "../../../../admin/employees/LabOperatorService";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";

var labSampleService = new LabSampleService();

const areaTypeLabelSelector = (item: SelectOption) => item.description;
const areaTypeIdSelector = (item: SelectOption) => item.id;
var labOperatorService = new LabOperatorService();

const employeeLabelSelector = (item: LabOperator) => item.employeeCode + " - " + item.name;
const employeeIdSelector = (item: LabOperator) => item.labOperatorId;


export interface ICloneSampleProps {
    cloneId?: string | undefined;
}

export function AddSamplePackagePopup(props: ICloneSampleProps) {

    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const locationID = useGlobalLocation();
    const navigate = useNavigate();
    const openPopup = usePopup();

    const packageAreaTypes = useMemo(() => {
        return GetPackageAreaTypesArray();
    }, [GetPackageAreaTypesArray]);


    const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
    const [isContentLoading, setIsContentLoading] = useState<boolean>(false);

    const [getLabOperatorCall, getLabOperatorCallIsLoading] = useServiceCallPro2(labOperatorService, labOperatorService.getLabOperators);


    const [employees, setEmployees] = useState<LabOperator[]>([]);


    const employeeFormControl = useFormControl<LabOperator>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
        isDisabled: false,
    });


    const batchFormControl = useFormControl<string>({
        isDisabled: false,
    });



    const waybillFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const supplierFormControl = useFormControl<string>({ isDisabled: false });

    const sourceFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const areaTypeControl = useFormControl<SelectOption>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
        isDisabled: false,
    });



    useEffect(() => {
        if (!locationID) {
            openPopup(<ErrorPopup>{translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}</ErrorPopup>);
            return;
        }
    }, [locationID])



    useEffect(() => {
        if (!locationID) return;
        getLabOperatorCall("" + locationID, { filterByIsActive: true })
            .then((r) => { setEmployees(r.operators) })
            .catch((error) => {

                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, []);



    useEffect(() => {

        if (!props.cloneId) return;

        setIsContentLoading(true);
        labSampleService.getLabSampleById(`${locationID}`, `${props.cloneId}`).then((labSample) => {

            waybillFormControl.setValue(labSample?.sample.waybill);
            supplierFormControl.setValue(labSample?.sample.supplier);
            sourceFormControl.setValue(labSample?.sample.source);
            batchFormControl.setValue(labSample.sample.batch);
            areaTypeControl.setValue(packageAreaTypes.find((a) => a.id === (labSample.sample.area)?.toString()))
            setIsContentLoading(false);

        }).catch(error => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });

    }, [props.cloneId]);



    const handleOnClickCreate = useCallback(() => {

        if (!locationID) {
            openPopup(<ErrorPopup>{translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}</ErrorPopup>);
            return;
        }

        if (!AllValid(waybillFormControl.validate(), sourceFormControl.validate(), areaTypeControl.validate(), employeeFormControl.validate())) return;

        setOnClickSubmit(true);

        var request: CreateSampleRequestDTO = {
            waybill: trimString(waybillFormControl.value),
            supplier: trimString(supplierFormControl.value),
            origin: trimString(sourceFormControl.value),
            labSampleType: SampleTypeEnum.PACKAGE,
            area: areaTypeControl.value?.id ? PackageAreaTypesAsEnum[areaTypeControl.value.id] : undefined,
            labOperatorId: employeeFormControl.value?.labOperatorId || -1,
            batch: trimString(batchFormControl.value)
        };

        labSampleService.addLabSample(locationID, request)
            .then(() => {
                setOnClickSubmit(false);
                closePopup();
                navigate("/samples/delivered");
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("SAMPLES.POPUPS.CreateSampleSuccess")}
                    ></Notification>
                );
            })
            .catch((error) => {
                setOnClickSubmit(false);
                closePopup();
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, openPopup, waybillFormControl.validate, supplierFormControl.validate, sourceFormControl.validate,
        areaTypeControl.validate, areaTypeControl.value, batchFormControl.value,
        employeeFormControl.validate, employeeFormControl.value,
        waybillFormControl.value, supplierFormControl.value, sourceFormControl.value,
        setOnClickSubmit, closePopup, navigate, createNotification]);






    return (
        <PopupContainer className="popup-producao">
            {onClickSubmit ? <FullScreenLoader /> : null}
            <PopupHeader navigateToOnCloseIcon="/samples/new"
                title={`${translate("SAMPLES.DeliverNewSample")}`} subtitle={`${translate("SAMPLES.INFOANDFORM.Type")}: ` + `${translate("SAMPLES.TYPES.Package")}`}
            />
            <PopupContent isLoading={isContentLoading || getLabOperatorCallIsLoading}>
                <FormContainer>
                    <FormFieldSelectSingle
                        label={translate("SAMPLES.INFOANDFORM.Employee")}
                        placeholder={translate(
                            "SAMPLES.INFOANDFORM.EmployeePlaceholder"
                        )}
                        disableFuzzySearch
                        options={employees}
                        labelSelector={employeeLabelSelector}
                        idSelector={employeeIdSelector}
                        formControl={employeeFormControl}
                        isClearSelectionAvailable={false}
                    />
                    <FormFieldSelectSingle
                        label={translate("SAMPLES.INFOANDFORM.Area")}
                        placeholder={translate("SAMPLES.INFOANDFORM.AreaPlaceholder")}
                        options={packageAreaTypes}
                        labelSelector={areaTypeLabelSelector}
                        idSelector={areaTypeIdSelector}
                        formControl={areaTypeControl}
                        isClearSelectionAvailable={false}
                    />
                    <FormFieldTextInput
                        formControl={batchFormControl}
                        label={translate("SAMPLES.INFOANDFORM.Batch") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
                    />

                    <FormFieldTextInput
                        formControl={supplierFormControl}
                        label={translate("SAMPLES.INFOANDFORM.Supplier") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("SAMPLES.INFOANDFORM.SupplierPlaceholder")}
                    />

                    <FormFieldTextInput
                        formControl={waybillFormControl}
                        label={translate("SAMPLES.INFOANDFORM.Waybill")}
                        placeholder={translate(
                            "SAMPLES.INFOANDFORM.WaybillPlaceholder"
                        )}
                    />

                    <FormFieldTextArea
                        formControl={sourceFormControl}
                        label={translate("SAMPLES.INFOANDFORM.Source")}
                        placeholder={translate("SAMPLES.INFOANDFORM.SourcePlaceholder")}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => {
                                /*                 navigate("..", { relative: "path" }); */
                                navigate("/samples/new")
                                closePopup();
                            },
                        },
                        {
                            text: translate("COMMON.Deliver"),
                            type: "primary",
                            onClick: handleOnClickCreate,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
