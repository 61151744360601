import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { regex7Characters, regexSamplesBatch, validateRegex } from "../../../../../../lib/validators/ValidateRegex";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { IGenericSampleFormsProps } from "../EditSampleDetailsPopup";
import { Material } from "../../../../../../models/domain/materials/Material";
import { SampleForm } from "../../../../models/domain/SampleForm";
import { hasOneElementSelected } from "../../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";

const materialLabelSelector = (item: Material) => item.name + " - " + item.description;
const materialIdSelector = (item: Material) => item.id;



export const ProductionSampleForm = forwardRef(
    function ProductionSampleForms(props: IGenericSampleFormsProps, ref: React.ForwardedRef<SampleForm>) {


        const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);

        const materialFormControl = useFormControl<Material>({
            validators: [hasOneElementSelected()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const oPFormControl = useFormControl<string>({
            validators: [
                validateRegex(
                    regex7Characters,
                    "SAMPLES.POPUPS.DELIVERPRODUCTION.ProductionOrderFormatError"
                ),
            ],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const batchFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,

        });

        const observationsFormControl = useFormControl<string>({
            isDisabled: false,
        });





        useEffect(() => {
            if (!props.materials && !props.sample)
                return;
            materialFormControl.setValue(props.materials.find((m) => m.id === props.sample?.materialId));
            oPFormControl.setValue(props.sample?.op);
            batchFormControl.setValue(props.sample?.batch);
            observationsFormControl.setValue(props.sample?.observations);

            setIsLoadingContent(false);

        }, [props.materials, props.sample]);





        useImperativeHandle(ref, () => {
            return {
                validate() {
                    return AllValid(materialFormControl.validate(), oPFormControl.validate(), batchFormControl.validate(), observationsFormControl.validate())

                },
                value() {
                    return {
                        materialId: materialFormControl.value?.id ?? -1,
                        batch: batchFormControl.value,
                        productionOrder: oPFormControl.value,
                        observations: observationsFormControl.value,
                    }
                },
            }
        }, [materialFormControl.validate, oPFormControl.validate, batchFormControl.validate, observationsFormControl.validate,
        materialFormControl.value, batchFormControl.value, oPFormControl.value, observationsFormControl.value, AllValid]);





        return (
            <>
                <FormFieldSelectSingle
                    label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
                    placeholder={translate(
                        "SAMPLES.INFOANDFORM.SAPMaterialPlaceholder"
                    )}
                    disableFuzzySearch
                    options={props.materials}
                    labelSelector={materialLabelSelector}
                    idSelector={materialIdSelector}
                    formControl={materialFormControl}
                    isClearSelectionAvailable={false}
                />
                <FormFieldTextInput
                    formControl={oPFormControl}
                    label={translate("SAMPLES.INFOANDFORM.ProductionOrder")}
                    placeholder={translate(
                        "SAMPLES.INFOANDFORM.ProductionOrderPlaceholder"
                    )}
                />
                <FormFieldTextInput
                    formControl={batchFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Batch")}
                    placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
                />

                <FormFieldTextArea
                    formControl={observationsFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Observations") + " (" + translate("COMMON.Optional") + ")"}
                    placeholder={translate("SAMPLES.INFOANDFORM.ObservationsPlaceholder")}
                />
            </>
        );
    }
);
