import "./LocationEmployeeDetailsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { EditLabOperatorPopup } from "./popups/EditLabOperatorPopup";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { LabOperator } from "./models/LabOperator";
import { LabOperatorService } from "./LabOperatorService";
import { LabOperatorStatusTranslationTerms } from "./models/LabOperatorStatus";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { TitledCardContainer } from "../../../lib/layouts/containers/card/TitledCardContainer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useParams } from "react-router-dom";
import { useServiceCallPro2 } from "../../../services/UseServiceCall";
import { useUpdatePageData } from "../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var labOperatorSvc = new LabOperatorService();

export function LocationEmployeeDetailsPage() {

    const openPopup = usePopup();
    const { id: locationId, employeeId } = useParams();
    const updateRouteData = useUpdatePageData();
    const windowResize = useWindowResize();

    const [employeeDetails, setEmployeeDetails] = useState<LabOperator>();
    const [getLabOperatorCall, getLabOperatorCallIsLoading] = useServiceCallPro2(labOperatorSvc, labOperatorSvc.getLabOperatorById);

    useEffect(() => {
        getEmployeeById();
    }, []);

    const getEmployeeById = useCallback(() => {
        getLabOperatorCall("" + locationId, "" + employeeId)
            .then((r) => {

                if (!r) return;
                setEmployeeDetails(r);
            })
    }, [locationId, employeeId, getLabOperatorCall]);

    const renderCardContainerInfo = useMemo(() => {
        return <FormSection unsetFlexGrow childrenFlexDirColumn={windowResize <= 425}>
            <InfoBlock
                label={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Code")}
                value={employeeDetails?.employeeCode || "-"}
            />
            <InfoBlock
                label={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Name")}
                value={employeeDetails?.name || "-"} />
            <InfoBlock
                label={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Division")}
                value={employeeDetails?.section || "-"}
            />
            <InfoBlock
                label={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Status")}
                value={employeeDetails?.status ? translate(LabOperatorStatusTranslationTerms[employeeDetails?.status]) : "-"}
            />
        </FormSection>
    }, [employeeDetails, windowResize]);

    const renderActionButtons = useMemo(() => {
        if (windowResize > 768)
            return <Button
                text={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.EditLabOperator")}
                type="primary"
                onClick={() => openPopup(<EditLabOperatorPopup employeeId={"" + employeeId} onCompletedOperations={getEmployeeById} locationId={"" + locationId} />)} />;
        else
            return (
                <IconButton
                    icon={<EditIcon />}
                    type="primary"
                    onClick={() => openPopup(<EditLabOperatorPopup employeeId={"" + employeeId} onCompletedOperations={getEmployeeById} locationId={"" + locationId} />)}
                    className="edit-btn"
                />
            );
    }, [employeeId, getEmployeeById, locationId, openPopup, windowResize]);

    return (
        <PageLayout
            tabTitle={"Valmet - LaMP"}
            pageTitle={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.EmployeeDetails")}
            className="employee-details-page"
            showBreadcrumb
            actions={renderActionButtons}
        >
            <div className="employee-data-info">
                <TitledCardContainer
                    title={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.EmployeeDetails")}
                    className="employee-info-container"
                    isLoading={getLabOperatorCallIsLoading} >
                    <Spacer mode="vertical" px="16" />
                    <div className="employee-data-content-info">
                        {renderCardContainerInfo}
                    </div>
                </TitledCardContainer >
            </div>
        </PageLayout >
    );
}
