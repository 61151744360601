export enum LocationGroupType {
    ACCESS = 1,
    REGISTER_SAMPLES = 2,
    ADMIN = 3,
    ANALISTS = 4,
    ANALISYS_REQUEST_GLOBAL = 5,
    AR = 6,
    DF = 7,
    DRYER = 8,
    PP = 9,
    WF = 10
}


export const LocationGroupTypeTranslationTerms: { [key: number]: string } = {
    1: "GLOBALADMIN.ACCESSGROUPS.TYPES.ACCESS",
    2: "GLOBALADMIN.ACCESSGROUPS.TYPES.REGISTER_SAMPLES",
    3: "GLOBALADMIN.ACCESSGROUPS.TYPES.ADMIN",
    4: "GLOBALADMIN.ACCESSGROUPS.TYPES.ANALISTS",
    5: "GLOBALADMIN.ACCESSGROUPS.TYPES.ANALISYS_REQUEST_GLOBAL",
    6: "GLOBALADMIN.ACCESSGROUPS.TYPES.AR",
    7: "GLOBALADMIN.ACCESSGROUPS.TYPES.DF",
    8: "GLOBALADMIN.ACCESSGROUPS.TYPES.DRYER",
    9: "GLOBALADMIN.ACCESSGROUPS.TYPES.PP",
    10: "GLOBALADMIN.ACCESSGROUPS.TYPES.WF"
}