import { AnalysisRequestStatusEnum } from "../../../models/domain/AnalysisRequestStatusEnum";
import { InfoBlock } from "../../../../../lib/components/info-block/InfoBlock";
import { RequestDetails } from "../../../models/domain/RequestDetails";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";

export interface IRenderCancelationReasonProps {
    reqDetails: RequestDetails;
}

export function RenderCancelationReason(props: IRenderCancelationReasonProps){

    const renderJustification = useMemo(() => {
        if (props.reqDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED)
          return;
    
        if (!props.reqDetails?.cancelledStatusReason) return;
    
        var canceledReasonTitle = "";
    
        if (props.reqDetails?.requestStatus === AnalysisRequestStatusEnum.CANCELED)
          canceledReasonTitle = translate("REQUESTS.INFOANDFORM.CancelReason");
    
        return (
          <div className="justification">
            <InfoBlock
              label={canceledReasonTitle}
              value={props.reqDetails?.cancelledStatusReason}
            />
          </div>
        );
      }, [props.reqDetails]);

      
    return(<div className="justification">{renderJustification}</div>)
}