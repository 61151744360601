import "./AddLocationPopup.css";

import {
  ValidatorFunction,
  useFormControl,
} from "../../../lib/components/form/Form";
import { useCallback, useMemo, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
} from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { CreateLocationRequestDTO } from "../../../models/api/location/CreateLocationRequestDTO";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { LocationService } from "../../../services/LocationServices";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { UpdateNavbarLocationsEvent } from "../../../events/UpdateNavbarLocationsEvent";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";

var locationService = new LocationService();

interface IProps {
  onChangesMade: () => void;
}

function requiredValidator<T>(): ValidatorFunction<T> {
  return (value: T | undefined) => {
    if (!value) {
      return translate("COMMON.FORM.VALIDATIONS.RequiredField");
    }
    return null;
  };
}

export function AddLocationPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const createNotification = useCreateNotification();

  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);

  const nameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const addLocation = useCallback(() => {

    if (!AllValid(nameFormControl.validate())) return;

    let locationRequest: CreateLocationRequestDTO = {
      name: trimString(nameFormControl.value),
    };

    setOnClickSubmit(true);
    locationService
      .addLocation(locationRequest)
      .then(() => {
        closePopup();
        setOnClickSubmit(false);
        props.onChangesMade();
        UpdateNavbarLocationsEvent.fireUpdateNavbarLocations();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate(
              "GLOBALADMIN.LOCATIONS.POPUP.CreateLocationSuccess"
            )}
          ></Notification>
        );
      })
      .catch((error) => {
        if (!error) return;
        setOnClickSubmit(false);
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [
    nameFormControl.value,
    nameFormControl.validate,
    closePopup,
    openPopup,
    props.onChangesMade,
    setOnClickSubmit,
    createNotification,
  ]);



  return (
    <PopupContainer className="popup-add-location">
      {onClickSubmit ? <FullScreenLoader /> : null}

      <PopupHeader title={translate("GLOBALADMIN.LOCATIONS.CreateLocation")} />
      <PopupContent>
        <FormContainer>
          <FormFieldTextInput
            formControl={nameFormControl}
            label={translate("GLOBALADMIN.LOCATIONS.INFO.Name")}
            placeholder={translate("GLOBALADMIN.LOCATIONS.INFO.NamePlaceholder")}
          />
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Create"),
              type: "primary",
              onClick: addLocation,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
