import { LabTestDTO } from "../LabTestDTO";
import { LabTestDetails } from "../../../domain/lab-tests/LabTestDetails";

export function LabTestToLabTestDTOMapper(labTest: LabTestDTO) {
    var mappedLabTest: LabTestDetails = {
        labTestId: labTest.labTestId,
        labTestTypeId: labTest.labTestTypeId,
        testTypeName: labTest.testTypeName,
        testNamePt: labTest.testNamePt,
        testNameEn: labTest.testNameEn,
        multiplier: labTest.multiplier,
        rehearsalTime: labTest.rehearsalTime,
        workedHours: labTest.workedHours,
        sampleSize: labTest.sampleSize,
        isForDestroyingBarOrFilter: labTest.isForDestroyingBarOrFilter,
        cost: labTest.cost,
        usedCost: labTest.usedCost,
        internalProcedure: labTest.internalProcedure,
        standard: labTest.standard,
        isUsed: labTest.isUsed,
        isDisabled: labTest.isDisabled,
        measurement: labTest.measurement,
        length: labTest.length,
        width: labTest.width,
        unit: labTest.unit,
    };
    return mappedLabTest;
}