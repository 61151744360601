import "./AddAttachments.css";

import { useCallback, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { AddInfoAttachments } from "./AddInfoAttachments";
import { AnalysisRequestStatusEnum } from "../../models/domain/AnalysisRequestStatusEnum";
import { ReactComponent as AttachmentsIcon } from "../../../../lib/assets/icons/anexo.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { Contact } from "../../../samples/models/domain/Contact";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { ReactComponent as DownloadIcon } from "../../../../lib/assets/icons/download.svg";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IFileUploadButtonProps } from "../../../../lib/components/file/FileUploadButton";
import { IResponsiveDataTableProps } from "../../../../lib/components/table/ResponsiveDataTable";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { Permission } from "../../../../models/api/gate/Permission";
import { RequestAttachmentsService } from "../../services/RequestAttachmentsService";
import { RequestDetails } from "../../models/domain/RequestDetails";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useIdentityUserDisplayName } from "../../../../lib/infrastructure/identity/hooks/useIdentityUserDisplayName";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { ReactComponent as SendEmailIcon } from "../../../../lib/assets/icons/send.svg";
import { useServiceCallPro2 } from "../../../../services/UseServiceCall";

var attachmentsSvc = new RequestAttachmentsService();


export interface Attachment {
  id: number;
  name: string;
  creatorName: string;
  creatorEmail: string;
}

interface IAddAttachmentsProps {
  className?: string;
  locationId: string | undefined;
  requestId: string | undefined;
  contactsInfo: Contact[] | undefined;
  isLoading: boolean;
  onTableRowClick?: () => void;
  requestDetails: RequestDetails | undefined;
  attachmentsInfo: Attachment[] | undefined;
  onCompletedOperations: () => void;
}

const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];
const permissionsToManagerAttachments = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS, Permission.LOCATION_ANALISYS_REQUEST_GLOBAL];



export function AddAttachments(props: IAddAttachmentsProps) {
  const useIdentity = useIdentityUserDisplayName();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const createNotification = useCreateNotification();

  const { isLoadingPermissions: isLoadingPermissionManageAttachments, hasPermission: hasPermissionManageAttachments } = useHasPermissions(permissionsToManagerAttachments);
  const { isLoadingPermissions: isLoadingTopPermissions, hasPermission: hasTopPermissions } = useHasPermissions(topPermissions);



  const [sendNotificationReportCompletedCall, sendNotificationReportCompletedCallIsLoading] = useServiceCallPro2(attachmentsSvc, attachmentsSvc.sendNotificationReportCompleted);
  const [deleteAttachmentCall, deleteAttachmentCallIsLoading] = useServiceCallPro2(attachmentsSvc, attachmentsSvc.deleteRequestAttachment);


  const handleDownloadAttachmentBtnClicked = useCallback((fileId: string) => {
    if (!props.locationId || !props.requestId) return;

    attachmentsSvc.getUrlDownloadAttachment(props.locationId, props.requestId, fileId)
      .then((url) => window.open(url, "_blank"))
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })
  }, [props.locationId, props.requestId, openPopup]);



  const handleSendNotificationReportBtnClicked = useCallback(() => {

    if (!props.locationId || !props.requestId) return;

    sendNotificationReportCompletedCall(props.locationId, props.requestId)
      .then((_) => {
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("REQUESTS.NOTIFICATIONS.NotificationSent")}
          />
        );
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })
  }, [props.locationId, props.requestId, createNotification, openPopup, sendNotificationReportCompletedCall]);




  const handleDeleteRequestAttachmentBtnClicked = useCallback((fileId: string) => {

    if (!props.locationId || !props.requestId) return;


    deleteAttachmentCall(props.locationId, props.requestId, fileId)
      .then((_) => {
        props.onCompletedOperations && props.onCompletedOperations();
        createNotification(<Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate("REQUESTS.INFOANDFORM_2.DeleteAttachmentSuccess")} />);
      }).catch(error => {

        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [props.locationId, props.requestId, deleteAttachmentCall,
  props.onCompletedOperations, createNotification, openPopup]);



  const hasInsertAttachmentsPermissions = useMemo(() => {

    if (isLoadingPermissionManageAttachments || !props.requestDetails?.requestStatus) return false;

    var authorHasPermission = props.requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()
      && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED)

    var otherActorsHavePermission = hasPermissionManageAttachments
      && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

    return (authorHasPermission || otherActorsHavePermission)

  }, [isLoadingPermissionManageAttachments, props.requestDetails?.requestStatus, props.requestDetails?.createdBy?.email, useIdentity.userDisplayEmail,
    hasPermissionManageAttachments]);



  const hasRemoveBasePermissions = useMemo(() => {

    if (isLoadingTopPermissions || !props.requestDetails?.requestStatus) return false;

    var basePermissions = hasTopPermissions
      && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

    return (basePermissions)

  }, [isLoadingTopPermissions, props.requestDetails?.requestStatus, hasTopPermissions]);




  const hasPermissionToSendEmailNotification = useMemo(() => {

    if (isLoadingTopPermissions || !props.requestDetails?.requestStatus) return false;

    return hasTopPermissions && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DRAFT && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.REGISTERED);

  }, [isLoadingTopPermissions, props.requestDetails?.requestStatus, hasTopPermissions]);




  const isTheAuthor = useCallback((creatorEmail: string) => {

    var authorHasPermission = useIdentity.userDisplayEmail?.toLowerCase() === creatorEmail.toLowerCase();

    return (authorHasPermission && (props.requestDetails?.requestStatus === AnalysisRequestStatusEnum.DRAFT || props.requestDetails?.requestStatus === AnalysisRequestStatusEnum.REGISTERED))

  }, [useIdentity.userDisplayEmail, props.requestDetails?.requestStatus]);




  const hasDownloadAttachmentsPermission = useMemo(() => {
    var hasPermission = true;

    if (isLoadingPermissionManageAttachments)
      hasPermission = false;

    if (hasPermissionManageAttachments)
      hasPermission = true;

    var authorHasPermission = props.requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase();

    var requestContactLoggedHasPermission = props.contactsInfo?.some((user) => user.email.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase());

    return hasPermission || authorHasPermission || requestContactLoggedHasPermission;
  }, [isLoadingPermissionManageAttachments, hasPermissionManageAttachments,
    props.requestDetails, useIdentity.userDisplayEmail, props.contactsInfo]);



  const columns: ColumnDefinition<Attachment>[] = useMemo(() => [
    {
      cellRenderProp: (v) => v.name,
      headerRender: translate("REQUESTS.INFOANDFORM.FileName"),
      columnKey: "name",
      isMobilePrimaryCell: true,
      className: "word-break"
    },
    {
      cellRenderProp: (v) => v.creatorName,
      headerRender: translate("REQUESTS.INFOANDFORM.AddedAttachmentBy"),
      columnKey: "creatorName",
      isMobilePrimaryCell: true,
    },
    {
      cellRenderProp: (v) => (
        <ScalableIconButton
          size={24}
          icon={<DownloadIcon />}
          isDisabled={hasDownloadAttachmentsPermission ? false : true}
          onClick={() => {
            handleDownloadAttachmentBtnClicked(`${v.id}`);
          }}
        />
      ),
      columnKey: "download",
      width: "24px",
      isMobileHeaderIcon: true,
    },
    {
      cellRenderProp: (v) => (<ScalableIconButton size={24} isDisabled={(hasRemoveBasePermissions || isTheAuthor(v.creatorEmail)) ? false : true} icon={<DeleteIcon />} onClick={() => {
        openPopup(
          <WarningPopup
            onDismissClicked={() => { closePopup(); }}
            onConfirmClicked={() => { handleDeleteRequestAttachmentBtnClicked(`${v.id}`) }}
            dismissText={translate("COMMON.Cancel")}
            confirmText={translate("REQUESTS.INFOANDFORM_2.DeleteAttachment")}
            className="button-red"
          >
            {translate("REQUESTS.INFOANDFORM_2.DeleteAttachmentConfirmText", v.name)}
          </WarningPopup>
        );
      }} />),
      columnKey: "delete",
      width: "24px",
      isMobileHeaderIcon: true,
    }
  ], [handleDownloadAttachmentBtnClicked, hasDownloadAttachmentsPermission, isTheAuthor,
    handleDeleteRequestAttachmentBtnClicked, openPopup, closePopup, hasRemoveBasePermissions]);



  const attachmentsTableInfo: IResponsiveDataTableProps<Attachment> = useMemo(() => {
    return {
      items: props.attachmentsInfo || [],
      columnDefinitions: columns,
      totalitems: 0,
      isLoading: props.isLoading,
      onClickRow: props.onTableRowClick,
    };
  }, [props.onTableRowClick, props.attachmentsInfo, columns, props.isLoading]);



  const addAttachmentsButton: IFileUploadButtonProps = useMemo(() => {
    return {
      text: translate("REQUESTS.ACTIONS.AddAttachments"),
      type: "secondary",
      icon: <AttachmentsIcon />,
      isDisabled: hasInsertAttachmentsPermissions ? false : true,
      onFileSelected: (f: File) => {
        if (!props.locationId || !props.requestId) return;

        attachmentsSvc.uploadRequestAttachment(props.locationId, props.requestId, f)
          .then(res => {
            props.onCompletedOperations && props.onCompletedOperations();
            createNotification(<Notification
              type="success"
              title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("REQUESTS.INFOANDFORM_2.NewAttachmentAdded")} />);
          }).catch(error => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
          });
      }
    };
  }, [isLoadingPermissionManageAttachments, props.requestDetails?.requestId, props.requestDetails?.requestStatus, hasPermissionManageAttachments,
    props.locationId, props.requestId, createNotification, props.requestDetails?.createdBy?.email, props.onCompletedOperations,
    useIdentity.userDisplayEmail, openPopup, hasInsertAttachmentsPermissions]);




  const renderReportSendNotificationBtn = useMemo(() => {

    if (attachmentsTableInfo.items.length > 0 && hasPermissionToSendEmailNotification) {

      return (<Tooltip text={translate("REQUESTS.NOTIFICATIONS.NotifyReportCompletedTooltip")} >
        <IconButton
          icon={<SendEmailIcon className="send-email-icon" />}
          type="secondary"
          onClick={() => {
            openPopup(<WarningPopup
              onDismissClicked={() => { closePopup(); }}
              onConfirmClicked={handleSendNotificationReportBtnClicked}
              dismissText={translate("COMMON.Cancel")}
              confirmText={translate("REQUESTS.NOTIFICATIONS.Notify")}
            >
              {translate("REQUESTS.NOTIFICATIONS.RequestReportCompletedConfirmText")}
            </WarningPopup>
            );
          }}
        /></Tooltip>)
    }
    else return null;


  }, [hasPermissionToSendEmailNotification, handleSendNotificationReportBtnClicked, attachmentsTableInfo.items, openPopup, closePopup]);




  return (<>
    {deleteAttachmentCallIsLoading || sendNotificationReportCompletedCallIsLoading ? <FullScreenLoader /> : null}
    <AddInfoAttachments
      title={translate("REQUESTS.INFOANDFORM.Attachments")}
      button={addAttachmentsButton}
      tableInfo={attachmentsTableInfo}
      className="add-info-attachments"
      isLoading={props.isLoading}
      children={renderReportSendNotificationBtn}
    />
  </>
  );
}