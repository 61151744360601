import "./TitledCardContainer.css";

import { CardContainer } from "./CardContainer";
import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { Loader } from "../../../components/loader/Loader";
import { useMemo } from "react";

interface ITitledCardContainerProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  title?: string;
  extraTitle?: React.ReactNode;
  subtitle?: React.ReactNode;
  loadingCardMinHeigth?: "small" | "medium" | "large";
}


export function TitledCardContainer(props: ITitledCardContainerProps) {
  const popupContainerCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("titled")
      .addConditional(props.className, props.className)
      .addConditional(props.isLoading, "loading")
      .addConditional(props.loadingCardMinHeigth && props.isLoading, props.loadingCardMinHeigth)
      .build();
  }, [props.className, props.isLoading, props.loadingCardMinHeigth]);


  return (
    <CardContainer className={popupContainerCss}>

      <div className="header-container">
        <div className="header">
          <div className="title-left">
            {props.title}</div>
          <div className="title-right">
            {props.extraTitle}
          </div>

        </div>
        {props.subtitle ? <div className="small-copy subtitle">{props.subtitle}</div> : null}

      </div>

      {props.isLoading ? <Loader /> : props.children}



    </CardContainer>);
}



