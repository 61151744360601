import { Optional, ValidatorFunction } from "../../../lib/components/form/Form";

import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";

export function validateIfConditional<T>(condition: boolean): ValidatorFunction<T> {
    return (value: T | undefined) => {
        if (condition && !value) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}



export function validateStringIfConditional(condition: boolean): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {
        if (condition && (!value || value?.trim() === "")) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}