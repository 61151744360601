import { SampleStatusEnum } from "../../features/samples/models/domain/SamplesStatusEnum";
import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export function sampleStatusStateMachine(statusId: number) {

    switch (statusId) {
        case 1:
            return { nextStep: translate("SAMPLES.STATUS.UnderAnalysis"), nextStepID: SampleStatusEnum.UNDERANALYSIS };
        case 2:
            return { nextStep: translate("SAMPLES.STATUS.OnHold"), nextStepID: SampleStatusEnum.ONHOLD };
        case 3:
            return { nextStep: translate("SAMPLES.STATUS.UnderAnalysis"), nextStepID: SampleStatusEnum.UNDERANALYSIS };
        default:
            return null;
    }
}
