import "./RequestDetailsCard.css";

import { InternationalizationService, translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";

import { ClientRegistrationTranslationTerms } from "../../../models/domain/ClientRegistrationEnum";
import { DateFormatByCurrentLanguageWithHours } from "../FormatDateByCurrentLocal";
import { DateTime } from "luxon";
import { IRequestDetailsCardProps } from "./ARRequestDetailsCard";
import { InfoBlock } from "../../../../../lib/components/info-block/InfoBlock";
import { RenderCancelationReason } from "./RenderCancelationReason";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { TitledCardContainer } from "../../../../../lib/layouts/containers/card/TitledCardContainer";
import { requestStatusCss } from "../../../../../common/helpers/RequestStatusCss";
import { requestStatusLabel } from "../../../../../common/helpers/RequestStatusLabel";
import { useMemo } from "react";

export function DFRequestDetailsCard(props: IRequestDetailsCardProps) {

/*     const formattedDate = useMemo(() => {
        if (props.reqDetails?.formalizingDate)
            return DateFormatByCurrentLanguageWithHours(props.reqDetails?.formalizingDate);
        return "";
    }, [props.reqDetails.formalizingDate]); */


    const renderRequestStatusTag = useMemo(() => {
        if (props.reqDetails.requestStatus)
            return <Tag
                text={requestStatusLabel(props.reqDetails.requestStatus ? props.reqDetails.requestStatus : -1)}
                backgroundColor={requestStatusCss(props.reqDetails.requestStatus ? props.reqDetails.requestStatus : -1)}
            />
        return null;
    }, [props.reqDetails.requestStatus])





    const renderRequestCreatorInfo = useMemo(() => {
        if (props.reqDetails.formalizingDate && props.reqDetails.createdBy?.name) {
            return (
                <>
                    {translate("REQUESTS.INFOANDFORM_2.RequestFormalized")}: {props.reqDetails.createdBy?.name}{" "}
                    {translate("COMMON.PrepositionForDateTime")}{" "}
                    {DateTime.fromISO(props.reqDetails.formalizingDate).toFormat(
                        "dd LLL yyyy HH'h'mm",
                        { locale: InternationalizationService.getLocale() }
                    )}
                </>
            );
        }

        else if (!props.reqDetails.createdDate || !props.reqDetails.createdBy?.name) return;

        return (
            <>
                {translate("REQUESTS.INFOANDFORM_2.DraftCreatedBy")}: {props.reqDetails.createdBy?.name}{" "}
                {translate("COMMON.PrepositionForDateTime")}{" "}
                {DateTime.fromISO(props.reqDetails.createdDate).toFormat(
                    "dd LLL yyyy HH'h'mm",
                    { locale: InternationalizationService.getLocale() }
                )}
            </>
        );
    }, [props.reqDetails.createdDate, props.reqDetails.createdBy, props.reqDetails.formalizingDate]);



    return (
        <TitledCardContainer title={translate("REQUESTS.RequestData")} className="request-details-main-container"
            extraTitle={renderRequestStatusTag}
            subtitle={renderRequestCreatorInfo}>

            <RenderCancelationReason reqDetails={props.reqDetails} />


            <div className="request-details-form">

                <div className="request-info-blocks">
                    {props.reqDetails?.reference ? <InfoBlock label={translate("REQUESTS.INFOANDFORM.Reference")} value={props.reqDetails?.reference}></InfoBlock> : null}
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.Name")} value={props.reqDetails?.name || "-"}></InfoBlock>
{/*                     <InfoBlock label={translate("REQUESTS.INFOANDFORM.FormalizingDate")} value={formattedDate}></InfoBlock> */}
                </div>


                <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.Client")}</div>
                <div className="request-info-blocks">
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.Company")} value={props.reqDetails.company || "-"}></InfoBlock>
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.Factory")} value={props.reqDetails.factory || "-"}></InfoBlock>
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM_2.ClientRegistration")}
                        value={props.reqDetails?.clientRegistration ?
                            translate(ClientRegistrationTranslationTerms[props.reqDetails?.clientRegistration]) : "-"}></InfoBlock>
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.ClientNumber")} value={props.reqDetails?.clientRegistrationDescription || "-"}></InfoBlock>
                </div>

                <div className="request-info-blocks">
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.Comments")} value={props.reqDetails.customerComments || ""}></InfoBlock>
                </div>

                <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.EquipmentInformation")}</div>
                <div className="request-info-blocks">
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.ContainsHazardMaterial")}
                        value={props.reqDetails.hasHazardousMaterial
                            ? translate("COMMON.Yes") : translate("COMMON.No") || "-"} />
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.HazardousMaterials")} value={props.reqDetails.hazardousMaterialDescription || "-"}></InfoBlock>
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.ContainsCarcinogenicSubstances")}
                        value={props.reqDetails.hasCarcinogenicSubstances
                            ? translate("COMMON.Yes") : translate("COMMON.No") || "-"} />
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.CarginogenicSubstances")} value={props.reqDetails.carcinogenicSubstancesDescription || "-"}></InfoBlock>
                </div>

                <div className="request-info-blocks">
                    <InfoBlock label={translate("REQUESTS.INFOANDFORM.FurtherInformation")}
                        value={props.reqDetails.otherInformations || "-"} />
                </div>
            </div>
        </TitledCardContainer >

    );
}