import { ILanguage } from "./ILanguage";

export const languageEN: ILanguage = {
  COMMON: {
    ProjectName: "LAMP",
    Continue: "Continue",
    Yes: "Yes",
    No: "No",
    Cancel: "Cancel",
    Create: "Create",
    Add: "Add",
    Activate: "Activate",
    Import: "Import",
    Change: "Change",
    Suspend: "Suspend",
    Save: "Save",
    Edit: "Edit",
    Delete: "Delete",
    Remove: "Remove",
    Deactivate: "Deactivate",
    Logout: "Logout",
    Optional: "Optional",
    Registar: "Create",
    PrepositionForDateTime: " on ",
    CreatedBy: "Registed by",
    CreatedOn: "Registed on",
    All: "All",
    Clear: "Clear",
    Deliver: "Deliver",
    OtherActions: "Other actions",
    Description: "Description",
    CloneAction: "Clone Sample",
    CloneRequestAction: "Clone Request",
    CloneRequestSample: "Clone Request Sample",
    SelectOneOptionPlaceholder: "Select one option",
    Name: "Name",
    Back: "Back",



    FORM: {
      Details: "Details",
      Search: "Search",
      SearchPlaceholder: "Search",
      InitialDate: "Initial Date",
      InitialDatePlaceholder: "Initial Date",
      InitialCreationDatePlaceholder: "Initial Creation Date",
      FinalDate: "Final Date",
      FinalDatePlaceholder: "Final Date",
      FinalCreationDatePlaceholder: "Final Creation Date",
      RequestDatePlaceholder: "Select a date",
      DefaultPlaceholder: "Type Here",
      SelectDefaultPlaceholder: "Type to search",
      SelectStatusPlaceholder: "Select status",
      Filters: "Filters",
      CleanFilters: "Reset Filters",
      ClearSelection: "Clear selection",
      Sunday: "Su",
      Monday: "Mo",
      Tuesday: "Tu",
      Wednesday: "We",
      Thursday: "Th",
      Friday: "Fr",
      Saturday: "Sa",
      MoreOptionsBtn: "View more...",

      VALIDATIONS: {
        CannotBeEmpty: "This field cannot be empty.",
        RequiredField: "This field is required.",
        GuidValidator: "Invalid GUID format.",
        SelectOneOption: "Select an option",
        BatchFormatError: "Must contain the following format: ####### or #######/# or #######/##",
        FillMandatoryFields: "Please fill the mandatory fields",
        InvalidLocationId: "Location ID is invalid.",
        InvalidRequestId: "Analysis Request ID is invalid.",
        PositiveNumber: "Insert an integer number",
        PositiveNumberOrDecimal: "Insert an integer number / decimal",
        SelectDate: "Please select a date"
      }
    },
    POPUPS: {
      ImportOptions: "Import Options",
      ExcelTemplate: "Excel Template",
      ExcelTemplateText: "Click the button below to download an Excel template file. This template contains the correct structure for data import.",
      Import: "Import",
      ImportText: "Click the button below to select an Excel file and start the import process. Make sure you select the correct file.",
    },

    ERRORPAGES: {
      SomethingWrong: "Something Went Wrong",
      BackToHome: "Back to Home",
      AccessDenied: "Access Denied",
      AccessDeniedText1: "You do not have permission to view this page!",
      AccessDeniedText2: "Please check your credentials and try again.",
      PageNotFound: "Page Not Found",
      PageNotFoundText: "We can't seem to find the page you are looking for.",
      SessionExpired: "Session Expired",
      SessionExpiredText: "Your session has expired due to your inactivity.",
      Login: "Login",
    },

    SYSTEMPOPUPS: {
      Error: "Error!",
      ErrorText: "",
      Success: "Success!",
      SuccessText: "",
      Warning: "Warning",
      WarningText: "",
    },

    TABLE: {
      NoResults: "No results found",
      NoResultsText: "We couldn't find what you're looking for, please search again",
      RowsPerPage: "Rows per page",
      Of: "of",
    }
  },


  SAMPLES: {
    Samples: "Samples",
    DeliverSample: "Deliver Sample",
    ViewSamplesOnHold: "Samples On Hold",
    ViewSamples: "View Samples",
    SamplesInLaboratory: "Samples in Laboratory",
    LastSamplesDelivered: "Last Samples Delivered",
    OnHoldSample: "Suspend Analysis",
    RejectSample: "Reject Sample",
    SampleDetails: "Sample Details",
    SampleData: "Sample Data",
    DeliverNewSample: "Deliver New Sample",
    MySamples: "My Samples",
    ControlZeroFour: "Control 04",
    ChangeTo: "Change to",

    STATUS: {
      TotalNotFinished: "Total Not Finished",
      MultipleRegisted: "Registed",
      Registed: "Registed",
      UnderAnalysis: "Under Analysis",
      OnHold: "On Hold",
      MultipleRejected: "Rejected",
      Rejected: "Rejected",
      MultipleFinished: "Finished",
      Finished: "Finished",
      WithRequestDate: "Request Date",
      WithRequestDateExtended: "Request Date",
      TotalPending: "Total pending",
      PendingWithRequestDate: "Pending w/ Request Date",
      ProcessedCurrentYear: "Processed in Current Year"

    },

    INFOANDFORM: {
      RegistrationDate: "Registration Date",
      RemoveCorrelationConfirmText: "Are you sure you pretend to remove this correlation?",
      RemoveCorrelationSuccessMessage: "Correlation removed.",
      RemoveCorrelation: "Remove Correlation",
      RemoveCorrelationAbrev: "Remove",
      Section: "Section",
      Employee: "Operator",
      EmployeePlaceholder: "Select one option",
      AreaPlaceholder: "Select area type",
      Material: "Material",
      MaterialPlaceholder: "Type a material",
      SAPMaterial: "SAP Material",
      SAPMaterialPlaceholder: "Select a material",
      Batch: "Batch",
      BatchPlaceholder: "Ex: 6258559",
      Name: "Name",
      NamePlaceholder: "Type your name",
      Type: "Type",
      TypePlaceholder: "Select type",
      Description: "Description",
      DescriptionPlaceholder: "",
      Analyst: "Analist",
      SelectAnalyst: "Select Analyst",
      AnalystPlaceholder: "",
      CreationDate: "Creation Date",
      RequestDate: "Request Date",
      RequestDateSummary: "Req. Date",
      Waybill: "Waybill",
      WaybillPlaceholder: "Ex: WB2023/01",
      Status: "Status",
      TimeToEdit: "Time to edit ",
      ProductionOrder: "Production Order",
      ProductionOrderPlaceholder: "Ex: 6262292",
      Observations: "Observations",
      ObservationsPlaceholder: "Type your observations",
      RequestedBy: "Request by",
      RejectReason: "Rejection Reason",
      OnHoldReason: "On hold Reason",
      Supplier: "Supplier",
      SupplierPlaceholder: "",
      NumSpools: "No. of Spools",
      NumSpoolsPlaceholder: "",
      Facility: "Facility",
      FacilityPlaceholder: "Select a facility",
      Reason: "Reason",
      SelectReasonPlaceholder: "Select a reason",
      ExecuteControlZeroFour: "Execute Control 04",
      Control04: "Control 04",
      LastControlZeroFour: "Last execution",
      NoControls04Found: "Control 04 will be executed for the first time in this material.",
      Control04Active: "C04 Activated",
      Control04ActiveTooltip: "Control 04 Ativated",
      Control04Needed: "C04 Needed",
      Control04NeededTooltip: "Control 04 Needed",
      Repeated: "Repeated Sample",
      Source: "Source",
      SourcePlaceholder: "Ex: VLMT Guangzhou",
      ExecutedOn: "Executed on",
      Pending: "Pending",
      PendingControls04: "Pending Controls 04",
      LastControl04: "Last Control 04",
      Clone: "Cloning",
      Manufacturer: "Manufacturer",
      Used: "Used",
      AssociatedRequest: "Associated Request",
      RequestSampleData: "Request Sample Data",
      Area: "Area",
    },
    TYPES: {
      Production: "Production",
      Fibers: "Fibers",
      Produced: "Outsourced",
      Accessories: "Accessories",
      Threads: "Yarns",
      Others: "Others",
      Package: "Package",
    },

    TYPESENUM: {
      PRODUCTION: "Production",
      FIBERS: "Fibers",
      PRODUCED: "Outsourced / Finished Outside",
      ACCESSORIES: "Accessories",
      THREADS: "Yarns",
      OTHERS: "Others",
      PACKAGE: "Package"
    },

    PACKAGEAREATYPES: {
      DF: "DF",
      Dryer: "Dryer",
      LP: "LP",
      Minning: "Minning",
      Pulp: "Pulp"
    },

    OTHERTYPESENUM: {
      ACCESSORIES: "Accessories",
      FABRICS: "Fabrics",
      FELTS: "Felts",
      FIBERS: "Fibers",
      THREADS: "Yarns",
      OTHERS: "Others"
    },

    ACTIONS: {
      Analyse: "Analyse",
      Reject: "Reject",
      OnHold: "On Hold",
      Finish: "Finish",
      Revert: "Revert to Registed",
    },

    POPUPS: {
      PossibleCorrelationFoundText: "A possible correlation was found. Do you want to correlate?",
      CorrelateSampleConfirmText: "Do you prented to correlate this new sample?",
      CreateSampleSuccess: "Sample created.",
      UpdateSampleSuccess: "Sample updated.",
      UpdateRequisitionDateSuccess: "Requistion date updated.",
      ChangeSampleStatusConfirmText: "Are you sure you want to change the sample status to",
      EditSampleDetails: "Edit Sample",
      EditSampleDetailsSuccess: "Sample details edited!",
      EditSampleDetailsError: "Error editing sample details!",
      StatusChangedSuccess: "Sample status changed.",
      RevertSampleStatusConfirmText: "Are you sure you want to revert the sample status to Registed?",
      DELIVERPRODUCTION: {
        ProductionOrderFormatError: "Must contain seven characters!",
      }
    }
  },


  REQUESTS: {
    New: "New",
    Used: "Used",
    Requests: "Requests",
    ViewRequests: "View Requests",
    AddNewSample: "Add Request Sample",
    CreateRequest: "Create Request",
    RequestDetails: "Request Details",
    CustomerData: "Customer Data",
    OtherData: "Other Data",
    RequestData: "Request Data",
    SubmitRequest: "Submit Request",
    Pending: "Pending",
    CloseRequest: "Close Request",
    RequestClosed: "Request Closed",
    ReopenRequest: "Reopen Request",
    RequestReopened: "Request Reopened",
    MyRequests: "My Requests",
    ContactsWarning: "Always check that the email is correct before adding.",

    
    NOTIFICATIONS:{
      Notify: "Notify",
      NotificationSent: "Notification Sent",
      NotifyReportCompletedTooltip: "Notify that the report is completed",
      RequestReportCompletedConfirmText: "Are you sure you want to notify the requester and associated contacts that the request report is completed?" 
    },

    CLIENTREGISTRATIONENUM: {
      NotRegistered: "Not Registered",
      CompassRegistered: "Registered in Compass",
      SAPRegistered: "Registered in SAP"
    },

    ANALYSISREASONTYPE: {
      AnalysisReasonType: "Analysis Reason Type",
      Competitor: "Competitor",
      Complaint: "Complaint",
      Development: "Development",
      EndLife: "End of Life",
      Others: "Others"
    },

    STATUS: {
      TotalNotFinished: "Total Not Finished",
      Pending: "Pending",

      OnHold: "On Hold",

      Registed: "Registered",
      RegistedSingular: "Registered",
      Canceled: "Canceled",
      CanceledSingular: "Canceled",
      Draft: "Drafts",
      DraftSingular: "Draft",
      UnderAnalysisSingular: "Under Analysis",
      UnderAnalysis: "Under Analysis",
      ReadyToClose: "Concluded",
      ReadyToCloseSingular: "Completed",
      Finished: "Closed",
      FinishedSingular: "Closed",
    },

    TYPES: {
      AR: "AR",
      WF: "WF",
      DF: "DF",
      PP: "PP",
      DRYER: "DRYER",
      Dryer: "Dryer",

    },

    INFOANDFORM: {
      RequestType: "Request Type",
      Reference: "Request No.",
      KetoProject: "Keto Project",
      KetoProjectPlaceholder: "Describe Keto Project",
      MaterialReferencePlaceholder: "Type material reference",
      Sample: "Sample",
      CarginogenicSubstances: "Carginogenic Substances",
      HazardousMaterials: "Hazardous Materials",
      DeliveredByCourier: "Delivered by courier",
      DeliveredPersonally: "Delivered personally",
      Transport: "Transport",
      PerformanceComments: "Comments about the fabric, performance, etc",
      RemovalReason: "Removal reason",
      RemovalReasonPlaceholder: "Type removal reason",
      InstalledDate: "Installed Date",
      InstalledDatePlaceholder: "Select installed Date",
      RemovedDate: "Removed Date",
      RemovedDatePlaceholder: "Select removed Date",
      RunningTime: "Running Time",
      Duration: "Duration",
      DurationPlaceholder: "Type running time",
      ProvideSlurrySafetySheet: "PROVIDE THE SLURRY MATERIAL SAFETY DATA SHEET IF POSSIBLE",
      MachineInformation: "Machine Information",
      PositionMachine: "PM No.",
      PositionMachinePlaceholder: "Type PM No.",
      Position: "Position",
      PositionPlaceholder: "Type the position",
      Description: "Description",
      Factory: "Manufacturer",
      FactoryPlaceholder: "Type the manufacturer",
      Client: "Client",
      Name: "Subject",
      NamePlaceholder: "Type the request subject",
      Type: "Type",
      NumSamples: "No. Samples",
      Requester: "Requester",
      Analyst: "Analyst",

      CreationDate: "Creation Date",
      FormalizingDate: "Formalizing Date",
      Status: "Status",
      Company: "Company",
      CompanyPlaceholder: "Type company name",
      Industry: "Industry",
      IndustryPlaceholder: "Type the industry",
      ProductDescription: "Product Description",
      ProductDescriptionPlaceholder: "Type a product description",
      ClientNumber: "Client Number",
      ClientNumberPlaceholder: "Type the client number",
      Manufacturer: "Manufacturer",
      ManufacturerPlaceholder: "Type the manufacturer",
      Material: "Material",
      MaterialPlaceholder: "Type the material",
      Other: "Other",
      OtherPlaceholder: "Type which one",
      SAPMaterial: "SAP Material",
      SAPMaterialPlaceholder: "Select a material",
      New: "New",
      CompareWith: "Compare With",
      CompareWithPlaceholder: "Select an option",
      Observations: "Observations",
      ObservationsPlaceholder: "Type your observations",
      Comments: "Comments",
      CommentsPlaceholder: "Type your comments",
      NameDetailText: "Name entered by the request creator",
      DescriptionDetailText: "Description entered by the request creator",
      AwaitingReceipt: "Awaiting Receipt",
      TestsSamplesAssociations: "Tests/Samples Associations",
      Cost: "Cost",
      TotalCost: "Total Cost",
      Attachments: "Attachments",
      FileName: "File Name",
      UsedMaterial: "Used Material",
      Waybill: "Waybill",
      WaybillAbrev: "Waybill",
      WaybillPlaceholder: "Type waybill",
      MaterialDescriptionPlaceholder: "Select a material",
      MaterialDescription: "Material/Description",
      TargetSAP: "Target SAP",
      TechNote: "Technical Note",
      TargetOrTechNote: "Target SAP or Technical Note",
      MaterialStatus: "Material Status",
      MaterialStatusPlaceholder: "Select material status",
      TargetOrTechNotePlaceholder: "Select an option",
      AnalysisReason: "Analysis Reason",
      AnalysisReasonPlaceholder: "Type the reason for the analysis",
      RegistedOnCompass: "Registed on Compass",
      SAPNumberOrCompass: "SAP number/Compass",
      SAPNumberOrCompassPlaceholder: "Type a SAP number/Compass",
      Shipping: "Transport",
      ShippingMethod: "Shipping method",
      DeliverByCourier: "Deliver by Courier",
      DeliverPersonally: "Deliver personally",
      EquipmentInformation: "Equipment Information",
      IndustrialSector: "Industrial Sector",
      IndustrialSectorDescription: "Other Industrial Sector",
      FilterType: "Filter Type",
      FilterTypeDescription: "Other Filter Type",
      Slurry: "Slurry",
      SlurryPlaceholder: "Provide more information",
      ContainsHazardMaterial: "Process contains hazardous materials",
      ContainsHazardMaterialPlaceholder: "Type the hazardous material",
      ContainsCarcinogenicSubstances: "Process contains carcinogens",
      ContainsCarcinogenicSubstancesPlaceholder: "Type the carcinogenic substances",
      FurtherInformation: "Further information",
      FurtherInformationPlaceholder: "Provide more information",
      AddedAttachmentBy: "Added by",
      Clone: "Cloning",
      CancelReason: "Cancellation reason",
      AssociateTestsWarning: "For resistance and shrinkage tests, the recommended size for conducting this test is 50cm across the entire width or two A3 samples",
    },

    INFOANDFORM_2: {
      Draft: "Draft",
      DraftPlural: "Drafts",
      NewAttachmentAdded: "New attachment added!",
      DeleteAttachment: "Remove Attachment",
      DeleteAttachmentConfirmText: "Are you sure you pretend to remove the attachment '{0}'? Data will be lost permanently!",
      DeleteAttachmentSuccess: "Attachment removed.",
      Contacts: "Contacts",
      Email: "Email",
      EmailPlaceholder: "Type the contact email",
      NamePlaceholder: "Type the contact name",
      DeleteContact: "Remove Contact",
      DeleteContactConfirmText: "Are you sure you pretend to remove the contact with email '{0}'?",
      DeleteContactSuccess: "Contact removed.",
      TestRemoved: "Test removed.",
      ClientRegistration: "Client Registration",
      AddSampleSuccess: "New sampled added!",
      DestroysSample: "Destroys Sample",
      CorrelatedSample: "Correlated Sample",
      DraftCreatedBy: "Draft created by",
      RequestFormalized: "Formalized request",
      AssociatedTests: "Associated tests"
    },

    ACTIONS: {
      AddTests: "Add Tests to the Request",
      AddSample: "Add Request Sample",
      AddAttachments: "Add Attachments",
      AddContacts: "Add Contacts"
    },

    POPUPS: {
      MaterialRef: "Material Reference",
      SampleType: "Sample Type",
      Observations: "Observations",
      Waybill: "Waybill",
      RemoveCorrelationConfirmText: "Are you sure you pretend to remove correlation on this sample?",
      RemoveCorrelationSuccess: "Sample correlation removed!",
      AssociateTestsWithSample: "Associate Tests with Sample",
      SampleStatus: "Sample Status",
      RemoveSample: "Remove Sample",
      EditSample: "Edit Sample",
      RemoveTest: "Remove Test",
      CloneSampleConfirmText: "Are you sure you pretend to clone this sample in this Analysis Request?",
      RemoveSampleConfirmText: "Are you sure you pretend to remove the sample with material '{0}'? Sample data will be lost!",
      RemoveTestConfirmText: "Are you sure you pretend to remove the test with the name '{0}'? Test data will be lost!",
      AddContact: "Add Contact",
      CancelTestConfirmText: "Are you sure you want to cancel this Request? All data will be lost",
      SubmitRequestConfirmText: "Are you sure you want to submit this Request?",
      Reason: "Reason",
      ReasonPlaceholder: "Type the reason for cancel request",
      CancelRequest: "Cancel Request",
      CancelReq: "Cancel Req.",
      CancelRequestSuccess: "Request canceled!",
      SubmitRequest: "Submit Request",
      SubmitRequestSuccess: "Request submitted!",
      CreateRequestSuccess: "Request created.",
      TestSampleAssociation: "Associate Tests to Sample",
      SelectedSample: "Selected Sample",
      NumSelectedTests: "No. Selected Tests",
      Associate: "Associate",
      CorrelateSample: "Correlate Sample",
      SelectSampleToCorrelate: "Select the sample to correlate",
      RemoveCorrelation: "Remove Correlation",
      Correlate: "Correlate",
      TestAddedToRequestSuccess: "Test added to the request!",
      NoSelectedTests: "Number of selected tests",
      TotalCost: "Total Cost",
      EditRequestDetails: "Edit Request",
      EditRequestDetailsSuccess: "Request updated.",
    },
  },

  GLOBALADMIN: {
    GlobalAdmin: "Global Administration",

    EMPLOYEES: {
      Employees: "Operators",
      LabOperators: "Lab Operators",
      LabOperator: "Lab Operator",

      INFOANDFORM: {
        Status: "Status",
        AddLabOperator: "Add Operator",
        EditLabOperator: "Edit Operator",
        Code: "Code",
        Division: "Section",
        Name: "Name",
        EmployeeDetails: "Operator Details",
        Disabled: "Disabled",
        Enabled: "Enabled"
      },
      MESSAGES: {
        ImportFileSuccess: "Import File concluded",
        AddOperatorSuccess: "Operator added",
        EditOperatorSuccess: "Operator edited",
        DisableEmployeeConfirmText: "Are you sure you pretend to deactivate '{0}'?",
        EnableEmployeeConfirmText: "Are you sure you pretend to activate '{0}'?",
        EmployeeStatusSuccess: "Status {0}!"
      },
      INFO: {
        Enabled: "Enabled",
        Disabled: "Disabled",
      }
    },

    LOCATIONS: {
      Locations: "Locations",
      CreateLocation: "Create Location",
      AccessGroups: "Access Groups",

      INFO: {
        Name: "Name",
        NamePlaceholder: "Type the location name",
        NumGroups: "No. Groups",
        Type: "Type",
      },

      POPUP: {
        Activate: "Activate",
        ActivateConfirmText: "Are you sure you want to activate the location?",
        ActivateLocationSuccess: "Location activated",
        ActivateLocationError: "Error activating location!",
        CreateLocationSuccess: "Location created!",
        EditLocation: "Edit Location",
        EditLocationError: "Error editing location!",
        EditLocationSuccess: "Location edited!",
        DeactivateLocationError: "Error deactivating location!",
        Deactivate: "Disable",
        DeactivateConfirmText: "Are you sure you want to disable the location?",
        DeactivateLocationSuccess: "Location deactivated!"
      },

      ACCESSGROUPS: {
        Type: "Type",
        Name: "Name",
      },
    },

    TESTTYPES: {
      TestTypes: "Test Types",
      INFOANDFORM: {

        CreateTestType: "Create Test Type",
        EditTestType: "Edit Test Type",
        NamePlaceholder: "Ex: Felts",
        Name: "Name",
        NamePT: "Name (pt-PT)",
        NameEN: "Name (en-EN)",
        Description: "Description",
      },
      POPUP: {
        Activate: "Activate",
        ActivateConfirmText: "Are you sure you want to activate this test type?",
        ActivateTestTypeSuccess: "Test type activated.",
        CreateTestTypeSuccess: "Test type created!",
        EditTestTypeSuccess: "Test type edited!",
        Deactivate: "Deactivate",
        DeactivateConfirmText: "Are you sure you want to disable this test type?",
        DeactivateTestTypeSuccess: "Test type deactivated!"
      },
    },

    FACILITIES: {
      CreateFacility: "Create Facility",
      Facilities: "Facilities",
      INFOANDFORM: {
        Name: "Name",
      },
      POPUP: {
        ActivateFacilityConfirmText: "Are you sure you want to activate the facility?",
        ActivateFacilityError: "Error activating facility!",
        ActivateFacilitySuccess: "Facility activated!",
        CreateFacilitySuccess: "Facility created!",
        DeactivateFacilityConfirmText: "Are you sure you want to disable the facility?",
        DeactivateFacilityError: "Error deactivating facility!",
        DeactivateFacilitySuccess: "Facility deactivated!",
        Deactivate: "Disable",
        EditFacilityError: "Error while updating facility!",
        EditFacility: "Edit Facility",
        EditFacilitySuccess: "Facility edited!",
        LoadingFacilityError: "Error while loading facility information."
      }
    },
    MATERIALS: {
      Materials: "Materials",
      INFO: {
        Plant: "Plant",
        Name: "Name",
        Code: "Material Code",
        Description: "Description",
        SubjectToC04: "Subject to Control 04",
        RemoveMaterialToC04: "Remove Material subjection to Control 04",
        Control04: "Control 04",
        Status: "Status",
        SubjectMaterialToC04: "Subject material for Control 04",
        RemoveSubjection: "Remove subjection",
        Subject: "Subject",
        RemoveSubjectMaterialToC04ConfirmMessage: "Are you sure you want to remove the subjecting of this material for Control 04?",
        RemoveSubjectMaterialToC04SuccessMessage: "Material subjection for Control 04 removed",
        SubjectMaterialToC04ConfirmMessage: "Are you sure you want to subject this material for Control 04?",
        SubjectMaterialToC04SuccessMessage: "Material subjection for Control 04 added"

      },
      POPUP: {
        CreateMaterial: "Create Material",
        CreateMaterialSuccess: "Material created!",
        EditMaterialSuccess: "Material edited!",
        EditMaterial: "Edit Material",
        ActivateMaterialConfirmText: "Are you sure you want to activate this material?",
        DeleteMaterialConfirmText: "Are you sure you want to delete this material?",
        DeactivateMaterialConfirmText: "Are you sure you want to deactivate this material?",
        Deactivate: "Disable",
        DisableMaterialSuccess: "Material deactivated!",
        ActivateMaterialSuccess: "Material activated!",
        ImportFileSuccess: "Import File concluded",
      }
    },
    ACCESSGROUPS:
    {
      Type: "Type",
      ID: "ID",
      Name: "Name",
      EditedGroupSuccess: "Access groups edited!",
      AccessGroups: "Access Groups",
      SubmitError: "Please, verify errors in the form!",
      TYPES: {
        ACCESS: "General Access",
        REGISTER_SAMPLES: "Deliver Sample",
        ADMIN: "Local Administrator",
        ANALISTS: "Analyst",
        ANALISYS_REQUEST_GLOBAL: "Requests Supervision",
        AR: "AR",
        DF: "DF",
        DRYER: "Dryer",
        PP: "PP",
        WF: "WF"
      }
    },
    TESTS: {
      Tests: "Tests",

      INFO: {
        FilteredByNonUsed: "Available tests for non-used samples",
        FilteredByUsed: "Available tests for used samples",
        DestroysSample: "Sample will be destroyed",
        Type: "Type",
        Usage: "Usage",
        Essays: "Essays",
        EssaysPlaceholder: "Ex: Count/DTex",
        Tests: "Tests",
        TestsPlaceholder: "Ex: Count/DTex",
        Multiplier: "Multiplier DF",
        MultiplierSimplified: "Multi. DF",
        MultiplierPlaceholder: "Ex: ",
        RehearsalTime: "Test Duration (h)",
        RehearsalTimeSimplified: "Test duration",
        RehearsalTimePlaceholder: "Ex: 0.2",
        WorkedHours: "Work Hours (h)",
        WorkedHoursSimplified: "Work Hours",
        WorkedHoursPlaceholder: "Ex: 0.2",
        SampleSize: "Sample Size",
        SampleSizePlaceholder: "Ex: 10 x 10 mm",
        DestroyingBarOrFilter: "Sample Dest.",
        DestroyingBarOrFilterPlaceholder: "Sample Destruction",
        Cost: "Cost (€)",
        CostsSimplified: "Costs",
        CostPlaceholder: "Ex: 10.5",
        UsedCost: "Used Cost (€)",
        UsedCostPlaceholder: "Ex: 10.5",
        Procedure: "Procedures",
        InternalProcedureSimplified: "Internal Proc.",
        InternalProcedure: "Internal Procedure",
        InternalProcedurePlaceholder: "Ex: IN S6 104",
        Standard: "Standard",
        StandardPlaceholder: "Ex: ISO 17202 / ISO 2061",
        Used: "Used",
        Duration: "Duration",
        Name: "Name",
        NamePlaceholder: "Type Test Name",
        Measurement: "Measurement",
        MeasurementPlaceholder: "Type the measurement",
        MeasurementType: "Measurement type",
        Area: "Area",
        Length: "Length",
        LengthPlaceholder: "Type the length",
        Width: "Width",
        WidthPlaceholder: "Type the width",
        Units: "Unit",
        UnitsPlaceholder: "Type the unit",
      },
      POPUP: {
        Name: "Name",
        NamePlaceholder: "Ex: Test 01",
        CreateTest: "Create Test",
        CreateTestSuccess: "Test created!",
        EditTestSuccess: "Test edited!",
        EditTest: "Edit Test",
        ActivateTestConfirmText: "Are you sure you want to activate this test?",
        DeleteTestConfirmText: "Are you sure you want to delete this test?",
        DeactivateTestConfirmText: "Are you sure you want to deactivate this test?",
        Deactivate: "Disable",
        DisableTestSuccess: "Test deactivated!",
        ActivateTestSuccess: "Test activated!",
        ImportFileSuccess: "Import File concluded",

      },

      TYPES: {
        Fabrics: "Fabrics",
        Wires: "Wires",
        Felts: "Felts",
        Others: "Others",
      },

      TYPESENUM: {
        FABRICS: "Fabrics",
        WIRES: "Wires",
        FELTS: "Felts",
        OTHERS: "Others",
      }
    }
  },


};
