import { SelectOption } from "./SelectOption";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum TargetSpecEnum {
    TARGETSAP = 0,
    TECHNOTE = 1,
}


export const TaregtSpecTranslationTerms: { [key: string]: string } = {
    0: "REQUESTS.INFOANDFORM.TargetSAP",
    1: "REQUESTS.INFOANDFORM.TechNote",
}


export const GetTargetSpecEnum: { [key: string]: TargetSpecEnum } = {
    0: TargetSpecEnum.TARGETSAP,
    1: TargetSpecEnum.TECHNOTE
}


export function GetTargetSpecsArray() {

    const selectOptionsArray: SelectOption[] = Object.values(TargetSpecEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSelectOption: SelectOption = {
                id: "" + enumValue,
                description: translate(TaregtSpecTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedSelectOption;
        });

    return selectOptionsArray;
}