import { Optional, ValidatorFunction } from "../../../lib/components/form/Form";

import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";

export function validateRequiredIfValmetManufacturer<A>(isManufacturerValmet: boolean): ValidatorFunction<Optional<A>> {
    return (value: A | undefined) => {
        if (isManufacturerValmet && !value) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}