import "./EditLabTestPopup.css";

import { GetMeasurementEnumAsArray, MeasurementAsEnum, MeasurementEnum } from "./models/MeasurementEnum";
import { GetUnitsEnumAsArray, UnitEnum, UnitEnumAsEnum } from "./models/UnitEnum";
import { regexIntegerOrDecimals, regexIntegerOrDecimalsV2, regexZeroIntegerOrDecimals, validateRegexOrEmpty, validateRegexOrEmptyV2 } from "../../../lib/validators/ValidateRegex";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, } from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../lib/components/checkbox/Checkbox";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { LabTest } from "../../../models/domain/lab-tests/LabTest";
import { LabTestDetails } from "../../../models/domain/lab-tests/LabTestDetails";
import { LabTestTypeDetails } from "../../../models/domain/lab-tests/LabTestTypeDetails";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { SelectOption } from "../../requests/models/domain/SelectOption";
import { TestTypesService } from "./services/TestTypesService";
import { TestsService } from "./services/TestsService";
import { hasOneElementSelected } from "../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../services/UseServiceCall";
import { validateStringIfConditional } from "../../requests/validators/ValidateIfConditional";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";

function replaceCommaByPoint(value: string | undefined) {
    if (!value) return null;
    if (value?.includes(','))
        return trimString(value.replace(',', '.'));
    return trimString(value);
}



export interface IEditLabTestProps {
    locationId: string;
    labTestID: string;
    onCompletedOperations: () => void;
}



var testsService = new TestsService();
var testTypesService = new TestTypesService();

const labelTestTypeSelector = (item: LabTestTypeDetails) => item.labTestTypeName;
const idTestTypeSelector = (item: LabTestTypeDetails) => item.id;

const labelMeasurementTypeSelector = (item: SelectOption) => item.description;
const idMeasurementTypeSelector = (item: SelectOption) => item.id;

const labelUnitsTypeSelector = (item: SelectOption) => item.description;
const idUnitsSelector = (item: SelectOption) => item.id;


export function EditLabTestPopup(props: IEditLabTestProps) {
    const createNotification = useCreateNotification();
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const [getLabTestCall, getLabTestCallIsLoading] = useServiceCallPro2(testsService, testsService.getLabTestByID);
    const [editLabTestCall, editLabTestCallIsLoading] = useServiceCallPro2(testsService, testsService.editLabTest);


    const [isForDestroyingBarOrFilter, setIsForDestroyingBarOrFilterFormControl] = useState<boolean>(false);
    const [isUsed, setIsUsed] = useState<boolean>(false);
    const [testTypes, setTestTypes] = useState<LabTestTypeDetails[]>([]);
    const [labTest, setLabTest] = useState<LabTestDetails>();


    const measurementTypes = useMemo(() => {
        return GetMeasurementEnumAsArray();
    }, [GetMeasurementEnumAsArray]);

    const unitMeasurementTypes = useMemo(() => {
        return GetUnitsEnumAsArray();
    }, [GetUnitsEnumAsArray]);

    const labTestTypeFormControl = useFormControl<LabTestTypeDetails>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const nameENFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const namePTFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const multiplierFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimalsV2, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const rehearsalTimeFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexZeroIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const workedHoursFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexZeroIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const sampleSizeFormControl = useFormControl<string>({
    });

    const costFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexZeroIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const usedCostFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexZeroIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const internalProcedureFormControl = useFormControl<string>({});

    const standardFormControl = useFormControl<string>({});

    const measurementTypeFormControl = useFormControl<SelectOption>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const unitsFormControl = useFormControl<SelectOption>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const sampleLengthFormControl = useFormControl<string>({
        validators: [validateRegexOrEmptyV2(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const sampleWidthFormControl = useFormControl<string>({
        validators: [validateStringIfConditional(measurementTypeFormControl.value?.id === (MeasurementEnum.AREA).toString()),
        validateRegexOrEmpty(regexZeroIntegerOrDecimals, true, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });



    useEffect(() => {

        if (!props.locationId || !props.labTestID) return;

        getLabTestCall(props.locationId, props.labTestID)
            .then((res) => {

                if (!res) return;
                setLabTest(res);
                setIsForDestroyingBarOrFilterFormControl(res.isForDestroyingBarOrFilter);
                setIsUsed(res.isUsed);
                rehearsalTimeFormControl.setValue(!res.rehearsalTime ? undefined : "" + res.rehearsalTime);
                workedHoursFormControl.setValue(!res.workedHours ? undefined : "" + res.workedHours);
                sampleSizeFormControl.setValue("" + res.sampleSize);
                costFormControl.setValue(!res.cost ? undefined : "" + res.cost);
                usedCostFormControl.setValue(!res.usedCost ? undefined : "" + res.usedCost);
                internalProcedureFormControl.setValue(res.internalProcedure ?? "");
                standardFormControl.setValue(res.standard ?? "");
                sampleLengthFormControl.setValue("" + res.length);
                sampleWidthFormControl.setValue(!res.width ? undefined : "" + res.width);
                nameENFormControl.setValue("" + res.testNameEn);
                namePTFormControl.setValue("" + res.testNamePt);
                multiplierFormControl.setValue(!res.multiplier ? undefined : ""+ res.multiplier);

                measurementTypeFormControl.setValue(measurementTypes.find((m) => m.id === (res.measurement).toString()));
                unitsFormControl.setValue(unitMeasurementTypes.find((u) => u.id === res.unit.toString()))

            }).catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });


    }, [props.locationId, props.labTestID]);


    useEffect(() => {
        if (!labTest || testTypes.length < 1) return;
        labTestTypeFormControl.setValue(testTypes.find((a) => a.id === labTest.labTestTypeId) ?? undefined);
    }, [labTest, testTypes]);






    useEffect(() => {
        if (measurementTypeFormControl.value?.id === (MeasurementEnum.AREA).toString())
            sampleWidthFormControl.setIsDisabled(false);
        else {
            sampleWidthFormControl.setIsDisabled(true);
            sampleWidthFormControl.validate();
        }
    }, [measurementTypeFormControl?.value?.id]);



    useEffect(() => {

        if (!props.locationId) return;

        testTypesService.getActiveLabTestTypesByLocationId(props.locationId, { filterByIsActive: false })
            .then((response) => {
                setTestTypes(response.testTypes);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId]);




    const onClickEditLabTest = useCallback(() => {
        if (!props.locationId || !props.labTestID) return;

        if (!AllValid(labTestTypeFormControl.validate(), nameENFormControl.validate(), namePTFormControl.validate(), multiplierFormControl.validate(),
            rehearsalTimeFormControl.validate(), workedHoursFormControl.validate(), sampleSizeFormControl.validate(),
            costFormControl.validate(), usedCostFormControl.validate(), internalProcedureFormControl.validate(), standardFormControl.validate(),
            measurementTypeFormControl.validate(), unitsFormControl.validate(), sampleLengthFormControl.validate(), sampleWidthFormControl.validate())) {
            return;
        }

        if(!sampleLengthFormControl.isValid)
                return; 

        if(!sampleLengthFormControl.validate()) return; 
        editLabTestCall(props.locationId, props.labTestID, {
            labTestTypeId: labTestTypeFormControl.value?.id,
            nameEn: trimString(nameENFormControl.value),
            namePt: trimString(namePTFormControl.value),
            multiplier: parseFloat(trimString(multiplierFormControl.value)),
            rehearsalTime: replaceCommaByPoint(rehearsalTimeFormControl.value),
            workedHours: replaceCommaByPoint(workedHoursFormControl.value),
            sampleSize: trimString(sampleSizeFormControl.value),
            isForDestroyingBarOrFilter: isForDestroyingBarOrFilter,
            cost: replaceCommaByPoint(costFormControl.value),
            usedCost: replaceCommaByPoint(usedCostFormControl.value),
            internalProcedure: trimString(internalProcedureFormControl.value),
            standard: trimString(standardFormControl.value),
            isUsed: isUsed,
            measurement: measurementTypeFormControl.value?.id ? MeasurementAsEnum[measurementTypeFormControl.value.id] : MeasurementEnum.AREA,
            length: replaceCommaByPoint(sampleLengthFormControl.value) || "0",
            width: replaceCommaByPoint(sampleWidthFormControl.value),
            unit: unitsFormControl.value?.id ? UnitEnumAsEnum[unitsFormControl.value.id] : UnitEnum.METERS,
        })
            .then((_) => {
                props.onCompletedOperations();

                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.TESTS.POPUP.EditTestSuccess")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;

                openPopup(
                    <ErrorPopup>{error.response.data.message}</ErrorPopup>,
                    false
                );
            });
    }, [measurementTypeFormControl.value, sampleLengthFormControl.value, sampleWidthFormControl.value,
    labTestTypeFormControl.value, unitsFormControl.value, nameENFormControl.value, namePTFormControl.value,
    multiplierFormControl.value, rehearsalTimeFormControl.value,
    workedHoursFormControl.value, sampleSizeFormControl.value, isForDestroyingBarOrFilter, costFormControl.value, usedCostFormControl.value,
    internalProcedureFormControl.value, standardFormControl.value, isUsed, labTestTypeFormControl.validate, nameENFormControl.validate,
    namePTFormControl.validate, multiplierFormControl.validate, rehearsalTimeFormControl.validate, workedHoursFormControl.validate,
    sampleSizeFormControl.validate, costFormControl.validate, usedCostFormControl.validate, internalProcedureFormControl.validate,
    standardFormControl.validate, measurementTypeFormControl.validate, unitsFormControl.validate, sampleLengthFormControl.validate,
    sampleWidthFormControl.validate, createNotification, closePopup, openPopup, props.onCompletedOperations, props.labTestID, props.locationId]);



    return (
        <PopupContainer className="popup-edit-test">
         {editLabTestCallIsLoading ? <FullScreenLoader /> : null}

            <PopupHeader title={translate("GLOBALADMIN.TESTS.POPUP.EditTest")} />
            <PopupContent isLoading={getLabTestCallIsLoading} className="edit-test">
                <FormContainer>
                    <FormSection title={translate("REQUESTS.INFOANDFORM.Description")}>
                        <FormFieldSelectSingle
                            label={translate("GLOBALADMIN.TESTS.INFO.Type")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.Type")}
                            options={testTypes || []}
                            labelSelector={labelTestTypeSelector}
                            idSelector={idTestTypeSelector}
                            formControl={labTestTypeFormControl}
                            isClearSelectionAvailable={false}
                        />
                        <FormFieldTextInput
                            formControl={nameENFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.Name") + " (en-EN)"}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.NamePlaceholder")}
                        />
                        <FormFieldTextInput
                            formControl={namePTFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.Name") + " (pt-PT)"}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.NamePlaceholder")}
                        />
                    </FormSection>
                    <FormSection title={translate("COMMON.FORM.Details")}>
                        <FormFieldTextInput
                            formControl={rehearsalTimeFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.RehearsalTime")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.RehearsalTimePlaceholder")}
                        />
                        <FormFieldTextInput
                            formControl={workedHoursFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.WorkedHours")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.WorkedHoursPlaceholder")}
                        />
                        <FormFieldTextInput
                            formControl={multiplierFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.Multiplier")}
                        />
                    </FormSection>
                    <FormSection title={translate("GLOBALADMIN.TESTS.INFO.SampleSize")}>
                        <FormFieldSelectSingle
                            label={translate("GLOBALADMIN.TESTS.INFO.MeasurementType")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.MeasurementPlaceholder")}
                            options={measurementTypes || []}
                            labelSelector={labelMeasurementTypeSelector}
                            idSelector={idMeasurementTypeSelector}
                            formControl={measurementTypeFormControl}
                            isClearSelectionAvailable={false}
                        />
                        <FormFieldTextInput
                            formControl={sampleLengthFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.Length")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.LengthPlaceholder")}
                        />
                        <FormFieldTextInput
                            formControl={sampleWidthFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.Width")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.WidthPlaceholder")}
                        />
                        <FormFieldSelectSingle
                            label={translate("GLOBALADMIN.TESTS.INFO.Units")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.UnitsPlaceholder")}
                            options={unitMeasurementTypes || []}
                            labelSelector={labelUnitsTypeSelector}
                            idSelector={idUnitsSelector}
                            formControl={unitsFormControl}
                            isClearSelectionAvailable={false}
                        />
                    </FormSection>
                    <FormSection title={translate("GLOBALADMIN.TESTS.INFO.Procedure")}>
                        <FormFieldTextInput
                            formControl={internalProcedureFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.InternalProcedure")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.InternalProcedurePlaceholder")}
                        />
                        <FormFieldTextInput
                            formControl={standardFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.Standard")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.StandardPlaceholder")}
                        />
                    </FormSection>
                    <FormSection title={translate("REQUESTS.INFOANDFORM.Cost")}>
                        <FormFieldTextInput
                            formControl={costFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.Cost")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.CostPlaceholder")}
                        />
                        <FormFieldTextInput
                            formControl={usedCostFormControl}
                            label={translate("GLOBALADMIN.TESTS.INFO.UsedCost")}
                            placeholder={translate("GLOBALADMIN.TESTS.INFO.UsedCostPlaceholder")}
                        />
                    </FormSection>
                    <FormSection title={translate("REQUESTS.INFOANDFORM.MaterialStatus")}>
                        <Checkbox
                            text={translate("GLOBALADMIN.TESTS.INFO.DestroyingBarOrFilterPlaceholder")}
                            isChecked={isForDestroyingBarOrFilter}
                            onClick={() => setIsForDestroyingBarOrFilterFormControl(!isForDestroyingBarOrFilter)}
                        />
                        <Checkbox
                            text={translate("GLOBALADMIN.TESTS.INFO.Used")}
                            isChecked={isUsed}
                            onClick={() => setIsUsed(!isUsed)}
                        />
                    </FormSection>
                    <PopupActionButtons
                        className="edit-test-btns"
                        buttons={[
                            {
                                text: translate("COMMON.Cancel"),
                                type: "tertiary",
                                onClick: () => closePopup(),
                            },
                            {
                                text: translate("COMMON.Edit"),
                                type: "primary",
                                onClick: onClickEditLabTest
                            },
                        ]}
                    />
                </FormContainer>
            </PopupContent>
        </PopupContainer>
    );
}
