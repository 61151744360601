import { AnalysisRequestSampleDetails } from "../../../../models/domain/AnalysisRequestSampleDetails";
import { FormContainer } from "../../../../../../lib/layouts/containers/form/FormContainer";
import { FormSection } from "../../../../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../../../../lib/components/info-block/InfoBlock";
import { TaregtSpecTranslationTerms } from "../../../../models/domain/TargetSpec";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";

export interface IRequestSampleDetailsContainer {
    data?: AnalysisRequestSampleDetails;
}


export function SampleARDetailsContainer(props: IRequestSampleDetailsContainer) {

    return (
        <FormContainer>
            <FormSection unsetFlexGrow>
                <InfoBlock label={translate("REQUESTS.INFOANDFORM.Manufacturer")}
                    value={props.data?.manufacturerIsValmet ? "Valmet" : props.data?.otherManufacturer || translate("REQUESTS.INFOANDFORM.Other")} />
                <InfoBlock label={translate("REQUESTS.Used")}
                    value={props.data?.isUsed ? translate("COMMON.Yes") : translate("COMMON.No")} />
            </FormSection>

            <FormSection title={translate("REQUESTS.INFOANDFORM.Material")} unsetFlexGrow>
                <InfoBlock label={translate("REQUESTS.INFOANDFORM.ProductDescription")}
                    value={props.data?.productDescription ?? "-"} />
                <InfoBlock label={translate("REQUESTS.INFOANDFORM.Material")}
                    value={props.data?.material?.id ? `${props.data.material.number} - ${props.data.material.name}` : props.data?.otherMaterial || "-"} />
                <InfoBlock label={translate("SAMPLES.INFOANDFORM.Batch")}
                    value={props.data?.batchNumber ?? "-"} />
                {/* new */}
            </FormSection>

            <FormSection title={translate("REQUESTS.INFOANDFORM.CompareWith")} unsetFlexGrow>
                <InfoBlock label={translate("REQUESTS.INFOANDFORM.MaterialDescription")}
                    value={props.data?.compareToMaterial?.number ? props.data?.compareToMaterial?.number + " - " + props.data?.compareToMaterial?.name : "-"} />
                <InfoBlock label={translate("REQUESTS.INFOANDFORM.TargetOrTechNote")}
                    value={props.data?.compareToSpec !== undefined && props.data?.compareToSpec !== null ?
                        translate(TaregtSpecTranslationTerms[props.data?.compareToSpec]) : "-"} />
            </FormSection>
        </FormContainer>
    )
}