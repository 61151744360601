import { SampleType } from "./SampleType";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum SampleTypeEnum {
    PRODUCTION = 1,
    FIBERS = 2,
    ACCESSORIES = 3,
    THREADS = 4,
    PRODUCED = 5,
    OTHERS = 6,
    PACKAGE = 7,
}

export const SampleTypeTranslationTerms: { [key: string]: string } = {
    1: "SAMPLES.TYPESENUM.PRODUCTION",
    2: "SAMPLES.TYPESENUM.FIBERS",
    3: "SAMPLES.TYPESENUM.ACCESSORIES",
    4: "SAMPLES.TYPESENUM.THREADS",
    5: "SAMPLES.TYPESENUM.PRODUCED",
    6: "SAMPLES.TYPESENUM.OTHERS",
    7: "SAMPLES.TYPESENUM.PACKAGE",
}

export const SampleTypeAsEnum: { [key: number]: SampleTypeEnum } = {
    1: SampleTypeEnum.PRODUCTION,
    2: SampleTypeEnum.FIBERS,
    3: SampleTypeEnum.ACCESSORIES,
    4: SampleTypeEnum.THREADS,
    5: SampleTypeEnum.PRODUCED,
    6: SampleTypeEnum.OTHERS,
    7: SampleTypeEnum.PACKAGE,
};


export function GetSampleTypes() {


    const sampleTypesArray: SampleType[] = Object.values(SampleTypeEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSampleType: SampleType = {
                id: "" + enumValue,
                description: translate(SampleTypeTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedSampleType;
        });

    return sampleTypesArray;
}

