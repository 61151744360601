import "./LocationsContainer.css";

import { useEffect, useMemo } from "react";

import { ReactComponent as EmployeesIcon } from "../../../lib/assets/icons/employees.svg";
import { ReactComponent as FacilitesIcon } from "../../../lib/assets/icons/facilities.svg";
import { ReactComponent as GroupsAccessIcon } from "../../../lib/assets/icons/groups-access.svg";
import { LocationService } from "../../../services/LocationServices";
import { ReactComponent as MaterialsIcon } from "../../../lib/assets/icons/materials-dark.svg";
import { MobileSubSidebar } from "../../../lib/components/side-bar/MobileSubSidebar";
import { SubSidebar } from "../../../lib/components/side-bar/SubSidebar";
import { ReactComponent as TestsIcon } from "../../../lib/assets/icons/teste-dark.svg";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useParams } from "react-router-dom";
import { useUpdatePageData } from '../../../lib/infrastructure/navigation/hooks/useUpdatePageData';
import { useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

var locationService = new LocationService();
interface ILocationsContainerProps {
  children: React.ReactNode;
}

const subSideBarButtons = [
  {
    icon: <MaterialsIcon />,
    navigateTo: "materials",
    name: translate("GLOBALADMIN.MATERIALS.Materials"),
  },
  {
    icon: <TestsIcon />,
    navigateTo: "tests",
    name: translate("GLOBALADMIN.TESTS.Tests"),
  },
  {
    icon: <EmployeesIcon />,
    navigateTo: "employees",
    name: translate("GLOBALADMIN.EMPLOYEES.LabOperators"),
  }, {
    icon: <FacilitesIcon />,
    navigateTo: "facilities",
    name: translate("GLOBALADMIN.FACILITIES.Facilities"),
  },
  {
    icon: <GroupsAccessIcon />,
    navigateTo: "groupaccess",
    name: translate("GLOBALADMIN.LOCATIONS.AccessGroups"),
  },


];

export function LocationsContainer(props: ILocationsContainerProps) {
  const windowResize = useWindowResize();

  const renderSubSideBar = useMemo(() => {
    return windowResize > 425 ? <SubSidebar buttons={subSideBarButtons} /> : <MobileSubSidebar buttons={subSideBarButtons} />;
  }, [windowResize]);


  const { id } = useParams();
  const updateRouteData = useUpdatePageData();

  useEffect(() => {
    locationService
      .getLocationById(id || "")
      .then((response) => {
        updateRouteData(["##LOCATION##", response.name]);

      })
      .catch((error) => { });


  }, []);

  return (
    <div className="locations-content-container">
      {renderSubSideBar}
      {props.children}
    </div>
  );
}
