import "./AssReqCardSample.css";

import { AnalysisRequestSampleDetails } from "../../../../../requests/models/domain/AnalysisRequestSampleDetails";
import { FormSection } from "../../../../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../../../../lib/components/info-block/InfoBlock";
import { TaregtSpecTranslationTerms } from "../../../../../requests/models/domain/TargetSpec";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";

interface IAssReqCardSampleARProps {
    sampleInfo: AnalysisRequestSampleDetails | null;
}

export function AssReqCardSampleAR(props: IAssReqCardSampleARProps) {

    return <div className="request-sample-info">
        <FormSection>
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.Manufacturer")}
                value={props.sampleInfo?.manufacturerIsValmet ? "Valmet" : translate("REQUESTS.INFOANDFORM.Other")}
            />
            <InfoBlock
                label={translate("REQUESTS.Used")}
                value={props.sampleInfo?.isUsed ? translate("COMMON.Yes") : translate("COMMON.No")}
            />
        </FormSection>
        <FormSection title={translate("REQUESTS.INFOANDFORM.Material")}>
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.ProductDescription")}
                value={props.sampleInfo?.productDescription || "-"}
            />
            <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Material")}
                value={props.sampleInfo?.material?.number ? props.sampleInfo?.material.number + " - " + props.sampleInfo?.material.name : props.sampleInfo?.otherMaterial || "-"}
            />
            <InfoBlock label={translate("SAMPLES.INFOANDFORM.Batch")}
                value={props.sampleInfo?.batchNumber ?? "-"} />
        </FormSection>
        <FormSection title={translate("REQUESTS.INFOANDFORM.CompareWith")}>
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.MaterialDescription")}
                value={props.sampleInfo?.compareToMaterial ? props.sampleInfo?.compareToMaterial?.number + " - " + props.sampleInfo?.compareToMaterial?.name : "-"} />

            <InfoBlock label={translate("REQUESTS.INFOANDFORM.TargetOrTechNote")}
                value={props.sampleInfo?.compareToSpec !== undefined && props.sampleInfo?.compareToSpec !== null ?
                    translate(TaregtSpecTranslationTerms[props.sampleInfo?.compareToSpec]) : "-"}></InfoBlock>
        </FormSection>
    </div>;
}

