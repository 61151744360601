import { SelectOption } from "../../../requests/models/domain/SelectOption";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum UnitEnum {
    METERS = 1,
    CENTIMETERS = 2,
    MILIMITERS = 3
}

export const UnitMeasurementTerms: { [key: string]: string } = {
    1: "m",
    2: "cm",
    3: "mm"
}

export const UnitEnumAsEnum: { [key: string]: UnitEnum } = {
    "1": UnitEnum.METERS,
    "2": UnitEnum.CENTIMETERS,
    "3": UnitEnum.MILIMITERS
};




export function GetUnitsEnumAsArray() {
    const unitsArray: SelectOption[] = Object.values(UnitEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSampleStatus: SelectOption = {
                id: `${enumValue}`,
                description: UnitMeasurementTerms[enumValue] || "-unknown-"
            };
            return mappedSampleStatus;
        });

    return unitsArray;
}