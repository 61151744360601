import { OtherType } from "./OtherType";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum OtherTypeEnum {
    THREADS = 1,
    FABRICS = 2,
    FELTS = 3,
    ACCESSORIES = 4,
    FIBERS = 5,
    OTHERS = 6
}


export const SampleOtherTypeTranslationTerms: { [key: string]: string } = {
    1: "SAMPLES.OTHERTYPESENUM.ACCESSORIES",
    2: "SAMPLES.OTHERTYPESENUM.FABRICS",
    3: "SAMPLES.OTHERTYPESENUM.FELTS",
    4: "SAMPLES.OTHERTYPESENUM.FIBERS",
    5: "SAMPLES.OTHERTYPESENUM.THREADS",
    6: "SAMPLES.OTHERTYPESENUM.OTHERS"
}

export const SampleOtherTypeTerms: { [key: number]: OtherTypeEnum } = {
    1: OtherTypeEnum.THREADS,
    2: OtherTypeEnum.FABRICS,
    3: OtherTypeEnum.FELTS,
    4: OtherTypeEnum.ACCESSORIES,
    5: OtherTypeEnum.FIBERS,
    6: OtherTypeEnum.OTHERS
  };

export function GetOtherTypes() {

    const sampleTypesArray: OtherType[] = Object.values(OtherTypeEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSampleType: OtherType = {
                id: "" + enumValue,
                description: translate(SampleOtherTypeTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedSampleType;
        });

    return sampleTypesArray;
}
