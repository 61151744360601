import { AnalysisRequestFilterTypeResponseDTO } from "../models/api/AnalysisRequestFilterTypeResponseDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { FilterType } from "../models/domain/AnalysisRequestFilterType";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class AnalysisRequestFilterTypeService {
    
    getAnalysisRequestFilterTypes(): Promise<FilterType[]> {

    return HttpClient.sessionRequest<AnalysisRequestFilterTypeResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/AnalysisRequestFilterTypes`),
    })
      .then((response) => {
        var mapped = response.data.analysisRequestFilterTypes.map((p): FilterType => ({
          id: p.analysisRequestFilterTypeId,
          description: p.analysisRequestFilterType,
          isDescriptionRequired: p.isDescriptionRequired
        }));
        return mapped;
      })
      .catch((error) => {
        throw error;
      });
  }
}
