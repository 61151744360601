import * as qs from "qs";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CorrelatedRequestSampleSummary } from "../models/domain/CorrelatedRequestSampleSummary";
import { CorrelatedRequestSampleSummaryDTO } from "../models/api/CorrelatedRequestSampleSummaryDTO";
import { CreateSampleRequestDTO } from "../models/api/CreateSampleRequestDTO";
import { GetSamplesRequestDTO } from "../models/api/GetSamplesRequestDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { LabSampleDTOtoSampleMapper } from "./mappers/GetSampleResponseDTOtoSampleMapper";
import { LabSampleResponseDTO } from "../models/api/LabSampleResponseDTO";
import { LabSamplesResponseDTO } from "../models/api/LabSamplesResponseDTO";
import { LabSamplesStatsResponseDTO } from "../models/api/GetLabSamplesResponseDTO";
import { LastLabSampleStats } from "../models/domain/LastLabSampleStats";
import { LastLabSampleStatsResponseDTO } from "../models/api/LastLabSampleStatsResponseDTO";
import { QueryOptions } from "../../../models/domain/materials/QueryOptions";
import { RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper } from "../../requests/services/mappers/RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper";
import { RequisitionDate } from "../models/domain/RequisitionDate";
import { RequisitionDateResponse } from "../models/api/UpdateRequisitionDateResponse";
import { Sample } from "../models/domain/Sample";
import { SampleAllData } from "../models/domain/SampleAllData";
import { SamplesAndTotalItems } from "../models/domain/SamplesAndTotalItems";
import { UpdateRequistionDateRequestDTO } from "../models/api/UpdateRequisitionDateRequestDTO";
import { UpdateSampleRequestDTO } from "../models/api/UpdateSampleRequestDTO";
import { UpdateStatusRequestDTO } from "../models/api/UpdateSampleStatusRequestDTO";
import { RemoveLabSampleCorrelationRequestDTO } from "../models/api/RemoveLabSampleCorrelationRequestDTO";
import { AnalysisRequestSamplesToCorrelateFilterRequestDTO } from "../models/api/AnalysisRequestSamplesToCorrelateFilterRequestDTO";
import { AnalysisRequestSamplesResponseDTO } from "../models/api/AnalysisRequestSamplesResponseDTO";
import { PossibleLabSampleCorrelationsItems } from "../models/domain/PossibleLabSampleCorrelations";
import { PossibleLabSampleCorrelation } from "../models/domain/PossibleLabSampleCorrelation";
import { LabSampleStats } from "../models/domain/LabSampleStats";
import { CorrelateLabSampleRequestDTO } from "../models/api/CorrelateLabSampleRequestDTO";
import { LabSampleDTO } from "../models/api/LabSampleDTO";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};



export class LabSampleService {
  addLabSample(locationId: number | string, request: CreateSampleRequestDTO): Promise<Sample> {
    return HttpClient.sessionRequest<LabSampleDTO>({
      method: "POST",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples`),
      data: request,
    })
      .then((response) => {

        return LabSampleDTOtoSampleMapper(response.data);
      })
      .catch((error) => {
        throw error;
      });
  }



  getLastDeliveredSamplesStats(locationId: string | number): Promise<LastLabSampleStats> {
    return HttpClient.sessionRequest<LastLabSampleStatsResponseDTO>({
      method: "GET",
      url: Route(
        `/api/v1/Locations/${locationId}/LabSamples/LastLabSamplesStats`
      ),
    })
      .then((response) => {
        var mappedStats: LastLabSampleStats = {
          pending: "" + response.data.labSamplesPending,
          underAnalysis: "" + response.data.labSamplesUnderAnalysis,
          onHold: "" + response.data.labSamplesOnHold,
          pendingWithRequestDate: "" + response.data.labSamplesPendingWithRequestDate
        };
        return mappedStats;
      })
      .catch((error) => {
        throw error;
      });
  }



  getLastDeliveredSamples(locationId: string | number, queryOptions?: QueryOptions): Promise<SamplesAndTotalItems> {
    return HttpClient.sessionRequest<LabSamplesResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/LastSamples`),
      params: queryOptions,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {
        var mappedSamples = response.data.samples.map((ob) =>
          LabSampleDTOtoSampleMapper(ob)
        );
        return {
          samples: mappedSamples,
          totalSamples: response.data.total,
        };
      })
      .catch((error) => {
        throw error;
      });
  }



  getSamples(locationId: string | number, filters: GetSamplesRequestDTO, abortSignal: AbortSignal | undefined): Promise<SamplesAndTotalItems> {
    return HttpClient.sessionRequest<LabSamplesResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples`),
      params: filters,
      signal: abortSignal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {


        var mappedSamples = response.data.samples.map((ob) => LabSampleDTOtoSampleMapper(ob));
        return {
          samples: mappedSamples,
          totalSamples: response.data.total,
        };
      })
      .catch((error) => {
        throw error;
      });
  }


  getLabSampleById(locationId: string | number, labSampleId: string): Promise<SampleAllData> {
    return HttpClient.sessionRequest<LabSampleResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/${labSampleId}`),
    })
      .then((response) => {
        var sampleInfo = LabSampleDTOtoSampleMapper(response.data.labSample);
        var lastCompletedSampleMapped: Sample | null = null;
        var incompletedSamplesControl04Mapped: Sample[] = [];

        if (response.data.lastLabSamplesForControl04) {
          if (response.data.lastLabSamplesForControl04.lastCompletedSample)
            lastCompletedSampleMapped = LabSampleDTOtoSampleMapper(response.data.lastLabSamplesForControl04.lastCompletedSample);

          if (response.data.lastLabSamplesForControl04.incompletedSamples)
            incompletedSamplesControl04Mapped = response.data.lastLabSamplesForControl04.incompletedSamples?.map((ob) => LabSampleDTOtoSampleMapper(ob));
        }
        var mappedResponse: SampleAllData = {
          sample: sampleInfo,
          lastLabSamplesControl04: {
            lastSampleControl04: lastCompletedSampleMapped,
            incompletedSampleControl04: incompletedSamplesControl04Mapped
          }
        }

        return mappedResponse;
      })
      .catch((error) => {
        throw error;
      });
  }



  updateLabSample(locationId: string | number, labSampleId: string, sample: UpdateSampleRequestDTO): Promise<void> {
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/${labSampleId}`),
      data: sample,
    })
      .then((_) => { })
      .catch((error) => {
        throw error;
      });
  }



  getSamplesStats(locationId: string | number): Promise<LabSampleStats> {

    return HttpClient.sessionRequest<LabSamplesStatsResponseDTO>({
      method: "GET",
      url: Route(
        `/api/v1/Locations/${locationId}/LabSamples/LabSamplesStats`
      ),
    })
      .then((response) => {

        var mappedStats: LabSampleStats = {
          pending: "" + response.data.labSamplesPending,
          underAnalysis: "" + response.data.labSamplesUnderAnalysis,
          onHold: "" + response.data.labSamplesOnHold,
          pendingWithRequestDate: "" + response.data.labSamplesPendingWithRequestDate,
          processedInCurrentYear: "" + response.data.labSamplesCurrentYear
        };
        return mappedStats;
      })
      .catch((error) => {
        throw error;
      });
  }




  updateRequisitionDate(locationId: string | number, labSampleId: string, requisitionDate: string): Promise<RequisitionDate> {

    var request: UpdateRequistionDateRequestDTO = {
      requestDate: requisitionDate
    };

    return HttpClient.sessionRequest<RequisitionDateResponse>({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/${labSampleId}/RequisitionDate`),
      data: request,
    })
      .then((response) => {
        var mappedResponse: RequisitionDate = {
          requestDate: response.data.requestDate,
          requestedBy: {
            email: response.data.requestedBy?.userEmail ?? null,
            name: response.data.requestedBy?.userDisplayName ?? null
          },
          modifiedDateRequestDate: response.data.modifiedDateRequestDate,
          locationId: response.data.locationId,
          sampleId: response.data.labSampleId,
        }
        return mappedResponse;
      })
      .catch((error) => {
        throw error;
      });
  }



  updateLabSampleStatus(locationId: string | number, labSampleId: string, request: UpdateStatusRequestDTO): Promise<void> {

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/${labSampleId}/SampleStatus`),
      data: request,
    })
      .then((_) => {
      })
      .catch((error) => {
        throw error;
      });
  }


  getRequestSampleCorrelatedBySampleId(locationId: string, labSampleId: string): Promise<CorrelatedRequestSampleSummary> {

    return HttpClient.sessionRequest<CorrelatedRequestSampleSummaryDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/${labSampleId}/CorrelatedRequestSample`),
    })
      .then((r) => {

        if (r.data.sample && r.data.requestSummary) {

          var mapped: CorrelatedRequestSampleSummary = {
            info: r.data.sample ? RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper(r.data.sample) : null,
            requestSummary: {
              analysisRequestId: r.data.requestSummary.analysisRequestId,
              createdBy: r.data.requestSummary.createdBy,
              createdByEmail: r.data.requestSummary.createdByEmail,
              createdDate: r.data.requestSummary.createdDate,
              name: r.data.requestSummary.name,
              status: r.data.requestSummary.status,
              type: r.data.requestSummary.type,
              waybill: r.data.requestSummary.waybill,
              reference: r.data.requestSummary.reference
            }
          };
          return mapped;
        }

        return {
          info: null,
          requestSummary: null
        }

      })
      .catch((error) => {
        throw error;
      });
  }



  removeLabSampleCorrelation(locationId: string, labSampleID: string, request: RemoveLabSampleCorrelationRequestDTO): Promise<void> {
    return HttpClient.sessionRequest<LastLabSampleStatsResponseDTO>({
      method: "DELETE",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/${labSampleID}/Correlation`),
      data: request
    })
      .then((_) => { })
      .catch((error) => {
        throw error;
      });
  }




  getPossibleSampleCorrelations(locationId: string, sampleID: string,
    queryOpts: AnalysisRequestSamplesToCorrelateFilterRequestDTO, abortSignal: AbortSignal | undefined): Promise<PossibleLabSampleCorrelationsItems> {

    return HttpClient.sessionRequest<AnalysisRequestSamplesResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/${sampleID}/Correlations`),
      params: queryOpts,
      signal: abortSignal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {

        var mappedSamples: PossibleLabSampleCorrelation[] = response.data.samples.map((ob) => {
          return {
            sample: RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper(ob.requestSample),
            requestID: ob.analysisRequestId,
            reference: ob.reference,
            requestName: ob.name,
            waybill: ob.waybill
          };
        });


        return {
          samples: mappedSamples,
          totalItems: response.data.total,
        };

      })
      .catch((error) => {
        throw error;
      });
  }





  correlateLabSamplePage(locationId: string, labSampleId: string, requestDto: CorrelateLabSampleRequestDTO): Promise<void> {
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Locations/${locationId}/LabSamples/${labSampleId}/Correlation`),
      data: requestDto
    })
      .then((_) => { })
      .catch((error) => {
        throw error;
      });
  }



}


