import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { IGenericSampleFormsProps } from "../EditSampleDetailsPopup";
import { Material } from "../../../../../../models/domain/materials/Material";
import React from "react";
import { SampleForm } from "../../../../models/domain/SampleForm";
import { hasOneElementSelected } from "../../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";

const materialLabelSelector = (item: Material) => item.name + " - " + item.description;
const materialIdSelector = (item: Material) => item.id;




export const AccessoriesSampleForm = forwardRef(
  function AccessoriesSampleForms(props: IGenericSampleFormsProps, ref: React.ForwardedRef<SampleForm>) {

/*     const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true); */


    const materialFormControl = useFormControl<Material>({
      validators: [hasOneElementSelected()],
      enableAutoValidate: true,
      isDisabled: false,
    });

    const batchFormControl = useFormControl<string>({
      isDisabled: false,
    });

    const waybillFormControl = useFormControl<string>({
      isDisabled: false,
    });

    const observationsFormControl = useFormControl<string>({
      isDisabled: false,
    });





    useEffect(() => {
      if (!props.materials && !props.sample)
        return;

      materialFormControl.setValue(props.materials.find((m) => m.id === props.sample?.materialId));
      batchFormControl.setValue(props.sample?.batch);
      waybillFormControl.setValue(props.sample?.waybill);
      observationsFormControl.setValue(props.sample?.observations);

    }, [props.materials, props.sample]);





    useImperativeHandle(ref, () => {
      return {
        validate() {
          return AllValid(materialFormControl.validate(), batchFormControl.validate(), waybillFormControl.validate(), observationsFormControl.validate());
        },
        value() {
          return {
            materialId: materialFormControl.value?.id ?? -1,
            batch: batchFormControl.value,
            waybill: waybillFormControl.value,
            observations: observationsFormControl.value,
          }
        }
      }
    }, [materialFormControl.validate, batchFormControl.validate, waybillFormControl.validate, observationsFormControl.validate,
    materialFormControl.value, batchFormControl.value, waybillFormControl.value, observationsFormControl.value, AllValid]);





    return (
      <>
        <FormFieldSelectSingle
          label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
          placeholder={translate(
            "SAMPLES.INFOANDFORM.SAPMaterialPlaceholder"
          )}
          disableFuzzySearch
          options={props.materials}
          labelSelector={materialLabelSelector}
          idSelector={materialIdSelector}
          formControl={materialFormControl}
          isClearSelectionAvailable={false}
        />
        <FormFieldTextInput
          formControl={batchFormControl}
          label={translate("SAMPLES.INFOANDFORM.Batch") + " (" + translate("COMMON.Optional") + ")"}
          placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
        />
        <FormFieldTextInput
          formControl={waybillFormControl}
          label={translate("SAMPLES.INFOANDFORM.Waybill")}
          placeholder={translate("SAMPLES.INFOANDFORM.WaybillPlaceholder")}
        />
        <FormFieldTextArea
          formControl={observationsFormControl}
          label={translate("SAMPLES.INFOANDFORM.Observations") + " (" + translate("COMMON.Optional") + ")"}
          placeholder={translate(
            "SAMPLES.INFOANDFORM.ObservationsPlaceholder"
          )}
        />
      </>
    );
  });
