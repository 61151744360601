import "./EditRequestDetailsPopup.css";

import {
  AnalysisRequestTypeEnum,
  RequestTypeTranslationTerms,
} from "../../../models/domain/AnalysisRequestTypeEnum";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AnalysisRequestService } from "../../../services/AnalysisRequestService";
import { AnalysisRequestToUpdateAnalysisRequestDTOMapper } from "../../../services/mappers/AnalysisRequestToUpdateAnalysisRequestDTOMapper";
import { EditRequestARForm } from "./edit-request-forms/EditRequestARForm";
import { EditRequestDFForm } from "./edit-request-forms/EditRequestDFForm";
import { EditRequestDryerForm } from "./edit-request-forms/EditRequestDryerForm";
import { EditRequestForm } from "../../../models/domain/EditRequestForm";
import { EditRequestWFPPForm } from "./edit-request-forms/EditRequestWFPPForm";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RequestDetails } from "../../../models/domain/RequestDetails";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";
import { AnalysisRequestReasonTypeService } from "../../../services/AnalysisRequestReasonTypeService";
import { AnalysisRequestReasonType } from "../../../models/domain/AnalysisRequestReasonType";


var reasonTypeSvc = new AnalysisRequestReasonTypeService();

interface IEditRequestDetailsPopupProps {
  requestId: number;
  locationId: number;
  typeId: number;
  onOperationsCompleted: () => void;
}

var analysisRequestSvc = new AnalysisRequestService();

export function EditRequestDetailsPopup(props: IEditRequestDetailsPopupProps) {

  const editRequestFormRef = useRef<EditRequestForm>(null);
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const createNotification = useCreateNotification();

  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState<boolean>(false);

  const [requestDetails, setRequestDetails] = useState<RequestDetails>();
  const [requestReasonTypes, setRequestReasonTypes] = useState<AnalysisRequestReasonType[] | null>();
  const [isLoadingRequestDetails, setIsLoadingRequestDetails] = useState<boolean>(true);

  const [getAnalysisReasonTypesCall, getAnalysisReasonTypesCallIsLoading] = useServiceCallPro2(reasonTypeSvc, reasonTypeSvc.getAnalysisRequestReasonTypes);


  
  useEffect(() => {
    if (!props.requestId || !props.locationId) return;
    analysisRequestSvc.getAnalysisRequestById(props.locationId, `${props.requestId}`)
      .then((data) => {
        if (!data) return;
        setRequestDetails(data);
        setIsLoadingRequestDetails(false);
      })
      .catch((error) => {
        setIsLoadingRequestDetails(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, []);


  useEffect(() => {

    getAnalysisReasonTypesCall().then((data) => {
      if (!data) return;
      setRequestReasonTypes(data);

    }).catch((error) => {
      if (!error) return;
      openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
    });
  }, []);
 


  const renderRequestFormsByType = useMemo(() => {
    if (!requestDetails) return;

    switch (props.typeId) {
      case AnalysisRequestTypeEnum.AR:
        return <EditRequestARForm info={requestDetails} ref={editRequestFormRef} analysisReasonTypes={requestReasonTypes || []}/>;
      case AnalysisRequestTypeEnum.WF:
        return <EditRequestWFPPForm info={requestDetails} ref={editRequestFormRef}  analysisReasonTypes={requestReasonTypes || []}/>;
      case AnalysisRequestTypeEnum.PP:
        return <EditRequestWFPPForm info={requestDetails} ref={editRequestFormRef} analysisReasonTypes={requestReasonTypes || []}/>;
      case AnalysisRequestTypeEnum.DF:
        return <EditRequestDFForm info={requestDetails} ref={editRequestFormRef} analysisReasonTypes={requestReasonTypes || []}/>;
      case AnalysisRequestTypeEnum.DRYER:
        return <EditRequestDryerForm info={requestDetails} ref={editRequestFormRef} analysisReasonTypes={requestReasonTypes || []}/>;
      default:
        return;
    }
  }, [props.typeId, requestDetails, editRequestFormRef, requestReasonTypes]);


  const handleSaveBtnClicked = useCallback(() => {

    if (!editRequestFormRef.current) return;
    if (!editRequestFormRef.current.validate()) return;

    setIsSaveButtonClicked(true);

    var updateDTO = AnalysisRequestToUpdateAnalysisRequestDTOMapper(editRequestFormRef.current.value());

    analysisRequestSvc
      .updateAnalysisRequest("" + props.locationId, "" + props.requestId, updateDTO)
      .then((_) => {
        props.onOperationsCompleted();
        setIsSaveButtonClicked(false);
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("REQUESTS.POPUPS.EditRequestDetailsSuccess")}
          ></Notification>
        );
        closePopup();
      })
      .catch((error) => {
        setIsSaveButtonClicked(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [editRequestFormRef.current, setIsSaveButtonClicked, editRequestFormRef.current?.validate, editRequestFormRef.current?.value, props.locationId, props.requestId,
  props.onOperationsCompleted, closePopup, createNotification, openPopup]);



  return (
    <PopupContainer className="edit-request">
      {isSaveButtonClicked ? <FullScreenLoader /> : null}
      <PopupHeader
        title={`${translate("REQUESTS.POPUPS.EditRequestDetails")}`}
        subtitle={`${translate("REQUESTS.INFOANDFORM.Type")}: ${translate(
          RequestTypeTranslationTerms[props.typeId]
        )}`}
      />

      <PopupContent isLoading={isLoadingRequestDetails || getAnalysisReasonTypesCallIsLoading}>
        <FormContainer>
          <div className="edit-request-form">{renderRequestFormsByType}</div>
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Edit"),
              type: "primary",
              onClick: handleSaveBtnClicked,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
