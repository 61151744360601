import * as qs from "qs";

import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EditLabTestStatusDTO } from "../../../../models/api/lab-tests/EditLabTestStatusDTO";
import { HttpClient } from "../../../../lib/infrastructure/http/HttpClient";
import { LabTest } from "../../../../models/domain/lab-tests/LabTest";
import { LabTestDetails } from "../../../../models/domain/lab-tests/LabTestDetails";
import { LabTestRequestDTO } from "../../../../models/api/lab-tests/LabTestRequestDTO";
import { LabTestToLabTestDTOMapper } from "../../../../models/api/lab-tests/mappers/LabTestToLabTestDTOMapper";
import { LabTestToLabTestForAnalysisRequestsDTOMapper } from "../../../../models/api/lab-tests/mappers/LabTestToLabTestForAnalysisRequestsDTOMapper";
import { LabTestsDetailsTotalItems } from "../../../../models/domain/lab-tests/LabTestDetailsTotalItems";
import { LabTestsForAnalysisRequestsResponseDTO } from "../../../../models/api/lab-tests/LabTestsForAnalysisRequestsResponseDTO";
import { LabTestsQueryOptions } from "../../../../models/api/lab-tests/LabTestsQueryOptions";
import { LabTestsResponseDTO } from "../../../../models/api/lab-tests/LabTestsResponseDTO";
import { LabTestsTotalItems } from "../../../../models/domain/lab-tests/LabTestsTotalItems";
import { LabTestDTO } from "../../../../models/api/lab-tests/LabTestDTO";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class TestsService {
  addLabTest(locationId: number | string, request: LabTestRequestDTO): Promise<void> {
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Locations/${locationId}/LabTests`),
      data: request,
    })
      .then((_) => { })
      .catch((error) => {
        throw error;
      });
  }


  getLabTests(locationId: number | string, queryOptions?: LabTestsQueryOptions): Promise<LabTestsDetailsTotalItems> {

    return HttpClient.sessionRequest<LabTestsResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/LabTests`),
      params: queryOptions,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {

        var tests: LabTestDetails[] = [];
        var totalTests = 0;

        if (response.data.labTests)
          tests = response.data.labTests.map((ob) => LabTestToLabTestDTOMapper(ob));
        if (response.data.total)
          totalTests = response.data.total;

        return {
          labTests: tests,
          totalLabTests: response.data.total,
        };

      }).catch((error) => {
        throw error;
      });
  }

  getLabTestsForAnalysisRequests(locationId: number | string, queryOptions?: LabTestsQueryOptions): Promise<LabTestsTotalItems> {

    return HttpClient.sessionRequest<LabTestsForAnalysisRequestsResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/LabTests/ActiveTests`),
      params: queryOptions,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {

        var tests: LabTest[] = [];
        var totalTests = 0;

        if (response.data.labTests)
          tests = response.data.labTests.map((ob) => LabTestToLabTestForAnalysisRequestsDTOMapper(ob));
        if (response.data.total)
          totalTests = response.data.total;

        return {
          labTests: tests,
          totalLabTests: response.data.total,
        };

      }).catch((error) => {
        throw error;
      });
  }



  editLabTestStatus(locationId: number | string, labTestId: number, disable: boolean): Promise<void> {
    const requestBody: EditLabTestStatusDTO = {
      disable: disable
    }

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/LabTests/${labTestId}/Status`),
      data: requestBody
    })
      .then((_) => {
      })
      .catch((error) => {
        throw error;
      });
  }



  uploadTestFile(locationId: string,
    file: File): Promise<void> {

    var formData = new FormData();
    formData.append("file", file)

    return HttpClient.sessionRequest({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
      },
      url: Route(`/api/v1/Locations/${locationId}/LabTests/asCSV`),
      data: formData
    })
      .then((_) => {

      })
      .catch((error) => {
        throw error;
      });
  }



  getLabTestByID(locationId: string, labTestID: string): Promise<LabTestDetails | null> {

    return HttpClient.sessionRequest<LabTestDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/LabTests/${labTestID}`),
    }).then((response) => {

      if (!response?.data) return null;
      return LabTestToLabTestDTOMapper(response.data);

    }).catch((error) => {
      throw error;
    });
  }



  
  editLabTest(locationId: number | string, labTestID: string, request: LabTestRequestDTO): Promise<void> {
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/LabTests/${labTestID}`),
      data: request,
    })
      .then((_) => { })
      .catch((error) => {
        throw error;
      });
  }

} 