import { AnalysisRequestSampleDetails } from "../../models/domain/AnalysisRequestSampleDetails";
import { RequestSampleDetailsDTO } from "../../models/api/RequestSampleDetailsDTO";

export function RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper(requestSample: RequestSampleDetailsDTO){
    var mappedRequestSample: AnalysisRequestSampleDetails = {
        id: requestSample.id,
        createdBy: {
            email: requestSample.createdBy.userEmail,
            name: requestSample.createdBy.userDisplayName
        },
        createdDate: requestSample.createdDate,
        modifiedDate: requestSample.modifiedDate,
        isUsed: requestSample.isUsed,
        manufacturerIsValmet: requestSample.manufacturerIsValmet,
        otherManufacturer: requestSample.otherManufacturer,
        material: requestSample.material,
        materialId: requestSample.material?.id,
        otherMaterial: requestSample.otherMaterial,
        productDescription: requestSample.productDescription,
        compareToMaterial: requestSample.compareToMaterial,
        compareToMaterialId: requestSample.compareToMaterial?.id,
        compareToSpec: requestSample.compareToSpec,
        position: requestSample.position,
        installationDate: requestSample.installationDate,
        removalDate: requestSample.removalDate,
        runningTime: requestSample.runningTime,
        reasonForRemoval: requestSample.reasonForRemoval,
        otherInfo: requestSample.otherInfo,
        sampleType: requestSample.type,
        batchNumber: requestSample.batchNumber,
        correlatedLabSampleId: requestSample.correlatedLabSampleId
    }
    return mappedRequestSample;
}