import { SelectOption } from "./SelectOption";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum DeliverTypeEnum {
    Courier = 1,
    Personally = 2
}

export const DeliverTypeTranslationTerms: { [key: string]: string } = {
    1: "REQUESTS.INFOANDFORM.DeliverByCourier",
    2: "REQUESTS.INFOANDFORM.DeliverPersonally",
}

export function GetDeliverTypeArray() {
    const selectOptionsArray: SelectOption[] = Object.values(DeliverTypeEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSelectOption: SelectOption = {
                id: "" + enumValue,
                description: translate(DeliverTypeTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedSelectOption;
        });
    return selectOptionsArray;
}