import "./RejectSamplePopup.css";

import { IFormControl, Optional, ValidatorFunction, useFormControl } from "../../../../../lib/components/form/Form";
import { useCallback, useEffect, useState } from "react";
import {
    useClosePopup,
    usePopup,
} from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { LabSampleStatusReasonService } from "../../../services/LabSampleStatusReasonService";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { SampleStatusEnum } from "../../../models/domain/SamplesStatusEnum";
import { SampleStatusReason } from "../../../models/domain/SampleStatusReason";
import { SampleTypeTranslationTerms } from "../../../models/domain/SampleTypeEnum";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { UpdateStatusRequestDTO } from "../../../models/api/UpdateSampleStatusRequestDTO";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";

const labelSelector = (item: SampleStatusReason) => item.reason;
const idSelector = (item: SampleStatusReason) => item.sampleStatusReasonId;

var sampleStatusReasonSvc = new LabSampleStatusReasonService();

export function validateRequiredIfOthers(othersFormControl: IFormControl<SampleStatusReason>): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {
        if (othersFormControl.value?.isJustificationRequired && (!value || value?.trim() === "")) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}

export interface IRejectSamplePopupProps {
    onChange?: (dto: UpdateStatusRequestDTO) => void;
    typeId: number;
}


export function RejectSamplePopup(props: IRejectSamplePopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    const [reasons, setReasons] = useState<SampleStatusReason[]>();
    const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);

    const reasonFormControl = useFormControl<SampleStatusReason>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const observationsFormControl = useFormControl<string>({
        validators: [validateRequiredIfOthers(reasonFormControl)],
        enableAutoValidate: true,
        isDisabled: false,
    });






    useEffect(() => {
        sampleStatusReasonSvc.getRejectReasons().then((response) => {
            var filteredReasons = response.filter((r) => r.sampleStatus === SampleStatusEnum.REJECTED);
            setReasons(filteredReasons);
            setIsLoadingContent(false);
        }).catch(error => {
            setIsLoadingContent(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, []);

    


    
    useEffect(() => {
        observationsFormControl.validate();
    }, [reasonFormControl.value]);





    const handleOnClickReject = useCallback(() => {

        if (!AllValid(reasonFormControl.validate(), observationsFormControl.validate())) return;

        var dto: UpdateStatusRequestDTO = {
            nextStatus: SampleStatusEnum.REJECTED,
            reasonId: reasonFormControl.value?.sampleStatusReasonId,
            statusJustification: observationsFormControl.value
        };

        props.onChange && props.onChange(dto);
        closePopup();

    }, [closePopup, props.onChange, reasonFormControl.validate, reasonFormControl.value, observationsFormControl.value, observationsFormControl.validate]);





    return (
        <PopupContainer className="popup-reject-sample">
            <PopupHeader
                title={`${translate("SAMPLES.RejectSample")} `} subtitle={`${translate(
                    SampleTypeTranslationTerms[props.typeId]
                )}`}
            />
            <PopupContent isLoading={isLoadingContent}>
                <FormContainer>
                    <FormFieldSelectSingle
                        label={translate("SAMPLES.INFOANDFORM.Reason")}
                        placeholder={translate(
                            "SAMPLES.INFOANDFORM.SelectReasonPlaceholder"
                        )}
                        options={reasons || []}
                        labelSelector={labelSelector}
                        idSelector={idSelector}
                        formControl={reasonFormControl}
                        isClearSelectionAvailable={false}
                    />
                    <FormFieldTextArea
                        formControl={observationsFormControl}
                        label={
                            translate("SAMPLES.INFOANDFORM.Observations")
                        }
                        placeholder={translate(
                            "SAMPLES.INFOANDFORM.ObservationsPlaceholder"
                        )}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("SAMPLES.ACTIONS.Reject"),
                            className: "reject-btn",
                            type: "secondary",
                            onClick: handleOnClickReject,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
