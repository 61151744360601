import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { AnalysisRequestService } from "../../../services/AnalysisRequestService";
import { AnalysisRequestStatusEnum } from "../../../models/domain/AnalysisRequestStatusEnum";
import { CancelAnalysisRequestDTO } from "../../../models/api/CancelAnalysisRequestDTO";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

export interface IRejectSamplePopupProps {
    onChange?: (dto: CancelAnalysisRequestDTO) => void;
}


export function CancelRequestPopup(props: IRejectSamplePopupProps) {
    const closePopup = useClosePopup();

    const reasonFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });


    const handleSaveButtonClicked = useCallback(() => {

        if (!AllValid(reasonFormControl.validate())) return;

        var dto: CancelAnalysisRequestDTO = {
            cancelReason: trimString(reasonFormControl.value),
            status: AnalysisRequestStatusEnum.CANCELED
        }

        props.onChange && props.onChange(dto);
        closePopup();

    }, [closePopup, reasonFormControl.validate, reasonFormControl.value]);



    return (
        <PopupContainer className="popup-reject-sample">
            <PopupHeader title={`${translate("REQUESTS.POPUPS.CancelRequest")} `} />
            <PopupContent>
                <FormContainer>
                    <FormFieldTextArea
                        formControl={reasonFormControl}
                        label={translate("REQUESTS.POPUPS.Reason")}
                        placeholder={translate("REQUESTS.POPUPS.ReasonPlaceholder")}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("REQUESTS.POPUPS.CancelReq"),
                            className: "reject-btn",
                            type: "secondary",
                            onClick: handleSaveButtonClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
