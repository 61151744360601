import "./AddSamplePopup.css";

import { GetTargetSpecEnum, GetTargetSpecsArray, TargetSpecEnum } from "../../../../models/domain/TargetSpec";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../../lib/infrastructure/ui/UIServices";
import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../../lib/functional/ConditionalRender";
import { ErrorPopup } from "../../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../../lib/components/loader/FullScreenLoader";
import { Material } from "../../../../../../models/domain/materials/Material";
import { MaterialsService } from "../../../../../../services/MaterialsService";
import { Notification } from "../../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RequestSampleCreateDTO } from "../../../../models/api/RequestSampleCreateDTO";
import { RequestSamplesService } from "../../../../services/RequestSamplesService";
import { Spacer } from "../../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../../../lib/infrastructure/location/LocationServices";
import { validateRequiredIfValmetManufacturer } from "../../../../validators/ValidateRequiredIfValmetManufacturer";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";

import { useServiceCallPro2 } from "../../../../../../services/UseServiceCall";

var materialService = new MaterialsService();
var samplesSvc = new RequestSamplesService();

interface SelectSingleOption {
    id: string;
    description: string;
}
const labelSelector = (item: Material) => item.name + " - " + item.description;
const idSelector = (item: Material) => item.id;

const labelCompareToSpecSelector = (item: SelectSingleOption) => item.description;
const idCompareToSpecSelector = (item: SelectSingleOption) => item.id;



export interface IAddRequestSamplePopup {
    requestID: string;
    locationID: string;
    cloneSampleID?: string | undefined;
    onCompletedOperations: () => void;
}



export function AddSampleARPopup(props: IAddRequestSamplePopup) {
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const locationID = useGlobalLocation();
    const openPopup = usePopup();

    const compareToSpec = useMemo(() => {
        return GetTargetSpecsArray();
    }, [GetTargetSpecsArray])


    const [getSampleByIDCall, getSampleByIDCallIsLoading] = useServiceCallPro2(samplesSvc, samplesSvc.getRequestSampleByIdExtended);
    const [cloneSampleCall, cloneSampleCallIsLoading] = useServiceCallPro2(samplesSvc, samplesSvc.cloneRequestSample);

    const [materials, setMaterials] = useState<Material[]>([]);
    const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);

    const [isSampleUsed, setIsSampleUsed] = useState<boolean>(false);
    const [isValmetManufacturer, setIsValmetManufacturer] = useState<boolean>(true);

    const valmetMaterialFormControl = useFormControl<Material>({
        validators: [validateRequiredIfValmetManufacturer(isValmetManufacturer)],
        enableAutoValidate: true,
        isDisabled: false
    });

    const batchNumberFormControl = useFormControl<string>({});

    const otherManufacturerMaterialFormControl = useFormControl<string>({ isDisabled: false });

    const productDescriptionFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const [isOtherManufacturer, setIsOtherManufacturer] = useState<boolean>(false);

    const compareWithMaterialFormControl = useFormControl<Material>({
        isDisabled: false
    });

    const compareToSpecFormControl = useFormControl<SelectSingleOption>({
        isDisabled: false,
    });


    useEffect(() => {
        valmetMaterialFormControl.setValue(undefined);
        otherManufacturerMaterialFormControl.setValue("");
    }, [isValmetManufacturer, isOtherManufacturer]);


    useEffect(() => {
        if (!locationID) {
            openPopup(<ErrorPopup>{translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}</ErrorPopup>); return;
        }

        materialService.getMaterials(locationID, { filterByIsActive: true })
            .then((data) => {
                setMaterials(data.materials);
                setIsContentLoading(false);

            })
            .catch((error) => {
                setIsContentLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [locationID]);



    useEffect(() => {

        if (!props.cloneSampleID || !props.requestID || materials.length < 1) return;

        getSampleByIDCall(`${locationID}`, `${props.requestID}`, `${props.cloneSampleID}`)
            .then((sample) => {

                if (!sample) return;

                setIsSampleUsed(sample?.isUsed ?? false);

                if (sample.manufacturerIsValmet) {
                    setIsValmetManufacturer(true);
                    setIsOtherManufacturer(false);
                } else {
                    setIsValmetManufacturer(false);
                    setIsOtherManufacturer(true)
                }

                valmetMaterialFormControl.setValue(materials.find((m) => m.id === sample.material?.id));
                compareWithMaterialFormControl.setValue(materials.find((m) => m.id === sample.compareToMaterial?.id));

                if (sample.otherMaterial)
                    otherManufacturerMaterialFormControl.setValue(sample.otherMaterial);

                if (sample.productDescription)
                    productDescriptionFormControl.setValue(sample.productDescription);

                if (sample.batchNumber)
                    batchNumberFormControl.setValue(sample.batchNumber);

                if (sample.compareToSpec === TargetSpecEnum.TECHNOTE || sample.compareToSpec === TargetSpecEnum.TARGETSAP)
                    compareToSpecFormControl.setValue(compareToSpec.find((c) => c.id === sample.compareToSpec?.toString()));

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.cloneSampleID, props.requestID, materials]);





    const handleSaveButtonClicked = useCallback(() => {

        if (!AllValid(valmetMaterialFormControl.validate(), productDescriptionFormControl.validate())) return;
        if (!props.requestID || !props.locationID) return;

        setOnClickSubmit(true);

        var requestDto: RequestSampleCreateDTO = {
            isUsed: isSampleUsed,
            manufacturerIsValmet: isValmetManufacturer,
            materialId: valmetMaterialFormControl.value?.id || null,
            otherMaterial: otherManufacturerMaterialFormControl.value || null,
            compareToMaterialId: compareWithMaterialFormControl.value?.id || null,
            compareToSpec: compareToSpecFormControl.value?.id ? GetTargetSpecEnum[compareToSpecFormControl.value?.id] : null,
            productDescription: productDescriptionFormControl.value,
            batchNumber: batchNumberFormControl.value
        }

        samplesSvc.addRequestSample(props.locationID, props.requestID, requestDto)
            .then(() => {
                setOnClickSubmit(false);
                props.onCompletedOperations();
                createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("REQUESTS.INFOANDFORM_2.AddSampleSuccess")}></Notification>);
                closePopup();
            }).catch((error) => {
                setOnClickSubmit(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })


    }, [valmetMaterialFormControl.validate, productDescriptionFormControl.validate, batchNumberFormControl.value, openPopup,
        isSampleUsed, isValmetManufacturer, valmetMaterialFormControl.value, otherManufacturerMaterialFormControl.value,
    compareWithMaterialFormControl.value, productDescriptionFormControl.value, setOnClickSubmit, props.onCompletedOperations, closePopup, createNotification]);



    const handleCloneClicked = useCallback(() => {

        if (!AllValid(valmetMaterialFormControl.validate(), productDescriptionFormControl.validate()) || !props.cloneSampleID) return;

        if (!props.requestID || !props.locationID) return;

        var requestDto: RequestSampleCreateDTO = {
            isUsed: isSampleUsed,
            manufacturerIsValmet: isValmetManufacturer,
            materialId: valmetMaterialFormControl.value?.id || null,
            otherMaterial: otherManufacturerMaterialFormControl.value || null,
            compareToMaterialId: compareWithMaterialFormControl.value?.id || null,
            compareToSpec: compareToSpecFormControl.value?.id ? GetTargetSpecEnum[compareToSpecFormControl.value?.id] : null,
            productDescription: productDescriptionFormControl.value,
            batchNumber: batchNumberFormControl.value
        }

        cloneSampleCall(props.locationID, props.requestID, props.cloneSampleID, requestDto)
            .then(() => {
                setOnClickSubmit(false);
                props.onCompletedOperations();
                createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("REQUESTS.INFOANDFORM_2.AddSampleSuccess")}></Notification>);
                closePopup();
            }).catch((error) => {
                setOnClickSubmit(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })


    }, [valmetMaterialFormControl.validate, productDescriptionFormControl.validate, batchNumberFormControl.value, openPopup,
        isSampleUsed, isValmetManufacturer, valmetMaterialFormControl.value, otherManufacturerMaterialFormControl.value, props.cloneSampleID, cloneSampleCall,
    compareWithMaterialFormControl.value, productDescriptionFormControl.value, setOnClickSubmit, props.onCompletedOperations, closePopup, createNotification]);



    return (
        <PopupContainer className="add-request-sample">
            <PopupHeader
                title={`${translate("REQUESTS.AddNewSample")}`} subtitle={`${translate("REQUESTS.INFOANDFORM.Type")}: ` + `AR`}
            />
            <PopupContent isLoading={isContentLoading || getSampleByIDCallIsLoading} >
                {onClickSubmit || cloneSampleCallIsLoading ? <FullScreenLoader /> : null}
                <FormContainer>

                    <div className="separator">{translate("REQUESTS.INFOANDFORM.Manufacturer")}</div>
                    <div className="manufacturer">
                        <Checkbox
                            text={"Valmet"}
                            isChecked={isValmetManufacturer}
                            onClick={() => { setIsValmetManufacturer(true); setIsOtherManufacturer(false) }}
                        />
                        <Checkbox
                            text={translate("REQUESTS.INFOANDFORM.Other")}
                            isChecked={isOtherManufacturer}
                            onClick={() => { setIsOtherManufacturer(true); setIsValmetManufacturer(false) }}
                        />
                    </div>
                    <div className="separator">{translate("REQUESTS.POPUPS.SampleStatus")}</div>
                    <Checkbox
                        text={translate("REQUESTS.Used")}
                        isChecked={isSampleUsed}
                        onClick={() => setIsSampleUsed(!isSampleUsed)}
                    />
                    <div className="separator">{translate("REQUESTS.INFOANDFORM.Material")}</div>
                    <FormFieldTextInput
                        formControl={productDescriptionFormControl}
                        label={translate("REQUESTS.INFOANDFORM.ProductDescription")}
                        placeholder={translate("REQUESTS.INFOANDFORM.ProductDescriptionPlaceholder")}
                    />
                    <ConditionalRender if={isValmetManufacturer}>
                        <FormFieldSelectSingle
                            label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
                            placeholder={translate("SAMPLES.INFOANDFORM.SAPMaterialPlaceholder")}
                            disableFuzzySearch
                            options={materials}
                            labelSelector={labelSelector}
                            idSelector={idSelector}
                            formControl={valmetMaterialFormControl}
                            isClearSelectionAvailable={false}
                        />
                    </ConditionalRender>
                    <ConditionalRender if={isOtherManufacturer}>
                        <FormFieldTextInput
                            formControl={otherManufacturerMaterialFormControl}
                            label={translate("SAMPLES.INFOANDFORM.Material")}
                            placeholder={translate("REQUESTS.INFOANDFORM.MaterialPlaceholder")}
                        />
                    </ConditionalRender>



                    <FormFieldTextInput
                        formControl={batchNumberFormControl}
                        label={translate("SAMPLES.INFOANDFORM.Batch") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
                    />





                    <div className="separator">{translate("REQUESTS.INFOANDFORM.CompareWith")}</div>

                    <FormFieldSelectSingle
                        label={translate("REQUESTS.INFOANDFORM.MaterialDescription") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("REQUESTS.INFOANDFORM.MaterialDescriptionPlaceholder")}
                        disableFuzzySearch
                        options={materials}
                        labelSelector={labelSelector}
                        idSelector={idSelector}
                        formControl={compareWithMaterialFormControl}
                        isClearSelectionAvailable={true}
                    />
                    <FormFieldSelectSingle
                        label={translate("REQUESTS.INFOANDFORM.TargetOrTechNote") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("REQUESTS.INFOANDFORM.TargetOrTechNotePlaceholder")}
                        options={compareToSpec}
                        labelSelector={labelCompareToSpecSelector}
                        idSelector={idCompareToSpecSelector}
                        formControl={compareToSpecFormControl}
                        isClearSelectionAvailable={true}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: props.cloneSampleID ? handleCloneClicked : handleSaveButtonClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
