import "./FormSection.css";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { SectionSeparator } from "../separator/SectionSeparator";
import { useMemo } from "react";

interface IFormSectionProps {
  title?: string;
  children: React.ReactNode;
  childrenFlexDirColumn?: boolean;
  unsetFlexGrow?: boolean;
  className?: string;
}

export function FormSection(props: IFormSectionProps) {
  const formSectionCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("form-section")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const sectionInputsCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("section-inputs")
      .addConditional(props.unsetFlexGrow, "flex-unset")
      .addConditional(props.childrenFlexDirColumn, "column")
      .build();
  }, [props.childrenFlexDirColumn, props.unsetFlexGrow]);

  const renderSectionSeparator = useMemo(() => {
    if (props.title) return <SectionSeparator title={props.title} />;
  }, [props.title]);

  return (
    <div className={formSectionCss}>
      {renderSectionSeparator}
      <div className={sectionInputsCss}>{props.children}</div>
    </div>
  );
}
