import "./RequestPageFilters.css";

import { AnalysisRequestStatusEnum, GetAnalysisRequestStatus } from "../../models/domain/AnalysisRequestStatusEnum";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { AnalysisRequestStatus } from "../../models/domain/AnalysisRequestStatus";
import { AnalysisRequestType } from "../../models/domain/AnalysisRequestType";
import { ReactComponent as ArrowDown } from "../../../../lib/assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../lib/assets/icons/arrowUp.svg";
import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { ReactComponent as CleanIcon } from "../../../../lib/assets/icons/clean.svg";
import { DateTime } from "luxon";
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectMultipleSimple } from "../../../../lib/components/form/form-field/FormFieldSelectMultipleSimple";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { GetAnalysisRequestType } from "../../models/domain/AnalysisRequestTypeEnum";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { SelectOption } from "../../models/domain/SelectOption";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { validateDateTime } from "../../../../lib/validators/ValidateDateTime";

const labelRequestStatusSelector = (item: AnalysisRequestStatus) => item.description;
const idRequestStatusSelector = (item: AnalysisRequestStatus) => item.id;

const labelRequestTypeSelector = (item: AnalysisRequestType) => item.description;
const idRequestTypeSelector = (item: AnalysisRequestType) => item.id;

export interface RequestFilters {
  analysisRequestType: AnalysisRequestType | undefined;
  analysisRequestStatus: AnalysisRequestStatus[];
  isRequestDateSelected: boolean;
  searchWord: string | undefined;
  initialCreationDate: DateTime | undefined;
  finalCreationDate: DateTime | undefined;
  isAuthor: boolean;
  byAnalist: boolean;
}

interface ISetOptions {
  clearFilters?: boolean;
  defaultFilters?: boolean;
}

export interface FiltersForm {
  cleanFilters: () => void;
  setStatus: (
    status: AnalysisRequestStatusEnum[],
    options: ISetOptions | undefined
  ) => void;
  setRequestDate: (options: ISetOptions | undefined) => void;
}

export interface IFiltersProps {
  defaultStatusAsEnum: AnalysisRequestStatusEnum[];
  requestStatusOptions: AnalysisRequestStatus[];
  onFiltersChanged: (values: RequestFilters) => void;
}

export const RequestPageFilters = forwardRef(function RequestPageFilters(props: IFiltersProps, ref: React.ForwardedRef<FiltersForm>) {
  const windowResize = useWindowResize();
  const upperRowRef = useRef<HTMLDivElement>(null);
  const bottomRowRef = useRef<HTMLDivElement>(null);

  const requestStatus = useMemo(() => {
    return GetAnalysisRequestStatus();
  }, []);

  const requestTypes = useMemo(() => {
    return GetAnalysisRequestType()
  }, []);


  const defaultSampleStatuses = useMemo(() => {

    if (!props.defaultStatusAsEnum && !props.requestStatusOptions) return;
    const requestStatusKeysAsString = props.defaultStatusAsEnum.map((key) => key.toString());

    return props.requestStatusOptions.filter((obj) => requestStatusKeysAsString.includes(obj.id));

  }, [props.requestStatusOptions, props.defaultStatusAsEnum]);

  

  var searchBoxFormControl = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
    initialValue: "",
  });


  const requestStatusFormControl = useFormControl<AnalysisRequestStatus[]>({
    isDisabled: false,
    initialValue: defaultSampleStatuses
  });

  const requestTypeFormControl = useFormControl<AnalysisRequestType>({ isDisabled: false });

  const initialDatePickerControl = useFormControl<DateTime>({
    validators: [validateDateTime()],
    enableAutoValidate: false,
    isDisabled: false,
  });

  const finalDatePickerControl = useFormControl<DateTime>({
    validators: [validateDateTime()],
    enableAutoValidate: false,
    isDisabled: false,
  });

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();

  const [isRequestDateSelected, setIsRequestDateSelected] = useState<boolean>(false);
  const [isByAnalistSelected, setIsByAnalistSelected] = useState<boolean>(false);
  const [isFiltersPanelExpanded, setIsFiltersPanelExpanded] = useState<boolean>(false);
  const [isAuthorSelected, setIsAuthorSelected] = useState<boolean>(false);

  const handleClearFilters = useCallback(() => {
    searchBoxFormControl.setValue("");
    requestTypeFormControl.setValue({ id: "", description: "" });
    setIsRequestDateSelected(false);
    setIsByAnalistSelected(false);
    setIsAuthorSelected(false);
    requestStatusFormControl.setValue([]);
    initialDatePickerControl.setValue(undefined);
    finalDatePickerControl.setValue(undefined);
  }, [
    searchBoxFormControl,
    setIsByAnalistSelected,
    requestTypeFormControl,
    setIsRequestDateSelected,
    setIsAuthorSelected,
    requestStatusFormControl,
    initialDatePickerControl,
    finalDatePickerControl,
  ]);

  useImperativeHandle(ref, () => {
    return {
      cleanFilters: () => { handleClearFilters(); },
      setStatus: (values: AnalysisRequestStatusEnum[], options: ISetOptions | undefined) => {
        if (options && options.clearFilters) handleClearFilters();
        if (options && options.defaultFilters)
          requestStatusFormControl.setValue([]);

        var mappedStatus = values.map((v) => { return props.requestStatusOptions.find((s) => s.id === `${v}`); })
          .filter((s) => s) as AnalysisRequestStatus[];

        requestStatusFormControl.setValue(mappedStatus);
      },
      setRequestDate: (options: ISetOptions | undefined) => {
        setIsRequestDateSelected(true);
      },
    };
  },
    [handleClearFilters, props.requestStatusOptions, requestStatusFormControl.setValue,
      setIsRequestDateSelected, props.defaultStatusAsEnum]
  );

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    var responseTimeOut = setTimeout(() => {
      if (timer) {
        setSearchWord(searchBoxFormControl.value);
      }
    }, 1000);
    setTimer(responseTimeOut);
  }, [searchBoxFormControl.value]);


  useEffect(() => {
    props.onFiltersChanged({
      isRequestDateSelected: isRequestDateSelected,
      analysisRequestStatus: requestStatusFormControl.value || [],
      analysisRequestType: requestTypeFormControl.value,
      searchWord: searchWord,
      initialCreationDate: initialDatePickerControl.value,
      finalCreationDate: finalDatePickerControl.value,
      byAnalist: isByAnalistSelected,
      isAuthor: isAuthorSelected
    });
  }, [
    searchWord, isByAnalistSelected, isAuthorSelected, requestTypeFormControl.value, requestStatusFormControl.value, initialDatePickerControl.value,
    finalDatePickerControl.value, isRequestDateSelected]);


    
  const handleResetFiltersClicked = useCallback(() => {
    searchBoxFormControl.setValue("");
    requestTypeFormControl.setValue({ id: "", description: "" });
    requestStatusFormControl.setValue(defaultSampleStatuses);
    initialDatePickerControl.setValue(undefined);
    finalDatePickerControl.setValue(undefined);
    setIsAuthorSelected(false);

  }, [searchBoxFormControl.setValue, setIsAuthorSelected, requestTypeFormControl.setValue, requestStatusFormControl.setValue, initialDatePickerControl.setValue, finalDatePickerControl.setValue]);




  useEffect(() => {
    const upperRowNode = upperRowRef.current;
    const bottomRowNode = bottomRowRef.current;

    if (upperRowNode && bottomRowNode) {
      let hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
        return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
      });

      while (hasWrapOccurred) {
        const lastChild = upperRowNode.lastElementChild;
        if (lastChild) {
          upperRowNode.removeChild(lastChild);
          bottomRowNode.appendChild(lastChild);
        }

        hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
          return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
        });
      }
    }
  }, [windowResize]);

  return (
    <div className="view-requests-page-filters">
      <div className="filters">
        <div className="upper-row-filters" ref={upperRowRef}>
          <FormFieldTextInput
            formControl={searchBoxFormControl}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
          />
          <FormFieldSelectSingle
            placeholder={translate("SAMPLES.INFOANDFORM.TypePlaceholder")}
            options={requestTypes}
            labelSelector={labelRequestTypeSelector}
            idSelector={idRequestTypeSelector}
            formControl={requestTypeFormControl}
            isClearSelectionAvailable={true}
          />
          <FormFieldSelectMultipleSimple<SelectOption>
            placeholder={translate("COMMON.FORM.SelectStatusPlaceholder")}
            options={requestStatus}
            labelSelector={labelRequestStatusSelector}
            idSelector={idRequestStatusSelector}
            formControl={requestStatusFormControl}
            maxHeightOptions={"16rem"}
          />

          <Checkbox
            text={translate("REQUESTS.MyRequests")}
            isChecked={isAuthorSelected}
            onClick={() => setIsAuthorSelected(!isAuthorSelected)}
          />
        </div>
        <div className="action-button-filters">
          <IconButton
            icon={isFiltersPanelExpanded ? <ArrowUp /> : <ArrowDown />}
            type="tertiary"
            onClick={() => setIsFiltersPanelExpanded(!isFiltersPanelExpanded)}
            className="view-more-filters-btn"
          />
          <Tooltip text={translate("COMMON.FORM.CleanFilters")}>
            <IconButton
              icon={<CleanIcon />}
              type="secondary"
              onClick={handleResetFiltersClicked}
              className="reset-filters-btn"
            />
          </Tooltip>
        </div>
        <div className={"bottom-row-filters" + (isFiltersPanelExpanded ? " expanded" : "")} ref={bottomRowRef}>
          <FormFieldDatePicker
            className="date-picker"
            formControl={initialDatePickerControl}
            placeholder={translate("COMMON.FORM.InitialCreationDatePlaceholder")}
            maxDate={finalDatePickerControl.value}
          />
          <FormFieldDatePicker
            className="date-picker"
            formControl={finalDatePickerControl}
            placeholder={translate("COMMON.FORM.FinalCreationDatePlaceholder")}
            minDate={initialDatePickerControl.value}
          />
        </div>
      </div>
    </div>
  );
});
