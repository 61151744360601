import { Navigate, Route, Routes } from "react-router-dom";

import { CreateRequestPage } from "../features/requests/pages/create-request-page/CreateRequestPage";
import { CreateRequestTypePage } from "../features/requests/pages/choose-request-types-page/CreateRequestTypePage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from "../models/api/gate/Permission";
import { RequestDetailsPage } from "../features/requests/pages/request-details-page/RequestDetailsPage";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { ViewRequestsPage } from "../features/requests/pages/ViewRequestsPage";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";

const moduleDefinition: ModuleDefinition = {
  basePath: "/requests",
  routes: [
    {
      name: () => translate("REQUESTS.ViewRequests"),
      pathPattern: "/",
    },
    {
      name: () => translate("REQUESTS.CreateRequest"),
      pathPattern: "/create/types",
    },
    {
      name: () => translate("REQUESTS.ViewRequests"),
      pathPattern: "view",
    },
    {
      name: () => "##REQUEST##",
      pathPattern: "/view/:requestId",
    },
  ],
};



const permissionsToViewRequests = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_AR,
  Permission.LOCATION_DF,
  Permission.LOCATION_DRYER,
  Permission.LOCATION_PP,
  Permission.LOCATION_WF,
  Permission.LOCATION_ANALISYS_REQUEST_GLOBAL,
   Permission.LOCATION_REGISTER_SAMPLES, 
  Permission.LOCATION_ACCESS];


const permissionsToAddRequest = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_AR,
  Permission.LOCATION_DF,
  Permission.LOCATION_DRYER,
  Permission.LOCATION_PP,
  Permission.LOCATION_WF,
  Permission.LOCATION_ANALISYS_REQUEST_GLOBAL,
  Permission.LOCATION_ACCESS];


export function RequestsModule() {
  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);

  return (

    <RouteGuard permissions={permissionsToViewRequests}>
      <Routes>
        <Route path="/" element={<Navigate to="view" />} />
        <Route path="/view" element={<ViewRequestsPage />} />
        <Route path="/view/:requestId" element={<RequestDetailsPage />} />

          //TODO: FALTA PROTEGER A ROTA DE QUEM PODE CRIAR PEDIDOS.
        <Route path="/create/types" element={<CreateRequestTypePage />} />

        <Route path="/create/types/:type" element={<CreateRequestPage />} />
        <Route path="/create/types/:type/:cloneId" element={<CreateRequestPage />} />
      </Routes >
    </RouteGuard >
  );
}