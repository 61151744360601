import "./CreateRequestDetails.css";

import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { AnalysisRequestSampleDetails } from "../../../models/domain/AnalysisRequestSampleDetails";
import { AnalysisRequestService } from "../../../services/AnalysisRequestService";
import { AnalysisRequestTypeEnum } from "../../../models/domain/AnalysisRequestTypeEnum";
import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { Contact } from "../../../../samples/models/domain/Contact";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { IGenericRequestDetailsFormsProps } from "../CreateRequestPage";
import { Permission } from "../../../../../models/api/gate/Permission";
import { RequestContactsService } from "../../../services/RequestContactsService";
import { RequestDetails } from "../../../models/domain/RequestDetails";
import { RequestForm } from "../../../models/domain/RequestForm";
import { RequestSampleSummaryDTO } from "../../../models/api/RequestSampleSummaryDTO";
import { RequestSamplesService } from "../../../services/RequestSamplesService";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useHasPermissions } from "../../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

var analysisRequestSvc = new AnalysisRequestService();
var analysisRequestSampleSvc = new RequestSamplesService();
var analysisRequestContactsSvc = new RequestContactsService();

const dateNow = DateTime.now();

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];


export const CreateRequestDetailsAR = forwardRef(
    function CreateRequestDetailsAR(props: IGenericRequestDetailsFormsProps, ref: React.ForwardedRef<RequestForm>) {
        const openPopup = usePopup();

        const [requestDetails, setRequestDetails] = useState<RequestDetails>();
        const [requestSamples, setRequestSamples] = useState<RequestSampleSummaryDTO[]>([]);
        const [requestContacts, setRequestContacts] = useState<Contact[]>([]);
        
        const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);

        const requestNameFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const companyFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const industryFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const creationRequestDateFormControl = useFormControl<DateTime>({
            validators: [validateDateTime()],
            enableAutoValidate: false,
            initialValue: dateNow,
        });
        const customerCommentsFormControl = useFormControl<string>({ isDisabled: false });

        const ketoProjectFormControl = useFormControl<string>({});


        /*****************
         * SERVICE CALLS 
        ****************/


        const [getAnalysisRequestByIdCall, getAnalysisRequestByIdCallIsLoading] = useServiceCallPro2(analysisRequestSvc, analysisRequestSvc.getAnalysisRequestById);
        const [getRequestSamplesCall, getRequestSamplesCallIsLoading] = useServiceCallPro2(analysisRequestSampleSvc, analysisRequestSampleSvc.getRequestSamples);
        const [getRequestContactsCall, getRequestContactsCallIsLoading] = useServiceCallPro2(analysisRequestContactsSvc, analysisRequestContactsSvc.getRequestContacts);





        /****************************
         * DATA REQUESTS
        *****************************/



        const getAnalysisRequestById = useCallback(() => {

            if (!props.locationId || !props.cloneId) return;
        
            getAnalysisRequestByIdCall(`${props.locationId}`, `${props.cloneId}`)
              .then((data) => {
                if (!data) return;
                setRequestDetails(data);
              })
              .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
              });
          }, [props.locationId, props.cloneId, getAnalysisRequestByIdCall, setRequestDetails, openPopup]);




          
        //   const getRequestSamples = useCallback(() => {
        //     if (!props.locationId || !props.cloneId) return;
        
        //     getRequestSamplesCall(`${props.locationId}`, `${props.cloneId}`)
        //       .then((response) => {
        //         setRequestSamples(response);
        //       })
        //       .catch((error) => {
        //         if (!error) return;
        //         openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        //       })
        //   }, [props.locationId, props.cloneId, getRequestSamplesCall, setRequestSamples, openPopup]);





        //   const getRequestContacts = useCallback(() => {
        //     if (!props.locationId || !props.cloneId) return;
        
        //     getRequestContactsCall(`${props.locationId}`, `${props.cloneId}`)
        //       .then((response) => {
        //         setRequestContacts(response);
        //       })
        //       .catch((error) => {
        //         if (!error) return;
        //         openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        //       })
        //   }, [props.locationId, props.cloneId, getRequestContactsCall, setRequestContacts, openPopup]);



        /****************************
         * DATA MANIPULATION EFFECTS
        *****************************/



        


        useEffect(() => {
            getAnalysisRequestById();
            // getRequestSamples();
            // getRequestContacts();
        }, []);





        useEffect(() => {
            if (!props.locationId || !props.cloneId) return;
        
                requestNameFormControl.setValue(requestDetails?.name);
                companyFormControl.setValue(requestDetails?.company);
                industryFormControl.setValue(requestDetails?.industry);
                if(requestDetails?.createdDate)
                    creationRequestDateFormControl.setValue(DateTime.fromISO(requestDetails?.createdDate));
                customerCommentsFormControl.setValue(requestDetails?.customerComments);
                ketoProjectFormControl.setValue(requestDetails?.ketoProject);
        
          }, [props.locationId, props.cloneId, requestDetails]);



        
        useEffect(() => {
            if (!isLoadingPermissions && !hasPermission)
                creationRequestDateFormControl.setIsDisabled(true);

        }, [hasPermission, isLoadingPermissions]);







        useImperativeHandle(ref, () => {
            return {
                validate() {
                    return AllValid(requestNameFormControl.validate(), creationRequestDateFormControl.validate(), companyFormControl.validate(), industryFormControl.validate());
                },
                value() {
                    return {
                        requestType: AnalysisRequestTypeEnum.AR,
                        name: requestNameFormControl.value,
                        createdDate: creationRequestDateFormControl.value,
                        company: companyFormControl.value,
                        industry: industryFormControl.value,
                        customerComments: customerCommentsFormControl.value,
                        ketoProject: ketoProjectFormControl.value
                    }
                }
            }
        }, [requestNameFormControl.validate, creationRequestDateFormControl.validate, companyFormControl.validate, industryFormControl.validate, ketoProjectFormControl.value,
        requestNameFormControl.value, creationRequestDateFormControl.value, companyFormControl.value, industryFormControl.value, customerCommentsFormControl.value]);





        /****************************
         * CSS & HTML
        *****************************/

        return (
            <CardContainer className="create-request-form-container">
                <div className="subtitle">{translate("REQUESTS.RequestDetails")}</div>
                <div className="create-request-form">
                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            formControl={requestNameFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Name")}
                            placeholder={translate("REQUESTS.INFOANDFORM.NamePlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldDatePicker
                            formControl={creationRequestDateFormControl}
                            label={translate("REQUESTS.INFOANDFORM.CreationDate")}
                            maxDate={dateNow}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.Client")}</div>

                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            formControl={companyFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Company")}
                            placeholder={translate("REQUESTS.INFOANDFORM.CompanyPlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldTextInput
                            formControl={industryFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Industry")}
                            placeholder={translate("REQUESTS.INFOANDFORM.IndustryPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="small-copy separator">{translate("REQUESTS.OtherData")}</div>

                    <div className="request-info-inputs">
                        <FormFieldTextArea
                            formControl={ketoProjectFormControl}
                            label={translate("REQUESTS.INFOANDFORM.KetoProject") + " (" + translate("COMMON.Optional") + ")"}
                            placeholder={translate("REQUESTS.INFOANDFORM.KetoProjectPlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldTextArea
                            formControl={customerCommentsFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Comments") + " (" + translate("COMMON.Optional") + ")"}
                            placeholder={translate("REQUESTS.INFOANDFORM.CommentsPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>
                </div>
            </CardContainer>
        );
    });

