import { Sample } from "../../features/samples/models/domain/Sample";
import { SampleTypeEnum } from "../../features/samples/models/domain/SampleTypeEnum";

export function buildMaterialCellinfo(sample: Sample) {
    var response = "";

    if (sample.othersMaterialDescription)
        response = sample.othersMaterialDescription;
    if (sample.sapNumber)
        response = sample.sapNumber + " - " + sample.materialDescription;
    if(sample.typeId === SampleTypeEnum.PACKAGE && sample.waybill)
        response = sample.waybill;

    return response;
}


export function buildMaterialCellInfo2(sample: Sample) {
    var response = "";

    if (sample.othersMaterialDescription)
        response = sample.othersMaterialDescription;
    if (sample.sapNumber)
        response = sample.sapNumber;
    if(sample.typeId === SampleTypeEnum.PACKAGE && sample.waybill)
        response = sample.waybill;

    return response;
}