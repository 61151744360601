import "./FacilitiesHomePage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";

import { AddFacilityPopup } from "./AddFacilityPopup";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { Button } from "../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { EditFacilityPopup } from "./EditFacilityPopup";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FacilitiesQueryOptions } from "../../../models/api/facilities/FacilitiesQueryOptions";
import { FacilityDTO } from "../../../models/api/facilities/FacilitiesResponseDTO";
import { FacilityService } from "../../../services/FacilityService";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useParams } from "react-router-dom";

/***** ICONS ******/

var facilityService = new FacilityService();

export function FacilitiesHomePage() {
  const { id: locationId } = useParams();
  const createNotification = useCreateNotification();
  const windowResize = useWindowResize();

  const openPopup = usePopup();
  const closePopup = useClosePopup();

  const [facilities, setFacilities] = useState<FacilityDTO[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (locationId) {
      getFacilities(locationId);
    }
  }, []);

  const getFacilities = useCallback(
    (locationId: string) => {
      setIsLoading(true);

      var queryFacilityOptions: FacilitiesQueryOptions = {
        filterByIsActive: false,
      };

      if (locationId) {
        facilityService
          .getFacilities("" + locationId, queryFacilityOptions)
          .then((response) => {
            setFacilities(response.facilities);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
          });
      }
    },
    [setFacilities, setIsLoading, openPopup]
  );

  const deactivateFacility = useCallback(
    (facilityId: number) => {
      if (locationId && facilityId) {
        facilityService
          .disableFacility(locationId + "", facilityId)
          .then((_) => {
            getFacilities("" + locationId);
            createNotification(
              <Notification
                type="success"
                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                text={translate(
                  "GLOBALADMIN.FACILITIES.POPUP.DeactivateFacilitySuccess"
                )}
              ></Notification>
            );
          })
          .catch((error) => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
          });
      }
    },
    [getFacilities, openPopup, locationId, createNotification]
  );

  const activateFacility = useCallback(
    (facilityId: number) => {
      if (facilityId && locationId) {
        facilityService
          .activateFacility(locationId + "", facilityId)
          .then((_) => {
            getFacilities("" + locationId);
            createNotification(
              <Notification
                type="success"
                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                text={translate(
                  "GLOBALADMIN.FACILITIES.POPUP.ActivateFacilitySuccess"
                )}
              ></Notification>
            );
          })
          .catch((error) => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
          });
      }
    },
    [getFacilities, openPopup, locationId, createNotification]
  );

  const columns: ColumnDefinition<FacilityDTO>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.facilityId,
        width: "15%",
        headerRender: <>ID</>,
        columnKey: "Name",
        isSortable: true,
      },
      {
        cellRenderProp: (v) => v.name,
        width: "70%",
        headerRender: <>{translate("GLOBALADMIN.LOCATIONS.INFO.Name")}</>,
        columnKey: "options",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => (
          <div className="toggle-div">
            <Toggle value={!v.isDisabled} />

            <div
              className="toggle-div-overlay"
              onClick={(ev) => {
                ev.stopPropagation();
                if (!v.isDisabled) {
                  openPopup(
                    <WarningPopup
                      className="disable-facility-popup"
                      onDismissClicked={() => {
                        closePopup();
                      }}
                      onConfirmClicked={() => {
                        deactivateFacility(v.facilityId);
                      }}
                      dismissText={translate("COMMON.Cancel")}
                      confirmText={translate(
                        "GLOBALADMIN.FACILITIES.POPUP.Deactivate"
                      )}
                    >
                      {translate(
                        "GLOBALADMIN.FACILITIES.POPUP.DeactivateFacilityConfirmText"
                      )}
                    </WarningPopup>
                  );
                } else {
                  if (v.facilityId) {
                    openPopup(
                      <WarningPopup
                        onDismissClicked={() => {
                          closePopup();
                        }}
                        onConfirmClicked={() => {
                          activateFacility(v.facilityId);
                        }}
                        dismissText={translate("COMMON.Cancel")}
                        confirmText={translate(
                          "GLOBALADMIN.LOCATIONS.POPUP.Activate"
                        )}
                      >
                        {translate(
                          "GLOBALADMIN.FACILITIES.POPUP.ActivateFacilityConfirmText"
                        )}
                      </WarningPopup>
                    );
                  }
                }
              }}
            />
          </div>
        ),
        width: "5%",
        columnKey: "stats",
        isSortable: false,
        isMobileHeaderIcon: true
      },
      {
        cellRenderProp: (v) => (
          <ScalableIconButton
            icon={<EditIcon />}
            onClick={() => {
              if (v.facilityId && locationId) {
                openPopup(
                  <EditFacilityPopup
                    locationId={locationId}
                    facilityId={v.facilityId}
                    onCompleted={() => {
                      getFacilities("" + locationId);
                    }}
                  />
                );
              }
            }}
            size={30}
          />
        ),
        width: "5%",
        columnKey: "stats",
        isSortable: false,
        isMobileHeaderIcon: true
      },
    ],
    [openPopup, getFacilities, locationId, activateFacility, closePopup, deactivateFacility]
  );

  const renderActionButtons = useMemo(() => {
    if (windowResize > 768)
      return (
        <Button
          text={translate("GLOBALADMIN.FACILITIES.CreateFacility")}
          type="primary"
          onClick={() => {
            const parsedLocationId = parseInt(locationId || "", 10);
            if (Number.isNaN(parsedLocationId)) return;
            openPopup(
              <AddFacilityPopup
                onCompleted={() => {
                  getFacilities("" + locationId);
                }}
                locationId={Number(locationId)}
              />
            );
          }}
        />
      );
    else
      return (
        <IconButton
          icon={<AddIcon />}
          type="primary"
          onClick={() => {
            const parsedLocationId = parseInt(locationId || "", 10);
            if (Number.isNaN(parsedLocationId)) return;
            openPopup(
              <AddFacilityPopup
                onCompleted={() => {
                  getFacilities("" + locationId);
                }}
                locationId={Number(locationId)}
              />
            );
          }}
        />
      );
  }, [getFacilities, locationId, openPopup, windowResize]);

  return (
    <PageLayout
      tabTitle={"laMP - " + translate("GLOBALADMIN.FACILITIES.Facilities")}
      pageTitle={translate("GLOBALADMIN.FACILITIES.Facilities")}
      className="facilities-home"
      showBreadcrumb
      actions={renderActionButtons}
    >
      <ResponsiveDataTable
        columnDefinitions={columns}
        items={facilities || []}
        totalitems={facilities?.length || 0}
        onClickRow={(facility: FacilityDTO) => {
          openPopup(
            <EditFacilityPopup
              locationId={"" + locationId}
              facilityId={facility.facilityId}
              onCompleted={() => {
                getFacilities("" + locationId);
              }}
            />
          );
        }}
        isLoading={isLoading}
      ></ResponsiveDataTable>
    </PageLayout>
  );
}
