export enum Permission {
    LOCATION_ACCESS = 1,
    LOCATION_REGISTER_SAMPLES = 2,
    LOCATION_ADMIN = 3,
    LOCATION_ANALISTS = 4,
    LOCATION_ANALISYS_REQUEST_GLOBAL = 5,
    LOCATION_AR = 6,
    LOCATION_DF = 7,
    LOCATION_DRYER = 8,
    LOCATION_PP = 9,
    LOCATION_WF = 10,
    GLOBAL_ADMIN = 100
}