import { FullScreenLoader } from "../../components/loader/FullScreenLoader";
import { Navigate } from "react-router-dom";
import { Permission } from "../../../models/api/gate/Permission";
import { useHasPermissions } from "./useHasLocationPermissions";

interface IRouteGuardProps {
  permissions: Permission[];
  children: React.ReactNode;
}

export function RouteGuard(props: IRouteGuardProps) {

  const { isLoadingPermissions, hasPermission } = useHasPermissions(props.permissions);

  if (isLoadingPermissions) return <FullScreenLoader />;

  if (hasPermission) return <>{props.children}</>;

  return <Navigate to="/access-denied" />;
}