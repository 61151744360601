import "./ViewRequestsPage.css";

import { AnalysisRequestStatusEnum, GetAnalysisRequestStatus, RequestStatusAsEnum } from "../models/domain/AnalysisRequestStatusEnum";
import { RequestTypeAsEnum, RequestTypeTranslationTerms } from "../models/domain/AnalysisRequestTypeEnum";
import { FiltersForm, RequestFilters, RequestPageFilters } from "./request-page-filters/RequestPageFilters";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { AnalysisRequestFiltersDTO } from "../models/api/GetAnalysisRequestFiltersDTO";
import { AnalysisRequestService } from "../services/AnalysisRequestService";
import { AnalysisRequestStats } from "../models/domain/AnalysisRequestStats";
import { ReactComponent as CloneIcon } from "../../../lib/assets/icons/clone.svg";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { DateFormatByCurrentLanguage } from "./request-details-page/FormatDateByCurrentLocal";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { LargeButton } from "../../../lib/components/buttons/LargeButton";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../models/api/gate/Permission";
import { RequestDetails } from "../models/domain/RequestDetails";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { StatusCard } from "../../../lib/components/cards/stats-card/StatusCard";
import { Tag } from "../../../lib/components/tag/Tag";
import { Tooltip } from "../../../lib/components/tooltip/Tooltip";
import axios from "axios";
import { requestStatusCss } from "../../../common/helpers/RequestStatusCss";
import { requestStatusLabel } from "../../../common/helpers/RequestStatusLabel";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";

var analysisRequestSvc = new AnalysisRequestService();

export interface Request {
  requestId: number;
  name: string;
  typeId: number;
  numSamples: number;
  requester: string;
  analyst: string;
  creationDate: DateTime;
  statusId: number;
}



const permissionsToAddRequest = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_AR,
  Permission.LOCATION_DF,
  Permission.LOCATION_DRYER,
  Permission.LOCATION_PP,
  Permission.LOCATION_WF,
  Permission.LOCATION_ANALISYS_REQUEST_GLOBAL,
/*   Permission.LOCATION_ACCESS */
];



const defaultRequestStatus = [
  AnalysisRequestStatusEnum.DRAFT,
  AnalysisRequestStatusEnum.REGISTERED
];



export function ViewRequestsPage() {

  const navigate = useNavigate();
  const locationId = useGlobalLocation();
  const openPopup = usePopup();
  const windowResize = useWindowResize();
  const location = useLocation();

  const { isLoadingPermissions: isloadingPermissionToAddRequest, hasPermission: hasPermissionToAddRequest } = useHasPermissions(permissionsToAddRequest);

  const [isLoadingContent, setIsLoadingContent] = useState<boolean>();

  const [currentpage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  /****************************
    ***** FILTER STATES *****
   *****************************/

  const [pageFilters, setPageFilters] = useState<RequestFilters>();

  const [requests, setRequests] = useState<RequestDetails[]>([]);
  const [cardStats, setCardStats] = useState<AnalysisRequestStats>();
  const [totalItems, setTotalItems] = useState<number>(0);

  const filtersRef = useRef<FiltersForm | null>(null);


  const requestStatus = useMemo(() => {
    return GetAnalysisRequestStatus();
  }, [GetAnalysisRequestStatus]);



  const currentPageURL = useMemo(() => {
    const baseUrl = ConfigurationProvider.getConfiguration().App.BaseUrl;
    return baseUrl + location.pathname;
  }, [location.pathname]);


  useEffect(() => {
    setCurrentPage(0);
  }, [pageFilters]);



  useEffect(() => {
    const controller = new AbortController();
    getRequests(controller);
    return () => { controller.abort() };
  }, [pageFilters, currentpage, itemsPerPage]);




  useEffect(() => {
    if (!locationId) return;

    analysisRequestSvc.getAnalysisRequestsStats(locationId).then((response) => {
      setCardStats(response);
    }).catch((error) => {
      if (!error) return;
      openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
    })
  }, [locationId]);








  const getRequests = useCallback((controller: AbortController) => {
    setIsLoadingContent(true);

    if (!locationId) return;
    if (pageFilters === undefined) return;

    var request: AnalysisRequestFiltersDTO = {
      analysisRequestType: pageFilters?.analysisRequestType?.id ? RequestTypeAsEnum[parseInt(pageFilters.analysisRequestType?.id)] : undefined,
      analysisRequestStatus: pageFilters?.analysisRequestStatus?.map(obj => RequestStatusAsEnum[parseInt(obj.id)]),
      startDate: pageFilters?.initialCreationDate?.toUTC().toISO() ?? undefined,
      endDate: pageFilters?.finalCreationDate?.toUTC().plus({ days: 1 }).toISO() ?? undefined,
      page: currentpage,
      createdByMe: pageFilters?.isAuthor,
      pageLength: itemsPerPage,
      searchWord: pageFilters?.searchWord,
    };

    analysisRequestSvc.getAnalysisRequests(locationId, request, controller.signal)
      .then((response) => {

        setIsLoadingContent(false);
        setRequests(response.requestDetails);
        setTotalItems(response.total);

      }).catch((error) => {
        setIsLoadingContent(false);

        if (!error || axios.isCancel(error)) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })
  }, [locationId, pageFilters, setIsLoadingContent, currentpage, itemsPerPage, openPopup]);


  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );


  /*   const setFiltersToDefault = useCallback(() => {
      if (!filtersRef.current) return;
      filtersRef.current.setStatus(defaultRequestStatus, { clearFilters: true }
      );
    }, [filtersRef]); */




  const handleStatusCardsClicked = useCallback(
    (status: AnalysisRequestStatusEnum[]) => {
      if (!filtersRef.current) return;

      filtersRef.current.setStatus(status, { clearFilters: true });
    },
    [filtersRef]
  );


  const columns: ColumnDefinition<RequestDetails>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.reference,
        headerRender: <>{translate("REQUESTS.INFOANDFORM.Reference")}</>,
        columnKey: "ref",
        isMobilePrimaryCell: true,
        width: "20%"
      },
      {
        cellRenderProp: (v) => v.name,
        headerRender: translate("REQUESTS.INFOANDFORM.Name"),
        columnKey: "name",
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => translate(RequestTypeTranslationTerms[v.requestType ? v.requestType : -1]),
        headerRender: translate("REQUESTS.INFOANDFORM.Type"),
        columnKey: "type",
      },
      {
        cellRenderProp: (v) => v.waybill,
        headerRender: translate("REQUESTS.INFOANDFORM.Waybill"),
        columnKey: "numSamples",
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.createdBy?.name ? v.createdBy.name : null,
        headerRender: translate("REQUESTS.INFOANDFORM.Requester"),
        columnKey: "requester",
      },
      {
        cellRenderProp: (v) => v.formalizingDate ? DateFormatByCurrentLanguage(v.formalizingDate) : null,
        headerRender: translate("REQUESTS.INFOANDFORM.FormalizingDate"),
        columnKey: "creationDate",
        width: "16%",
      },
      {
        cellRenderProp: (v) => (
          <Tag
            text={requestStatusLabel(v.requestStatus ? v.requestStatus : -1)}
            backgroundColor={requestStatusCss(v.requestStatus ? v.requestStatus : -1)}
            isTiny={windowResize < 1024}
          />
        ),
        headerRender: translate("REQUESTS.INFOANDFORM.Status"),
        columnKey: "status",
        width: "5%",
        isStatusCell: true,
      },
      {
        cellRenderProp: (v) => <div className="action-icons">
          <Tooltip text={translate("COMMON.CloneRequestAction")}>
            <ScalableIconButton
              className="clone-btn" size={24} icon={<CloneIcon />}
              onClick={() => v.requestType && v.requestId ? navigate(`/requests/create/types/${translate(RequestTypeTranslationTerms[v.requestType]).toLowerCase()}/${v.requestId.toString()}`) : null} />
          </Tooltip>
        </div>,
        columnKey: "action-icons",
        width: "4.4286rem",
        isVisible: windowResize > 768,
      },
    ],
    [windowResize, navigate]
  );

  const renderStatsCards = useMemo(() => {
    if (windowResize > 1024)
      return <div className="overview-header-stats">
        <StatusCard
          title={translate("REQUESTS.STATUS.Pending")}
          value={cardStats?.pending?.toString() || "-"}
          onClick={() => handleStatusCardsClicked([
            AnalysisRequestStatusEnum.DRAFT,
            AnalysisRequestStatusEnum.REGISTERED,
            AnalysisRequestStatusEnum.UNDERANALYSIS,
            AnalysisRequestStatusEnum.READYTOCLOSE])}
        />
        <StatusCard
          title={translate("REQUESTS.STATUS.Registed")}
          value={cardStats?.registered?.toString() || "-"}
          backgroundColor="status-light-grey"
          onClick={() => handleStatusCardsClicked([AnalysisRequestStatusEnum.REGISTERED])}
        />
        <StatusCard
          title={translate("REQUESTS.STATUS.UnderAnalysis")}
          value={cardStats?.underAnalysis?.toString() || "-"}
          backgroundColor="status-blue"
          onClick={() => handleStatusCardsClicked([AnalysisRequestStatusEnum.UNDERANALYSIS])}
        />
        <StatusCard
          title={translate("REQUESTS.STATUS.Canceled")}
          value={cardStats?.canceled?.toString() || "-"}
          backgroundColor="status-red"
          onClick={() => handleStatusCardsClicked([AnalysisRequestStatusEnum.CANCELED])}
        />
      </div>
  }, [windowResize, cardStats?.canceled, cardStats?.pending, cardStats?.registered, cardStats?.underAnalysis, handleStatusCardsClicked])

  return (
    <PageLayout
      tabTitle={`laMP - ${translate("REQUESTS.Requests")}`}
      pageTitle={translate("REQUESTS.ViewRequests")}
      className="view-requests-page"
      showBreadcrumb
    >
      <div className="overview-header">
        {renderStatsCards}
        <div className="overview-header-actions">
          <LargeButton
            isDisabled={isloadingPermissionToAddRequest || !hasPermissionToAddRequest}
            text={translate("REQUESTS.CreateRequest")}
            type="primary"
            onClick={() => navigate("/requests/create/types")}
          />
        </div>
      </div>

      <Spacer mode="vertical" px="24" />
      <div className="requests-list">
        <div className="subtitle">{translate("REQUESTS.Requests")}</div>
        <RequestPageFilters
          ref={filtersRef}
          onFiltersChanged={setPageFilters}
          requestStatusOptions={requestStatus}
          defaultStatusAsEnum={defaultRequestStatus}
        />
        <ResponsiveDataTable
          columnDefinitions={columns}
          items={requests || []}
          totalitems={totalItems || 0}
          paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30, 40, 50] }}
          currentpage={currentpage}
          isLoading={isLoadingContent}
          onPageAndItemsChanged={handleOnPageAndItemsChanged}
          onClickRow={(request) => { window.open(currentPageURL + `/${request.requestId}`, "_blank") }}
        /*  onClickRow={(request) => { navigate(`${request.requestId}`) }} */
        />
      </div>
    </PageLayout>
  );
}
