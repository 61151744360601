import "./CorrelateSamplesPopup.css";

import { GetSampleTypes, SampleTypeAsEnum, SampleTypeTranslationTerms } from "../../../../samples/models/domain/SampleTypeEnum";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as ArrowDown } from "../../../../../lib/assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../../lib/assets/icons/arrowUp.svg";
import { BetweenNumbersHelper } from "../../../../../lib/helpers/BetweenNumbersHelper";
import { ReactComponent as CleanIcon } from "../../../../../lib/assets/icons/clean.svg";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ReactComponent as CorrelateIcon } from "../../../../../lib/assets/icons/correlacionar.svg"
import { CorrelateSamplesRequestDTO } from "../../../models/api/CorrelateSamplesRequestDTO";
import { DateTimeHelper } from "../../../../../lib/utils/DateTimeHelper";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../../../lib/components/info-block/InfoBlock";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RequestSampleSummaryDTO } from "../../../models/api/RequestSampleSummaryDTO";
import { RequestSamplesService } from "../../../services/RequestSamplesService";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { Sample } from "../../../../samples/models/domain/Sample";
import { SampleMaterialDTO } from "../../../models/api/SampleMaterialDTO";
import { SampleType } from "../../../../samples/models/domain/SampleType";
import { SamplesToCorrelateFilterRequestDTO } from "../../../models/api/SamplesToCorrelateFilterRequestDTO";
import { ReactComponent as SearchIcon } from "../../../../../lib/assets/icons/search-icon.svg";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { TitledCardContainer } from "../../../../../lib/layouts/containers/card/TitledCardContainer";
import { Tooltip } from "../../../../../lib/components/tooltip/Tooltip";
import axios from "axios";
import { buildMaterialCellinfo } from "../../../../../common/helpers/BuildMaterialCellinfo";
import { getSampleStatusCss } from "../../../../../common/helpers/GetSampleStatusCss";
import { getSampleStatusLabel } from "../../../../../common/helpers/GetSampleStatusLabel";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { Notification } from "../../../../../lib/components/notifications/Notification";

var requestSampleSvc = new RequestSamplesService();

const labelSampleTypeSelector = (item: SampleType) => item.description;
const idSampleTypeSelector = (item: SampleType) => item.id;

interface ICorrelateSamplesPopup {
    className?: string;
    locationId: string | undefined;
    requestId: string | undefined;
    sampleId: string | undefined;
    waybill?: string | undefined;
    materialReference?: SampleMaterialDTO | undefined;
    onCompletedOperations?: () => void;
}

export interface CorrelateSampleFilters {
    sampleType: SampleType | undefined;
    searchWord: string | undefined;
    waybill?: string;
    material?: string;
}


export function CorrelateSamplesPopup(props: ICorrelateSamplesPopup) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const windowResize = useWindowResize();
    const createNotification = useCreateNotification();
    const upperRowRef = useRef<HTMLDivElement>(null);
    const bottomRowRef = useRef<HTMLDivElement>(null);

    const [sampleData, setSampleData] = useState<RequestSampleSummaryDTO>();
    const [possibleSamplesToCorrelate, setPossibleSamplesToCorrelate] = useState<Sample[]>();
    const [totalItems, setTotalItems] = useState<number>(0);

    const [selectedSampleId, setSelectedSampleId] = useState<number>();
    const [isFullScreenLoaderActive, setIsFullScreenLoaderActive] = useState<boolean>(false);

    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [isLoadingTableContent, setIsLoadingTableContent] = useState<boolean>(true);
    const [isLoadingSampleData, setIsLoadingSampleData] = useState<boolean>(true);
    const [isFiltersPanelExpanded, setIsFiltersPanelExpanded] = useState<boolean>(false);
    const [isFiltersWrapped, setIsFiltersWrapped] = useState<boolean>(false);

    const [tableRenderId, setTableRenderID] = useState<number>(1);

    const samplesTypes = useMemo(() => {
        return GetSampleTypes();
    }, [GetSampleTypes]);


    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
        initialValue: "",
    });

    var waybillControl = useFormControl<string>({
        initialValue: props.waybill,
    });

    var materialReferenceControl = useFormControl<string>({
        initialValue: props.materialReference?.number ? props.materialReference.number : props.materialReference?.name
    })

    const typeFormControl = useFormControl<SampleType>({ isDisabled: false });


    useEffect(() => {
        setTableRenderID(tableRenderId + 1);
        const controller = new AbortController();
        getPossibleCorrelations(controller);
        return () => { controller.abort() };
    }, [currentpage, itemsPerPage,
        typeFormControl.value?.id, searchBoxFormControl.value,
        waybillControl.value, materialReferenceControl.value])



    useEffect(() => {

        if (!props.locationId || !props.requestId || !props.sampleId) return;

        requestSampleSvc
            .getRequestSampleById(props.locationId, props.requestId, props.sampleId)
            .then((res) => {
                setSampleData(res);
                setIsLoadingSampleData(false);
            })
            .catch((error) => {
                setIsLoadingSampleData(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationId, props.requestId, props.sampleId]);




    const getPossibleCorrelations = useCallback((controller: AbortController) => {

        if (!props.locationId || !props.requestId || !props.sampleId) return;

        setIsLoadingTableContent(true);

        var requestDto: SamplesToCorrelateFilterRequestDTO = {
            labSampleType: typeFormControl.value?.id ? SampleTypeAsEnum[parseInt(typeFormControl.value?.id)] : undefined,
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: trimString(searchBoxFormControl.value),
            waybill: trimString(waybillControl.value),
            material: trimString(materialReferenceControl.value),
        };

        requestSampleSvc
            .getPossibleSampleCorrelations(props.locationId, props.requestId, props.sampleId, requestDto, controller.signal)
            .then((res) => {
                setPossibleSamplesToCorrelate(res.samples);
                setTotalItems(res.totalSamples);
                setIsLoadingTableContent(false);
            })
            .catch((error) => {
                setIsLoadingTableContent(false);
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [openPopup, setIsLoadingTableContent, setTotalItems, setPossibleSamplesToCorrelate,
        searchBoxFormControl.value, waybillControl.value, materialReferenceControl.value,
        props.locationId, props.requestId, props.sampleId, typeFormControl.value?.id, currentpage, itemsPerPage]);



    const handleCorrelateSampleBtnClicked = useCallback(() => {

        if (!props.locationId || !props.requestId || !props.sampleId) return;

        setIsFullScreenLoaderActive(true);

        var requestDto: CorrelateSamplesRequestDTO = {
            labSampleId: "" + selectedSampleId ?? "",
            requestSampleId: props.sampleId
        };

        requestSampleSvc
            .correlateSampleOnRequestsPage(props.locationId, props.requestId, requestDto)
            .then((_) => {
                setIsFullScreenLoaderActive(false);

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("REQUESTS.INFOANDFORM_2.CorrelatedSample")}
                    />
                );
                props.onCompletedOperations && props.onCompletedOperations();

                closePopup();
            })
            .catch((error) => {
                setIsFullScreenLoaderActive(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [openPopup, closePopup, setIsFullScreenLoaderActive, selectedSampleId,
        props.locationId, props.requestId, props.sampleId, props.onCompletedOperations]);




    const columns: ColumnDefinition<Sample>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => buildMaterialCellinfo(v),
                headerRender: translate("SAMPLES.INFOANDFORM.Material"),
                columnKey: "MAT",
                isSortable: true,
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.batch,
                headerRender: translate("SAMPLES.INFOANDFORM.Batch"),
                isSortable: true,
                columnKey: "BTC",
            },
            {
                cellRenderProp: (v) => v.observations,
                headerRender: translate("SAMPLES.INFOANDFORM.Observations"),
                columnKey: "Observations",
                isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
            },
            {
                cellRenderProp: (v) => translate(SampleTypeTranslationTerms[v.typeId]),
                headerRender: translate("SAMPLES.INFOANDFORM.Type"),
                columnKey: "LabSampleType",
                isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
            },
            {
                cellRenderProp: (v) => v.waybill,
                headerRender: translate("SAMPLES.INFOANDFORM.Waybill"),
                columnKey: "waybill",
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.isRepeated ? (
                    <Tooltip text={translate("SAMPLES.INFOANDFORM.Repeated")}>
                        <div className="caption repeated-sample">R</div>
                    </Tooltip>
                ) : null,
                columnKey: "repeated",
                width: "1.0714rem",
                isMobileHeaderIcon: true
            },
            {
                cellRenderProp: (v) =>
                    <Tag
                        text={getSampleStatusLabel(v.statusId)}
                        backgroundColor={getSampleStatusCss(v.statusId)}
                        isTiny={windowResize < 1024}
                    />,
                headerRender: translate("SAMPLES.INFOANDFORM.Status"),
                columnKey: "LabSampleStatus",
                isStatusCell: true,
            },
            {
                cellRenderProp: (v) => v.requestedDate ? DateTimeHelper.formatDateTime("" + v.requestedDate) : null,
                headerRender: translate("SAMPLES.INFOANDFORM.RequestDateSummary"),
                columnKey: "RD",
                isSortable: true,
            },
            {
                cellRenderProp: (v) => {
                    return (<>{v.correlatedRequestId
                        ? (<Tag
                            text={translate("REQUESTS.INFOANDFORM_2.CorrelatedSample")}
                            backgroundColor="status-green"
                            isTiny={windowResize < 1024}
                            icon={<CorrelateIcon />
                            }
                        />
                        ) : null}</>)
                },
                columnKey: "correlated-sample",
                isMobilePrimaryCell: true,
            },
        ],
        [windowResize]
    );



    const handleOnPageAndItemsChanged = useCallback((items: number, currentPage: number) => {
        setItemsPerPage(items);
        setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);



    const handleResetFiltersClicked = useCallback(() => {
        searchBoxFormControl.setValue("");
        waybillControl.setValue("")
        materialReferenceControl.setValue("");
        typeFormControl.setValue({ id: "", description: "" });
    }, [searchBoxFormControl.setValue, waybillControl.setValue, materialReferenceControl.setValue, typeFormControl.setValue]);

    useEffect(() => {
        const upperRowNode = upperRowRef.current;
        const bottomRowNode = bottomRowRef.current;

        if (upperRowNode && bottomRowNode) {
            let hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
            });

            while (hasWrapOccurred) {
                const lastChild = upperRowNode.lastElementChild;
                setIsFiltersWrapped(true);
                if (lastChild) {
                    upperRowNode.removeChild(lastChild);
                    bottomRowNode.appendChild(lastChild);
                }
                hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
                    return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
                });
            }
        }
    }, [windowResize]);

    return (
        <PopupContainer className="correlate-samples">
            <PopupHeader title={translate("REQUESTS.POPUPS.CorrelateSample")} />
            <PopupContent isLoading={isLoadingSampleData}>
                {isFullScreenLoaderActive ? <FullScreenLoader /> : null}
                <TitledCardContainer className="request-sample-details-card" title={translate("REQUESTS.POPUPS.SelectedSample")}>
                    <div className="request-sample-details">
                        <InfoBlock label={translate("SAMPLES.INFOANDFORM.Material")}
                            value={sampleData?.material.number ? `${sampleData?.material.number} - ${sampleData?.material.name}` || "-" : sampleData?.material.name || "-"}></InfoBlock>
                        <InfoBlock label={translate("SAMPLES.INFOANDFORM.Manufacturer")} value={sampleData?.manufacturerIsValmet ? "Valmet" : sampleData?.otherManufacturer || "-"}></InfoBlock>
                        <InfoBlock label={translate("SAMPLES.INFOANDFORM.Used")} value={sampleData?.isUsed ? translate("COMMON.Yes") : translate("COMMON.No")}></InfoBlock>
                    </div>
                </TitledCardContainer>

                <Spacer mode="vertical" px="24" />

                <div className="samples-to-correlate">
                    <div className="subtitle">
                        {translate("REQUESTS.POPUPS.SelectSampleToCorrelate")}
                    </div>

                    <div className="filters">
                        <div className="upper-row-filters" ref={upperRowRef}>
                            <FormFieldTextInput
                                formControl={searchBoxFormControl}
                                icon={<SearchIcon />}
                                label={translate("REQUESTS.POPUPS.Observations")}
                                placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                                className="search-box"
                            />
                            <FormFieldTextInput
                                formControl={waybillControl}
                                icon={<SearchIcon />}
                                label={translate("REQUESTS.POPUPS.Waybill")}
                                placeholder={translate("REQUESTS.INFOANDFORM.WaybillPlaceholder")}
                                className="waybill-box"
                            />
                            <FormFieldTextInput
                                formControl={materialReferenceControl}
                                icon={<SearchIcon />}
                                label={translate("REQUESTS.POPUPS.MaterialRef")}
                                placeholder={translate("REQUESTS.INFOANDFORM.MaterialReferencePlaceholder")}
                                className="matRef-box"
                            />
                            <FormFieldSelectSingle
                                placeholder={translate("SAMPLES.INFOANDFORM.TypePlaceholder")}
                                label={translate("REQUESTS.POPUPS.SampleType")}
                                options={samplesTypes}
                                labelSelector={labelSampleTypeSelector}
                                idSelector={idSampleTypeSelector}
                                formControl={typeFormControl}
                                isClearSelectionAvailable={false}
                            />
                        </div>
                        <div className="action-button-filters">
                            {isFiltersWrapped ? <IconButton
                                icon={isFiltersPanelExpanded ? <ArrowUp /> : <ArrowDown />}
                                type="tertiary"
                                onClick={() => setIsFiltersPanelExpanded(!isFiltersPanelExpanded)}
                                className="view-more-filters-btn"
                            /> : null}
                            <Tooltip text={translate("COMMON.FORM.CleanFilters")}>
                                <IconButton
                                    icon={<CleanIcon />}
                                    type="secondary"
                                    onClick={handleResetFiltersClicked}
                                    className="reset-filters-btn"
                                />
                            </Tooltip>
                        </div>
                        <div className={"bottom-row-filters" + (isFiltersPanelExpanded ? " expanded" : "")} ref={bottomRowRef}>
                        </div>
                    </div>

                    <ResponsiveDataTable
                    key={tableRenderId}
                        columnDefinitions={columns}
                        items={possibleSamplesToCorrelate || []}
                        totalitems={totalItems || 0}
                        paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [4, 8, 12] }}
                        currentpage={currentpage}
                        isLoading={isLoadingTableContent}
                        onPageAndItemsChanged={handleOnPageAndItemsChanged}
                        showSelectedRowHighlighted
                        onClickRow={(sample) => {
                            if (sample.correlatedRequestId)
                                setSelectedSampleId(undefined);
                            else if (selectedSampleId === sample.sampleId)
                                setSelectedSampleId(undefined);
                            else setSelectedSampleId(sample.sampleId);
                        }}
                    />
                </div>

                <Spacer mode={"vertical"} px="30" />

                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            isDisabled: selectedSampleId ? false : true,
                            text: translate("REQUESTS.POPUPS.Correlate"),
                            type: "primary",
                            onClick: handleCorrelateSampleBtnClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer >
    );
}
