import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { SelectOption } from "../../../requests/models/domain/SelectOption";

export enum PackageAreaTypesEnum {
    LP = 1,
    DF = 2,
    Minning = 3,
    Pulp = 4,
    Dryer = 5
}

 export const PackageAreaTypesTranslationTermsPlural: { [key: string]: string } = {
    1: "SAMPLES.PACKAGEAREATYPES.LP",
    2: "SAMPLES.PACKAGEAREATYPES.DF",
    3: "SAMPLES.PACKAGEAREATYPES.Minning",
    4: "SAMPLES.PACKAGEAREATYPES.Pulp",
    5: "SAMPLES.PACKAGEAREATYPES.Dryer",
} 


export const PackageAreaTypesAsEnum: { [key: string]: PackageAreaTypesEnum } = {
    1: PackageAreaTypesEnum.LP,
    2: PackageAreaTypesEnum.DF,
    3: PackageAreaTypesEnum.Minning,
    4: PackageAreaTypesEnum.Pulp,
    5: PackageAreaTypesEnum.Dryer,
};

export function GetPackageAreaTypesArray() {

    const sampleStatusArray: SelectOption[] = Object.values(PackageAreaTypesEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSampleStatus: SelectOption = {
                id: "" + enumValue,
                description: translate(PackageAreaTypesTranslationTermsPlural[enumValue]) || "-unknown-"
            };
            return mappedSampleStatus;
        });

    return sampleStatusArray;
}