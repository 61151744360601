import "./AssociateTestsSamplesCopy.css";

import { AnalysisRequestStatusEnum } from "../../models/domain/AnalysisRequestStatusEnum";
import { AssociateSampleTestsPopupCopy } from "./popup/AssociateSampleTestsPopupCopy";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { LabTest } from "../../../../models/domain/lab-tests/LabTest";
import { ReactComponent as LabTestDarkIcon } from "../../../../lib/assets/icons/teste-dark.svg";
import { ReactComponent as LabTestIcon } from "../../../../lib/assets/icons/teste.svg";
import { Permission } from "../../../../models/api/gate/Permission";
import { RequestDetails } from "../../models/domain/RequestDetails";
import { RequestSampleSummaryDTO } from "../../models/api/RequestSampleSummaryDTO";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { SampleStatusEnum } from "../../../samples/models/domain/SamplesStatusEnum";
import { SampleTestsAssociationsSummary } from "../../models/domain/SampleTestsAssociationsSummary";
import { SampleTestsSummary } from "../../models/domain/SampleTestsSummary";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { ReactComponent as WarningIcon } from "../../../../lib/assets/icons/warning-icon-v2.svg"
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useIdentityUserDisplayName } from "../../../../lib/infrastructure/identity/hooks/useIdentityUserDisplayName";
import { useMemo } from "react";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";



interface IAssociateTestsSamplesProps {
    labTests: LabTest[];
    locationId: string;
    requestId: string;
    associations: SampleTestsAssociationsSummary | undefined;
    requestSamples: RequestSampleSummaryDTO[] | undefined;
    requestDetails: RequestDetails | undefined;
    isLoading: boolean;
    onCompletedOperations: () => void;
}

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];


function findSampleTestsBySampleId(sampleId: number, associations: SampleTestsSummary[] | undefined) {
    return associations?.find((a) => a.sampleId === sampleId);
}

function hasDestroyingSampleTests(sampleId: number, labTests: LabTest[], associations: SampleTestsAssociationsSummary | undefined) {
    var sampleTestsIdsAssociated = associations?.samplesTestsSummary?.find((a) => a.sampleId === sampleId)?.sampleTests.map(a => a.sampleTestId);

    return labTests.filter((a) => sampleTestsIdsAssociated?.some((b) => b === a.labTestId)).some((c) => c.isForDestroyingBarOrFilter);
}



function isAssociateBtnDisabled(correlatedSampleStatus: SampleStatusEnum | undefined, canAnalystManageAssociations: boolean, canAuthorManageAssociations: boolean) {


    if (!correlatedSampleStatus && (canAnalystManageAssociations || canAuthorManageAssociations)) return false;

    if (canAnalystManageAssociations && correlatedSampleStatus !== SampleStatusEnum.DONE && correlatedSampleStatus !== SampleStatusEnum.REJECTED) return false;

    if (canAuthorManageAssociations && correlatedSampleStatus === SampleStatusEnum.REGISTERED) return false;

    return true;
}



export function AssociateTestsSamplesCopy(props: IAssociateTestsSamplesProps) {


    const useIdentity = useIdentityUserDisplayName();
    const openPopup = usePopup();

    const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);


    const canAnalystManageAssociations = useMemo(() => {

        if (isLoadingPermissions || !props.requestDetails?.requestStatus) return false;

        var analystHasPermissions = hasPermission &&
            (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

        return (analystHasPermissions)

    }, [isLoadingPermissions, props.requestDetails?.requestStatus, hasPermission]);


    const canAuthorManageAssociations = useMemo(() => {

        if (isLoadingPermissions || !props.requestDetails?.requestStatus) return false;

        var authorHasPermission = props.requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()
            && (props.requestDetails?.requestStatus === AnalysisRequestStatusEnum.DRAFT || props.requestDetails?.requestStatus === AnalysisRequestStatusEnum.REGISTERED)

        return (authorHasPermission)

    }, [isLoadingPermissions, props.requestDetails?.requestStatus, props.requestDetails?.createdBy?.email, useIdentity.userDisplayEmail]);




    const canAuthorAssociateLatTests = useMemo(() => {

        if (isLoadingPermissions || !props.requestDetails?.requestStatus) return false;

        var authorHasPermission = props.requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()
            && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE)

        return authorHasPermission;

    }, [isLoadingPermissions, props.requestDetails?.requestStatus, props.requestDetails?.createdBy?.email, useIdentity.userDisplayEmail]);



    const columns: ColumnDefinition<RequestSampleSummaryDTO>[] = useMemo(() => [
        {
            cellRenderProp: (v) => {
                return (
                    <div className="material-container">
                        <div className="code">{v.material.number ? v.material.number : v.material.name}</div>
                        <div className="small-copy material-description">{v.material.number ? v.material.name : null}</div>
                    </div>)
            },
            headerRender: <>{translate("SAMPLES.INFOANDFORM.Material")}</>,
            columnKey: "sample",
            isMobilePrimaryCell: true,
            width: "15%",
        },
        {
            cellRenderProp: (v) => {
                var filtered = findSampleTestsBySampleId(v.id, props.associations?.samplesTestsSummary);
                if (!filtered) return (<></>);
                return (<div className="tests-container">
                    {filtered.sampleTests.map((t) => {
                        return <div className="test-item">
                            <div className="test-icon"><LabTestDarkIcon /></div>
                            <div className="test-description">{t.testName}</div>
                        </div>;
                    })}
                </div>);
            },
            headerRender: <>{translate("GLOBALADMIN.TESTS.Tests")}</>,
            columnKey: "tests",
            width: "60%"
        },
        {
            cellRenderProp: (v) => {
                return (<>{hasDestroyingSampleTests(v.id, props.labTests, props.associations)
                    ? (<Tag
                        text={translate("GLOBALADMIN.TESTS.INFO.DestroysSample")}
                        backgroundColor="status-red"
                        icon={<WarningIcon />}
                        isTiny
                    />
                    ) : null}</>)
            },
            columnKey: "test-destruction",
            width: "15%",
            isStatusCell: true
        },
        {
            cellRenderProp: (v) => {
                return (<Tooltip text={translate("REQUESTS.POPUPS.AssociateTestsWithSample")}>
                    <IconButton type="primary" isDisabled={isAssociateBtnDisabled(v.labSampleCorrelated?.labSampleStatus, canAnalystManageAssociations,
                        canAuthorAssociateLatTests)} icon={<LabTestIcon />}
                        onClick={() => openPopup(<AssociateSampleTestsPopupCopy
                            onCompletedOperations={() => props.onCompletedOperations && props.onCompletedOperations()}
                            sampleTestsAssociations={
                                findSampleTestsBySampleId(v.id, props.associations?.samplesTestsSummary)
                                    ?.sampleTests.map((a) => a)}
                            labTests={props.labTests}
                            locationId={props.locationId}
                            requestId={props.requestId}
                            sampleId={`${v.id}`}
                        />)}
                    />
                </Tooltip>)
            },
            columnKey: "addTests",
            width: "5%",
            isMobileHeaderIcon: true,
        },
        {
            cellRenderProp: (v) => {
                var sampleTestsCost = props.associations?.samplesTestsSummary.find((a) => a.sampleId === v.id)?.cost;
                if (sampleTestsCost)
                    return (sampleTestsCost + "€");
                return null;
            },
            headerRender: <>{translate("REQUESTS.INFOANDFORM.Cost")}</>,
            columnKey: "cost",
            width: "8%",
            isMobilePrimaryCell: true
        },
    ], [props.onCompletedOperations, props.labTests, props.requestSamples, props.associations, canAuthorAssociateLatTests,
    props.associations?.samplesTestsSummary, props.locationId, props.requestId, openPopup, canAnalystManageAssociations, canAuthorManageAssociations]);




    return <div className="association-tests-samples-container">
        <div className="subtitle">{translate("REQUESTS.INFOANDFORM.TestsSamplesAssociations")}</div>
        <ResponsiveDataTable
            totalitems={0}
            columnDefinitions={columns}
            items={props.requestSamples || []}
            isLoading={props.isLoading}
        />
        <div className="small-copy strength-shrinkage-tests"><div><WarningIcon /></div><div>{translate("REQUESTS.INFOANDFORM.AssociateTestsWarning")}</div></div>
        {props.associations?.totalCost ? (
            <div className="total-cost-container">
                <div className="label">{translate("REQUESTS.POPUPS.TotalCost") + ":"}</div>
                <div className="cost-description">{props.associations?.totalCost + "€"}</div>
            </div>) : null}
    </div>;
}