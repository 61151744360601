import "./CreateRequestTypePage.css";

import { LargeButton } from "../../../../lib/components/buttons/LargeButton";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useNavigate } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";

/***** ICONS ******/

export function CreateRequestTypePage() {
  const openPopup = usePopup();
  const navigate = useNavigate();

  return (
    <PageLayout
      tabTitle={`laMP - ${translate("REQUESTS.CreateRequest")}`}
      pageTitle={translate("REQUESTS.CreateRequest")}
      className="create-request-type"
      showBreadcrumb
    >
      <div className="create-request-type-actions">
        <LargeButton
          text={translate("REQUESTS.TYPES.AR")}
          type="primary"
          onClick={() => navigate("ar")}
        />
        <LargeButton
          text={translate("REQUESTS.TYPES.WF")}
          type="primary"
          onClick={() => navigate("wf")}
        />
        <LargeButton
          text={translate("REQUESTS.TYPES.PP")}
          type="primary"
          onClick={() => navigate("pp")}
        />
        <LargeButton
          text={translate("REQUESTS.TYPES.DF")}
          type="primary"
          onClick={() => navigate("df")}
        />
        <LargeButton
          text={translate("REQUESTS.TYPES.Dryer")}
          type="primary"
          onClick={() => navigate("dryer")}
        />
      </div>
    </PageLayout>
  );
}
