import { AnalysisRequestSampleDetails } from "../../models/domain/AnalysisRequestSampleDetails";
import { RequestSampleUpdateDTO } from "../../models/api/RequestSampleUpdateDTO";

export function AnalysisRequestSampleDetailsToRequestSampleUpdateDTOMapper(requestSample: AnalysisRequestSampleDetails){
    var mappedRequestSample: RequestSampleUpdateDTO = {
        isUsed: requestSample.isUsed,
        manufacturerIsValmet: requestSample.manufacturerIsValmet,
        otherManufacturer: requestSample.otherManufacturer,
        materialId: requestSample.materialId,
        material: requestSample.material,
        otherMaterial: requestSample.otherMaterial,
        productDescription: requestSample.productDescription,
        compareToMaterialId: requestSample.compareToMaterialId,
        compareToMaterial: requestSample.compareToMaterial,
        compareToSpec: requestSample.compareToSpec,
        position: requestSample.position,
        installationDate: requestSample.installationDate,
        removalDate: requestSample.removalDate,
        runningTime: requestSample.runningTime,
        reasonForRemoval: requestSample.reasonForRemoval,
        otherInfo: requestSample.otherInfo,
        batchNumber: requestSample.batchNumber,
    }
    return mappedRequestSample;
}