import "./CreateRequestDetails.css";

import { ClientRegistrationEnum, GetClientRegistrationEnumByKey, GetClientRegistrationsArray } from "../../../models/domain/ClientRegistrationEnum";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { AnalysisRequestService } from "../../../services/AnalysisRequestService";
import { AnalysisRequestTypeEnum } from "../../../models/domain/AnalysisRequestTypeEnum";
import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { IGenericRequestDetailsFormsProps } from "../CreateRequestPage";
import { Permission } from "../../../../../models/api/gate/Permission";
import { RequestDetails } from "../../../models/domain/RequestDetails";
import { RequestForm } from "../../../models/domain/RequestForm";
import { SelectOption } from "../../../models/domain/SelectOption";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useHasPermissions } from "../../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";
import { validateClientRegistrationNumber } from "../../../validators/ValidateClientRegistrationNumber";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateStringIfConditional } from "../../../validators/ValidateIfConditional";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

var analysisRequestSvc = new AnalysisRequestService();

const dateNow = DateTime.now();

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];

const labelClientRegistrationSelector = (item: SelectOption) => item.description;
const idClientRegistrationSelector = (item: SelectOption) => item.id;



export const CreateRequestDetailsDF = forwardRef(
    function CreateRequestDetailsDF(props: IGenericRequestDetailsFormsProps, ref: React.ForwardedRef<RequestForm>) {

        const openPopup = usePopup();

        const [requestDetails, setRequestDetails] = useState<RequestDetails>();

        const clientRegistrationTypes = useMemo(() => { return GetClientRegistrationsArray(); }, [GetClientRegistrationsArray]);
        const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);

        const [isRegisteredOnCompass, setIsRegistedOnCompass] = useState<boolean>(false);
        const [containsCarcinogens, setContainsCarcinogens] = useState<boolean>(false);
        const [containsHazardousMaterial, setContainsHazardousMaterial] = useState<boolean>(false);


        const requestNameFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const creationRequestDateFormControl = useFormControl<DateTime>({
            validators: [validateDateTime()],
            enableAutoValidate: false,
            initialValue: dateNow,
        });

        const companyFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const factoryFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const clientRegistrationControl = useFormControl<SelectOption>({
            validators: [hasOneElementSelected()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const clientNumberControl = useFormControl<string>({
            validators: [validateClientRegistrationNumber(clientRegistrationControl)],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const customerCommentsFormControl = useFormControl<string>({
            isDisabled: false
        });

        const numberSapOrCompassFormControl = useFormControl<string>({
            validators: [validateStringIfConditional(isRegisteredOnCompass)],
            enableAutoValidate: true,
            isDisabled: false
        });

        const hazardousMaterialFormControl = useFormControl<string>({
            validators: [validateStringIfConditional(containsHazardousMaterial)],
            enableAutoValidate: true,
            isDisabled: false
        });

        const carcinogenicsFormControl = useFormControl<string>({
            validators: [validateStringIfConditional(containsCarcinogens)],
            enableAutoValidate: true,
            isDisabled: false
        });

        const otherEquipmentInformationsFormControl = useFormControl<string>({
            isDisabled: false
        });



        /*****************
         * SERVICE CALLS 
        ****************/


        const [getAnalysisRequestByIdCall, getAnalysisRequestByIdCallIsLoading] = useServiceCallPro2(analysisRequestSvc, analysisRequestSvc.getAnalysisRequestById);



        /****************************
         * DATA REQUESTS
        *****************************/



        const getAnalysisRequestById = useCallback(() => {

            if (!props.locationId || !props.cloneId) return;

            getAnalysisRequestByIdCall(`${props.locationId}`, `${props.cloneId}`)
                .then((data) => {
                    if (!data) return;
                    setRequestDetails(data);
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
                });
        }, [props.locationId, props.cloneId, getAnalysisRequestByIdCall, setRequestDetails, openPopup]);




        /****************************
         * DATA MANIPULATION EFFECTS
        *****************************/




        useEffect(() => {
            getAnalysisRequestById();
        }, []);




        useEffect(() => {
            if (!props.locationId || !props.cloneId) return;

            if (requestDetails?.isRegisteredInCompass) setIsRegistedOnCompass(requestDetails.isRegisteredInCompass);
            if (requestDetails?.hasCarcinogenicSubstances) setContainsCarcinogens(requestDetails.hasCarcinogenicSubstances);
            if (requestDetails?.hasHazardousMaterial) setContainsHazardousMaterial(requestDetails.hasHazardousMaterial);

            requestNameFormControl.setValue(requestDetails?.name);
            companyFormControl.setValue(requestDetails?.company);
            factoryFormControl.setValue(requestDetails?.factory);
            if (requestDetails?.createdDate)
                creationRequestDateFormControl.setValue(DateTime.fromISO(requestDetails?.createdDate));

            customerCommentsFormControl.setValue(requestDetails?.customerComments);
            clientRegistrationControl.setValue(clientRegistrationTypes.find((a) => a.id === (requestDetails?.clientRegistration)?.toString()))
            clientNumberControl.setValue(requestDetails?.clientRegistrationDescription);

            numberSapOrCompassFormControl.setValue(requestDetails?.clientRegistrationDescription);
            hazardousMaterialFormControl.setValue(requestDetails?.hazardousMaterialDescription);
            carcinogenicsFormControl.setValue(requestDetails?.carcinogenicSubstancesDescription);
            otherEquipmentInformationsFormControl.setValue(requestDetails?.otherInformations);

        }, [props.locationId, props.cloneId, requestDetails]);




        useEffect(() => {
            if (clientRegistrationControl.value?.id === (ClientRegistrationEnum.NOTREGISTERED).toString())
                clientNumberControl.setValue(undefined);
        }, [clientRegistrationControl.value?.id]);



        useEffect(() => {
            if (clientRegistrationControl.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString())
                clientNumberControl.setIsDisabled(false);
            else
                clientNumberControl.setIsDisabled(true);
        }, [clientRegistrationControl.value?.id]);




        useEffect(() => {
            if (!isLoadingPermissions && !hasPermission)
                creationRequestDateFormControl.setIsDisabled(true);
        }, [isLoadingPermissions, hasPermission]);




        useEffect(() => {
            if (containsCarcinogens)
                carcinogenicsFormControl.setIsDisabled(false);
            else
                carcinogenicsFormControl.setIsDisabled(true);
        }, [containsCarcinogens]);




        useEffect(() => {
            if (containsHazardousMaterial)
                hazardousMaterialFormControl.setIsDisabled(false);
            else
                hazardousMaterialFormControl.setIsDisabled(true);
        }, [containsHazardousMaterial]);





        useImperativeHandle(ref, () => {
            return {
                validate() {
                    return AllValid(requestNameFormControl.validate(), creationRequestDateFormControl.validate(),
                        companyFormControl.validate(), factoryFormControl.validate(),
                        clientNumberControl.validate(), clientRegistrationControl.validate(),
                        customerCommentsFormControl.validate(), numberSapOrCompassFormControl.validate(),
                        clientNumberControl.validate(), clientRegistrationControl.validate(),
                        hazardousMaterialFormControl.validate(), carcinogenicsFormControl.validate(), otherEquipmentInformationsFormControl.validate());
                },
                value() {
                    return {
                        requestType: AnalysisRequestTypeEnum.DF,
                        name: requestNameFormControl.value,
                        createdDate: creationRequestDateFormControl.value,
                        company: companyFormControl.value,
                        factory: factoryFormControl.value,
                        customerComments: customerCommentsFormControl.value,
                        clientRegistration: clientRegistrationControl.value?.id ? GetClientRegistrationEnumByKey[parseInt(clientRegistrationControl.value.id)] : ClientRegistrationEnum.NOTREGISTERED,
                        clientRegistrationDescription: clientNumberControl.value,
                        hasCarcinogenicSubstances: containsCarcinogens,
                        hasHazardousMaterial: containsHazardousMaterial,
                        hazardousMaterialDescription: hazardousMaterialFormControl.value,
                        carcinogenicSubstancesDescription: carcinogenicsFormControl.value,
                        otherInformations: otherEquipmentInformationsFormControl.value
                    }
                }
            }
        }, [requestNameFormControl.validate, creationRequestDateFormControl.validate,
        companyFormControl.validate, factoryFormControl.validate, customerCommentsFormControl.validate,
        clientRegistrationControl.validate, clientNumberControl.validate,
        numberSapOrCompassFormControl.validate, hazardousMaterialFormControl.validate, carcinogenicsFormControl.validate,
        otherEquipmentInformationsFormControl.validate, clientNumberControl.value, clientRegistrationControl.value?.id,
        requestNameFormControl.value, creationRequestDateFormControl.value, companyFormControl.value, factoryFormControl.value,
        customerCommentsFormControl.value, isRegisteredOnCompass, numberSapOrCompassFormControl.value, containsCarcinogens, containsHazardousMaterial,
        hazardousMaterialFormControl.value, carcinogenicsFormControl.value, otherEquipmentInformationsFormControl.value]);




        /****************************
         * CSS & HTML
        *****************************/

        return (

            <CardContainer className="create-request-form-container">
                <div className="subtitle">{translate("REQUESTS.RequestDetails")}</div>
                <div className="create-request-form">
                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            formControl={requestNameFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Name")}
                            placeholder={translate("REQUESTS.INFOANDFORM.NamePlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldDatePicker
                            formControl={creationRequestDateFormControl}
                            label={translate("REQUESTS.INFOANDFORM.CreationDate")}
                            maxDate={dateNow}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.Client")}</div>

                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            formControl={companyFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Company")}
                            placeholder={translate("REQUESTS.INFOANDFORM.CompanyPlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldTextInput
                            formControl={factoryFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Factory")}
                            placeholder={translate("REQUESTS.INFOANDFORM.FactoryPlaceholder")}
                            alternativeStyle={true}
                        />

                    </div>

                    <div className="request-info-inputs">
                        <FormFieldSelectSingle
                            label={translate("REQUESTS.INFOANDFORM_2.ClientRegistration")}
                            placeholder={translate("COMMON.SelectOneOptionPlaceholder")}
                            options={clientRegistrationTypes || []}
                            labelSelector={labelClientRegistrationSelector}
                            idSelector={idClientRegistrationSelector}
                            formControl={clientRegistrationControl}
                            isClearSelectionAvailable={false}
                            alternativeStyle={true}
                        />

                        <ConditionalRender if={clientRegistrationControl.value?.id && clientRegistrationControl.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString() ? true : false}>
                            <FormFieldTextInput
                                formControl={clientNumberControl}
                                label={translate("REQUESTS.INFOANDFORM.ClientNumber")}
                                placeholder={translate("REQUESTS.INFOANDFORM.ClientNumberPlaceholder")}
                                alternativeStyle={true}
                            />
                        </ConditionalRender>
                    </div>


                    <div className="request-info-inputs">
                        <FormFieldTextArea
                            formControl={customerCommentsFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Comments") + " (" + translate("COMMON.Optional") + ")"}
                            placeholder={translate("REQUESTS.INFOANDFORM.CommentsPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.EquipmentInformation")}</div>

                    <div className="request-info-inputs">
                        <Checkbox
                            text={translate("REQUESTS.INFOANDFORM.ContainsHazardMaterial") + "?"}
                            isChecked={containsHazardousMaterial}
                            onClick={() => { setContainsHazardousMaterial(!containsHazardousMaterial) }}
                        />
                        <FormFieldTextInput
                            formControl={hazardousMaterialFormControl}
                            placeholder={translate("REQUESTS.INFOANDFORM.ContainsHazardMaterialPlaceholder")}
                            alternativeStyle={true}
                        />
                        <Checkbox
                            text={translate("REQUESTS.INFOANDFORM.ContainsCarcinogenicSubstances") + "?"}
                            isChecked={containsCarcinogens}
                            onClick={() => { setContainsCarcinogens(!containsCarcinogens) }}
                        />
                        <FormFieldTextInput
                            formControl={carcinogenicsFormControl}
                            placeholder={translate("REQUESTS.INFOANDFORM.ContainsCarcinogenicSubstancesPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="request-info-inputs">
                        <FormFieldTextArea
                            formControl={otherEquipmentInformationsFormControl}
                            label={translate("REQUESTS.INFOANDFORM.FurtherInformation") + " (" + translate("COMMON.Optional") + ")"}
                            placeholder={translate("REQUESTS.INFOANDFORM.FurtherInformationPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                </div>
            </CardContainer>
        );
    });
