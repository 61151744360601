import * as qs from "qs";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { LabOperator } from "./models/LabOperator";
import { LabOperatorCreateDTO } from "./models/LabOperatorCreateDTO";
import { LabOperatorDTO } from "./models/LabOperatorDTO";
import { LabOperatorUpdateDTO } from "./models/LabOperatorUpdateDTO";
import { LabOperatorUpdateStatusDTO } from "./models/LabOperatorUpdateStatusDTO";
import { LabOperatorsAndTotal } from "./models/LabOperatorsAndTotal";
import { LabOperatorsQueryOptions } from "./models/LabOperatorsQueryOptions";
import { LabOperatorsResponseDTO } from "./models/LabOperatorsResponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class LabOperatorService {


    uploadEmployeesFile(locationId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/LabOperators/FromXLSX`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    getLabOperators(locationId: string, queryParams: LabOperatorsQueryOptions): Promise<LabOperatorsAndTotal> {

        return HttpClient.sessionRequest<LabOperatorsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/LabOperators`),
            params: queryParams,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                var mappedOperators: LabOperator[] = response.data.operators.map(ob => ({
                    labOperatorId: ob.labOperatorId,
                    employeeCode: ob.employeeCode,
                    name: ob.name,
                    section: ob.section,
                    status: ob.status
                }));

                return {
                    operators: mappedOperators,
                    totalItems: response.data.totalItems
                };
            })
            .catch((error) => { throw error; });
    }


    getLabOperatorById(locationId: string, operatorId: string): Promise<LabOperator | null> {


        return HttpClient.sessionRequest<LabOperatorDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/LabOperators/${operatorId}`),
        })
            .then((r) => {

                if (!r.data.labOperatorId) return null;

                var operator: LabOperator = {
                    labOperatorId: r.data.labOperatorId,
                    employeeCode: r.data.employeeCode,
                    name: r.data.name,
                    section: r.data.section,
                    status: r.data.status,
                }

                return operator;

            })
            .catch((error) => { throw error; });
    }


    addLabOperator(locationId: string, requestDTO: LabOperatorCreateDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/LabOperators`),
            data: requestDTO
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    editLabOperator(locationId: string, labOperatorId: string, requestDTO: LabOperatorUpdateDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/LabOperators/${labOperatorId}`),
            data: requestDTO
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    updateLabOperatorStatus(locationId: string, employeeId: number, requestDto: LabOperatorUpdateStatusDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/LabOperators/${employeeId}/Status`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}
