import "./AddInfo.css";

import { Button, IButtonProps, } from "../../../../lib/components/buttons/Button";
import { FileUploadButton, IFileUploadButtonProps } from "../../../../lib/components/file/FileUploadButton";
import { IResponsiveDataTableProps, ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";

import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { CssClassnameBuilder } from "../../../../lib/utils/CssClassnameBuilder";
import { IconButtonFile } from "../../../../lib/components/buttons/IconButtonFile";
import { Loader } from "../../../../lib/components/loader/Loader";
import { useMemo } from "react";

interface IAddInfoProps<T> {
  className?: string;
  title?: string;
  button: IFileUploadButtonProps;
  tableInfo?: IResponsiveDataTableProps<T>;
  buttonType?: "normal" | "file-upload";
  isLoading: boolean;
  children?: React.ReactNode;
}


export function AddInfoAttachments<T>(props: IAddInfoProps<T>) {
  const renderTableOrButton = useMemo(() => {
    if (props.tableInfo?.items.length) {
      return (
        <ResponsiveDataTable
          className=""
          items={props.tableInfo.items}
          totalitems={props.tableInfo.totalitems}
          columnDefinitions={props.tableInfo.columnDefinitions}
          isLoading={props.tableInfo.isLoading}
          onClickRow={props.tableInfo.onClickRow}
        />
      );
    } else {
      return (
        <div className="add-info-btn">
          <FileUploadButton
            text={props.button.text}
            type={props.button.type}
            onFileSelected={props.button.onFileSelected}
            icon={props.button.icon}
            className={props.button.className}
            isDisabled={props.button.isDisabled}
          />
        </div>
      );
    }
  }, [props]);

  const renderHeaderButton = useMemo(() => {
    if (props.tableInfo?.items.length) {
      return (
        <IconButtonFile
          type={props.button.type !== "tertiary" ? props.button.type : "secondary"}
          icon={props.button.icon}
          onFileSelected={props.button.onFileSelected}
          isDisabled={props.button.isDisabled}
        />
      );
    }
  }, [props.tableInfo?.items, props.button, props.button.isDisabled]);


  const addInfoCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("add-info")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  return (
    <CardContainer className={addInfoCss}>
      <div className="add-info-header">
        <div className="subtitle">{props.title || ""}</div>
        <div className="add-info-header-actions">
          {props.children}
          {renderHeaderButton}
        </div>
      </div>
      {props.isLoading ? <Loader /> :
        <div className="add-info-content">{renderTableOrButton}</div>}
    </CardContainer>
  );
}
