import "./ViewSamplesPage.css";

import {
  ColumnDefinition,
  IOrderInfo,
} from "../../../lib/components/table/TableInterfaces";
import {
  FiltersForm,
  SamplesFilters,
  SamplesPageFilters,
} from "./samples-page-filters/SamplesPageFilters";
import {
  GetSampleStatus,
  SampleStatusAsEnum,
  SampleStatusEnum,
} from "../models/domain/SamplesStatusEnum";
import {
  SampleTypeAsEnum,
  SampleTypeEnum,
  SampleTypeTranslationTerms,
} from "../models/domain/SampleTypeEnum";
import { buildMaterialCellInfo2, buildMaterialCellinfo } from "../../../common/helpers/BuildMaterialCellinfo";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";
import { useLocation, useNavigate } from "react-router-dom";

import { BetweenNumbersHelper } from "../../../lib/helpers/BetweenNumbersHelper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ReactComponent as CorrelateIcon } from "../../../lib/assets/icons/correlacionar.svg";
import { CorrelateLabSamplesPopup } from "./popups/CorrelateLabSamplesPopup";
import { DateTimeHelper } from "../../../lib/utils/DateTimeHelper";
import { Employee } from "../models/domain/Employee";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { GetSamplesRequestDTO } from "../models/api/GetSamplesRequestDTO";
import { LabOperator } from "../../admin/employees/models/LabOperator";
import { LabOperatorService } from "../../admin/employees/LabOperatorService";
import { LabSampleService } from "../services/LabSampleService";
import { LabSampleStats } from "../models/domain/LabSampleStats";
import { LocationService } from "../../../services/LocationServices";
import { Notification } from "../../../lib/components/notifications/Notification";
import { OnHoldSamplePopup } from "./sample-details/popups/OnHoldSamplePopup";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ReactComponent as PauseIcon } from "../../../lib/assets/icons/pause.svg";
import { Permission } from "../../../models/api/gate/Permission";
import { ReactComponent as PlayIcon } from "../../../lib/assets/icons/play.svg";
import { ReactComponent as RequestIcon } from "../../../lib/assets/icons/pedidos.svg";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { RoundIconButton } from "../../../lib/components/buttons/RoundIconButton";
import { Sample } from "../models/domain/Sample";
import { SampleOtherTypeTranslationTerms } from "../models/domain/OtherTypesEnum";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { StatusCard } from "../../../lib/components/cards/stats-card/StatusCard";
import { Tag } from "../../../lib/components/tag/Tag";
import { Tooltip } from "../../../lib/components/tooltip/Tooltip";
import { UpdateStatusRequestDTO } from "../models/api/UpdateSampleStatusRequestDTO";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import axios from "axios";
import { getSampleStatusCss } from "../../../common/helpers/GetSampleStatusCss";
import { getSampleStatusLabel } from "../../../common/helpers/GetSampleStatusLabel";
import { sampleStatusStateMachine } from "../../../common/helpers/SampleStatusStateMachine";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro2 } from "../../../services/UseServiceCall";
import { AccessGroupsService } from "../../../services/AccessGroupsService";

/***** ICONS ******/
var labSamplesSvc = new LabSampleService();
var locationGroupsSvc = new AccessGroupsService();

const defaultStatus = [SampleStatusEnum.REGISTERED];

const topPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS
];

export function ViewSamplesPage() {
  const navigate = useNavigate();
  const windowResize = useWindowResize();

  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const locationID = useGlobalLocation();
  const createNotification = useCreateNotification();
  const location = useLocation();


  const [samples, setSamples] = useState<Sample[]>([]);
  const [totalSamples, setTotalSamples] = useState<number>();
  const [sampleStats, setSampleStats] = useState<LabSampleStats>();
  const [analysts, setAnalysts] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([]);


  const [currentpage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [dataFilters, setDataFilters] = useState<SamplesFilters>();
  const filtersRef = useRef<FiltersForm | null>(null);


  const { isLoadingPermissions: isLoadingTopPermissions, hasPermission: hasTopPermission } = useHasPermissions(topPermissions);

  const [getAnalystsCall, getAnalystsCallIsLoading] = useServiceCallPro2(locationGroupsSvc, locationGroupsSvc.getLocationAnalysts);



  const currentPageURL = useMemo(() => {
    const baseUrl = ConfigurationProvider.getConfiguration().App.BaseUrl;
    return baseUrl + location.pathname;
  }, [location.pathname]);



  const baseUrl = useMemo(() => {
    return ConfigurationProvider.getConfiguration().App.BaseUrl;
  }, [ConfigurationProvider.getConfiguration]);



  const sampleStatus = useMemo(() => {
    return GetSampleStatus();
  }, []);


  useEffect(() => {
    setCurrentPage(0);
  }, [dataFilters]);


  useEffect(() => {
    const controller = new AbortController();
    getSamples(controller);
    return () => { controller.abort() };
  }, [currentpage, itemsPerPage, dataFilters, orderInfoColumns]);


  useEffect(() => {
    getSampleStats();
    getAnalysts();
  }, []);


  const getAnalysts = useCallback(() => {
    if (!locationID) return;
    getAnalystsCall("" + locationID)
      .then((r) => {
        setAnalysts(r)
      })
      .catch((error) => {
        if (!error || axios.isCancel(error)) return;
        if (error?.response?.data?.httpCode && error.response.data.httpCode === 403)
          navigate("/access-denied");
        else
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);

      });
  }, [locationID, setAnalysts, openPopup, getAnalystsCall, navigate]);


  const getSampleStats = useCallback(() => {
    if (!locationID) return;

    labSamplesSvc.getSamplesStats(locationID)
      .then((data) => {
        setSampleStats(data);
      })
      .catch((error) => {
        setIsLoading(false);
        if (!error || axios.isCancel(error)) return;
        if (error?.response?.data?.httpCode && error.response.data.httpCode === 403)
          navigate("/access-denied");
        else
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, setSampleStats, setIsLoading, openPopup]);



  const getSamples = useCallback((controller: AbortController | undefined) => {
    setIsLoading(true);

    if (!locationID) return;

    if (dataFilters === undefined) return;

    var request: GetSamplesRequestDTO = {
      labSampleType: dataFilters?.sampleType?.id ? SampleTypeAsEnum[parseInt(dataFilters.sampleType?.id)] : undefined,
      labSampleStatus: dataFilters?.samplesStatus?.map((obj) => SampleStatusAsEnum[parseInt(obj.id)]),
      isRequestedDateSelected: dataFilters?.isRequestDateSelected,
      startDate: dataFilters?.initialCreationDate?.toUTC().toISO() ?? undefined,
      endDate: dataFilters?.finalCreationDate?.toUTC().plus({ days: 1 }).toISO() ?? undefined,
      isAnalyst: dataFilters?.byAnalist,
      page: currentpage,
      pageLength: itemsPerPage,
      searchWord: dataFilters?.searchWord,
      analystEmail: dataFilters.analystEmail,
      column:
        orderInfoColumns.length > 0 ? orderInfoColumns[0].columnKey : undefined,
    };

    labSamplesSvc
      .getSamples(locationID, request, controller?.signal ? controller.signal : undefined)
      .then((data) => {

        setSamples(data.samples);
        setTotalSamples(data.totalSamples);
        setIsLoading(false);

      })
      .catch((error) => {
        setIsLoading(false);
        if (!error || axios.isCancel(error)) return;
        if (error?.response?.data?.httpCode && error.response.data.httpCode === 403)
          navigate("/access-denied");
        else
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [currentpage, itemsPerPage, setIsLoading, openPopup, dataFilters, orderInfoColumns, locationID, navigate]);



  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);




  /*   const setFiltersToDefault = useCallback(() => {
      if (!filtersRef.current) return;
      filtersRef.current.setStatus([
        SampleStatusEnum.REGISTERED,
        SampleStatusEnum.UNDERANALYSIS,
        SampleStatusEnum.ONHOLD,],
        { clearFilters: true }
      );
    }, [filtersRef]); */




  const handleOnClickUpdateSampleStatus = useCallback(
    (updateSampleDTO: UpdateStatusRequestDTO, sampleId: number) => {
      setIsLoading(true);

      labSamplesSvc
        .updateLabSampleStatus(`${locationID}`, `${sampleId}`, updateSampleDTO)
        .then(() => {
          getSampleStats();

          if (samples.length === 1 && currentpage > 0)
            setCurrentPage(currentpage - 1);

          getSamples(undefined);

          createNotification(
            <Notification
              type="success"
              title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("SAMPLES.POPUPS.StatusChangedSuccess")}
            ></Notification>
          );
        })
        .catch((error) => {
          setIsLoading(false);
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [locationID, setCurrentPage, samples.length, currentpage, getSamples, getSampleStats, setIsLoading, createNotification, openPopup]);




  const onClickPlayPauseButton = useCallback(
    (sampleStatusID: number, sampleId: number, typeId: number) => {

      if (isLoadingTopPermissions || !hasTopPermission) return;

      if (!sampleStatusID || !sampleId) return;

      const result = sampleStatusStateMachine(sampleStatusID);
      if (!result?.nextStepID) return;

      if (result.nextStepID === SampleStatusEnum.ONHOLD) {
        openPopup(
          <OnHoldSamplePopup
            onChange={(dto) => handleOnClickUpdateSampleStatus(dto, sampleId)}
            typeId={typeId}
          />
        );
      } else {
        openPopup(
          <WarningPopup
            className="pause-btn-analyse-sample-popup"
            onDismissClicked={() => {
              closePopup();
            }}
            onConfirmClicked={() => {
              handleOnClickUpdateSampleStatus({ nextStatus: result.nextStepID }, sampleId);
            }}
            dismissText={translate("COMMON.Cancel")}
            confirmText={translate("COMMON.Change")}
          >
            {translate("SAMPLES.POPUPS.ChangeSampleStatusConfirmText") + " " + result?.nextStep + "?"}
          </WarningPopup>
        );
      }
    }, [openPopup, closePopup, handleOnClickUpdateSampleStatus, isLoadingTopPermissions, hasTopPermission]);




  const renderRequestIcon = useCallback((requestId: string | null) => {

    if (isLoadingTopPermissions || !hasTopPermission) return null;
    if (!requestId) return null;

    return <Tooltip text={translate("SAMPLES.INFOANDFORM.AssociatedRequest")}>
      <ScalableIconButton size={24}
        icon={<RequestIcon />} onClick={() => {
          window.open(baseUrl + `/requests/view/` + requestId, "_blank")
        }} /></Tooltip>
  }, [baseUrl, isLoadingTopPermissions, hasTopPermission])




  const sampleStatusIconCell = useCallback(
    (statusId: number, sampleId: number, typeId: number) => {
      let btnInfo: { icon: JSX.Element, tooltipText: string };

      if (!statusId || !sampleId) return;


      if (isLoadingTopPermissions || !hasTopPermission) return;
      if (statusId === SampleStatusEnum.DONE || statusId === SampleStatusEnum.REJECTED)
        return;

      if (statusId === SampleStatusEnum.UNDERANALYSIS) btnInfo = { icon: <PauseIcon />, tooltipText: `${translate("SAMPLES.ChangeTo")} ${translate("SAMPLES.STATUS.OnHold")}` };
      else btnInfo = { icon: <PlayIcon />, tooltipText: `${translate("SAMPLES.ChangeTo")} ${translate("SAMPLES.STATUS.UnderAnalysis")}` };

      return (

        <Tooltip text={btnInfo.tooltipText}>
          <RoundIconButton
            icon={btnInfo.icon}
            onClick={() => onClickPlayPauseButton(statusId, sampleId, typeId)}
          />
        </Tooltip>
      );
    },
    [onClickPlayPauseButton, hasTopPermission, isLoadingTopPermissions]
  );



  const renderCorrelateIcon = useCallback((labSample: Sample) => {
    if (labSample.correlatedRequestId) return null;
    if (isLoadingTopPermissions || !hasTopPermission) return null;

    if (!labSample.sampleId) return null;

    return <Tooltip text={translate("REQUESTS.POPUPS.CorrelateSample")}>
      <ScalableIconButton size={24} icon={<CorrelateIcon />}
        onClick={() =>
          openPopup(<CorrelateLabSamplesPopup
            batch={labSample.batch}
            labSampleID={"" + labSample.sampleId}
            locationID={"" + locationID}
            waybill={labSample.waybill}
            materialReference={labSample.sapNumber ?
              "" + labSample.sapNumber :
              (labSample.materialDescription ? labSample.materialDescription : labSample.othersMaterialDescription)}
            onCompletedOperations={() => { getSamples(undefined); }}
          />)}
      />
    </Tooltip>
  }, [locationID, hasTopPermission, isLoadingTopPermissions, openPopup, getSamples])



  const columns: ColumnDefinition<Sample>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => {
          return (
            <div className="material-container">
              <div className="code">{buildMaterialCellInfo2(v)}</div>
              <div className="small-copy material-description">{v.materialDescription ? v.materialDescription : null}</div>
            </div>)
        },
        headerRender: <>{translate("SAMPLES.INFOANDFORM.Material")}</>,
        columnKey: "MAT",
        isMobilePrimaryCell: true,

      },
      {
        cellRenderProp: (v: Sample) => {
          if (v.labOperator || v.operatorSection)
            return (
              <div className="section-container">
                <div className="section">{v.operatorSection}</div>
                <div className="small-copy employee-name">{v.labOperator}</div>
              </div>
            )
        },
        headerRender: translate("SAMPLES.INFOANDFORM.Section"),
        isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024)
      },
      {
        cellRenderProp: (v) => v.batch,
        headerRender: translate("SAMPLES.INFOANDFORM.Batch"),
        columnKey: "BTC",
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.observations,
        headerRender: translate("SAMPLES.INFOANDFORM.Observations"),
        columnKey: "Observations",
        isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
      },
      {
        cellRenderProp: (v) => v.typeId !== SampleTypeEnum.OTHERS ? translate(SampleTypeTranslationTerms[v.typeId]) : (v.othersType ?
          translate(SampleTypeTranslationTerms[v.typeId]) + ' / ' + translate(SampleOtherTypeTranslationTerms[v.othersType]) :
          translate(SampleTypeTranslationTerms[v.typeId])),
        headerRender: translate("SAMPLES.INFOANDFORM.Type"),
        columnKey: "LabSampleType",
      },
      {
        cellRenderProp: (v) => v.analyst?.name,
        headerRender: translate("SAMPLES.INFOANDFORM.Analyst"),
        columnKey: "Analyst",
        isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
      },
      {
        cellRenderProp: (v) => <div className="repeated-c04">{v.isRepeated ?
          <Tooltip text={translate("SAMPLES.INFOANDFORM.Repeated")}>
            <div className="caption repeated-sample">R</div>
          </Tooltip>
          : null}
          {v.needControl04 ?
            <Tooltip text={translate("SAMPLES.INFOANDFORM.Control04NeededTooltip")}>
              <Tag
                text={"C04"}
                backgroundColor="status-yellow"
                isTiny
              />
            </Tooltip> : null}</div>,
        columnKey: "repeated",
        width: "1.0714rem",
        isMobileHeaderIcon: true
      },
      {
        cellRenderProp: (v) => <Tag
          text={getSampleStatusLabel(v.statusId)}
          backgroundColor={getSampleStatusCss(v.statusId)}
          isTiny={windowResize < 1024}
        />,
        headerRender: windowResize > 768 ? translate("SAMPLES.INFOANDFORM.Status") : null,
        columnKey: "LabSampleStatus",
        isStatusCell: true,
      },
      {
        cellRenderProp: (v) => v.requestedDate ? DateTimeHelper.formatDateTime("" + v.requestedDate) : null,
        headerRender: translate("SAMPLES.INFOANDFORM.RequestDateSummary"),
        columnKey: "RD",
        width: "7.8571rem",
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => <div className="action-icons">
          {renderRequestIcon(v.correlatedRequestId ? v.correlatedRequestId : null)}
          {renderCorrelateIcon(v)}
          {sampleStatusIconCell(v.statusId, v.sampleId, v.typeId)}

        </div>,
        columnKey: "STATUSID",
        width: "4.4286rem",
        isVisible: windowResize > 768,
      },
    ],
    [sampleStatusIconCell, renderRequestIcon, windowResize, renderCorrelateIcon]
  );




  const handleOnClickStatusCards = useCallback(
    (status: SampleStatusEnum[]) => {
      if (!filtersRef.current) return;
      filtersRef.current.setStatus(status, { clearFilters: true });
    },
    [filtersRef]
  );

  const handleOnClickRequestDateCard = useCallback(() => {
    if (!filtersRef.current) return;

    filtersRef.current.setRequestDate({ defaultFilters: true });
  }, [filtersRef]);




  const updateColumnOrderInfo = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      /*       setOrderInfoColumns(orderedPriorities); */
    },
    [/* setOrderInfoColumns */]
  );




  const renderStatsCards = useMemo(() => {
    if (windowResize > 1024)
      return <><div className="overview-header-stats">
        <StatusCard
          title={translate("SAMPLES.STATUS.ProcessedCurrentYear")}
          value={sampleStats?.processedInCurrentYear || "0"}
          backgroundColor="status-dark-grey"
        />
        <StatusCard
          title={translate("SAMPLES.STATUS.TotalPending")}
          value={sampleStats?.pending || "0"}
          backgroundColor="status-light-grey"
          onClick={() =>
            handleOnClickStatusCards([
              SampleStatusEnum.REGISTERED,
              SampleStatusEnum.UNDERANALYSIS,
              SampleStatusEnum.ONHOLD,
            ])
          }
        />
        <StatusCard
          title={translate("SAMPLES.STATUS.UnderAnalysis")}
          value={sampleStats?.underAnalysis || "0"}
          backgroundColor="status-blue"
          onClick={() => {
            handleOnClickStatusCards([SampleStatusEnum.UNDERANALYSIS]);
          }}
        />
        <StatusCard
          title={translate("SAMPLES.STATUS.OnHold")}
          value={sampleStats?.onHold || "0"}
          backgroundColor="status-yellow"
          onClick={() => handleOnClickStatusCards([SampleStatusEnum.ONHOLD])}
        />
        <StatusCard
          title={translate("SAMPLES.STATUS.PendingWithRequestDate")}
          value={sampleStats?.pendingWithRequestDate || "0"}
          backgroundColor="status-red"
          onClick={() => {
            handleOnClickRequestDateCard();
          }}
        />
      </div>
        <Spacer mode="vertical" px="24" /></>
  }, [handleOnClickRequestDateCard, handleOnClickStatusCards, sampleStats?.onHold, sampleStats?.pending, sampleStats?.pendingWithRequestDate, sampleStats?.processedInCurrentYear, sampleStats?.underAnalysis, windowResize])

  return (
    <PageLayout
      tabTitle={`laMP - ${translate("SAMPLES.Samples")}`}
      pageTitle={translate("SAMPLES.ViewSamples")}
      className="view-samples-page"
      showBreadcrumb
    >
      {renderStatsCards}
      <div className="samples-in-laboratory">
        <div className="subtitle">
          {translate("SAMPLES.SamplesInLaboratory")}
        </div>

        <SamplesPageFilters
          ref={filtersRef}
          onFiltersChanged={setDataFilters}
          analysts={analysts}
          sampleStatusOptions={sampleStatus}
          defaultStatusAsEnum={defaultStatus}
          isLoadingAnalystsData={getAnalystsCallIsLoading}
        />

        <ResponsiveDataTable
          columnDefinitions={columns}
          items={samples || []}
          totalitems={totalSamples || 0}
          paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30, 40, 50] }}
          currentpage={currentpage}
          isLoading={isLoading}
          onPageAndItemsChanged={handleOnPageAndItemsChanged}
          onClickRow={(sample) => { window.open(currentPageURL + `/${sample.sampleId}`, "_blank") }}
          onOrderChanged={(orderInfoPriority) => { updateColumnOrderInfo(orderInfoPriority); }}
        />
      </div>
    </PageLayout>
  );
}
