import { CreateAnalysisRequestDTO } from "../../models/api/CreateAnalysisRequestDTO";
import { CreateRequestDetailsForm as RequestDetailsForm } from "../../models/domain/CreateRequestDetailsForm";

export function AnalysisRequestToAnalysisRequestDTOMapper(analysisRequest: RequestDetailsForm, reason: string, waybill: string, shippedByCourier: boolean, analysisReasonTypeId: number) {
   
    var mappedAnalysisRequest: CreateAnalysisRequestDTO = {
        type: analysisRequest.requestType,
        analysisRequestTypeReasonId: analysisReasonTypeId,
        analysisRequestReason: reason,
        name: analysisRequest.name,
        createdDate: analysisRequest.createdDate?.toUTC().toISO(),
        waybill: waybill,
        shippingByCourier: shippedByCourier,
        company: analysisRequest.company,
        industry: analysisRequest.industry,
        factory: analysisRequest.factory,
        comments: analysisRequest.customerComments,
        clientRegistration: analysisRequest.clientRegistration,
        clientRegistrationDescription: analysisRequest.clientRegistrationDescription,
        slurry: analysisRequest.slurry,
        containsHazardousMaterial: analysisRequest.hasHazardousMaterial,
        hazardousMaterialDescription: analysisRequest.hazardousMaterialDescription,
        containsCarcinogenicSubstances: analysisRequest.hasCarcinogenicSubstances,
        carcinogenicSubstancesDescription: analysisRequest.carcinogenicSubstancesDescription,
        otherInformation: analysisRequest.otherInformations,
        pmNumber: analysisRequest.pmNumber,
        position: analysisRequest.position,
        analysisRequestIndustrialSectorId: analysisRequest.industrialSectorId,
        analysisRequestFilterTypeId: analysisRequest.filterTypeId,
        industrialSectorDescription: analysisRequest.industrialSectorDescription,
        filterTypeDescription: analysisRequest.filterTypeDescription,
        ketoProject: analysisRequest.ketoProject,
    };
    return mappedAnalysisRequest;
}
