import "./SideBarButton.css";
import { NavLink } from "react-router-dom";
import { MenuItem } from "./MenuItem";
import { useMemo } from "react";

const isActive = (props: { isActive: boolean; isPending: boolean }) =>
  props.isActive ? "selected" : "is-pending";

export function SideBarButton(props: MenuItem) {
  const isBottomAligned = useMemo(() => {
    return props.isBottomAligned ? "bottom-aligned" : "";
  }, [props.isBottomAligned]);

  return (
    <div className={`icon ${isBottomAligned}`}>
        <NavLink to={props.navigateTo} className={isActive} target={props.isTargetNewTab ? "_blank" : undefined}>
          {props.icon}
        </NavLink> 
    </div>
  );
}
