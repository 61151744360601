import "./EditSampleDetailsPopup.css";

import {
  SampleTypeEnum,
  SampleTypeTranslationTerms,
} from "../../../models/domain/SampleTypeEnum";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
} from "../../../../../lib/infrastructure/ui/UIServices";

import { AccessoriesSampleForm } from "./edit-sample-forms/AccessoriesSampleForm";
import { ReactComponent as ArrowIcon } from "../../../../../lib/assets/icons/arrowRight.svg";
import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { ConfigurationProvider } from "../../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FacilityDTO } from "../../../../../models/api/facilities/FacilitiesResponseDTO";
import { FacilityService } from "../../../../../services/FacilityService";
import { FibersSampleForm } from "./edit-sample-forms/FibersSampleForm";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { LabSampleService } from "../../../services/LabSampleService";
import { Material } from "../../../../../models/domain/materials/Material";
import { MaterialsService } from "../../../../../services/MaterialsService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { OthersSampleForm } from "./edit-sample-forms/OthersSampleForm";
import { PackageSampleForm } from "./edit-sample-forms/PackageSampleForm";
import { Permission } from "../../../../../models/api/gate/Permission";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { ProducedSampleForm } from "./edit-sample-forms/ProducedSampleForm";
import { ProductionSampleForm } from "./edit-sample-forms/ProductionSampleForm";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { Sample } from "../../../models/domain/Sample";
import { SampleAllData } from "../../../models/domain/SampleAllData";
import { SampleForm } from "../../../models/domain/SampleForm";
import { ScalableIconButton } from "../../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { ThreadsSampleForm } from "./edit-sample-forms/ThreadsSampleForm";
import { UpdateSampleRequestDTO } from "../../../models/api/UpdateSampleRequestDTO";
import { getSampleStatusCss } from "../../../../../common/helpers/GetSampleStatusCss";
import { getSampleStatusLabel } from "../../../../../common/helpers/GetSampleStatusLabel";
import { replaceSampleOnURL } from "../../../../../common/helpers/ReplaceSampleOnURL";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useLocation } from "react-router-dom";

/* ICONS */

var materialService = new MaterialsService();
var labSampleSvc = new LabSampleService();
var facilitySvc = new FacilityService();

export interface IGenericSampleFormsProps {
  sample: Sample | undefined;
  materials: Material[];
}

interface IEditSampleDetailsPopupProps {
  sampleId: number;
  typeId: number;
  onOperationsCompleted: () => void;
}

const control04Permissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
];

export function EditSampleDetailsPopup(props: IEditSampleDetailsPopupProps) {
  const { isLoadingPermissions, hasPermission } =
    useHasPermissions(control04Permissions);

  const location = useLocation();

  const currentPageURL = useMemo(() => {
    const baseUrl = ConfigurationProvider.getConfiguration().App.BaseUrl;
    return baseUrl + location.pathname;
  }, [location.pathname]);

  const sampleFormRef = useRef<SampleForm>(null);

  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const locationID = useGlobalLocation();
  const createNotification = useCreateNotification();

  const [sampleData, setSampleData] = useState<SampleAllData>();
  const [materials, setMaterials] = useState<Material[]>([]);
  const [facilities, setFacilities] = useState<FacilityDTO[]>([]);

  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);
  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (!locationID || !props.typeId) return;

    var tempSample: Sample | null = null;

    Promise.all([
      facilitySvc.getFacilities(locationID, { filterByIsActive: true }),
      labSampleSvc.getLabSampleById(`${locationID}`, `${props.sampleId}`),
    ])
      .then(([facilities, labSample]) => {
        setFacilities(facilities.facilities);
        setSampleData(labSample);

        if (props.typeId === SampleTypeEnum.PACKAGE && SampleTypeEnum.OTHERS)
          setIsLoadingContent(false);

        tempSample = labSample.sample;
      })
      .catch((error) => {
        setIsLoadingContent(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

    if (props.typeId !== SampleTypeEnum.PACKAGE && SampleTypeEnum.OTHERS) {
      materialService
        .getActivesMaterials(`${locationID}`, { filterByIsActive: false })
        .then((materialTotalItems) => {
          const filteredMaterials: Material[] =
            materialTotalItems.materials.filter((item) => {
              if (!item.isDisabled || item.id === tempSample?.materialId)
                return item;
            });
          setMaterials(filteredMaterials);
          setIsLoadingContent(false);
        })
        .catch((error) => {
          setIsLoadingContent(false);
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationID || props.typeId]);

  const renderSampleFormsByType = useMemo(() => {
    switch (props.typeId) {
      case SampleTypeEnum.PRODUCTION:
        return (
          <ProductionSampleForm
            ref={sampleFormRef}
            sample={sampleData?.sample}
            materials={materials}
          />
        );
      case SampleTypeEnum.FIBERS:
        return (
          <FibersSampleForm
            ref={sampleFormRef}
            sample={sampleData?.sample}
            materials={materials}
          />
        );
      case SampleTypeEnum.ACCESSORIES:
        return (
          <AccessoriesSampleForm
            ref={sampleFormRef}
            sample={sampleData?.sample}
            materials={materials}
          />
        );
      case SampleTypeEnum.THREADS:
        return (
          <ThreadsSampleForm
            ref={sampleFormRef}
            sample={sampleData?.sample}
            materials={materials}
            facilities={facilities}
          />
        );
      case SampleTypeEnum.PRODUCED:
        return (
          <ProducedSampleForm
            ref={sampleFormRef}
            sample={sampleData?.sample}
            materials={materials}
          />
        );
      case SampleTypeEnum.OTHERS:
        return (
          <OthersSampleForm ref={sampleFormRef} sample={sampleData?.sample} />
        );
      case SampleTypeEnum.PACKAGE:
        return (
          <PackageSampleForm ref={sampleFormRef} sample={sampleData?.sample} />
        );
      default:
        return;
    }
  }, [
    props.typeId,
    sampleData,
    sampleFormRef,
    materials,
    facilities,
    sampleData?.sample,
  ]);

  const handleOnClickEdit = useCallback(() => {
    if (!locationID) {
      openPopup(
        <ErrorPopup>
          {translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}
        </ErrorPopup>
      );
      return;
    }
    if (!sampleFormRef.current || !sampleData) return;

    if (sampleFormRef.current.validate()) {
      setOnClickSubmit(true);

      var request: UpdateSampleRequestDTO = {
        batch: sampleFormRef.current.value().batch,
        facilityId: sampleFormRef.current.value().facilityId,
        isControl04: sampleData?.sample.isControl04,
        materialId: sampleFormRef.current.value().materialId,
        numberOfSpools: sampleFormRef.current.value().numberOfSpools,
        observations: sampleFormRef.current.value().observations,
        othersMaterialDescription:
          sampleFormRef.current.value().othersMaterialDescription,
        othersType: sampleFormRef.current.value().othersType,
        productionOrder: sampleFormRef.current.value().productionOrder,
        supplier: sampleFormRef.current.value().supplier,
        waybill: sampleFormRef.current.value().waybill,
        origin: sampleFormRef.current.value().origin,
        area: sampleFormRef.current.value().area

      };

      labSampleSvc
        .updateLabSample(`${locationID}`, `${props.sampleId}`, request)
        .then((_) => {
          setOnClickSubmit(false);
          closePopup();
          props.onOperationsCompleted();
          createNotification(
            <Notification
              type="success"
              title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("SAMPLES.POPUPS.UpdateSampleSuccess")}
            ></Notification>
          );
        })
        .catch((error) => {
          setOnClickSubmit(false);
          closePopup();
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [
    locationID,
    sampleFormRef.current,
    sampleFormRef.current?.validate,
    setOnClickSubmit,
    openPopup,
    closePopup,
    createNotification,
    translate,
    props.onOperationsCompleted,
    sampleData?.sample.isControl04,
  ]);

  const lastAndPendingControls04: ColumnDefinition<Sample>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => (
          <Tag
            className="pending-item-status"
            text={getSampleStatusLabel(v.statusId)}
            backgroundColor={getSampleStatusCss(v.statusId)}
            isTiny={true}
          />
        ),
        columnKey: "status",
        width: "7.1429rem",
        isStatusCell: true,
      },
      {
        cellRenderProp: (v) =>
          v.createdDate
            ? DateTime.fromISO(v.createdDate).toFormat("dd/MM/yyyy HH'h'mm")
            : "",
        columnKey: "creationDate",
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => (
          <ScalableIconButton size={30} icon={<ArrowIcon />} />
        ),
        columnKey: "status",
        width: "2.1429rem",
        isMobileHeaderIcon: true
      },
    ],
    []
  );

  const renderLastControl04 = useMemo(() => {
    if (sampleData?.sample.isControl04) {
      var lastControl04Response = "";
      if (
        sampleData?.lastLabSamplesControl04.lastSampleControl04?.modifiedDate
      ) {
        lastControl04Response =
          translate("SAMPLES.INFOANDFORM.LastControlZeroFour") +
          ": " +
          DateTime.fromISO(
            sampleData?.lastLabSamplesControl04.lastSampleControl04
              ?.modifiedDate || ""
          ).toFormat("dd/MM/yyyy HH'h'mm");
      } else
        lastControl04Response = translate(
          "SAMPLES.INFOANDFORM.NoControls04Found"
        );

      return (
        <div className="controls04-last-pending">
          <div className="small-copy last-control04-info">
            {lastControl04Response}
          </div>
          {sampleData?.lastLabSamplesControl04.lastSampleControl04
            ?.modifiedDate ? (
            <>
              <ResponsiveDataTable
                className="last-control-table"
                columnDefinitions={lastAndPendingControls04}
                items={
                  [sampleData?.lastLabSamplesControl04.lastSampleControl04] ||
                  []
                }
                totalitems={
                  [sampleData?.lastLabSamplesControl04.lastSampleControl04]
                    .length
                }
                onClickRow={(v) => {
                  window.open(
                    replaceSampleOnURL(currentPageURL, `${v.sampleId}`),
                    "_blank"
                  );
                }}
              />
            </>
          ) : null}
        </div>
      );
    }
  }, [
    sampleData?.sample.isControl04, lastAndPendingControls04, sampleData?.lastLabSamplesControl04.lastSampleControl04,
  ]);

  const renderPendingControl04s = useMemo(() => {
    if (!sampleData?.lastLabSamplesControl04.incompletedSampleControl04)
      return null;

    if (
      sampleData?.lastLabSamplesControl04.incompletedSampleControl04.length >
      0 &&
      sampleData.sample.isControl04
    ) {
      return (
        <div className="controls04-last-pending">
          <div className="controls04-last-pending-title">
            {translate("SAMPLES.INFOANDFORM.PendingControls04")}{" "}
          </div>

          <ResponsiveDataTable
            className="pending-controls-table"
            columnDefinitions={lastAndPendingControls04}
            items={
              sampleData?.lastLabSamplesControl04.incompletedSampleControl04 ||
              []
            }
            totalitems={
              sampleData?.lastLabSamplesControl04.incompletedSampleControl04
                .length
            }
            onClickRow={(v) => {
              window.open(replaceSampleOnURL(currentPageURL, `${v.sampleId}`), "_blank");
            }}
          />
        </div>
      );
    }
  }, [
    sampleData?.lastLabSamplesControl04.incompletedSampleControl04,
    sampleData?.sample.isControl04,
    currentPageURL,
    lastAndPendingControls04,
  ]);

  const renderControl04Checkbox = useMemo(() => {
    return (
      <Checkbox
        text={translate("SAMPLES.INFOANDFORM.ExecuteControlZeroFour")}
        isChecked={sampleData?.sample.isControl04 || false}
        onClick={() => {
          if (sampleData?.sample.materialId && sampleData && sampleData?.sample.batch) {
            var clonedSample = { ...sampleData };
            clonedSample.sample.isControl04 = !clonedSample.sample.isControl04;
            setSampleData(clonedSample);
          }
        }}
      />
    );
  }, [
    sampleData?.sample.isControl04,
    sampleData?.sample.materialId,
    sampleData,
    sampleData?.sample.batch,
    setSampleData,
  ]);

  return (
    <PopupContainer className="edit-samples">
      {onClickSubmit ? <FullScreenLoader /> : null}
      <PopupHeader
        title={`${translate("SAMPLES.POPUPS.EditSampleDetails")}`}
        subtitle={`${translate(SampleTypeTranslationTerms[props.typeId])}`}
      />

      <PopupContent isLoading={isLoadingContent || isLoadingPermissions || !sampleData}>
        <FormContainer>
          <ConditionalRender if={!isLoadingPermissions && hasPermission}>
            {props.typeId === SampleTypeEnum.PRODUCTION && sampleData?.sample.materialIsSubjectToC04 ? (
              <CardContainer className="edit-sample-control04">
                <div className="subtitle">
                  {translate("SAMPLES.ControlZeroFour")}
                </div>
                <Spacer mode="vertical" px={14} />

                <div className="content">
                  <div className="control-checkbox">
                    {renderControl04Checkbox}
                    {renderLastControl04}
                  </div>
                  {renderPendingControl04s}
                </div>
              </CardContainer>
            ) : null}
          </ConditionalRender>

          {renderSampleFormsByType}
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Edit"),
              type: "primary",
              onClick: handleOnClickEdit,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
