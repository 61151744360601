import * as qs from "qs";

import { AnalysisRequestSampleDetails } from "../models/domain/AnalysisRequestSampleDetails";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ContactsCreateDTO } from "../models/api/ContactsCreateDTO";
import { CorrelateSamplesRequestDTO } from "../models/api/CorrelateSamplesRequestDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { LabSampleDTOtoSampleMapper } from "../../samples/services/mappers/GetSampleResponseDTOtoSampleMapper";
import { LabSamplesResponseDTO } from "../../samples/models/api/LabSamplesResponseDTO";
import { RequestSampleCreateDTO } from "../models/api/RequestSampleCreateDTO";
import { RequestSampleDetailsDTO } from "../models/api/RequestSampleDetailsDTO";
import { RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper } from "./mappers/RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper";
import { RequestSampleSummaryDTO } from "../models/api/RequestSampleSummaryDTO";
import { RequestSampleUpdateDTO } from "../models/api/RequestSampleUpdateDTO";
import { RequestSamplesResponseDTO } from "../models/api/RequestSamplesResponseDTO";
import { Sample } from "../../samples/models/domain/Sample";
import { SamplesAndTotalItems } from "../../samples/models/domain/SamplesAndTotalItems";
import { SamplesToCorrelateFilterRequestDTO } from "../models/api/SamplesToCorrelateFilterRequestDTO";

const Route = (path: string) => { return ConfigurationProvider.getConfiguration().App.BackendUrl + path; };

export class RequestSamplesService {

  addRequestSample(locationId: string, requestId: string, requestDto: RequestSampleCreateDTO): Promise<void> {

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples`),
      data: requestDto,
    })
      .then((_) => { })
      .catch((error) => {
        throw error;
      });
  }


  getRequestSamples(locationId: string, requestId: string): Promise<RequestSampleSummaryDTO[]> {
    return HttpClient.sessionRequest<RequestSamplesResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples`)
    })
      .then((response) => {

        return response.data.samples;//TODO: MAP RESPONSE FROM API TO ANOTHER OBJECT
      })
      .catch((error) => { throw error; });
  }



  getRequestSampleById(locationId: string, requestId: string, sampleId: string): Promise<RequestSampleSummaryDTO> {
    return HttpClient.sessionRequest<RequestSampleSummaryDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples/${sampleId}`)
    })
      .then((response) => {

        return response.data;//TODO: MAP RESPONSE FROM API TO ANOTHER OBJECT
      })
      .catch((error) => { throw error; });
  }



  getRequestSampleByIdExtended(locationId: string, requestId: string, sampleId: string): Promise<AnalysisRequestSampleDetails | null> {
    return HttpClient.sessionRequest<RequestSampleDetailsDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples/${sampleId}/Extended`)
    })
      .then((response) => {
        if (response.data)
          return RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper(response.data);
        return null;
      })
      .catch((error) => { throw error; });
  }


  deleteRequestSample(locationId: string, requestId: string, sampleId: number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples/${sampleId}`),
    })
      .then((_) => { })
      .catch((error) => { throw error; });
  }


  updateRequestSample(locationId: number | string, analysisRequestId: number | string, sampleId: number | string, request: RequestSampleUpdateDTO):
    Promise<AnalysisRequestSampleDetails | null> {
    return HttpClient.sessionRequest<RequestSampleDetailsDTO>({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${analysisRequestId}/Samples/${sampleId}`),
      data: request
    })
      .then((response) => {
        if (response.data)
          return RequestSampleDetailsDTOToAnalysisRequestSampleDetailsMapper(response.data);
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }


  getPossibleSampleCorrelations(locationId: string, requestId: string, requestSampleId: string, queryOpts: SamplesToCorrelateFilterRequestDTO, abortSignal: AbortSignal | undefined): Promise<SamplesAndTotalItems> {

    return HttpClient.sessionRequest<LabSamplesResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples/${requestSampleId}/Correlation`),
      params: queryOpts,
      signal: abortSignal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((response) => {
        var mappedSamples = response.data.samples.map((ob) =>
          LabSampleDTOtoSampleMapper(ob)
        );
        return {
          samples: mappedSamples,
          totalSamples: response.data.total,
        };
      })
      .catch((error) => {
        throw error;
      });
  }


  correlateSampleOnRequestsPage(locationId: string, requestId: string, requestDto: CorrelateSamplesRequestDTO): Promise<void> {
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples/Correlation`),
      data: requestDto
    })
      .then((_) => { })
      .catch((error) => {
        throw error;
      });
  }



  deleteRequestSampleCorrelation(locationId: string, requestId: string, sampleId: string): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples/${sampleId}/Correlation`),
    })
      .then((_) => { })
      .catch((error) => { throw error; });
  }




  cloneRequestSample(locationId: string, requestId: string, sampleId: string, requestDto: RequestSampleCreateDTO): Promise<void> {

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Samples/${sampleId}/Clone`),
      data: requestDto,
    })
      .then((_) => {

      })
      .catch((error) => {
        throw error;
      });
  }
}