import "./EditRequestSampleDetailsPopup.css";

import {
  AnalysisRequestTypeEnum,
  RequestTypeTranslationTerms,
} from "../../../models/domain/AnalysisRequestTypeEnum";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AnalysisRequestSampleDetails } from "../../../models/domain/AnalysisRequestSampleDetails";
import { AnalysisRequestSampleDetailsToRequestSampleUpdateDTOMapper } from "../../../services/mappers/AnalysisRequestSampleDetailsToRequestSampleUpdateDTOMapper";
import { EditRequestSampleARForm } from "./edit-request-samples-forms/EditRequestSampleARForm";
import { EditRequestSampleDFForm } from "./edit-request-samples-forms/EditRequestSampleDFForm";
import { EditRequestSampleDryerForm } from "./edit-request-samples-forms/EditRequestSampleDryerForm";
import { EditRequestSampleForm } from "../../../models/domain/EditRequestSampleForm";
import { EditRequestSampleWFPPForm } from "./edit-request-samples-forms/EditRequestSampleWFPPForm";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Material } from "../../../../../models/domain/materials/Material";
import { MaterialsService } from "../../../../../services/MaterialsService";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RequestSamplesService } from "../../../services/RequestSamplesService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";

interface IEditRequestSampleDetailsPopupProps {
  requestId?: string;
  locationId?: string;
  sampleId?: string;
  onOperationsCompleted: () => void;
}

var analysisRequestSampleSvc = new RequestSamplesService();
var materialService = new MaterialsService();

export function EditRequestSampleDetailsPopup(props: IEditRequestSampleDetailsPopupProps) {
  const editRequestSampleFormRef = useRef<EditRequestSampleForm>(null);

  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const createNotification = useCreateNotification();

  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState<boolean>(false);

  const [requestSampleDetails, setRequestSampleDetails] = useState<AnalysisRequestSampleDetails>();

  const [materials, setMaterials] = useState<Material[]>([]);


  /*****************
    * SERVICE CALLS 
  ****************/


  const [getRequestSampleByIdExtendedCall, getRequestSampleByIdExtendedCallIsLoading] = useServiceCallPro2(analysisRequestSampleSvc, analysisRequestSampleSvc.getRequestSampleByIdExtended);
  const [getMaterialsCall, getMaterialsCallIsLoading] = useServiceCallPro2(materialService, materialService.getActivesMaterials);



  /****************************
    * DATA REQUESTS
  *****************************/



  const getRequestSampleByIdExtended = useCallback(() => {

    if (!props.requestId || !props.locationId || !props.sampleId) return;

    getRequestSampleByIdExtendedCall(`${props.locationId}`, `${props.requestId}`, `${props.sampleId}`)
      .then((data) => {
        if (!data) return;
        setRequestSampleDetails(data);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
      });
  }, [props.requestId, props.locationId, props.sampleId, getRequestSampleByIdExtendedCall, setRequestSampleDetails, openPopup]);




  const getMaterials = useCallback(() => {

    if (!props.locationId) {
      openPopup(<ErrorPopup>{translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}</ErrorPopup>); return;
    }

    getMaterialsCall(props.locationId, { filterByIsActive: true })
      .then((data) => {
        setMaterials(data.materials);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })
  }, [props.locationId, getMaterialsCall, setMaterials, openPopup]);






  const handleSaveBtnClicked = useCallback(() => {

    if (!editRequestSampleFormRef.current) return;
    if (!editRequestSampleFormRef.current.validate()) return;

    setIsSaveButtonClicked(true);

    var updateDTO = AnalysisRequestSampleDetailsToRequestSampleUpdateDTOMapper(editRequestSampleFormRef.current.value());

    analysisRequestSampleSvc
      .updateRequestSample("" + props.locationId, "" + props.requestId, "" + props.sampleId, updateDTO)
      .then((_) => {
        props.onOperationsCompleted();
        setIsSaveButtonClicked(false);
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("REQUESTS.POPUPS.EditRequestDetailsSuccess")}
          />
        );
        closePopup();
      })
      .catch((error) => {
        setIsSaveButtonClicked(false);
        if (!error) return;
        openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
      });

  }, [editRequestSampleFormRef.current, setIsSaveButtonClicked, editRequestSampleFormRef.current?.validate, editRequestSampleFormRef.current?.value, props.locationId, props.requestId,
  props.onOperationsCompleted, props.sampleId, closePopup, createNotification, openPopup]);




  /****************************
    * DATA MANIPULATION EFFECTS
  *****************************/



  useEffect(() => {
    getRequestSampleByIdExtended();
    getMaterials();
  }, []);






  const renderRequestFormsByType = useMemo(() => {
    if (!requestSampleDetails || !materials) return;

    switch (requestSampleDetails.sampleType) {
      case AnalysisRequestTypeEnum.AR:
        return <EditRequestSampleARForm info={requestSampleDetails} ref={editRequestSampleFormRef} materials={materials} isLoading={getMaterialsCallIsLoading} />;
      case AnalysisRequestTypeEnum.WF:
        return <EditRequestSampleWFPPForm info={requestSampleDetails} ref={editRequestSampleFormRef} materials={materials} isLoading={getMaterialsCallIsLoading} />;
      case AnalysisRequestTypeEnum.PP:
        return <EditRequestSampleWFPPForm info={requestSampleDetails} ref={editRequestSampleFormRef} materials={materials} isLoading={getMaterialsCallIsLoading} />;
      case AnalysisRequestTypeEnum.DF:
        return <EditRequestSampleDFForm info={requestSampleDetails} ref={editRequestSampleFormRef} materials={materials} isLoading={getMaterialsCallIsLoading} />;
      case AnalysisRequestTypeEnum.DRYER:
        return <EditRequestSampleDryerForm info={requestSampleDetails} ref={editRequestSampleFormRef} materials={materials} isLoading={getMaterialsCallIsLoading} />;
      default:
        return;
    }
  }, [requestSampleDetails, materials]);




  /****************************
    * USER ACTIONS
  *****************************/






  /****************************
    * CSS & HTML
  *****************************/



  return (
    <PopupContainer className="edit-request-sample">
      {isSaveButtonClicked ? <FullScreenLoader /> : null}
      <PopupHeader
        title={`${translate("REQUESTS.POPUPS.EditSample")}`}
        subtitle={`${translate("REQUESTS.INFOANDFORM.Type")}: ` +
          ((requestSampleDetails?.sampleType && requestSampleDetails?.sampleType !== undefined) ?
            `${translate(RequestTypeTranslationTerms[requestSampleDetails?.sampleType])}` : "")}
      />

      <PopupContent isLoading={getRequestSampleByIdExtendedCallIsLoading}>
        <FormContainer>
          <div className="edit-request-sample-form">{renderRequestFormsByType}</div>
        </FormContainer>
        <Spacer mode={"vertical"} px="30"></Spacer>
        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Edit"),
              type: "primary",
              onClick: handleSaveBtnClicked,
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
