import { GetOtherTypes, OtherTypeEnum, SampleOtherTypeTerms } from "../../../../models/domain/OtherTypesEnum";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { Material } from "../../../../../../models/domain/materials/Material";
import { OtherType } from "../../../../models/domain/OtherType";
import React from "react";
import { Sample } from "../../../../models/domain/Sample";
import { SampleForm } from "../../../../models/domain/SampleForm";
import { hasOneElementSelected } from "../../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";

export interface IOthersSampleFormsProps {
  sample: Sample | undefined;
}



const typeLabelSelector = (item: OtherType) => item.description;
const typeIdSelector = (item: OtherType) => item.id;



export const OthersSampleForm = forwardRef(
  function OthersSampleForms(props: IOthersSampleFormsProps, ref: React.ForwardedRef<SampleForm>) {

    //TODO: TEMP - needs fix
    const otherTypes = GetOtherTypes();

    const materialFormControl = useFormControl<string>({
      validators: [validateWhiteSpaceStrings()],
      enableAutoValidate: true,
      isDisabled: false,
    });

    const typeFormControl = useFormControl<OtherType>({
      validators: [hasOneElementSelected()],
      enableAutoValidate: true,
      isDisabled: false,
    });

    const oPFormControl = useFormControl<string>({
      isDisabled: false,
    });

    const batchFormControl = useFormControl<string>({
      isDisabled: false,
    });

    const waybillFormControl = useFormControl<string>({
      isDisabled: false,
    });

    const observationsFormControl = useFormControl<string>({
      isDisabled: false,
    });





    useEffect(() => {

      if (!props.sample) return;

      materialFormControl.setValue(props.sample.othersMaterialDescription);
      typeFormControl.setValue(otherTypes.find((ot) => ot.id === (props.sample?.othersType)?.toString()));
      batchFormControl.setValue(props.sample?.batch);
      oPFormControl.setValue(props.sample?.op);
      waybillFormControl.setValue(props.sample?.waybill);
      observationsFormControl.setValue(props.sample.observations);

    }, [props.sample]);





    useImperativeHandle(ref, () => {
      return {
        validate() {
          return AllValid(materialFormControl.validate(), typeFormControl.validate(), oPFormControl.validate(), batchFormControl.validate(), waybillFormControl.validate(), observationsFormControl.validate())
        },
        value() {
          return {
            othersType: SampleOtherTypeTerms[Number(typeFormControl.value?.id)], //?? OtherTypeEnum.OTHERS,
            batch: batchFormControl.value,
            productionOrder: oPFormControl.value,
            waybill: waybillFormControl.value,
            observations: observationsFormControl.value,
            othersMaterialDescription: materialFormControl.value
          }
        }
      }
    }, [materialFormControl.validate, typeFormControl.validate, batchFormControl.validate, oPFormControl.validate, waybillFormControl.validate, observationsFormControl.validate,
    materialFormControl.value, typeFormControl.value, batchFormControl.value, oPFormControl.value, waybillFormControl.value, observationsFormControl.value, SampleOtherTypeTerms]);





    return (
      <>
        <FormFieldTextInput
          label={translate("SAMPLES.INFOANDFORM.Material")}
          placeholder={translate(
            "SAMPLES.INFOANDFORM.MaterialPlaceholder")}
          formControl={materialFormControl}
        />
        <FormFieldSelectSingle
          label={translate("SAMPLES.INFOANDFORM.Type")}
          placeholder={translate("SAMPLES.INFOANDFORM.TypePlaceholder")}
          options={otherTypes}
          labelSelector={typeLabelSelector}
          idSelector={typeIdSelector}
          formControl={typeFormControl}
          isClearSelectionAvailable={false}
        />
        <FormFieldTextInput
          formControl={oPFormControl}
          label={translate("SAMPLES.INFOANDFORM.ProductionOrder") + " (" + translate("COMMON.Optional") + ")"}
          placeholder={translate(
            "SAMPLES.INFOANDFORM.ProductionOrderPlaceholder"
          )}
        />
        <FormFieldTextInput
          formControl={batchFormControl}
          label={translate("SAMPLES.INFOANDFORM.Batch") + " (" + translate("COMMON.Optional") + ")"}
          placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
        />
        <FormFieldTextInput
          formControl={waybillFormControl}
          label={translate("SAMPLES.INFOANDFORM.Waybill")}
          placeholder={translate("SAMPLES.INFOANDFORM.WaybillPlaceholder")}
        />
        <FormFieldTextArea
          formControl={observationsFormControl}
          label={translate("SAMPLES.INFOANDFORM.Observations")}
          placeholder={translate(
            "SAMPLES.INFOANDFORM.ObservationsPlaceholder"
          )}
        />
      </>
    );
  });
