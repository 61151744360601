import * as qs from "qs";

import { AllRequestDetails } from "../models/domain/AllRequestDetails";
import { AnalysisRequestDTO } from "../models/api/AnalysisRequestDTO";
import { AnalysisRequestDTOtoRequestDetailsMapper } from "./mappers/AnalysisRequestDTOtoRequestDetailsMapper";
import { AnalysisRequestFiltersDTO } from "../models/api/GetAnalysisRequestFiltersDTO";
import { AnalysisRequestResponseDTO } from "../models/api/AnalysisRequestResponseDTO";
import { AnalysisRequestStats } from "../models/domain/AnalysisRequestStats";
import { CancelAnalysisRequestDTO } from "../models/api/CancelAnalysisRequestDTO";
import { ChangeAnalysisRequestStatusDTO } from "../models/api/ChangeAnalysisRequestStatusDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CreateAnalysisRequestDTO } from "../models/api/CreateAnalysisRequestDTO";
import { GetAnalysisRequestsStatsResponseDTO } from "../models/api/GetAnalysisRequestsStatsResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { NumberLiteralType } from "typescript";
import { RequestDetails } from "../models/domain/RequestDetails";
import { UpdateAnalysisRequestRequestDTO } from "../models/api/UpdateAnalysisRequestRequestDTO";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class AnalysisRequestService {


  addAnalysisRequest(locationId: number | string, request: CreateAnalysisRequestDTO): Promise<RequestDetails | null> {
    return HttpClient.sessionRequest<AnalysisRequestDTO>({
      method: "POST",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests`),
      data: request,
    })
      .then((response) => {
        if (response.data)
          return AnalysisRequestDTOtoRequestDetailsMapper(response.data);
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }

  getAnalysisRequestsStats(locationId: string | number): Promise<AnalysisRequestStats> {
    return HttpClient.sessionRequest<GetAnalysisRequestsStatsResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/Stats`),
    })
      .then((response) => {

        var mappedResponse: AnalysisRequestStats = {};

        if (response.data) mappedResponse = {
          canceled: response.data.analysisRequestsCanceled,
          underAnalysis: response.data.analysisRequestsUnderAnalysis,
          pending: response.data.analysisRequestsToBeCompleted,
          registered: response.data.analysisRequestsRegistered
        }

        return mappedResponse;
      })
      .catch((error) => {
        throw error;
      });
  }



  getAnalysisRequests(locationId: string | number, filters: AnalysisRequestFiltersDTO, abortSignal: AbortSignal|undefined): Promise<AllRequestDetails> {
    return HttpClient.sessionRequest<AnalysisRequestResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests`),
      params: filters,
      signal: abortSignal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },

    })
      .then((response) => {
        var requestDetails: RequestDetails[] = []

        if (response.data.analysisRequests)
          requestDetails = response.data.analysisRequests.map((ob) => AnalysisRequestDTOtoRequestDetailsMapper(ob));

        return {
          requestDetails: requestDetails,
          total: response.data.total ?? 0,
        };
      })
      .catch((error) => {
        throw error;
      });
  }



  getAnalysisRequestById(locationId: string | number, requestId: string): Promise<RequestDetails | null> {
    return HttpClient.sessionRequest<AnalysisRequestDTO>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}`),
    })
      .then((response) => {
        if (response.data){

          return AnalysisRequestDTOtoRequestDetailsMapper(response.data);
        }
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateAnalysisRequest(locationId: number | string, analysisRequestId: number | string, request: UpdateAnalysisRequestRequestDTO): Promise<RequestDetails | null> {

    return HttpClient.sessionRequest<AnalysisRequestDTO>({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${analysisRequestId}`),
      data: request
    })
      .then((response) => {
        if (response.data)
          return AnalysisRequestDTOtoRequestDetailsMapper(response.data);
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }

  changeAnalysisRequestStatus(locationId: number | string, analysisRequestId: number | string, request: ChangeAnalysisRequestStatusDTO): Promise<RequestDetails | null> {
    return HttpClient.sessionRequest<AnalysisRequestDTO>({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${analysisRequestId}/Status`),
      data: request
    })
      .then((response) => {
        if (response.data)
          return AnalysisRequestDTOtoRequestDetailsMapper(response.data);
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }

  cancelAnalysisRequest(locationId: string, analysisRequestId: string, request: CancelAnalysisRequestDTO): Promise<void> {
    return HttpClient.sessionRequest<void>({
      method: "PUT",
      url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${analysisRequestId}/Cancel`),
      data: request
    })
      .then((_) => { })
      .catch((error) => { throw error; });
  }
}