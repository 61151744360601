import "./DeliverSamplesOverviewPage.css";

import { SampleTypeEnum, SampleTypeTranslationTerms } from "../../models/domain/SampleTypeEnum";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { BetweenNumbersHelper } from "../../../../lib/helpers/BetweenNumbersHelper";
import { ReactComponent as CloneIcon } from "../../../../lib/assets/icons/clone.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { DateTimeHelper } from "../../../../lib/utils/DateTimeHelper";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { LabSampleService } from "../../services/LabSampleService";
import { LargeButton } from "../../../../lib/components/buttons/LargeButton";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { QueryOptions } from "../../../../models/domain/materials/QueryOptions";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { Sample } from "../../models/domain/Sample";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { ReactComponent as CorrelateIcon } from "../../../../lib/assets/icons/correlacionar.svg";
import { ReactComponent as RequestIcon } from "../../../../lib/assets/icons/pedidos.svg";
import { buildMaterialCellInfo2, buildMaterialCellinfo } from "../../../../common/helpers/BuildMaterialCellinfo";
import { getSampleStatusCss } from "../../../../common/helpers/GetSampleStatusCss";
import { getSampleStatusLabel } from "../../../../common/helpers/GetSampleStatusLabel";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CorrelateLabSamplesPopup } from "../popups/CorrelateLabSamplesPopup";

/***** ICONS ******/

var labSampleService = new LabSampleService();

const deliverSamplePermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_ACCESS,
  Permission.LOCATION_REGISTER_SAMPLES
];



const topPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS
];

export function DeliverSamplesOverviewPage() {
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const navigate = useNavigate();
  const locationID = useGlobalLocation();



  const baseUrl = useMemo(() => {
    return ConfigurationProvider.getConfiguration().App.BaseUrl;
  }, [ConfigurationProvider.getConfiguration]);


  const { isLoadingPermissions, hasPermission } = useHasPermissions(deliverSamplePermissions);
  const { isLoadingPermissions: isLoadingCorrelationPermission, hasPermission: hasCorrelationPermissions } = useHasPermissions(topPermissions);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentpage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [totalSamples, setTotalSamples] = useState<number>(0);
  const [lastSamplesDelivered, setLastSamplesDelivered] = useState<Sample[]>([]);



  useEffect(() => {
    getLastSamplesDelivered();
  }, [currentpage, itemsPerPage]);




  const getLastSamplesDelivered = useCallback(() => {
    if (!locationID) {
      openPopup(
        <ErrorPopup>
          {translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}
        </ErrorPopup>
      );
      return;
    }

    setIsLoading(true);

    var queryOptions: QueryOptions = { page: currentpage, pageLength: itemsPerPage };

    labSampleService
      .getLastDeliveredSamples(locationID, queryOptions)
      .then((data) => {
        setLastSamplesDelivered(data.samples);
        setTotalSamples(data.totalSamples);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, openPopup, setIsLoading, currentpage, itemsPerPage, setLastSamplesDelivered, setTotalSamples,]);



  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    }, [setItemsPerPage, setCurrentPage]);



  const renderRequestIcon = useCallback((requestId: string | null) => {
    if (isLoadingCorrelationPermission) return null;
    if (!requestId || !hasCorrelationPermissions) return null;
    return <Tooltip text={translate("SAMPLES.INFOANDFORM.AssociatedRequest")}>
      <ScalableIconButton size={24} icon={<RequestIcon />} onClick={() => {
        window.open(baseUrl + `/requests/view/` + requestId, "_blank")
      }
      } />
    </Tooltip>;
  }, [baseUrl, hasCorrelationPermissions, isLoadingCorrelationPermission])




  const renderCorrelateIcon = useCallback((labSample: Sample) => {
    if (labSample.correlatedRequestId) return null;
    if (isLoadingCorrelationPermission) return null;



    if (!labSample.sampleId || !hasCorrelationPermissions) return null;

    return <Tooltip text={translate("REQUESTS.POPUPS.CorrelateSample")}>
      <ScalableIconButton size={24} icon={<CorrelateIcon />}
        onClick={() =>
          openPopup(<CorrelateLabSamplesPopup
            batch={labSample.batch}
            labSampleID={"" + labSample.sampleId}
            locationID={"" + locationID}
            waybill={labSample.waybill}
            materialReference={labSample.sapNumber ? "" + labSample.sapNumber :
              (labSample.materialDescription ? labSample.materialDescription : labSample.othersMaterialDescription)}
            onCompletedOperations={() => { getLastSamplesDelivered(); }}
          />)}
      />
    </Tooltip>
  }, [locationID, hasCorrelationPermissions, isLoadingCorrelationPermission, openPopup, getLastSamplesDelivered])







  const columns: ColumnDefinition<Sample>[] = useMemo(() => [
    {
      cellRenderProp: (v) => {
        return (
          <div className="material-container">
            <div className="code">{buildMaterialCellInfo2(v)}</div>
            <div className="small-copy material-description">{v.materialDescription ? v.materialDescription : null}</div>
          </div>)
      },
      headerRender: <>{translate("SAMPLES.INFOANDFORM.Material")}</>,
      columnKey: "material",
      isMobilePrimaryCell: true,
    },
    /*     {
          cellRenderProp: (v) => buildMaterialCellinfo(v),
          headerRender: translate("SAMPLES.INFOANDFORM.Description"),
          columnKey: "material",
          isMobilePrimaryCell: true,
        }, */
    {
      cellRenderProp: (v: Sample) => {
        if (v.labOperator || v.operatorSection)
          return (
            <div className="section-container">
              <div className="section">{v.operatorSection}</div>
              <div className="small-copy employee-name">{v.labOperator}</div>
            </div>
          )

      },
      headerRender: translate("SAMPLES.INFOANDFORM.Section"),
      isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024)
    },
    {
      cellRenderProp: (v) => v.batch,
      headerRender: translate("SAMPLES.INFOANDFORM.Batch"),
      columnKey: "batch",
      isMobilePrimaryCell: true,
    },
    {
      cellRenderProp: (v) => v.observations,
      headerRender: translate("SAMPLES.INFOANDFORM.Observations"),
      columnKey: "observations",
      isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1200),
    },
    {
      cellRenderProp: (v) => translate(SampleTypeTranslationTerms[v.typeId]) ?? "unknown",
      headerRender: translate("SAMPLES.INFOANDFORM.Type"),
      width: "8%",
      columnKey: "type",
    },
    {
      cellRenderProp: (v) => v.analyst?.name,
      headerRender: translate("SAMPLES.INFOANDFORM.Analyst"),
      columnKey: "analyst",
      isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
    },
    {
      cellRenderProp: (v) => v.requestedDate ? DateTimeHelper.formatDateTime("" + v.requestedDate) : null,
      headerRender: translate("SAMPLES.INFOANDFORM.RequestDate"),
      columnKey: "requestDate",
      width: "7.8571rem",
      isMobilePrimaryCell: true,
    },
    {
      cellRenderProp: (v) => <div className="repeated-c04">{v.isRepeated ?
        <Tooltip text={translate("SAMPLES.INFOANDFORM.Repeated")}>
          <div className="caption repeated-sample">R</div>
        </Tooltip>
        : null}
        {v.needControl04 ?
          <Tooltip text={translate("SAMPLES.INFOANDFORM.Control04NeededTooltip")}>
            <Tag
              text={"C04"}
              backgroundColor="status-yellow"
              isTiny
            />
          </Tooltip> : null}</div>,
      columnKey: "repeated",
      width: "1.0714rem",
      isMobileHeaderIcon: true
    },
    {
      cellRenderProp: (v) => <Tag
        text={getSampleStatusLabel(v.statusId)}
        backgroundColor={getSampleStatusCss(v.statusId)}
        isTiny={windowResize < 1024}
      />,
      headerRender: windowResize > 768 ? translate("SAMPLES.INFOANDFORM.Status") : null,
      columnKey: "status",
      isStatusCell: true,
      className: "mobile-tag-cell"
    },
    {
      cellRenderProp: (v) => DateTimeHelper.formatDateTime("" + v.createdDate),
      headerRender: translate("SAMPLES.INFOANDFORM.RegistrationDate"),
      columnKey: "creationDate",
      width: "7.8571rem",
      isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
    },
    {
      cellRenderProp: (v) => <div className="action-icons">
        {renderRequestIcon(v.correlatedRequestId ? v.correlatedRequestId : null)}
        {renderCorrelateIcon(v)}
        <Tooltip text={translate("COMMON.CloneAction")}>
          <ScalableIconButton
            className="clone-btn" size={24} icon={<CloneIcon />}
            onClick={() => navigate(`/samples/new/${SampleTypeEnum[v.typeId].toLowerCase()}/${v.sampleId.toString()}`)} />
        </Tooltip>
      </div>,
      columnKey: "action-icons",
      width: "4.4286rem",
      isVisible: windowResize > 768,
    },

  ], [windowResize, navigate, renderRequestIcon, renderCorrelateIcon]);


  return (
    <PageLayout
      tabTitle={`laMP - ${translate("SAMPLES.Samples")}`}
      pageTitle={translate("SAMPLES.Samples")}
      className="deliver-samples-overview"
      showBreadcrumb
    >
      {isLoadingPermissions ? <FullScreenLoader /> : null}
      <div className="overview-header">
        <ConditionalRender if={hasPermission}>

          <LargeButton
            text={translate("SAMPLES.DeliverSample")}
            type="primary"
            onClick={() => navigate("/samples/new")}
          />
        </ConditionalRender>
      </div>
      <Spacer mode="vertical" px="24" />
      <div className="last-samples-delivered">
        <div className="subtitle">
          {translate("SAMPLES.LastSamplesDelivered")}
        </div>
        <ResponsiveDataTable
          columnDefinitions={columns}
          items={lastSamplesDelivered || []}
          totalitems={totalSamples || 0}
          paginationOptions={{
            itemsPerPage: itemsPerPage,
            itemsPerPageOptions: [10, 20, 30, 40, 50],
          }}
          currentpage={currentpage}
          isLoading={isLoading}
          onPageAndItemsChanged={handleOnPageAndItemsChanged}
          /*   onClickRow={(sample) => { window.open(currentPageURL + `/${sample.sampleId}`, "_blank") }} */
          onClickRow={(row) => {
            if (row.sampleId) navigate(`${row.sampleId}`);
          }}
        />
      </div>
    </PageLayout>
  );
}
