import { DateTime } from "luxon";

export class DateTimeHelper {

  static formatDateTime(dateString: string) {

    if (dateString) {
      let date = DateTime.fromISO(dateString).toLocal();
      return date.toFormat("yyyy/MM/dd");
    }
    return "";
  }


  static formatDateTimeAsTimeAgo(dateString: string) {
    return DateTime.fromISO(dateString).toLocal().toRelative();
  }
}
