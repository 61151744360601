import { SampleStatus } from "./SampleStatus";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum SampleStatusEnum {
    REGISTERED = 1,
    UNDERANALYSIS = 2,
    ONHOLD = 3,
    DONE = 4,
    REJECTED = 5
}

export const SampleStatusTranslationTermsPlural: { [key: string]: string } = {
    1: "SAMPLES.STATUS.MultipleRegisted",
    2: "SAMPLES.STATUS.UnderAnalysis",
    3: "SAMPLES.STATUS.OnHold",
    4: "SAMPLES.STATUS.MultipleFinished",
    5: "SAMPLES.STATUS.MultipleRejected",
}

export const SampleStatusTranslationTerms: { [key: string ]: string } = {
    1: "SAMPLES.STATUS.Registed",
    2: "SAMPLES.STATUS.UnderAnalysis",
    3: "SAMPLES.STATUS.OnHold",
    4: "SAMPLES.STATUS.Finished",
    5: "SAMPLES.STATUS.Rejected",
}



export const SampleStatusAsEnum: { [key: number]: SampleStatusEnum } = {
    1: SampleStatusEnum.REGISTERED,
    2: SampleStatusEnum.UNDERANALYSIS,
    3: SampleStatusEnum.ONHOLD,
    4: SampleStatusEnum.DONE,
    5: SampleStatusEnum.REJECTED,
};

export function GetSampleStatus() {

    const sampleStatusArray: SampleStatus[] = Object.values(SampleStatusEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSampleStatus: SampleStatus = {
                id: "" + enumValue,
                description: translate(SampleStatusTranslationTermsPlural[enumValue]) || "-unknown-"
            };
            return mappedSampleStatus;
        });

    return sampleStatusArray;
}