import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export function getSampleStatusLabel(statusId: number) {
    switch (statusId) {
        case 1:
            return translate("SAMPLES.STATUS.Registed");
        case 2:
            return translate("SAMPLES.STATUS.UnderAnalysis");
        case 3:
            return translate("SAMPLES.STATUS.OnHold");
        case 4:
            return translate("SAMPLES.STATUS.Finished");
        case 5:
            return translate("SAMPLES.STATUS.Rejected");
        default:
            return "";
    }
}
