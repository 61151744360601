import { SampleTypeEnum, SampleTypeTranslationTerms } from "../../features/samples/models/domain/SampleTypeEnum";

import { Sample } from "../../features/samples/models/domain/Sample";
import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export function BuildSampleDetailsCellInfo(sample: Sample) {
    var response = "";

    if (sample.othersMaterialDescription)
        response = translate(SampleTypeTranslationTerms[sample.typeId]) + ": " + sample.othersMaterialDescription;
    if (sample.sapNumber)
        response = translate(SampleTypeTranslationTerms[sample.typeId]) + ": [" + sample.sapNumber + "] " + sample.materialDescription;
    if(sample.typeId === SampleTypeEnum.PACKAGE && sample.waybill)
        response = translate(SampleTypeTranslationTerms[sample.typeId]) + ": " + sample.waybill;

    return response;
}