import "./TestTypesHomePage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { AddTestTypePopup } from "./popups/AddTestTypePopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditTestTypePopup } from "./popups/EditTestTypePopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { LabTestType } from "../../../../models/domain/lab-tests/LabTestType";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { TestTypesQueryOptions } from "../services/TestTypesQueryOptions";
import { TestTypesService } from "../services/TestTypesService";
import { Toggle } from "../../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useParams } from "react-router-dom";

var testTypesSvc = new TestTypesService();

export function TestTypesHomePage() {
    const { id: locationId } = useParams();

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();

    const [testTypes, setTestTypes] = useState<LabTestType[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    var searchBoxFormControl = useFormControl<string>({});
    const [searchWord, setSearchWord] = useState<string>();


    useEffect(() => {
        getTestTypes();
    }, [currentpage, itemsPerPage, searchWord]);


    const getTestTypes = useCallback(() => {
        if (!locationId) return;

        setIsLoading(true);
        var queryOptions: TestTypesQueryOptions = {
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: searchWord,
            filterByIsActive: false,
        };

        testTypesSvc.getLabTestTypes(locationId, queryOptions)
            .then((response) => {
                setTestTypes(response.testTypes);
                setTotalItems(response.total);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [openPopup,
        locationId,
        setTestTypes,
        setTotalItems,
        setIsLoading,
        currentpage, itemsPerPage, searchWord
    ]);



    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setSearchWord(searchBoxFormControl.value);
            }
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchBoxFormControl.value]);

    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    const deactivateTestType = useCallback((testTypeId: number) => {
        if (!locationId || !testTypeId) return;

        testTypesSvc
            .editLabTestTypeStatus(locationId, testTypeId, true)
            .then((_) => {
                getTestTypes();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.TESTTYPES.POPUP.DeactivateTestTypeSuccess")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [openPopup, locationId, createNotification]);

    const activateTestType = useCallback((testTypeId: number) => {

        if (!locationId || !testTypeId) return;

        testTypesSvc
            .editLabTestTypeStatus(locationId, testTypeId, false)
            .then((_) => {
                getTestTypes();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.TESTTYPES.POPUP.ActivateTestTypeSuccess")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [openPopup, locationId, getTestTypes, createNotification]);


    const openEditPopup = useCallback((id: number) => {
        if (locationId && id)
            return openPopup(
                <EditTestTypePopup
                    onTestTypeEdited={getTestTypes}
                    locationId={"" + locationId}
                    testTypeId={"" + id}
                ></EditTestTypePopup>
            );
        return null;
    }, [openPopup, getTestTypes, locationId]);


    const columns: ColumnDefinition<LabTestType>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.id,
                width: "5%",
                headerRender: <>ID</>,
                columnKey: "id",
                isSortable: true,
            },
            {
                cellRenderProp: (v) => v.namePt,
                width: "20%",
                headerRender: <>{translate("GLOBALADMIN.TESTTYPES.INFOANDFORM.NamePT")}</>,
                columnKey: "namePt",
                isSortable: false,
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.nameEn,
                width: "55%",
                headerRender: <>{translate("GLOBALADMIN.TESTTYPES.INFOANDFORM.NameEN")}</>,
                columnKey: "nameEn",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <div className="toggle-div">
                        <Toggle value={!v.isDisabled} />

                        <div
                            className="toggle-div-overlay"
                            onClick={(ev) => {
                                ev.stopPropagation();
                                if (!v.isDisabled) {
                                    openPopup(
                                        <WarningPopup
                                            className="disable-test-type-popup"
                                            onDismissClicked={() => { closePopup(); }}
                                            onConfirmClicked={() => { deactivateTestType(v.id); }}
                                            dismissText={translate("COMMON.Cancel")}
                                            confirmText={translate("GLOBALADMIN.TESTTYPES.POPUP.Deactivate")}
                                        >
                                            {translate("GLOBALADMIN.TESTTYPES.POPUP.DeactivateConfirmText")}
                                        </WarningPopup>
                                    );
                                } else {
                                    if (v.id) {
                                        openPopup(
                                            <WarningPopup
                                                onDismissClicked={() => { closePopup(); }}
                                                onConfirmClicked={() => { activateTestType(v.id); }}
                                                dismissText={translate("COMMON.Cancel")}
                                                confirmText={translate("GLOBALADMIN.TESTTYPES.POPUP.Activate")}
                                            >
                                                {translate("GLOBALADMIN.TESTTYPES.POPUP.ActivateConfirmText")}
                                            </WarningPopup>
                                        );
                                    }
                                }
                            }}
                        />
                    </div>
                ),
                width: "5%",
                columnKey: "stats",
                isSortable: false,
                isMobileHeaderIcon: true
            },
            {
                cellRenderProp: (v) => (
                    <ScalableIconButton
                        icon={<EditIcon />}
                        onClick={() => {
                            if (v.id && locationId) {
                                openEditPopup(v.id);
                            }
                        }}
                        size={30}
                    />
                ),
                width: "5%",
                columnKey: "stats",
                isSortable: false,
                isMobileHeaderIcon: true
            },
        ],
        [openPopup, locationId, activateTestType, deactivateTestType, closePopup, openEditPopup]
    );


    const renderActionButtons = useMemo(() => {
        if (windowResize > 768)
            return (
                <Button
                    text={translate("COMMON.Create")}
                    type="primary"
                    onClick={() => {
                        if (locationId) {
                            openPopup(<AddTestTypePopup onChangesMade={getTestTypes} locationId={locationId}></AddTestTypePopup>)
                        }
                    }}
                    className="create-test-type-btn"
                />
            );
        else
            return (
                <IconButton
                    icon={<AddIcon />}
                    type="primary"
                    onClick={() => {
                        if (locationId) {
                            openPopup(<AddTestTypePopup onChangesMade={getTestTypes} locationId={locationId}></AddTestTypePopup>)
                        }
                    }}
                />
            );
    }, [getTestTypes, locationId, openPopup, windowResize]);

    return (
        <PageLayout
            tabTitle={"laMP - " + translate("GLOBALADMIN.TESTTYPES.TestTypes")}
            pageTitle={translate("GLOBALADMIN.TESTTYPES.TestTypes")}
            className="test-types-home"
            showBreadcrumb
            actions={renderActionButtons}
        >
            <FormFieldTextInput
                formControl={searchBoxFormControl}
                icon={<SearchIcon />}
                placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                className="search-box"
            />
            <ResponsiveDataTable
                columnDefinitions={columns}
                items={testTypes || []}
                totalitems={totalItems || 0}
                paginationOptions={{
                    itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30, 40, 50]
                }}
                currentpage={currentpage}
                isLoading={isLoading}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
            ></ResponsiveDataTable>
        </PageLayout>
    );
}
