import "./LocationEmployeesHomePage.css";

import { LabOperatorStatus, LabOperatorStatusTranslationTerms } from "./models/LabOperatorStatus";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { AddLabOperatorPopup } from "./popups/AddLabOperatorPopup";
import { Button } from "../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ContextualMenu } from "../../../lib/components/contextual-menu/ContextualMenu";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FileUploadButton } from "../../../lib/components/file/FileUploadButton";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import-dark.svg";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { LabOperator } from "./models/LabOperator";
import { LabOperatorService } from "./LabOperatorService";
import { LabOperatorsQueryOptions } from "./models/LabOperatorsQueryOptions";
import { ReactComponent as MoreActions } from "../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../services/UseServiceCall";

var labOperatorSvc = new LabOperatorService();



export function LocationEmployeesHomePage() {
    const { id: locationId } = useParams();
    const navigate = useNavigate();
    const createNotification = useCreateNotification();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const windowResize = useWindowResize();

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const [searchWord, setSearchWord] = useState<string>();

    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    const [labOperators, setLabOperators] = useState<LabOperator[]>([]);
    const [totalitems, setTotalItems] = useState<number>();

    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);


    const [uploadFileCall, uploadFileCallIsLoading] = useServiceCallPro2(labOperatorSvc, labOperatorSvc.uploadEmployeesFile);
    const [putEmployeeStatusCall, putEmployeeStatusCallIsLoading] = useServiceCallPro2(labOperatorSvc, labOperatorSvc.updateLabOperatorStatus);
    const [getEmployeesCall, getEmployeesCallIsLoading] = useServiceCallPro2(labOperatorSvc, labOperatorSvc.getLabOperators);



    useEffect(() => {
        getEmployees();
    }, [currentpage, itemsPerPage, searchWord]);



    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchBoxFormControl.value);
            }
        }, 1000);
        setTimer(responseTimeOut);
    }, [searchBoxFormControl.value]);





    const getEmployees = useCallback(() => {

        if (!locationId) return;

        var queryParams: LabOperatorsQueryOptions = {
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: searchWord,
            filterByIsActive: false
        };

        getEmployeesCall(locationId, queryParams).then(res => {
            setLabOperators(res.operators);
            setTotalItems(res.totalItems);
        }).catch(error => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });


    }, [locationId, currentpage, itemsPerPage, searchWord, setLabOperators, setTotalItems, openPopup, getEmployeesCall]);




    const handleFileImport = useCallback((file: File) => {
        if (!locationId) return;


        uploadFileCall(locationId, file).then(res => {

            getEmployees();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("GLOBALADMIN.EMPLOYEES.MESSAGES.ImportFileSuccess")}
                />
            );
        }).catch(error => {
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });

    }, [locationId, createNotification, openPopup, getEmployees, uploadFileCall]);






    const handleEmployeeStatusClicked = useCallback((employeeId: number, status: LabOperatorStatus) => {
        if (!locationId) return;

        putEmployeeStatusCall("" + locationId, employeeId, { nextStatus: status })
            .then((res) => {
                getEmployees();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.EMPLOYEES.MESSAGES.EmployeeStatusSuccess", translate(LabOperatorStatusTranslationTerms[status]))}
                    />
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationId, createNotification, openPopup, getEmployees, putEmployeeStatusCall]);





    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );



    const employeesColumnsList: ColumnDefinition<LabOperator>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.employeeCode,
                width: "20%",
                headerRender: <>{translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Code")}</>,
                columnKey: "plant",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.name,
                width: "25%",
                headerRender: <>{translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Name")}</>,
                columnKey: "name",
                isSortable: false,
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.section,
                width: "50%",
                headerRender: <>{translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Division")}</>,
                columnKey: "description",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    <div className="toggle-div">
                        <Toggle value={v.status === LabOperatorStatus.ENABLE} />
                        <div
                            className="toggle-div-overlay"
                            onClick={(ev) => {
                                ev.stopPropagation();
                                if (v.status === LabOperatorStatus.ENABLE) {
                                    openPopup(<WarningPopup
                                        className="disable-material-popup"
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => {
                                            handleEmployeeStatusClicked(v.labOperatorId, LabOperatorStatus.DISABLE)
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("GLOBALADMIN.MATERIALS.POPUP.Deactivate")}
                                    >
                                        {translate("GLOBALADMIN.EMPLOYEES.MESSAGES.DisableEmployeeConfirmText", v.name)}
                                    </WarningPopup>
                                    );
                                }
                                else {
                                    openPopup(<WarningPopup
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => { handleEmployeeStatusClicked(v.labOperatorId, LabOperatorStatus.ENABLE) }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("COMMON.Activate")}
                                    >
                                        {translate("GLOBALADMIN.EMPLOYEES.MESSAGES.EnableEmployeeConfirmText", v.name)}
                                    </WarningPopup>
                                    );
                                }
                            }}
                        />
                    </div>
                ),
                width: "5%",
                columnKey: "status",
                isSortable: false,
                isMobileHeaderIcon: true
            },
        ],
        [openPopup, closePopup, handleEmployeeStatusClicked]
    );


    const renderActionButtons = useMemo(() => {
        if (windowResize > 768)
            return <>
                <Button
                    text={translate("COMMON.Import")}
                    type="secondary"
                    onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

                        window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/LaMP_Operators_Template.xlsx", "_blank")}

                        onClickImportBtn={handleFileImport} />)}
                    className="import-material"
                />
                <Button
                    text={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.AddLabOperator")}
                    type="primary"
                    onClick={() => openPopup(<AddLabOperatorPopup onCompletedOperations={getEmployees} locationId={"" + locationId} />)} />
            </>
        else
            return <>
                <ContextualMenu
                    items={[
                        {
                            label: translate("COMMON.Import"),
                            icon: <ImportIcon />,
                            navigateTo: "",
                            onClick: () => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>
                                window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/LaMP_Operators_Template.xlsx", "_blank")}
                                onClickImportBtn={handleFileImport} />)
                        },
                    ]}
                >
                    <ScalableIconButton size={30} icon={<MoreActions />} />
                </ContextualMenu >
                <IconButton
                    icon={<AddIcon />}
                    type="primary"
                    onClick={() => openPopup(<AddLabOperatorPopup onCompletedOperations={getEmployees} locationId={"" + locationId} />)}
                />
            </>
    }, [getEmployees, handleFileImport, locationId, openPopup, windowResize])

    return (
        <PageLayout
            tabTitle={translate("GLOBALADMIN.EMPLOYEES.LabOperators")}
            pageTitle={translate("GLOBALADMIN.EMPLOYEES.LabOperators")}
            className="employees-home"
            showBreadcrumb
            actions={renderActionButtons}
        >
            <FormFieldTextInput
                formControl={searchBoxFormControl}
                icon={<SearchIcon />}
                placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                className="search-box"

            />
            <ResponsiveDataTable
                columnDefinitions={employeesColumnsList}
                items={labOperators || []}
                totalitems={totalitems || 0}
                paginationOptions={{
                    itemsPerPage: itemsPerPage,
                    itemsPerPageOptions: [10, 20, 30],
                }}
                currentpage={currentpage}
                onClickRow={(x) => navigate(`${x.labOperatorId}`)}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                isLoading={getEmployeesCallIsLoading}

            />
            <ConditionalRender if={uploadFileCallIsLoading} >
                <FullScreenLoader />
            </ConditionalRender>
        </PageLayout>
    );
}