import "./CountDownTimer.css";

import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

export interface CountdownTimerProps {
  creationDateTime: DateTime;
  durationMinutes: number;
  label?: string;
  timeFinished: (canEdit: boolean) => void;
}

export function CountdownTimer(props: CountdownTimerProps) {
  const [timeRemaining, setTimeRemaining] = useState<number>(calculateTimeRemaining(props.creationDateTime.toUTC(), props.durationMinutes));
  

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = calculateTimeRemaining(props.creationDateTime.toUTC(), props.durationMinutes);
      setTimeRemaining(remaining);

      if (remaining <= 0) {
        props.timeFinished(true);
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [props.creationDateTime, props.durationMinutes]);


  return (
    <>{timeRemaining > 0 ?
      <div className="countdown-timer">
        <div className="timer-label">{props.label} </div>
        <div className="timer-value">{formatTime(timeRemaining)}</div>
      </div> : null}</>
  );
};

const calculateTimeRemaining = (creationDateTime: DateTime, durationMinutes: number) => {

  const currentTime = DateTime.now().toUTC();
  const targetDateTime = creationDateTime.plus({ minutes: durationMinutes });

  if (currentTime > targetDateTime) {
    return 0;
  }

  const remainingSeconds = Math.floor((targetDateTime.toSeconds() - currentTime.toSeconds()));
  return Math.max(0, remainingSeconds);
};


function formatTime(timeInSeconds: number) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return ` ${minutes}:${seconds < 10 ? '0' : ''}${seconds}${`'`}`;
};