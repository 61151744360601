import { LabTest } from "../../../domain/lab-tests/LabTest";
import { LabTestForAnalysisRequestDTO } from "../LabTestForAnalysisRequestDTO";

export function LabTestToLabTestForAnalysisRequestsDTOMapper(labTest: LabTestForAnalysisRequestDTO) {
    var mappedLabTest: LabTest = {
        labTestId: labTest.labTestId,
        labTestTypeDescription: labTest.labTestType,
        labTestName: labTest.labTestName,
        multiplier: labTest.multiplier,
        cost: labTest.cost,
        usedCost: labTest.usedCost,
        isUsed: labTest.isUsed,
        isDisabled: labTest.isDisabled,
        isForDestroyingBarOrFilter: labTest.destroysSample
    };
    return mappedLabTest;
}