import "./IconButton.css";

import { ChangeEvent, useCallback, useMemo, useRef } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface IFileUploadButtonProps {
  type: "primary" | "secondary" | "tertiary";
  icon?: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
  onFileSelected: (f: File) => void;
}

export function IconButtonFile(props: IFileUploadButtonProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("icon-button")
      .addConditional(props.type, props.type)
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className, props.type, props.isDisabled]);



  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      if (props.isDisabled) return;
      inputRef.current?.click();
    },
    [inputRef, props.isDisabled]
  );

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    props.onFileSelected(e.target.files[0]);

    if (inputRef.current)
      inputRef.current.value = "";
  }, [inputRef]);


  return (
    <div className={buttonCss} onClick={handleClicked}>
      {props.icon ? <div className="icon">
        {props.icon}
        <input
          title="hidden-upload-input"
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div> : null}
    </div>
  );

}
