import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { AnalysisRequestSampleTestCorrelationDTO } from "../models/api/AnalysisRequestSampleTestCorrelationDTO";
import { SampleTestsCorrelationResponseDTO } from "../models/api/SampleTestsCorrelationResponseDTO";
import { UpdateSampleTestsCorrelationRequestDTO } from "../models/api/UpdateSampleTestsCorrelationRequestDTO";
import { SampleTestAssociation } from "../models/domain/SampleTestAssociation";
import { SampleTestsAssociationsSummary } from "../models/domain/SampleTestsAssociationsSummary";
import { SampleTestsSummary } from "../models/domain/SampleTestsSummary";


const Route = (path: string) => { return ConfigurationProvider.getConfiguration().App.BackendUrl + path; };

export class AssociationTestsSampleService {

    editSampleTestsCorrelation(locationId: string, requestId: string, requestDto: UpdateSampleTestsCorrelationRequestDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/SampleTestsCorrelation`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }



    getSampleTestAssociations(locationId: string, requestId: string): Promise<SampleTestsAssociationsSummary> {
        return HttpClient.sessionRequest<SampleTestsCorrelationResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/SampleTestsCorrelation`),
        })
            .then((r) => {

                var associations: SampleTestsAssociationsSummary = {

                    totalCost: r.data.totalCost,
                    analysisRequestId: r.data.analysisRequestId,

                    samplesTestsSummary: r.data.samplesTestsSummaryDTO.map((st) => {
                        var sampleTest: SampleTestsSummary = {
                            cost: "" + st.cost,
                            materialName: st.analysisRequestSample,
                            sampleId: st.analysisRequestSampleId,
                            sampleTests: st.analysisRequestSampleTests.map((ass: AnalysisRequestSampleTestCorrelationDTO) => {

                                var association: SampleTestAssociation = {
                                    analysisRequestTestId: ass.labTestId,
                                    testName: ass.sampleTest,
                                    sampleTestId: ass.sampleTestId
                                };

                                return association;
                            })
                        };
                        return sampleTest;
                    })
                };

                return associations;
            })
            .catch((error) => {
                throw error;
            });
    }
}