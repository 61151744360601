import { AnalysisRequestDTO } from "../../models/api/AnalysisRequestDTO";
import { RequestDetails } from "../../models/domain/RequestDetails";

export function AnalysisRequestDTOtoRequestDetailsMapper(analysisRequestDTO: AnalysisRequestDTO) {
    
    var mappedRequest: RequestDetails = {
        requestId: analysisRequestDTO.analysisRequestId,
        analysisReason: analysisRequestDTO.analysisRequestReason ?? "",
        analysisRequestReasonType: analysisRequestDTO.analysisRequestReasonType ?? "",
        analysisRequestReasonTypeId: analysisRequestDTO.analysisRequestReasonTypeId ?? -1,
        requestType: analysisRequestDTO.type,
        name: analysisRequestDTO.name,
        cancelledStatusReason: analysisRequestDTO.cancelledStatusReason,
        createdBy: {
            email: analysisRequestDTO.createdBy?.userEmail ?? "",
            name: analysisRequestDTO.createdBy?.userDisplayName ?? ""
        },
        createdDate: analysisRequestDTO.createdDate,
        formalizingDate: analysisRequestDTO.formalizingDate,
        waybill: analysisRequestDTO.waybill,
        shippingByCourier: analysisRequestDTO.shippingByCourier,
        company: analysisRequestDTO.company,
        industry: analysisRequestDTO.industry,
        factory: analysisRequestDTO.factory,
        customerComments: analysisRequestDTO.comments,
        ketoProject: analysisRequestDTO.ketoProject,

        clientRegistration: analysisRequestDTO.clientRegistration,
        clientRegistrationDescription: analysisRequestDTO.clientRegistrationDescription,

        industrialSectorId: analysisRequestDTO.analysisRequestIndustrialSectorId,
        industrialSectorOthersDescription: analysisRequestDTO.industrialSectorDescription,
        filterTypeId: analysisRequestDTO.analysisRequestFilterTypeId,
        filterTypeOthersDescription: analysisRequestDTO.filterTypeDescription,
        slurry: analysisRequestDTO.slurry,
        hasHazardousMaterial: analysisRequestDTO.containsHazardousMaterial,
        hazardousMaterialDescription: analysisRequestDTO.hazardousMaterialDescription,
        hasCarcinogenicSubstances: analysisRequestDTO.containsCarcinogenicSubstances,
        carcinogenicSubstancesDescription: analysisRequestDTO.carcinogenicSubstancesDescription,
        otherInformations: analysisRequestDTO.otherInformation,
        pmNumber: analysisRequestDTO.pmNumber,
        position: analysisRequestDTO.position,

        requestStatus: analysisRequestDTO.status,
        filterTypeName: analysisRequestDTO.analysisRequestFilterType,
        industrialSectorName: analysisRequestDTO.analysisRequestIndustrialSector,

        reference: analysisRequestDTO.reference
    };
    return mappedRequest;
}