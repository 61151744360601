import { InternationalizationService, translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useEffect, useMemo, useState } from "react";

import { AnalysisRequestSampleDetails } from "../../../../models/domain/AnalysisRequestSampleDetails";
import { AnalysisRequestTypeEnum } from "../../../../models/domain/AnalysisRequestTypeEnum";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../../lib/components/popup/ErrorPopup";
import { PopupContainer } from "../../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RequestSamplesService } from "../../../../services/RequestSamplesService";
import { SampleARDetailsContainer } from "./SampleARDetailsContainer";
import { SampleDFDetailsContainer } from "./SampleDFDetailsContainer";
import { SampleDRYERDetailsContainer } from "./SampleDRYERDetailsContainer";
import { SamplePPDetailsContainer } from "./SamplePPDetailsContainer";
import { SampleWFDetailsContainer } from "./SampleWFDetailsContainer";
import { usePopup } from "../../../../../../lib/infrastructure/ui/UIServices";

var requestSampleSvc = new RequestSamplesService();

export interface IRequestSampleDetailsPopupProps {
    locationID: string | undefined;
    requestID: string | undefined;
    sampleID: string | undefined;
}

export function RequestSampleDetailsPopup(props: IRequestSampleDetailsPopupProps) {
    const openPopup = usePopup();

    const [sampleData, setSampleData] = useState<AnalysisRequestSampleDetails | null>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {

        if (!props.locationID || !props.requestID || !props.sampleID) return;

        requestSampleSvc.getRequestSampleByIdExtended(props.locationID, props.requestID, props.sampleID)
            .then((data) => {
                setSampleData(data);
                setIsLoading(false);
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.requestID, props.sampleID, openPopup]);



    const renderAddSamplePopup = useMemo(() => {

        if (!sampleData?.sampleType || !sampleData) return;

        switch (sampleData?.sampleType) {
            case AnalysisRequestTypeEnum.AR:
                return <SampleARDetailsContainer data={sampleData} />;
            case AnalysisRequestTypeEnum.WF:
                return <SampleWFDetailsContainer data={sampleData} />;
            case AnalysisRequestTypeEnum.PP:
                return <SamplePPDetailsContainer data={sampleData} />;
            case AnalysisRequestTypeEnum.DF:
                return <SampleDFDetailsContainer data={sampleData} />;
            case AnalysisRequestTypeEnum.DRYER:
                return <SampleDRYERDetailsContainer data={sampleData} />;
            default:
                return;
        }
    }, [sampleData]);



    return (
        <PopupContainer className="request-sample-details">

            <PopupHeader
                title={`${translate("SAMPLES.SampleDetails")}`}

                subtitle={sampleData?.createdDate ? DateTime.fromISO(sampleData?.createdDate).toFormat(
                    "dd LLL yyyy HH'h'mm",
                    { locale: InternationalizationService.getLocale() }
                ) : "-"}
            />

            <PopupContent isLoading={isLoading}>

                {renderAddSamplePopup}

            </PopupContent>
        </PopupContainer>
    );
} 