import { IFormControl, Optional, ValidatorFunction } from "../../../lib/components/form/Form";


import { FilterType } from "../models/domain/AnalysisRequestFilterType";
import { IndustrialSector } from "../models/domain/AnalysisRequestIndustrialSector";

import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";

export function validateRequiredIfOthers(industrialSectorOthersFormControl?: IFormControl<IndustrialSector>, filterTypeOthersFormControl?: IFormControl<FilterType>): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {
        if (industrialSectorOthersFormControl?.value?.isDescriptionRequired && (!value || value?.trim() === "")) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        if (filterTypeOthersFormControl?.value?.isDescriptionRequired && (!value || value?.trim() === "")) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}





