import './EditRequestSampleForm.css'

import { GetTargetSpecEnum, GetTargetSpecsArray, TargetSpecEnum } from "../../../../models/domain/TargetSpec";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { AnalysisRequestSampleDetails } from "../../../../models/domain/AnalysisRequestSampleDetails";
import { Checkbox } from "../../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../../lib/functional/ConditionalRender";
import { EditRequestSampleForm } from "../../../../models/domain/EditRequestSampleForm";
import { FormContainer } from "../../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { Material } from "../../../../../../models/domain/materials/Material";
import { PopupContent } from '../../../../../../lib/layouts/containers/popup-content/PopupContent';
import { hasOneElementSelected } from "../../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { validateRequiredIfValmetManufacturer } from "../../../../validators/ValidateRequiredIfValmetManufacturer";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";

const labelSelector = (item: Material) => item.name + " - " + item.description;
const idSelector = (item: Material) => item.id;

const labelCompareToSpecSelector = (item: SelectSingleOption) => item.description;
const idCompareToSpecSelector = (item: SelectSingleOption) => item.id;


interface SelectSingleOption {
  id: string;
  description: string;
}


export interface IGenericEditRequestSampleFormsProps {
  info: AnalysisRequestSampleDetails;
  materials: Material[];
  isLoading: boolean;
}



export const EditRequestSampleARForm = forwardRef(function EditRequestSampleARForm(
  props: IGenericEditRequestSampleFormsProps,
  ref: React.ForwardedRef<EditRequestSampleForm>
) {


  const [isSampleUsed, setIsSampleUsed] = useState<boolean>(false);
  const [isValmetManufacturer, setIsValmetManufacturer] = useState<boolean>(true);


  const valmetMaterialFormControl = useFormControl<Material>({
    validators: [validateRequiredIfValmetManufacturer(isValmetManufacturer)],
    enableAutoValidate: true,
    isDisabled: false
  });


  const batchNumberFormControl = useFormControl<string>({});


  const compareWithMaterialFormControl = useFormControl<Material>({
    /*     validators: [hasOneElementSelected()],
        enableAutoValidate: true, */
    isDisabled: false
  });


  const otherManufacturerMaterialFormControl = useFormControl<string>({ isDisabled: false });


  const productDescriptionFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });


  const [isOtherManufacturer, setIsOtherManufacturer] = useState<boolean>(false);


  const compareToSpecFormControl = useFormControl<SelectSingleOption>({
    /*     validators: [hasOneElementSelected()],
        enableAutoValidate: true, */
    isDisabled: false,
  });





  /****************************
    * DATA MANIPULATION EFFECTS
  *****************************/


  const compareToSpec = useMemo(() => {
    return GetTargetSpecsArray();
  }, [GetTargetSpecsArray])





  useEffect(() => {

    if (isValmetManufacturer) {
      valmetMaterialFormControl.setValue(props.materials.find((m) => m.id === props.info.material?.id));
      otherManufacturerMaterialFormControl.setValue("");
    }

    if (isOtherManufacturer) {
      valmetMaterialFormControl.setValue(undefined);
      if (props.info.otherMaterial) {
        otherManufacturerMaterialFormControl.setValue(props.info.otherMaterial);
      } else {
        otherManufacturerMaterialFormControl.setValue("");
      }
    }


  }, [isValmetManufacturer, isOtherManufacturer]);






  useEffect(() => {
    if (!props.materials || !props.info) return;

    setIsSampleUsed(props.info.isUsed);

    if (props.info.manufacturerIsValmet) {
      setIsValmetManufacturer(true);
      setIsOtherManufacturer(false);
    } else {
      setIsValmetManufacturer(false);
      setIsOtherManufacturer(true)
    }

    valmetMaterialFormControl.setValue(props.materials.find((m) => m.id === props.info.material?.id));

    if (props.info.otherMaterial)
      otherManufacturerMaterialFormControl.setValue(props.info.otherMaterial);

    compareWithMaterialFormControl.setValue(props.materials.find((m) => m.id === props.info.compareToMaterial?.id));

    if (props.info.productDescription)
      productDescriptionFormControl.setValue(props.info.productDescription);

    if (props.info.batchNumber)
      batchNumberFormControl.setValue(props.info.batchNumber);

    if (props.info.compareToSpec === TargetSpecEnum.TECHNOTE || props.info.compareToSpec === TargetSpecEnum.TARGETSAP) {
      compareToSpecFormControl.setValue(compareToSpec.find((c) => c.id === props.info.compareToSpec?.toString()));
    }


  }, [props.materials, props.info]);





  useImperativeHandle(ref, () => {
    return {
      validate() {
        return AllValid(valmetMaterialFormControl.validate(), productDescriptionFormControl.validate());
      },
      value() {
        return {
          isUsed: isSampleUsed,
          manufacturerIsValmet: isValmetManufacturer,
          materialId: valmetMaterialFormControl.value?.id,
          otherMaterial: otherManufacturerMaterialFormControl.value,
          compareToMaterialId: compareWithMaterialFormControl.value?.id,
          compareToSpec: compareToSpecFormControl.value?.id ? GetTargetSpecEnum[compareToSpecFormControl.value?.id] : null,
          productDescription: productDescriptionFormControl.value,
          batchNumber: batchNumberFormControl.value
        }
      }
    }
  }, [valmetMaterialFormControl.validate, productDescriptionFormControl.validate, otherManufacturerMaterialFormControl.validate, batchNumberFormControl.validate,
  valmetMaterialFormControl.value, productDescriptionFormControl.value, compareToSpecFormControl.value, compareWithMaterialFormControl.value,
  otherManufacturerMaterialFormControl.value, batchNumberFormControl.value, isSampleUsed, isValmetManufacturer, isOtherManufacturer]);






  /****************************
    * CSS & HTML
  *****************************/

  return (
    <>
      <div className="edit-request-sample-details">
        <PopupContent isLoading={props.isLoading}>
          <FormContainer>
            <div className="separator">{translate("REQUESTS.INFOANDFORM.Manufacturer")}</div>
            <div className="manufacturer">
              <Checkbox
                text={"Valmet"}
                isChecked={isValmetManufacturer}
                onClick={() => { setIsValmetManufacturer(true); setIsOtherManufacturer(false) }}
              />
              <Checkbox
                text={translate("REQUESTS.INFOANDFORM.Other")}
                isChecked={isOtherManufacturer}
                onClick={() => { setIsOtherManufacturer(true); setIsValmetManufacturer(false) }}
              />
            </div>
            <div className="separator">{translate("REQUESTS.POPUPS.SampleStatus")}</div>
            <Checkbox
              text={translate("REQUESTS.Used")}
              isChecked={isSampleUsed}
              onClick={() => setIsSampleUsed(!isSampleUsed)}
            />
            <div className="separator">{translate("REQUESTS.INFOANDFORM.Material")}</div>
            <FormFieldTextInput
              formControl={productDescriptionFormControl}
              label={translate("REQUESTS.INFOANDFORM.ProductDescription")}
              placeholder={translate("REQUESTS.INFOANDFORM.ProductDescriptionPlaceholder")}
            />
            <ConditionalRender if={isValmetManufacturer}>
              <FormFieldSelectSingle
                label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
                placeholder={translate("SAMPLES.INFOANDFORM.SAPMaterialPlaceholder")}
                disableFuzzySearch
                options={props.materials}
                labelSelector={labelSelector}
                idSelector={idSelector}
                formControl={valmetMaterialFormControl}
                isClearSelectionAvailable={false}
              />
            </ConditionalRender>
            <ConditionalRender if={isOtherManufacturer}>
              <FormFieldTextInput
                formControl={otherManufacturerMaterialFormControl}
                label={translate("SAMPLES.INFOANDFORM.Material")}
                placeholder={translate("REQUESTS.INFOANDFORM.MaterialPlaceholder")}
              />
            </ConditionalRender>

            <FormFieldTextInput
              formControl={batchNumberFormControl}
              label={translate("SAMPLES.INFOANDFORM.Batch") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
            />

            <div className="separator">{translate("REQUESTS.INFOANDFORM.CompareWith")}</div>

            <FormFieldSelectSingle

              label={translate("REQUESTS.INFOANDFORM.MaterialDescription") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate("REQUESTS.INFOANDFORM.MaterialDescriptionPlaceholder")}
              disableFuzzySearch
              options={props.materials}
              labelSelector={labelSelector}
              idSelector={idSelector}
              formControl={compareWithMaterialFormControl}
              isClearSelectionAvailable={true}
            />
            <FormFieldSelectSingle
              label={translate("REQUESTS.INFOANDFORM.TargetOrTechNote") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate("REQUESTS.INFOANDFORM.TargetOrTechNotePlaceholder")}
              options={compareToSpec}
              labelSelector={labelCompareToSpecSelector}
              idSelector={idCompareToSpecSelector}
              formControl={compareToSpecFormControl}
              isClearSelectionAvailable={true}
            />
          </FormContainer>
        </PopupContent>
      </div>
    </>
  );
});