import { useMemo } from "react";

import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { TabMenu } from "../../../../../lib/components/menus/TabMenu";


export interface TestSelectorCategory {
  label: string;
  children: TestSelectorTest[];
}

export interface TestSelectorTest {
  id: number;
  name: string;
  cost: number | null;
  isUsed: boolean; 
  usedCost: number | null;
  isDisabled: boolean | null;
  additionalInfo?: string | null;
}

export interface ITestSelectorProps {
  definition: TestSelectorCategory[];
  selectedTestIds: number[];
  onSelectedTestIdsChanged: (testIds: number[]) => void;
}

export function TestSelector(props: ITestSelectorProps) {
  const tabs = useMemo(
    () =>
      props.definition.map((d, idx) => {
        const content = d.children.map((t) => {
          return (
            <Checkbox
              text={t.name}
              isChecked={props.selectedTestIds.includes(t.id)}
              additionalText={t.additionalInfo ? t.additionalInfo : null}
              additionalTextColor={t.additionalInfo ? "red" : undefined}
              onClick={(isChecked) => {
                if (isChecked) {
                  if (!props.selectedTestIds.includes(t.id)) {
                    let clone = [...props.selectedTestIds];
                    clone.push(t.id);
                    props.onSelectedTestIdsChanged(clone);
                  }
                } else {
                  if (props.selectedTestIds.includes(t.id)) {
                    let clone = props.selectedTestIds.filter(
                      (x) => x !== t.id
                    );
                    props.onSelectedTestIdsChanged(clone);
                  }
                }
              }}
            />
          );
        });

        return {
          key: "" + idx,
          menuTitle: d.label,
          content: content,
          counter: content.filter((checkbox) => checkbox.props.isChecked)
            .length,
        };
      }),
    [props.definition, props.selectedTestIds, props.onSelectedTestIdsChanged]
  );

  return <TabMenu tabs={tabs || []} className="tests-tab"></TabMenu>;
}
