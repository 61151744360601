import { LabSampleDTO } from "../../models/api/LabSampleDTO";
import { Sample } from "../../models/domain/Sample";

export function LabSampleDTOtoSampleMapper(sample: LabSampleDTO) {
    var mappedSample: Sample = {
        materialId: sample.materialId,
        materialDescription: sample.description,
        sapNumber: sample.matNumber,
        batch: sample.batch,
        facilityId: sample.facilityId,
        facilityName: sample.facilityName,
        op: sample.productionOrder,
        numSpools: sample.numberOfSpools,
        statusId: sample.labSampleStatus,
        typeId: sample.labSampleType,
        analyst: {
            email: sample.analyst?.userEmail ?? null,
            name: sample.analyst?.userDisplayName ?? null
        },
        supplier: sample.supplier,
        observations: sample.observations,
        createdBy: {
            email: sample.createdBy?.userEmail ?? null,
            name: sample.createdBy?.userDisplayName ?? null
        },
        createdDate: sample.createdDate,
        requestedDate: sample.requestDate,
        sampleId: sample.labSampleId,
        othersType: sample.othersType,
        modifiedDate: sample.modifiedDate,
        requestedBy: {
            email: sample.requestedBy?.userEmail ?? null,
            name: sample.requestedBy?.userDisplayName ?? null
        },
        requestedByName: sample.requestedByDisplayName,
        modifiedDateRequestDate: sample.modifiedDateRequestDate,
        locationId: sample.locationId,
        othersMaterialDescription: sample.othersMaterialDescription,
        rejectedJustification: sample.statusJustification,
        rejectedReason: sample.statusReason,
        waybill: sample.waybill,
        isRepeated: sample.isRepeated,
        isControl04: sample.isControl04,
        source: sample.origin,
        area: sample.area,
        correlatedRequestId: sample.correlatedAnalysisRequestId,
        needControl04: sample.needControl04,
        labOperator: sample.labOperator,
        operatorSection: sample.operatorSection,
        materialIsSubjectToC04: sample.materialIsSubjectToC04
    };
    return mappedSample;
}
