import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";

export function requestStatusLabel(statusId: number) {
    switch (statusId) {
        case 1:
            return translate("REQUESTS.STATUS.RegistedSingular");
        case 2:
            return translate("REQUESTS.STATUS.CanceledSingular");
        case 3:
            return translate("REQUESTS.STATUS.DraftSingular");
        case 4:
            return translate("REQUESTS.STATUS.UnderAnalysisSingular");
        case 5:
            return translate("REQUESTS.STATUS.FinishedSingular");
        case 6:
            return translate("REQUESTS.STATUS.ReadyToCloseSingular");
        default:
            return "";
    }
}
