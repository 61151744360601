import { ARRequestDetailsCard } from "./view-request-details-containers/ARRequestDetailsCard";
import { AnalysisRequestTypeEnum } from "../../models/domain/AnalysisRequestTypeEnum";
import { DFRequestDetailsCard } from "./view-request-details-containers/DFRequestDetailsCard";
import { DryerRequestDetailsCard } from "./view-request-details-containers/DryerRequestDetailsCard";
import { RequestDetails } from "../../models/domain/RequestDetails";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { WFAndPPRequestDetailsCard } from "./view-request-details-containers/WFAndPPRequestDetailsCard";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface IRequestDetailsCardProps {
    info?: RequestDetails;
    title?: string;
    isLoading: boolean;
}


export function RequestDetailsCard(props: IRequestDetailsCardProps) {
    const navigate = useNavigate();


    const requestDetailsHTML = useMemo(() => {
        if (!props.info?.requestType) return <></>;
        switch (props.info?.requestType) {
            case AnalysisRequestTypeEnum.AR:
                return <ARRequestDetailsCard reqDetails={props.info} />
            case AnalysisRequestTypeEnum.DRYER:
                return <DryerRequestDetailsCard reqDetails={props.info} />
            case AnalysisRequestTypeEnum.PP:
                return <WFAndPPRequestDetailsCard reqDetails={props.info} />
            case AnalysisRequestTypeEnum.WF:
                return <WFAndPPRequestDetailsCard reqDetails={props.info} />
            case AnalysisRequestTypeEnum.DF:
                return <DFRequestDetailsCard reqDetails={props.info} />
            default:
                return <></>;
        }
    }, [props.info]);



    if (props.isLoading)
        return (<TitledCardContainer isLoading={props.isLoading} loadingCardMinHeigth="large" title={translate("REQUESTS.RequestData")}>
        </TitledCardContainer>)

    if (!props.info)
        navigate("/notfound");

    return requestDetailsHTML;
}