import "./EditLocationGroupAccessPage.css";

import { AccessGroupsService, LocationGroups, UpdateLocationGroupItem, UpdateLocationGroups } from "../../../services/AccessGroupsService";
import {
  IFormControl,
  useFormControl,
} from "../../../lib/components/form/Form";
import { Table, TableBody, TableHeader, TableHeaderCell, TableRow, TableRowCell } from '../../../lib/components/table/Table';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../lib/components/buttons/Button";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { Loader } from "../../../lib/components/loader/Loader";
import { LocationDTO } from "../../../models/api/location/LocationDTO";
import { LocationGroupType } from "../../../models/enums/LocationGroupType";
import { LocationService } from "../../../services/LocationServices";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ReactComponent as SaveIcon } from "../../../lib/assets/icons/save.svg";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useParams } from "react-router-dom";
import { validateGuid } from "../../../lib/validators/ValidateGuid";

var accessGroupsService = new AccessGroupsService();
var locationService = new LocationService();

interface IGroupAccessRow {
  type: string;
  control: IFormControl<string>;
  AADName: string;
}

export function GroupAccessRow(props: IGroupAccessRow) {
  const windowResize = useWindowResize();

  if (windowResize > 768)
    return <TableRow>
      <TableRowCell>{props.type}</TableRowCell>
      <TableRowCell><FormFieldTextInput formControl={props.control} /></TableRowCell>
      <TableRowCell>{props.AADName}</TableRowCell>
    </TableRow>;
  else
    return <TableRow>
      <TableRowCell>
        <div className="mobile-access-groups-table-cell">
          <div>
            <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.ACCESSGROUPS.Type")}</div>
            <div>{props.type}</div>
          </div>
          <div>
            <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.ACCESSGROUPS.ID")}</div>
            <FormFieldTextInput formControl={props.control} />
          </div>
          {props.AADName ?
            <div>
              <div className="small-copy mobile-access-groups-table-cell-label">{translate("GLOBALADMIN.ACCESSGROUPS.Name")}</div>
              <div>{props.AADName}</div>
            </div> : null}
        </div>
      </TableRowCell>

    </TableRow>;

}
export interface LocationGroup {
  type: string;
  typeId: LocationGroupType;
  id: string;
  name: string;
}

export function EditLocationGroupAccessPage() {
  const { id: locationId } = useParams();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const createNotification = useCreateNotification();
  const windowResize = useWindowResize();

  const [location, setLocation] = useState<LocationDTO>();
  const [locationGroups, setLocationGroups] = useState<LocationGroups>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);


  /****************************
      * FORM CONTROL
      *****************************/
  const accessGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const registerSamplesGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const adminGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const analistGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const globalAnalysisRequestGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const arGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const dfGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const dryerGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const ppGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  const twfGuidFormControl = useFormControl<string>({
    validators: [validateGuid()],
    enableAutoValidate: true,
  });

  /****************************
    * FORM CONTROL
    *****************************/

  useEffect(() => {
    if (locationId) {

      locationService.getLocationById(locationId)
        .then((data) => {
          setLocation(data);
        })
        .catch((error) => {
          if (!error)
            return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, []);


  useEffect(() => {
    getLocationGroups();
  }, [locationId]);


  const getLocationGroups = useCallback(() => {
    if (locationId) {
      setIsLoading(true);

      accessGroupsService.getLocationGroups("" + locationId)
        .then((response) => {
          setLocationGroups(response);
          accessGuidFormControl.setValue(response.access.objectId);
          registerSamplesGuidFormControl.setValue(response.registerSamples.objectId);
          adminGuidFormControl.setValue(response.admin.objectId);
          analistGuidFormControl.setValue(response.analyst.objectId);
          globalAnalysisRequestGuidFormControl.setValue(response.requestsGlobal.objectId);
          arGuidFormControl.setValue(response.ar.objectId);
          dfGuidFormControl.setValue(response.df.objectId);
          dryerGuidFormControl.setValue(response.dryer.objectId);
          ppGuidFormControl.setValue(response.pp.objectId);
          twfGuidFormControl.setValue(response.wf.objectId);

          setIsLoading(false);

        }).catch((error) => {
          if (!error) return;
          setIsLoading(false);
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        })
    }
  }, [locationId, setLocationGroups, accessGuidFormControl.setValue, registerSamplesGuidFormControl.setValue, adminGuidFormControl.setValue,
    analistGuidFormControl.setValue, globalAnalysisRequestGuidFormControl.setValue, arGuidFormControl.setValue, dfGuidFormControl.setValue,
    ppGuidFormControl.setValue, twfGuidFormControl.setValue, setIsLoading, openPopup]);



  const onClickSave = useCallback(() => {
    if (locationId) {

      let formValid = accessGuidFormControl.isValid &&
        registerSamplesGuidFormControl.isValid &&
        adminGuidFormControl.isValid &&
        analistGuidFormControl.isValid &&
        globalAnalysisRequestGuidFormControl.isValid &&
        arGuidFormControl.isValid &&
        dfGuidFormControl.isValid &&
        dryerGuidFormControl.isValid &&
        ppGuidFormControl.isValid &&
        twfGuidFormControl.isValid;

      if (!formValid) {
        openPopup(<ErrorPopup>
          {translate("GLOBALADMIN.ACCESSGROUPS.SubmitError")}
        </ErrorPopup>);
        return;
      }

      setOnClickSubmit(true);

      const mapGroup = (type: LocationGroupType, objectId: string | undefined): UpdateLocationGroupItem => {
        return ({ type, objectId: objectId || "" });
      }

      var lg: UpdateLocationGroups = {
        admin: mapGroup(LocationGroupType.ADMIN, adminGuidFormControl.value),
        access: mapGroup(LocationGroupType.ACCESS, accessGuidFormControl.value),
        analyst: mapGroup(LocationGroupType.ANALISTS, analistGuidFormControl.value),
        requestsGlobal: mapGroup(LocationGroupType.ANALISYS_REQUEST_GLOBAL, globalAnalysisRequestGuidFormControl.value),
        registerSamples: mapGroup(LocationGroupType.REGISTER_SAMPLES, registerSamplesGuidFormControl.value),
        ar: mapGroup(LocationGroupType.AR, arGuidFormControl.value),
        dryer: mapGroup(LocationGroupType.DRYER, dryerGuidFormControl.value),
        df: mapGroup(LocationGroupType.DF, dfGuidFormControl.value),
        pp: mapGroup(LocationGroupType.PP, ppGuidFormControl.value),
        wf: mapGroup(LocationGroupType.WF, twfGuidFormControl.value)
      };


      accessGroupsService.updateLocationGroup(locationId, lg)
        .then((_) => {
          setOnClickSubmit(false);
          getLocationGroups();
          createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")} text={translate("GLOBALADMIN.ACCESSGROUPS.EditedGroupSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          setOnClickSubmit(false);
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)
        });
    }
  }, [openPopup, setOnClickSubmit, locationId, accessGuidFormControl, registerSamplesGuidFormControl, adminGuidFormControl, analistGuidFormControl, globalAnalysisRequestGuidFormControl,
    arGuidFormControl, dfGuidFormControl, dryerGuidFormControl, ppGuidFormControl, twfGuidFormControl, getLocationGroups, createNotification]);

  const renderActionButtons = useMemo(() => {
    if (windowResize > 768)
      return <Button
        text={translate("COMMON.Save")}
        type="primary"
        onClick={onClickSave}
        isDisabled={isLoading}
      />;
    else
      return (
        <IconButton
          icon={<SaveIcon />}
          type="primary"
          onClick={onClickSave}
          className="edit-btn"
        />
      );
  }, [isLoading, onClickSave, windowResize]);

  return (
    <PageLayout
      tabTitle={`laMP - ${translate("GLOBALADMIN.LOCATIONS.AccessGroups")}`}
      pageTitle={translate("GLOBALADMIN.LOCATIONS.AccessGroups")}
      className="location-groups"
      showBreadcrumb
      actions={renderActionButtons}
    >
      {onClickSubmit ? <FullScreenLoader /> : null}
      {isLoading ? <div className="access-groups-loader">
        <Loader />
      </div> : (

        <div className={"group-access-table"}>
          <Table>
            {windowResize > 768 ?
              <TableHeader>
                <TableHeaderCell key="1">{translate("GLOBALADMIN.ACCESSGROUPS.Type")}</TableHeaderCell>
                <TableHeaderCell key="2">{translate("GLOBALADMIN.ACCESSGROUPS.ID")}</TableHeaderCell>
                <TableHeaderCell key="3">{translate("GLOBALADMIN.ACCESSGROUPS.Name")}</TableHeaderCell>
              </TableHeader> : null}

            <TableBody>
              <GroupAccessRow control={accessGuidFormControl} AADName={locationGroups?.access.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.ACCESS")} />
              <GroupAccessRow control={registerSamplesGuidFormControl} AADName={locationGroups?.registerSamples.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.REGISTER_SAMPLES")} />
              <GroupAccessRow control={adminGuidFormControl} AADName={locationGroups?.admin.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.ADMIN")} />
              <GroupAccessRow control={analistGuidFormControl} AADName={locationGroups?.analyst.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.ANALISTS")} />
              <GroupAccessRow control={globalAnalysisRequestGuidFormControl} AADName={locationGroups?.requestsGlobal.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.ANALISYS_REQUEST_GLOBAL")} />
              <GroupAccessRow control={arGuidFormControl} AADName={locationGroups?.ar.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.AR")} />
              <GroupAccessRow control={dfGuidFormControl} AADName={locationGroups?.df.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.DF")} />
              <GroupAccessRow control={dryerGuidFormControl} AADName={locationGroups?.dryer.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.DRYER")} />
              <GroupAccessRow control={ppGuidFormControl} AADName={locationGroups?.pp.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.PP")} />
              <GroupAccessRow control={twfGuidFormControl} AADName={locationGroups?.wf.name || ""} type={translate("GLOBALADMIN.ACCESSGROUPS.TYPES.WF")} />
            </TableBody>
          </Table>
        </div>)}
    </PageLayout>
  );
}
