import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { GetStatusReasonResponseDTO } from "../models/api/GetLabSampleStatusReasonResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { LabSampleStatusReasonDTO } from "../models/api/LabSampleStatusReasonDTO";
import { SampleStatusReason } from "../models/domain/SampleStatusReason";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class LabSampleStatusReasonService {

  
  getRejectReasons(): Promise<SampleStatusReason[]> {
    return HttpClient.sessionRequest<GetStatusReasonResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/LabSamplesStatusReasons/StatusReasons`),
    })
      .then((response) => {
        var mappedSampleStatusReason = response.data.labSampleStatusReasons.map((r): SampleStatusReason => ({
          sampleStatusReasonId: r.labSampleStatusReasonId,
          sampleStatus: r.labSampleStatus,
          reason: r.reason,
          isJustificationRequired: r.isJustificationRequired
        }))
        return mappedSampleStatusReason;
      })
      .catch((error) => {
        throw error;
      });
  }
}