import { IFormControl, Optional, ValidatorFunction } from "../../../lib/components/form/Form";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { AnalysisRequestReasonType } from "../models/domain/AnalysisRequestReasonType";


export function validateAnalysisRequestReasonRequiredIfOthers(analysisRequestReasonType?: IFormControl<AnalysisRequestReasonType>): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {
        if (analysisRequestReasonType?.value?.isDescriptionRequired && (!value || value?.trim() === "")) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}
