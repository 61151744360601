import { useCallback, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AddContactPopup } from "./popup/AddContactPopup";
import { AddInfo } from "./AddInfo";
import { AnalysisRequestStatusEnum } from "../../models/domain/AnalysisRequestStatusEnum";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { Contact } from "../../../samples/models/domain/Contact";
import { ReactComponent as ContactsIcon } from "../../../../lib/assets/icons/contact.svg";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IButtonProps } from "../../../../lib/components/buttons/Button";
import { IResponsiveDataTableProps } from "../../../../lib/components/table/ResponsiveDataTable";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { Permission } from "../../../../models/api/gate/Permission";
import { RequestContactsService } from "../../services/RequestContactsService";
import { RequestDetails } from "../../models/domain/RequestDetails";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useIdentityUserDisplayName } from "../../../../lib/infrastructure/identity/hooks/useIdentityUserDisplayName";

var contactsService = new RequestContactsService();
interface IAddContactsProps {
    className?: string;
    locationId: string | undefined;
    requestId: string | undefined;
    requestDetails: RequestDetails | undefined;
    contacts: Contact[] | undefined;
    isLoading: boolean;
    onClickButton?: () => void;
    onTableRowClick?: () => void;
    onCompletedOperations: () => void;
}

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];


export function AddContacts(props: IAddContactsProps) {
    const useIdentity = useIdentityUserDisplayName();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const [showFullScreenLoader, setIsShowFullScreenLoader] = useState<boolean>(false);

    const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);



    const deleteRequestContact = useCallback((contactId: number) => {

        if (!props.locationId || !props.requestId) return;
        setIsShowFullScreenLoader(true);

        contactsService.deleteRequestContact(props.locationId, props.requestId, contactId)
            .then((_) => {

                setIsShowFullScreenLoader(false);
                props.onCompletedOperations && props.onCompletedOperations();

                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("REQUESTS.INFOANDFORM_2.DeleteContactSuccess")} />);
            }).catch(error => {

                setIsShowFullScreenLoader(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.requestId, props.onCompletedOperations, setIsShowFullScreenLoader, createNotification, openPopup]);



    const editContactsEnabled = useMemo(() => {

        if (isLoadingPermissions || !props.requestDetails?.requestStatus) return false;

        var authorHasPermission = props.requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()
            && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

        var analystHasPermissions = hasPermission && (props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && props.requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

        return (authorHasPermission || analystHasPermissions);

    }, [isLoadingPermissions, hasPermission, props.requestDetails?.requestStatus, props.requestDetails?.createdBy?.email, useIdentity.userDisplayEmail]);




    const columns: ColumnDefinition<Contact>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.email,
            headerRender: translate("REQUESTS.INFOANDFORM_2.Email"),
            columnKey: "email",
            isMobilePrimaryCell: true,
            className: "word-break"
        },
        {
            cellRenderProp: (v) => v.name,
            headerRender: translate("COMMON.Name"),
            columnKey: "name",
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (v) => (
                <ScalableIconButton size={24} isDisabled={editContactsEnabled ? false : true} icon={<DeleteIcon />} onClick={() => {
                    openPopup(
                        <WarningPopup
                            onDismissClicked={() => { closePopup(); }}
                            onConfirmClicked={() => { deleteRequestContact(v.id) }}
                            dismissText={translate("COMMON.Cancel")}
                            confirmText={translate("REQUESTS.INFOANDFORM_2.DeleteContact")}
                            className="button-red"
                        >
                            {translate("REQUESTS.INFOANDFORM_2.DeleteContactConfirmText", v.email)}
                        </WarningPopup>
                    );
                }} />
            ),
            columnKey: "delete",
            isMobileHeaderIcon: true,
            width: "24px",
        },
    ], [closePopup, deleteRequestContact, editContactsEnabled, openPopup]);



    const contactsTableInfo: IResponsiveDataTableProps<Contact> = useMemo(() => {
        return {
            items: props.contacts || [],
            columnDefinitions: columns,
            totalitems: 0,
            isLoading: props.isLoading,
            onClickRow: props.onTableRowClick,
        };
    }, [props.contacts, props.onTableRowClick, props.isLoading, columns]);



    const addContactsButton: IButtonProps = useMemo(() => {
        return {
            text: translate("REQUESTS.ACTIONS.AddContacts"),
            type: "secondary",
            icon: <ContactsIcon />,
            isDisabled: editContactsEnabled ? false : true,
            onClick: () => {
                openPopup(
                    <AddContactPopup
                        locationId={props.locationId}
                        requestId={props.requestId}
                        onCompletedOperations={() => { props.onCompletedOperations && props.onCompletedOperations() }}
                    />
                );
            },
        };
    }, [editContactsEnabled, props.locationId, props.requestId, openPopup, props.onCompletedOperations]);



    return (
        <>
            {showFullScreenLoader ? <FullScreenLoader /> : null}
            <AddInfo
                title={translate("REQUESTS.INFOANDFORM_2.Contacts")}
                button={addContactsButton}
                tableInfo={contactsTableInfo}
                className="add-info-contacts"
                isLoading={props.isLoading}
            />
        </>
    );
}
