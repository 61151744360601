import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { AnalysisRequestSampleDetails } from "../../../../models/domain/AnalysisRequestSampleDetails";
import { Checkbox } from "../../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { EditRequestSampleForm } from "../../../../models/domain/EditRequestSampleForm";
import { FormContainer } from "../../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldDatePicker } from "../../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { Material } from "../../../../../../models/domain/materials/Material";
import { PopupContent } from "../../../../../../lib/layouts/containers/popup-content/PopupContent";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { validateRequiredIfValmetManufacturer } from "../../../../validators/ValidateRequiredIfValmetManufacturer";
import { validateStringIfConditional } from "../../../../validators/ValidateIfConditional";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";

const labelSelector = (item: Material) => item.name + " - " + item.description;
const idSelector = (item: Material) => item.id;


export interface IGenericEditRequestSampleFormsProps {
  info: AnalysisRequestSampleDetails;
  materials: Material[];
  isLoading: boolean;
}



export const EditRequestSampleWFPPForm = forwardRef(function EditRequestSampleWFPPForm(
  props: IGenericEditRequestSampleFormsProps,
  ref: React.ForwardedRef<EditRequestSampleForm>
) {

  const [isSampleUsed, setIsSampleUsed] = useState<boolean>(false);
  const [isOtherManufacturer, setIsOtherManufacturer] = useState<boolean>(false);
  const [isValmetManufacturer, setIsValmetManufacturer] = useState<boolean>(true);


  const valmetMaterialFormControl = useFormControl<Material>({
    validators: [validateRequiredIfValmetManufacturer(isValmetManufacturer)],
    enableAutoValidate: true,
    isDisabled: false
  });


  const otherManufacturerFormControl = useFormControl<string>({
    validators: [validateStringIfConditional(isOtherManufacturer)],
    enableAutoValidate: true,
    isDisabled: false
  });


  const otherManufacturerMaterialFormControl = useFormControl<string>({ isDisabled: false });


  const positionFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });


  const installedDateFormControl = useFormControl<DateTime>({
    enableAutoValidate: false
  });


  const removedDateFormControl = useFormControl<DateTime>({
    enableAutoValidate: false
  });


  const runningTimeFormControl = useFormControl<string>({
    isDisabled: false,
  });


  const removalReasonFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });


  const batchNumberFormControl = useFormControl<string>({});





  /****************************
    * DATA MANIPULATION EFFECTS
  *****************************/




  useEffect(() => {
    if (isValmetManufacturer) {
      valmetMaterialFormControl.setValue(props.materials.find((m) => m.id === props.info.material?.id));
      otherManufacturerMaterialFormControl.setValue("");
      otherManufacturerFormControl.setValue("");
    }

    if (isOtherManufacturer) {
      valmetMaterialFormControl.setValue(undefined);
      if (props.info.otherMaterial) {
        otherManufacturerMaterialFormControl.setValue(props.info.otherMaterial);
      } else {
        otherManufacturerMaterialFormControl.setValue("");
      }
    }
  }, [isValmetManufacturer, isOtherManufacturer]);






  useImperativeHandle(ref, () => {
    return {
      validate() {
        return AllValid(valmetMaterialFormControl.validate(), positionFormControl.validate(), removalReasonFormControl.validate(),
          otherManufacturerFormControl.validate());
      },
      value() {
        return {
          isUsed: isSampleUsed,
          manufacturerIsValmet: isValmetManufacturer,
          materialId: valmetMaterialFormControl.value?.id,
          otherMaterial: otherManufacturerMaterialFormControl.value,
          otherManufacturer: otherManufacturerFormControl.value,
          position: positionFormControl.value,
          installationDate: installedDateFormControl.value?.toUTC().toISO() ?? undefined,
          removalDate: removedDateFormControl.value?.toUTC().toISO() ?? undefined,
          runningTime: runningTimeFormControl.value,
          reasonForRemoval: removalReasonFormControl.value,
          batchNumber: batchNumberFormControl.value
        }
      }
    }
  }, [valmetMaterialFormControl.validate, otherManufacturerFormControl.validate, positionFormControl.validate,
  installedDateFormControl.validate, otherManufacturerMaterialFormControl.validate, batchNumberFormControl.validate, removedDateFormControl.validate,
  runningTimeFormControl.validate, removalReasonFormControl.validate, valmetMaterialFormControl.value, otherManufacturerFormControl.value,
  positionFormControl.value, installedDateFormControl.value, otherManufacturerMaterialFormControl.value, batchNumberFormControl.value,
  removedDateFormControl.value, runningTimeFormControl.value, removalReasonFormControl.value, isSampleUsed, isValmetManufacturer, isOtherManufacturer]);





  useEffect(() => {
    if (!props.materials || !props.info) return;

    setIsSampleUsed(props.info.isUsed);

    if (props.info.manufacturerIsValmet) {
      setIsValmetManufacturer(true);
      setIsOtherManufacturer(false);
    } else {
      setIsValmetManufacturer(false);
      setIsOtherManufacturer(true)
    }

    valmetMaterialFormControl.setValue(props.materials.find((m) => m.id === props.info.material?.id));

    if (props.info.otherManufacturer)
      otherManufacturerFormControl.setValue(props.info.otherManufacturer)

    if (props.info.otherMaterial)
      otherManufacturerMaterialFormControl.setValue(props.info.otherMaterial);

    if (props.info.position)
      positionFormControl.setValue(props.info.position);

    if (props.info.installationDate)
      installedDateFormControl.setValue(DateTime.fromISO(props.info.installationDate));

    if (props.info.removalDate)
      removedDateFormControl.setValue(DateTime.fromISO(props.info.removalDate));

    if (props.info.runningTime)
      runningTimeFormControl.setValue(props.info.runningTime);

    if (props.info.reasonForRemoval)
      removalReasonFormControl.setValue(props.info.reasonForRemoval);

    if (props.info.batchNumber)
      batchNumberFormControl.setValue(props.info.batchNumber);

  }, [props.materials, props.info]);





  /****************************
    * CSS & HTML
  *****************************/

  return (
    <>
      <div className="edit-request-sample-details">
        <PopupContent isLoading={props.isLoading}>
          <FormContainer>
            <div className="separator">{translate("REQUESTS.INFOANDFORM.Manufacturer")}</div>
            <div className="manufacturer">
              <Checkbox
                text={"Valmet"}
                isChecked={isValmetManufacturer}
                onClick={() => { setIsValmetManufacturer(true); setIsOtherManufacturer(false) }}
              />
              <Checkbox
                text={translate("REQUESTS.INFOANDFORM.Other")}
                isChecked={isOtherManufacturer}
                onClick={() => { setIsOtherManufacturer(true); setIsValmetManufacturer(false) }}
              />
            </div>

            <div className="separator">{translate("REQUESTS.POPUPS.SampleStatus")}</div>
            <Checkbox
              text={translate("REQUESTS.Used")}
              isChecked={isSampleUsed}
              onClick={() => setIsSampleUsed(!isSampleUsed)}
            />

            <div className="separator">{translate("REQUESTS.INFOANDFORM.Material")}</div>

            <ConditionalRender if={isValmetManufacturer}>
              <FormFieldSelectSingle
                label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
                placeholder={translate("SAMPLES.INFOANDFORM.SAPMaterialPlaceholder")}
                disableFuzzySearch
                options={props.materials}
                labelSelector={labelSelector}
                idSelector={idSelector}
                formControl={valmetMaterialFormControl}
                isClearSelectionAvailable={false}
              />
            </ConditionalRender>
            <ConditionalRender if={isOtherManufacturer}>
              <FormFieldTextInput
                formControl={otherManufacturerFormControl}
                label={translate("REQUESTS.INFOANDFORM.Manufacturer")}
                placeholder={translate("REQUESTS.INFOANDFORM.ManufacturerPlaceholder")}
              />
              <FormFieldTextInput
                formControl={otherManufacturerMaterialFormControl}
                label={translate("SAMPLES.INFOANDFORM.Material") + " (" + translate("COMMON.Optional") + ")"}
                placeholder={translate("REQUESTS.INFOANDFORM.MaterialPlaceholder")}
              />
            </ConditionalRender>

            <FormFieldTextInput
              formControl={batchNumberFormControl}
              label={translate("SAMPLES.INFOANDFORM.Batch") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
            />
            <FormFieldTextInput
              formControl={positionFormControl}
              label={translate("REQUESTS.INFOANDFORM.Position")}
              placeholder={translate("REQUESTS.INFOANDFORM.PositionPlaceholder")}
            />
            <div className="separator">{translate("REQUESTS.INFOANDFORM.RunningTime")}</div>
            <FormFieldDatePicker
              formControl={installedDateFormControl}
              label={translate("REQUESTS.INFOANDFORM.InstalledDate") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate("REQUESTS.INFOANDFORM.InstalledDatePlaceholder")}
              maxDate={removedDateFormControl.value}

            />
            <FormFieldDatePicker
              formControl={removedDateFormControl}
              label={translate("REQUESTS.INFOANDFORM.RemovedDate") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate("REQUESTS.INFOANDFORM.RemovedDatePlaceholder")}
              minDate={installedDateFormControl.value}
            />
            <FormFieldTextInput
              formControl={runningTimeFormControl}
              label={translate("REQUESTS.INFOANDFORM.Duration") + " (" + translate("COMMON.Optional") + ")"}
              placeholder={translate("REQUESTS.INFOANDFORM.DurationPlaceholder")}
            />

            <div className="separator">{translate("REQUESTS.OtherData")}</div>

            <FormFieldTextArea
              formControl={removalReasonFormControl}
              label={translate("REQUESTS.INFOANDFORM.RemovalReason")}
              placeholder={translate("REQUESTS.INFOANDFORM.RemovalReasonPlaceholder")}
            />

          </FormContainer>
        </PopupContent>
      </div>
    </>
  );
});