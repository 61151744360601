import * as qs from "qs";

import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { CreateMaterialRequestDTO } from "../models/api/materials/CreateMaterialRequestDTO";
import { GetMaterialsRequestDTO } from "../models/api/materials/GetMaterialsRequestDTO";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";
import { Material } from "../models/domain/materials/Material";
import { MaterialDTO } from "../models/api/materials/MaterialDTO";
import { MaterialsQueryOptions } from "../models/api/materials/MaterialsQueryOptions";
import { MaterialsResponseDTO } from "../models/api/materials/MaterialsResponseDTO";
import { MaterialsTotalItems } from "../models/domain/materials/MaterialsTotalItems";
import { QueryOptions } from "../models/domain/materials/QueryOptions";
import { UpdateMaterialControl04RequestDTO } from "../models/api/materials/UpdateMaterialControl04RequestDTO";
import { UpdateMaterialRequestDTO } from "../models/api/materials/UpdateMaterialRequestDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};



export class MaterialsService {



    getMaterials(locationId: number | string, queryOptions?: MaterialsQueryOptions): Promise<MaterialsTotalItems> {

        return HttpClient.sessionRequest<MaterialsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Materials`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                var mappedMaterials = response.data.materials.map(
                    (p): Material => ({
                        id: p.materialId,
                        description: p.description,
                        name: p.matNumber,
                        plant: p.plant,
                        isDisabled: p.isDisabled,
                        isSubjectToC04: p.isSubjectToC04
                    })
                );
                return {
                    materials: mappedMaterials,
                    totalMaterials: response.data.total
                }
            }).catch((error) => {
                throw error;
            });
    }

    getActivesMaterials(locationId: number | string, queryOptions?: MaterialsQueryOptions): Promise<MaterialsTotalItems> {

        return HttpClient.sessionRequest<MaterialsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Materials/Actives`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                var mappedMaterials = response.data.materials.map(
                    (p): Material => ({
                        id: p.materialId,
                        description: p.description,
                        name: p.matNumber,
                        plant: p.plant,
                        isDisabled: p.isDisabled,
                        isSubjectToC04: p.isSubjectToC04
                    })
                );
                return {
                    materials: mappedMaterials,
                    totalMaterials: response.data.total
                }
            }).catch((error) => {
                throw error;
            });
    }



    getById(locationId: string, materialId: number): Promise<Material> {
        return HttpClient.sessionRequest<MaterialDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}`),
        })
            .then((response) => {
                var mappedMaterial: Material = {
                    id: response.data.materialId,
                    plant: response.data.plant,
                    name: response.data.matNumber,
                    description: response.data.description,
                    isSubjectToC04: response.data.isSubjectToC04
                };
                return mappedMaterial;
            })
            .catch((error) => {
                throw error;
            });
    }



    addMaterial(locationId: string,
        plant: string | undefined,
        name: string | undefined,
        description: string | undefined, subjectToC04: boolean): Promise<void> {

        var request: CreateMaterialRequestDTO = {
            plant: plant || "",
            matNumber: name || "",
            description: description || "",
            isSubjectToC04: subjectToC04

        };

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/Materials`),
            data: request
        })
            .then((_) => {

            })
            .catch((error) => {
                throw error;
            });
    }


    uploadMaterialFile(locationId: string,
        file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/Materials/asCSV`),
            data: formData
        })
            .then((_) => {

            })
            .catch((error) => {
                throw error;
            });
    }



    disableMaterial(locationId: string, materialId: number): Promise<void> {

        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    enableMaterial(locationId: string, materialId: number): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}/EnableMaterial`),
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }


    updateMaterial(locationId: string, materialId: number, plant: string, name: string, description: string, subjectToC04: boolean): Promise<void> {

        var request: UpdateMaterialRequestDTO = {
            materialId: materialId,
            plant: plant,
            matNumber: name,
            description: description,
            isSubjectToC04: subjectToC04
        };

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}`),
            data: request
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateMaterialControl04(locationId: string, materialId: number, subjectToC04: boolean): Promise<void> {

        var request: UpdateMaterialControl04RequestDTO = {
            isSubjectToC04: subjectToC04
        };

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/Materials/${materialId}/Control04`),
            data: request
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}
