import { DateTime } from "luxon";
import { InternationalizationService } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export function DateFormatByCurrentLanguage(date: string) {
    if(InternationalizationService.getLocale() === "en-EN"){
      return DateTime.fromISO(date).toLocal().toFormat("yyyy/MM/dd").toString();
    } else if(InternationalizationService.getLocale() === "pt-PT") {
        return DateTime.fromISO(date).toLocal().toFormat("dd/MM/yyyy").toString();
    } else {
      return DateTime.fromISO(date).toLocal().toFormat("yyyy/MM/dd").toString();
    }
  }

  export function DateFormatByCurrentLanguageWithHours(date: string) {
    if(InternationalizationService.getLocale() === "en-EN"){
      return DateTime.fromISO(date).toLocal().toFormat("yyyy/MM/dd HH'h'mm").toString();
    } else if(InternationalizationService.getLocale() === "pt-PT") {
        return DateTime.fromISO(date).toLocal().toFormat("dd/MM/yyyy HH'h'mm").toString();
    } else {
      return DateTime.fromISO(date).toLocal().toFormat("yyyy/MM/dd HH'h'mm").toString();
    }
  }