import { SelectOption } from "./SelectOption";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum ClientRegistrationEnum {
    NOTREGISTERED = 1,
    SAP = 2,
    COMPASS = 3,
}

export const ClientRegistrationTranslationTerms: { [key: string]: string } = {
    "1": "REQUESTS.CLIENTREGISTRATIONENUM.NotRegistered",
    "2": "REQUESTS.CLIENTREGISTRATIONENUM.SAPRegistered",
    "3": "REQUESTS.CLIENTREGISTRATIONENUM.CompassRegistered",
}


export const GetClientRegistrationEnumByKey: { [key: number]: ClientRegistrationEnum } = {
    1: ClientRegistrationEnum.NOTREGISTERED,
    2: ClientRegistrationEnum.SAP,
    3: ClientRegistrationEnum.COMPASS,
};

export function GetClientRegistrationsArray() {
    const selectOptionsArray: SelectOption[] = Object.values(ClientRegistrationEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSelectOption: SelectOption = {
                id: "" + enumValue,
                description: translate(ClientRegistrationTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedSelectOption;
        });
    return selectOptionsArray;
}