import "./TestsHomePage.css";

import { ColumnDefinition, IOrderInfo } from "../../../lib/components/table/TableInterfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { AddLabTestPopup } from "./AddLabTestPopup";
import { BetweenNumbersHelper } from "../../../lib/helpers/BetweenNumbersHelper";
import { Button } from "../../../lib/components/buttons/Button";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ContextualMenu } from "../../../lib/components/contextual-menu/ContextualMenu";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import-dark.svg";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { LabTestDetails } from "../../../models/domain/lab-tests/LabTestDetails";
import { LabTestsQueryOptions } from "../../../models/api/lab-tests/LabTestsQueryOptions";
import { MeasurementEnum } from "./models/MeasurementEnum";
import { ReactComponent as MoreActions } from "../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { ReactComponent as TestsIcon } from "../../../lib/assets/icons/teste-dark.svg";
import { TestsService } from "./services/TestsService";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { UnitMeasurementTerms } from "./models/UnitEnum";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { ReactComponent as CheckIcon } from "../../../lib/assets/icons/check-btn.svg";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { EditLabTestPopup } from "./EditLabTestPopup";

var testsService = new TestsService();

export function TestsHomePage() {
  const { id: locationId } = useParams();

  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const createNotification = useCreateNotification();
  const navigate = useNavigate();
  const windowResize = useWindowResize();

  const [tests, setTests] = useState<LabTestDetails[]>();
  const [totalTests, setTotalTests] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchWord, setSearchWord] = useState<string>();

  const [currentpage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>();


  const [isLoadingImport, setIsLoadingImport] = useState(false);

  const [timer, setTimer] = useState<NodeJS.Timeout>();

  var searchBoxFormControl = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });



  useEffect(() => {
    getLabTests();
  }, [currentpage, itemsPerPage, searchWord]);




  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    var responseTimeOut = setTimeout(() => {
      if (timer) {
        setCurrentPage(0);
        setSearchWord(searchBoxFormControl.value);
      }
    }, 1000);

    setTimer(responseTimeOut);
  }, [searchBoxFormControl.value]);



  const getLabTests = useCallback(() => {
    if (locationId) {
      setIsLoading(true);

      var queryOptions: LabTestsQueryOptions = {
        page: currentpage,
        pageLength: itemsPerPage,
        searchWord: searchWord,
        filterByIsActive: false,
      };

      if (locationId) {
        testsService
          .getLabTests(locationId, queryOptions)
          .then((response) => {
            setTests(response.labTests);
            setTotalTests(response.totalLabTests);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            if (!error) return;
            openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
          });
      }
    }
  }, [
    locationId,
    setTests,
    setTotalTests,
    setIsLoading,
    currentpage,
    itemsPerPage,
    searchWord,
  ]);


  const handleFileImport = useCallback((file: File) => {
    if (!locationId) return;

    setIsLoadingImport(true);
    testsService.uploadTestFile(locationId, file).then(res => {
      setIsLoadingImport(false);
      getLabTests();
      createNotification(
        <Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate(
            "GLOBALADMIN.TESTS.POPUP.ImportFileSuccess"
          )}
        ></Notification>
      );
    }).catch(error => {
      setIsLoadingImport(false)
      openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
    });

  }, [locationId, createNotification, openPopup, setIsLoadingImport, getLabTests]);

  const handleOnOrderChanged = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderedPriorities);
    },
    [setOrderInfoColumns]
  );



  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );



  const onClickDisableTest = useCallback((labTestID: number) => {

    if (labTestID && locationId) {
      testsService.editLabTestStatus("" + locationId, labTestID, true)
        .then((_) => {
          getLabTests();
          closePopup();
          createNotification(
            <Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("GLOBALADMIN.TESTS.POPUP.DisableTestSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationId, getLabTests, createNotification, openPopup, closePopup])



  const onClickEnableTest = useCallback((labTestID: number) => {

    if (labTestID && locationId) {
      testsService.editLabTestStatus("" + locationId, labTestID, false)
        .then((_) => {
          getLabTests();
          closePopup();
          createNotification(
            <Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("GLOBALADMIN.TESTS.POPUP.ActivateTestSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationId, getLabTests, createNotification, openPopup, closePopup])




  const testsColumnsList: ColumnDefinition<LabTestDetails>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.testTypeName,
        width: "5%",
        headerRender: translate("GLOBALADMIN.TESTS.INFO.Type"),
        columnKey: "typeDesc",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => (
          <div className="name">
            {v.testNamePt ?
              <div className="pt-pt">pt-PT: {v.testNamePt}</div> : null}
            {v.testNameEn ?
              <div className="en-en">en-EN: {v.testNameEn}</div> : null}
          </div>
        ),
        headerRender: translate("GLOBALADMIN.TESTS.INFO.Name"),
        columnKey: "testName",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.isUsed ? <CheckIcon className="check-icon" /> : null,
        width: "5%",
        headerRender: <>{translate("GLOBALADMIN.TESTS.INFO.Usage")}</>,
        columnKey: "isUsed",
        isSortable: false,
      },
      {
        cellRenderProp: (v) => {
          if (v.measurement === MeasurementEnum.AREA && v.length && v.width)
            return <>{v.length + "x" + v.width + UnitMeasurementTerms[v.unit]}</>;
          if (v.measurement === MeasurementEnum.LENGTH && v.length)
            return <>{v.length + v.unit + UnitMeasurementTerms[v.unit] || null}</>
          return <>-</>;
        },
        width: "5%",
        headerRender: translate("GLOBALADMIN.TESTS.INFO.SampleSize"),
        columnKey: "sampleSize",
        isSortable: false,
      },
      {
        cellRenderProp: (v) => v.multiplier,
        width: "5%",
        headerRender: translate("GLOBALADMIN.TESTS.INFO.MultiplierSimplified"),
        columnKey: "multiplier",
        isSortable: false,
      },
      /*       {
              cellRenderProp: (v) => v.isForDestroyingBarOrFilter ? translate("COMMON.Yes") : translate("COMMON.No"),
              width: "5%",
              headerRender: translate("GLOBALADMIN.TESTS.INFO.DestroyingBarOrFilter"),
              columnKey: "isForDestroyingBarOrFilter",
              isSortable: false,
            }, */
      {
        cellRenderProp: (v) => v.isForDestroyingBarOrFilter ? <CheckIcon className="check-icon" /> : null,
        width: "5%",
        headerRender: translate("GLOBALADMIN.TESTS.INFO.DestroyingBarOrFilter"),
        columnKey: "isForDestroyingBarOrFilter",
        isSortable: false,
      },


      {
        cellRenderProp: (v) => {
          if (v.rehearsalTime || v.workedHours)
            return <div className="duration">
              {v.rehearsalTime ?
                <div className="cost">{translate("GLOBALADMIN.TESTS.INFO.RehearsalTimeSimplified")}{" (h): "}{v.rehearsalTime}</div> : null}
              {v.workedHours ?
                <div className="used-cost">{translate("GLOBALADMIN.TESTS.INFO.WorkedHoursSimplified")}{": "}{v.workedHours}</div> : null}
            </div>
        },
        headerRender: translate("GLOBALADMIN.TESTS.INFO.Duration"),
        width: "10%",
        columnKey: "rehearsalTime",
        isSortable: false,
      },
      {
        cellRenderProp: (v) => {
          if (v.cost || v.usedCost)
            return <div className="costs">
              {v.cost ?
                <div className="cost">{translate("GLOBALADMIN.TESTS.INFO.Cost")}{": "}{v.cost?.toFixed(2)}</div> : null}
              {v.usedCost ?
                <div className="used-cost">{translate("GLOBALADMIN.TESTS.INFO.UsedCost")}{": "}{v.usedCost?.toFixed(2)}</div> : null}
            </div>
        },
        headerRender: translate("GLOBALADMIN.TESTS.INFO.CostsSimplified"),
        width: "13%",
        columnKey: "costs",
        isSortable: false,
      },
      {
        cellRenderProp: (v) => {
          if (v.internalProcedure || v.standard)
            return <div className="procedures">
              {v.internalProcedure ?
                <div className="internal-procedure">{translate("GLOBALADMIN.TESTS.INFO.InternalProcedureSimplified")}{": "}{v.internalProcedure}</div> : null}
              {v.standard ?
                <div className="standard">{translate("GLOBALADMIN.TESTS.INFO.Standard")}{": "} {v.standard}</div> : null}
            </div>
        },

        headerRender: translate("GLOBALADMIN.TESTS.INFO.Procedure"),
        width: "20%",
        columnKey: "procedures",
        isSortable: false,
        isVisible: !BetweenNumbersHelper.betweenNumbers(windowResize, 768, 1024),
      },
      {
        cellRenderProp: (v) => (
          <div className="toggle-div">
            <Toggle value={!v.isDisabled} />
            <div
              className="toggle-div-overlay"
              onClick={(ev) => {
                ev.stopPropagation();
                if (!v.isDisabled) {
                  openPopup(
                    <WarningPopup
                      className="disable-test-popup"
                      onDismissClicked={() => {
                        closePopup();
                      }}
                      onConfirmClicked={() => {
                        onClickDisableTest(v.labTestId);
                      }}
                      dismissText={translate("COMMON.Cancel")}
                      confirmText={translate(
                        "GLOBALADMIN.TESTS.POPUP.Deactivate"
                      )}
                    >
                      {translate(
                        "GLOBALADMIN.TESTS.POPUP.DeactivateTestConfirmText"
                      )}
                    </WarningPopup>
                  );
                }
                else {
                  openPopup(
                    <WarningPopup
                      onDismissClicked={() => {
                        closePopup();
                      }}
                      onConfirmClicked={() => {
                        onClickEnableTest(v.labTestId);
                      }}
                      dismissText={translate("COMMON.Cancel")}
                      confirmText={translate(
                        "COMMON.Activate"
                      )}
                    >
                      {translate(
                        "GLOBALADMIN.TESTS.POPUP.ActivateTestConfirmText"
                      )}
                    </WarningPopup>
                  );
                }
              }}
            />
          </div>
        ),
        width: "5%",
        columnKey: "status",
        isSortable: false,
        isMobileHeaderIcon: true
      },
      {
        cellRenderProp: (v) => (
          <ScalableIconButton
            icon={<EditIcon />}
            onClick={() => {
              if (!v.labTestId || !locationId) return;

              openPopup(<EditLabTestPopup
                labTestID={"" + v.labTestId}
                locationId={locationId}
                onCompletedOperations={getLabTests}
              />);
            }}
            size={30}
          />
        ),
        width: "3%",
        columnKey: "edit-test",
        isSortable: false,
      },],
    [openPopup, closePopup, onClickDisableTest, onClickEnableTest, locationId, windowResize, getLabTests]
  );




  const renderActionButtons = useMemo(() => {
    if (windowResize > 768)
      return <>
        <Button
          text={translate("COMMON.Import")}
          type="secondary"
          onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>

            window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/LaMP_Tests_Template.xlsx", "_blank")}

            onClickImportBtn={handleFileImport} />)}
          className="import-test-btn"
        />
        <Button
          text={translate("COMMON.Create")}
          type="primary"

          onClick={() => {
            if (locationId) {
              openPopup(<AddLabTestPopup locationId={"" + locationId} onChangesMade={getLabTests} />);
            }
          }}
          className="create-test-btn"
        />
        <Button
          text={translate("GLOBALADMIN.TESTTYPES.TestTypes")}
          type="primary"
          onClick={() => { navigate("types") }}
          className="navigate-test-types-btn"
        />
      </>
    else
      return <>
        <ContextualMenu
          items={[
            {
              label: translate("GLOBALADMIN.TESTTYPES.TestTypes"),
              icon: <TestsIcon />,
              navigateTo: "",
              onClick: () => { navigate("types") }
            },
            {
              label: translate("COMMON.Import"),
              icon: <ImportIcon />,
              navigateTo: "",
              onClick: () => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>
                window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/LaMP_Tests_Template.xlsx", "_blank")}
                onClickImportBtn={handleFileImport} />)
            },
          ]}
        >
          <ScalableIconButton size={30} icon={<MoreActions />} />
        </ContextualMenu >
        <IconButton
          icon={<AddIcon />}
          type="primary"
          onClick={() => {
            if (locationId) {
              openPopup(<AddLabTestPopup locationId={"" + locationId} onChangesMade={getLabTests} />);
            }
          }}
        />
      </>
  }, [windowResize, getLabTests, handleFileImport, locationId, navigate, openPopup])

  return (
    <PageLayout
      tabTitle={"laMP - " + translate("GLOBALADMIN.TESTS.Tests")}
      pageTitle={translate("GLOBALADMIN.TESTS.Tests")}
      className="tests-home"
      showBreadcrumb
      actions={renderActionButtons}
    >
      <FormFieldTextInput
        formControl={searchBoxFormControl}
        icon={<SearchIcon />}
        placeholder={translate("COMMON.FORM.SearchPlaceholder")}
        className="search-box"
      />
      <ResponsiveDataTable
        columnDefinitions={testsColumnsList}
        items={tests || []}
        totalitems={totalTests || 0}
        paginationOptions={{
          itemsPerPage: itemsPerPage,
          itemsPerPageOptions: [10, 20, 30, 40, 50],
        }}
        currentpage={currentpage}
        isLoading={isLoading}
        onOrderChanged={handleOnOrderChanged}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
      ></ResponsiveDataTable>

      <ConditionalRender if={isLoadingImport} >
        <FullScreenLoader />
      </ConditionalRender>
    </PageLayout>
  );
}
