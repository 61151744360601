import { AnalysisRequestType } from "./AnalysisRequestType";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum AnalysisRequestTypeEnum {
    AR = 1,
    WF = 2,
    DF = 3,
    PP = 4,
    DRYER = 5
}


export const RequestTypeTranslationTerms: { [key: string]: string } = {
    1: "REQUESTS.TYPES.AR",
    2: "REQUESTS.TYPES.WF",
    3: "REQUESTS.TYPES.DF",
    4: "REQUESTS.TYPES.PP",
    5: "REQUESTS.TYPES.DRYER",
}

export const RequestTypeAsEnum: { [key: number]: AnalysisRequestTypeEnum } = {
    1: AnalysisRequestTypeEnum.AR,
    2: AnalysisRequestTypeEnum.WF,
    3: AnalysisRequestTypeEnum.DF,
    4: AnalysisRequestTypeEnum.PP,
    5: AnalysisRequestTypeEnum.DRYER,
};

export function GetAnalysisRequestType() {

    const requestTypeArray: AnalysisRequestType[] = Object.values(AnalysisRequestTypeEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedAnalysisRequestType: AnalysisRequestType = {
                id: "" + enumValue,
                description: translate(RequestTypeTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedAnalysisRequestType;
        });

    return requestTypeArray;
}