import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { SamplesModule } from "./App.RoutingModule.SamplesModule";

import { AdminContainer } from "../common/layouts/content/AdminContainer";
import { AdminMainLayout } from "../common/layouts/admin-main-layout/AdminMainLayout";
import { AdministrationModule } from "./App.RoutingModule.AdministrationModule";
import { AppMainLayout } from "../common/layouts/app-main-layout/AppMainLayout";
import { PermissionNavigate } from "../lib/infrastructure/authorization/PermissionNavigate";
import { RequestsModule } from "./App.RoutingModule.RequestsModule";
import { Permission } from "../models/api/gate/Permission";
import { MainContainer } from "../common/layouts/content/MainContainer";

const MainTemplate = () => (
  <AppMainLayout>
    <MainContainer>
      <Outlet />
    </MainContainer>
  </AppMainLayout>
);

const AdminTemplate = () => (
  <AdminMainLayout>
    <AdminContainer>
      <Outlet />
    </AdminContainer>
  </AdminMainLayout>
);

const basePermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_ACCESS,
  Permission.LOCATION_REGISTER_SAMPLES
];



export function AppRoutingModule() {
  return (
    <Routes>
      <Route element={<MainTemplate />}>
        <Route path="/" element={<PermissionNavigate to="/samples" fallbackTo="/requests" permissions={basePermissions} />} />
        <Route path="/samples/*" element={<SamplesModule />} />
        <Route path="/requests/*" element={<RequestsModule />} />
      </Route>
      <Route element={<AdminTemplate />}>
        <Route path="/settings/*" element={<AdministrationModule />} />
      </Route>
      <Route path="/*" element={<Navigate to="/notfound" />} />
    </Routes>
  );
}
