import { useCallback } from "react";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { LabOperatorService } from "../LabOperatorService";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { useServiceCallPro2 } from "../../../../services/UseServiceCall";
import { LabOperatorCreateDTO } from "../models/LabOperatorCreateDTO";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
var labOperatorSvc = new LabOperatorService();


export interface IAddLabOperatorProps {
  locationId: string;
  onCompletedOperations: () => void;
}


export function AddLabOperatorPopup(props: IAddLabOperatorProps) {
  const createNotification = useCreateNotification();
  const closePopup = useClosePopup();
  const openPopup = usePopup();


  const [addLabOperatorCall, addLabOperatorCallIsLoading] = useServiceCallPro2(labOperatorSvc, labOperatorSvc.addLabOperator);


  /****************************
   * FORM CONTROL
   *****************************/
  const employeeCodeFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const employeeNameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });

  const employeeSectionFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
  });



  const handleAddEmployeeClicked = useCallback(() => {
    if (!props.locationId) return;

    if (!AllValid(employeeNameFormControl.validate(), employeeCodeFormControl.validate(), employeeSectionFormControl.validate())) return;


    var dto: LabOperatorCreateDTO = {
      employeeCode: trimString(employeeCodeFormControl.value),
      name: trimString(employeeNameFormControl.value),
      section: trimString(employeeSectionFormControl.value)
    }


    addLabOperatorCall(props.locationId, dto)
      .then(() => {

        props.onCompletedOperations();
        closePopup();

        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate(
              "GLOBALADMIN.EMPLOYEES.MESSAGES.AddOperatorSuccess"
            )}
          ></Notification>
        );
      })
      .catch((error) => {

        if (!error) return;
        openPopup(
          <ErrorPopup>{error.response.data.message}</ErrorPopup>,
          false
        );
      });

  }, [
    addLabOperatorCall,
    props.locationId,
    employeeCodeFormControl.value,
    employeeNameFormControl.value,
    employeeSectionFormControl.value,
    employeeCodeFormControl.validate,
    employeeNameFormControl.validate,
    employeeSectionFormControl.validate,
    props.onCompletedOperations,
    createNotification,
    closePopup,
    openPopup]);



  return (
    <PopupContainer className="popup-add-lab-operator">
      {addLabOperatorCallIsLoading ? <FullScreenLoader /> : null}

      <PopupHeader
        title={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.AddLabOperator")}
      />
      <PopupContent >
        <FormContainer>
          <FormFieldTextInput
            formControl={employeeCodeFormControl}
            label={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Code")}
            placeholder="Ex: 123456"
          />
          <FormFieldTextInput
            formControl={employeeNameFormControl}
            label={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Name")}
            placeholder="Ex: John Doe"
          />
          <FormFieldTextInput
            formControl={employeeSectionFormControl}
            label={translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Division")}
            placeholder="Ex: Laser"
          />
        </FormContainer>

        <Spacer mode={"vertical"} px="30"></Spacer>

        <PopupActionButtons
          buttons={[
            {
              text: translate("COMMON.Cancel"),
              type: "tertiary",
              onClick: () => closePopup(),
            },
            {
              text: translate("COMMON.Add"),
              type: "primary",
              onClick: handleAddEmployeeClicked
            },
          ]}
        />
      </PopupContent>
    </PopupContainer>
  );
}
