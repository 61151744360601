import "./AddContactPopup.css";

import { useCallback, useState } from "react";
import { useClosePopup, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RequestContactsService } from "../../../services/RequestContactsService";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { ReactComponent as WarningIcon } from "../../../../../lib/assets/icons/warning-icon-v2.svg"

var contactsService = new RequestContactsService();

export interface IAddContactPopupProps {
    onCompletedOperations: () => void;
    locationId: string | undefined;
    requestId: string | undefined;
}


export function AddContactPopup(props: IAddContactPopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const emailFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const handleSaveButtonClicked = useCallback(() => {

        if (!AllValid(emailFormControl.validate())) return;
        if (!props.locationId || !props.requestId) return;

        setIsLoading(true);

        contactsService.addRequestContacts(props.locationId, props.requestId, emailFormControl.value || "")
            .then(() => {
                setIsLoading(false);
                props.onCompletedOperations();
                closePopup();
            })
            .catch((error) => {
                setIsLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.requestId, props.onCompletedOperations, setIsLoading, closePopup, emailFormControl.validate, emailFormControl.value]);


    return (
        <PopupContainer className="popup-add-contact">
            <PopupHeader title={`${translate("REQUESTS.POPUPS.AddContact")} `} />

            <PopupContent >
                {isLoading ? < FullScreenLoader /> : null}
                <FormContainer>
                    <FormFieldTextInput
                        formControl={emailFormControl}
                        label={translate("REQUESTS.INFOANDFORM_2.Email")}
                        placeholder={translate("REQUESTS.INFOANDFORM_2.EmailPlaceholder")}
                    />
                </FormContainer>
                <Spacer mode={"vertical"} px="5"></Spacer>
                <div className="small-copy warning-email-message"><div><WarningIcon /></div><div>{translate("REQUESTS.ContactsWarning")}</div></div>
                <Spacer mode={"vertical"} px="40"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            className: "add-contact-btn",
                            type: "primary",
                            onClick: handleSaveButtonClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
