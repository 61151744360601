import * as qs from "qs";

import { ActiveLabTestTypesResponseDTO } from "../../../../models/api/lab-tests/ActiveLabTestTypesResponseDTO";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CreateLabTestTypeRequestDTO } from "../models/CreateLabTestTypeRequestDTO";
import { EditLabTestTypeRequestDTO } from "../models/EditLabTestTypeRequestDTO";
import { EditLabTestTypeStatusDTO } from "../../../../models/api/lab-tests/EditLabTestTypeStatusDTO";
import { HttpClient } from "../../../../lib/infrastructure/http/HttpClient";
import { LabTestType } from "../../../../models/domain/lab-tests/LabTestType";
import { LabTestTypeDetails } from "../../../../models/domain/lab-tests/LabTestTypeDetails";
import { LabTestTypeDetailsResponse } from "../../../../models/domain/lab-tests/LabTestTypeDetailsResponse";
import { LabTestTypeDetailsResponseDTO } from "../models/LabTestTypeDetailsResponseDTO";
import { LabTestTypeResponse } from "../../../../models/domain/lab-tests/LabTestTypesResponse";
import { LabTestTypesResponseDTO } from "../../../../models/api/lab-tests/LabTestTypesResponseDTO";
import { QueryOptions } from "../../../../models/domain/lab-tests/QueryOptions";
import { TestTypesQueryOptions } from "./TestTypesQueryOptions";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};



export class TestTypesService {


    getLabTestTypes(locationId: number | string, queryOptions: TestTypesQueryOptions): Promise<LabTestTypeResponse> {
        return HttpClient.sessionRequest<LabTestTypesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/LabTestTypes`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                var mappedTypes = response.data.labTestTypes.map((p): LabTestType => ({
                    id: p.labTestTypeId,
                    namePt: p.namePt,
                    nameEn: p.nameEn,
                    isDisabled: p.isDisabled
                }));

                return {
                    testTypes: mappedTypes,
                    total: response.data.total,
                }
            })
            .catch((error) => { throw error; });
    }

    getActiveLabTestTypesByLocationId(locationId: number | string, queryOptions: TestTypesQueryOptions): Promise<LabTestTypeDetailsResponse> {
        return HttpClient.sessionRequest<ActiveLabTestTypesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/LabTestTypes/ActiveTestTypes`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                var mappedTypes = response.data.labTestTypes.map((p): LabTestTypeDetails => ({
                    id: p.labTestTypeId,
                    labTestTypeName: p.labTestTypeName,
                    isDisabled: p.isDisabled
                }));

                return {
                    testTypes: mappedTypes,
                    total: response.data.total,
                }
            })
            .catch((error) => { throw error; });
    }


    getLabTestTypeDetailsById(locationId: number | string, id: string): Promise<LabTestTypeDetailsResponseDTO> {
        return HttpClient.sessionRequest<LabTestTypeDetailsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/LabTestTypes/${id}`),
        })
        .then((response) => { return response.data })
        .catch((error) => { throw error; });
    }


    editTestType(locationId: number | string, id: string, request: EditLabTestTypeRequestDTO): Promise<void> {
        return HttpClient.sessionRequest<void>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/LabTestTypes/${id}`),
            data: request
        })
        .then((response) => { return ; })
        .catch((error) => { throw error; });
    }


    addTestType(locationId: number | string, request: CreateLabTestTypeRequestDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/LabTestTypes`),
            data: request
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }



    editLabTestTypeStatus(locationId: number | string, labTestTypeId: number, disable: boolean): Promise<void> {
        const requestBody: EditLabTestTypeStatusDTO = {
            disable: disable
        }

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/LabTestTypes/${labTestTypeId}/Status`),
            data: requestBody
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }

}
