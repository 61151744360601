
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { AnalysisRequestReasonTypesResponseDTO } from "../models/api/AnalysisRequestReasonTypesResponseDTO";
import { AnalysisRequestReasonType } from "../models/domain/AnalysisRequestReasonType";


const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class AnalysisRequestReasonTypeService {


    getAnalysisRequestReasonTypes(): Promise<AnalysisRequestReasonType[] | null> {

        return HttpClient.sessionRequest<AnalysisRequestReasonTypesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/AnalysisRequestReasonTypes`),
        })
            .then((response) => {

                if (!response?.data) return null;

                var mapped = response.data.reasonTypes.map((p): AnalysisRequestReasonType => ({
                    id: p.id,
                    reasonTypeName: p.reasonTypeName,
                    isDescriptionRequired: p.isDescriptionRequired
                }));
                return mapped;
            })
            .catch((error) => {
                throw error;
            });
    }
}