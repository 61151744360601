import { Optional, ValidatorFunction } from "../components/form/Form";

import { translate } from "../infrastructure/i18n/InternationalizationService";

export function validateRegex(regex: RegExp, translation: string): ValidatorFunction<Optional<string>> {

    return (value: string | undefined) => {
        if (!regex.test(value || "")) {
            return translate(translation);
        }
        return null;
    }
}


export function validateRegexOrEmpty(regex: RegExp, allowNull: boolean, translation: string): ValidatorFunction<Optional<string>> {

    return (value: string | undefined) => {
        if (allowNull && !value)
            return null;
        if (!regex.test(value || "")) {
            return translate(translation);
        }
        return null;
    }
}

export function validateRegexOrEmptyV2(regex: RegExp, allowNull: boolean, translation: string): ValidatorFunction<Optional<string>> {

    return (value: string | undefined) => {
        if (allowNull && !value)
            return null;
        if (!allowNull && (!value || value?.trim() === "")) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        if (!regex.test(value || "")) {
            return translate(translation);
        }
        return null;
    }
}



/***************
 * Regex expressions
 **************/

export const regex7Characters = /^\S{7}$/;
export const regexSamplesBatch = /^[A-Za-z0-9-]{7}(\/[A-Za-z0-9-]{1,2})?$/;
export const regexOnlyPositiveIntegers = /^$|^[0-9]\d*$/;
/* export const regexIntegerOrDecimals = /^(?!0+(\.0+)?$)\d+(\.\d+)?$/; */

export const regexIntegerOrDecimals = /^(?!0+([.,]0+)?$)\d+([.,]\d+)?$/; 
export const regexZeroIntegerOrDecimals = /^(0|[1-9]\d*)([.,]\d+)?$/; 
export const regexIntegerOrDecimalsV2 = /^([1-9]\d*)([.,]\d+)?$/; 