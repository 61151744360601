import { RequestDetails } from "../../models/domain/RequestDetails";
import { UpdateAnalysisRequestRequestDTO } from "../../models/api/UpdateAnalysisRequestRequestDTO";

export function AnalysisRequestToUpdateAnalysisRequestDTOMapper(analysisRequest: RequestDetails) {
    var mappedUpdateAnalysisRequest: UpdateAnalysisRequestRequestDTO = {
        analysisRequestReason: analysisRequest.analysisReason,
        analysisRequestReasonTypeId: analysisRequest.analysisRequestReasonTypeId,
        name: analysisRequest.name,
        waybill: analysisRequest.waybill,
        shippingByCourier: analysisRequest.shippingByCourier,
        company: analysisRequest.company,
        factory: analysisRequest.factory,
        industry: analysisRequest.industry,
        comments: analysisRequest.customerComments,
        clientRegistration: analysisRequest.clientRegistration,
        clientRegistrationDescription: analysisRequest.clientRegistrationDescription,
        analysisRequestIndustrialSectorId: analysisRequest.industrialSectorId,
        industrialSectorDescription: analysisRequest.industrialSectorOthersDescription,
        analysisRequestFilterTypeId: analysisRequest.filterTypeId,
        filterTypeDescription: analysisRequest.filterTypeOthersDescription,
        slurry: analysisRequest.slurry,
        containsHazardousMaterial: analysisRequest.hasHazardousMaterial,
        hazardousMaterialDescription: analysisRequest.hazardousMaterialDescription,
        containsCarcinogenicSubstances: analysisRequest.hasCarcinogenicSubstances,
        carcinogenicSubstancesDescription: analysisRequest.carcinogenicSubstancesDescription,
        otherInformation: analysisRequest.otherInformations,
        pmNumber: analysisRequest.pmNumber,
        position: analysisRequest.position,
        ketoProject: analysisRequest.ketoProject
    };
    return mappedUpdateAnalysisRequest;
}
