import "./PopupHeader.css";

import {
  useClosePopup,
  useWindowResize,
} from "../../../infrastructure/ui/UIServices";

import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import { ScalableIconButton } from "../../../components/buttons/ScalableIconButton";
import { Spacer } from "../../../components/separator/Spacer";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface IPopupHeaderProps {
  title: string;
  className?: string;
  subtitle?: string;
  navigateToOnCloseIcon?: string;
}

export function PopupHeader(props: IPopupHeaderProps) {
  const closePopup = useClosePopup();
  const navigate = useNavigate();
  const windowResize = useWindowResize();

  const renderPopupTitle = useCallback(
    (title: string, subtitle?: string) => {
      if (windowResize <= 425) return <div className="title">{title}</div>;
      else
        return (
          <div className="title-and-subtitle">
            <div>
              <h3>{title}</h3>
            </div>
            <div className="small-copy popup-subtitle">{subtitle}</div>
          </div>
        );
    },
    [windowResize]
  );

  return (
    <>
      <div
        className={"popup-header " + (props.className ? props.className : "")}
      >
        {renderPopupTitle(props.title, props.subtitle ? props.subtitle : "")}

        <ScalableIconButton
          className="popup-close-icon"
          icon={<CloseIcon />}
          onClick={() => {
            if (props.navigateToOnCloseIcon)
              navigate(props.navigateToOnCloseIcon);
            closePopup();
          }}
          size={30}
        />
      </div>
      <Spacer mode="vertical" px={18} />
    </>
  );
}
