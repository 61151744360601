import "./AddSamplePopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldDatePicker } from "../../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../../lib/components/loader/FullScreenLoader";
import { IAddRequestSamplePopup } from "./AddSampleARPopup";
import { Material } from "../../../../../../models/domain/materials/Material";
import { MaterialsService } from "../../../../../../services/MaterialsService";
import { Notification } from "../../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { RequestSampleCreateDTO } from "../../../../models/api/RequestSampleCreateDTO";
import { RequestSamplesService } from "../../../../services/RequestSamplesService";
import { Spacer } from "../../../../../../lib/components/separator/Spacer";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../../../lib/infrastructure/location/LocationServices";
import { validateRequiredIfValmetManufacturer } from "../../../../validators/ValidateRequiredIfValmetManufacturer";
import { validateStringIfConditional } from "../../../../validators/ValidateIfConditional";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { useServiceCallPro2 } from "../../../../../../services/UseServiceCall";

const labelSelector = (item: Material) => item.name + " - " + item.description;
const idSelector = (item: Material) => item.id;

var materialService = new MaterialsService();
var samplesSvc = new RequestSamplesService();



export function AddSampleDFPopup(props: IAddRequestSamplePopup) {
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const locationID = useGlobalLocation();
    const openPopup = usePopup();

    const [isSampleUsed, setIsSampleUsed] = useState<boolean>(false);

    const [getSampleByIDCall, getSampleByIDCallIsLoading] = useServiceCallPro2(samplesSvc, samplesSvc.getRequestSampleByIdExtended);
    const [cloneSampleCall, cloneSampleCallIsLoading] = useServiceCallPro2(samplesSvc, samplesSvc.cloneRequestSample);


    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
    const [isOtherManufacturer, setIsOtherManufacturer] = useState<boolean>(false);
    const [isValmetManufacturer, setIsValmetManufacturer] = useState<boolean>(true);

    const [materials, setMaterials] = useState<Material[]>([]);
    const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);

    const valmetMaterialFormControl = useFormControl<Material>({
        validators: [validateRequiredIfValmetManufacturer(isValmetManufacturer)],
        enableAutoValidate: true,
        isDisabled: false
    });

    const otherManufacturerFormControl = useFormControl<string>({
        validators: [validateStringIfConditional(isOtherManufacturer)],
        enableAutoValidate: true,
        isDisabled: false
    });

    const otherManufacturerMaterialFormControl = useFormControl<string>({ isDisabled: false });

    const positionFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const installedDateFormControl = useFormControl<DateTime>({
        enableAutoValidate: false
    });

    const removedDateFormControl = useFormControl<DateTime>({
        enableAutoValidate: false
    });

    const runningTimeFormControl = useFormControl<string>({
        isDisabled: false,
    });

    const removalReasonFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const batchNumberFormControl = useFormControl<string>({});


    useEffect(() => {
        if (!locationID) {
            openPopup(<ErrorPopup>{translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}</ErrorPopup>); return;
        }
        materialService.getActivesMaterials(locationID, { filterByIsActive: true })
            .then((data) => {
                setMaterials(data.materials);
                setIsContentLoading(false);
            })
            .catch((error) => {
                setIsContentLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [locationID]);





    useEffect(() => {

        if (!props.cloneSampleID || !props.requestID || materials.length < 1) return;

        getSampleByIDCall(`${locationID}`, `${props.requestID}`, `${props.cloneSampleID}`)
            .then((sample) => {

                if (!sample) return;

                setIsSampleUsed(sample?.isUsed ?? false);

                if (sample.manufacturerIsValmet) {
                    setIsValmetManufacturer(true);
                    setIsOtherManufacturer(false);
                } else {
                    setIsValmetManufacturer(false);
                    setIsOtherManufacturer(true)
                }

                valmetMaterialFormControl.setValue(materials.find((m) => m.id === sample.material?.id));

                if (sample.otherManufacturer)
                    otherManufacturerFormControl.setValue(sample.otherManufacturer)

                if (sample.otherMaterial)
                    otherManufacturerMaterialFormControl.setValue(sample.otherMaterial);

                if (sample.position)
                    positionFormControl.setValue(sample.position);

                if (sample.installationDate)
                    installedDateFormControl.setValue(DateTime.fromISO(sample.installationDate));

                if (sample.removalDate)
                    removedDateFormControl.setValue(DateTime.fromISO(sample.removalDate));

                if (sample.runningTime)
                    runningTimeFormControl.setValue(sample.runningTime);

                if (sample.reasonForRemoval)
                    removalReasonFormControl.setValue(sample.reasonForRemoval);

                if (sample.batchNumber)
                    batchNumberFormControl.setValue(sample.batchNumber);

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.cloneSampleID, props.requestID, materials]);






    const handleSaveButtonClicked = useCallback(() => {

        if (!AllValid(valmetMaterialFormControl.validate(), positionFormControl.validate(), removalReasonFormControl.validate(), otherManufacturerFormControl.validate())) return;
        if (!props.requestID || !props.locationID) return;

        setOnClickSubmit(true);

        var requestDto: RequestSampleCreateDTO = {
            isUsed: isSampleUsed,
            manufacturerIsValmet: isValmetManufacturer,
            materialId: valmetMaterialFormControl.value?.id || null,
            otherMaterial: otherManufacturerMaterialFormControl.value,
            position: positionFormControl.value,
            installationDate: installedDateFormControl.value?.toUTC().toISO() || undefined,
            removalDate: removedDateFormControl.value?.toUTC().toISO() || undefined,
            runningTime: runningTimeFormControl.value,
            reasonForRemoval: removalReasonFormControl.value,
            otherManufacturer: otherManufacturerFormControl.value,
            batchNumber: batchNumberFormControl.value
        }

        samplesSvc.addRequestSample(props.locationID, props.requestID, requestDto)
            .then(() => {
                setOnClickSubmit(false);
                props.onCompletedOperations();
                createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("REQUESTS.INFOANDFORM_2.AddSampleSuccess")}></Notification>);
                closePopup();
            }).catch((error) => {
                setOnClickSubmit(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })


    }, [valmetMaterialFormControl.validate, positionFormControl.validate, removalReasonFormControl.validate, otherManufacturerFormControl.validate,
    batchNumberFormControl.value, openPopup,
    valmetMaterialFormControl.value, otherManufacturerMaterialFormControl.value, otherManufacturerFormControl.value,
    positionFormControl.value, installedDateFormControl.value, removedDateFormControl.value, runningTimeFormControl.value, isSampleUsed,
    removalReasonFormControl.value, isValmetManufacturer, setOnClickSubmit, props.onCompletedOperations, closePopup, createNotification]);



    const handleCloneSampleClicked = useCallback(() => {

        if (!AllValid(valmetMaterialFormControl.validate(), positionFormControl.validate(), removalReasonFormControl.validate(), otherManufacturerFormControl.validate())) return;
        if (!props.requestID || !props.locationID || !props.cloneSampleID) return;

        var requestDto: RequestSampleCreateDTO = {
            isUsed: isSampleUsed,
            manufacturerIsValmet: isValmetManufacturer,
            materialId: valmetMaterialFormControl.value?.id || null,
            otherMaterial: otherManufacturerMaterialFormControl.value,
            position: positionFormControl.value,
            installationDate: installedDateFormControl.value?.toUTC().toISO() || undefined,
            removalDate: removedDateFormControl.value?.toUTC().toISO() || undefined,
            runningTime: runningTimeFormControl.value,
            reasonForRemoval: removalReasonFormControl.value,
            otherManufacturer: otherManufacturerFormControl.value,
            batchNumber: batchNumberFormControl.value
        };

        cloneSampleCall(props.locationID, props.requestID, props.cloneSampleID, requestDto)
            .then(() => {

                props.onCompletedOperations();
                createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("REQUESTS.INFOANDFORM_2.AddSampleSuccess")}></Notification>);
                closePopup();
            }).catch((error) => {

                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })


    }, [valmetMaterialFormControl.validate, positionFormControl.validate, removalReasonFormControl.validate, otherManufacturerFormControl.validate,
    batchNumberFormControl.value, openPopup, props.cloneSampleID, cloneSampleCall,
    valmetMaterialFormControl.value, otherManufacturerMaterialFormControl.value, otherManufacturerFormControl.value,
    positionFormControl.value, installedDateFormControl.value, removedDateFormControl.value, runningTimeFormControl.value, isSampleUsed,
    removalReasonFormControl.value, isValmetManufacturer, setOnClickSubmit, props.onCompletedOperations, closePopup, createNotification]);


    return (
        <PopupContainer className="add-request-sample">
            {onClickSubmit || cloneSampleCallIsLoading ? <FullScreenLoader /> : null}
            <PopupHeader
                title={`${translate("REQUESTS.AddNewSample")}`} subtitle={`${translate("REQUESTS.INFOANDFORM.Type")}: ` + `DF`}
            />
            <PopupContent isLoading={isContentLoading || getSampleByIDCallIsLoading}>
                <FormContainer>
                    <div className="separator">{translate("REQUESTS.INFOANDFORM.Manufacturer")}</div>
                    <div className="manufacturer">
                        <Checkbox
                            text={"Valmet"}
                            isChecked={isValmetManufacturer}
                            onClick={() => { setIsValmetManufacturer(true); setIsOtherManufacturer(false) }}
                        />
                        <Checkbox
                            text={translate("REQUESTS.INFOANDFORM.Other")}
                            isChecked={isOtherManufacturer}
                            onClick={() => { setIsOtherManufacturer(true); setIsValmetManufacturer(false) }}
                        />
                    </div>
                    <div className="separator">{translate("REQUESTS.POPUPS.SampleStatus")}</div>
                    <Checkbox
                        text={translate("REQUESTS.Used")}
                        isChecked={isSampleUsed}
                        onClick={() => setIsSampleUsed(!isSampleUsed)}
                    />
                    <div className="separator">{translate("REQUESTS.INFOANDFORM.Material")}</div>

                    <ConditionalRender if={isValmetManufacturer}>
                        <FormFieldSelectSingle
                            label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
                            placeholder={translate("SAMPLES.INFOANDFORM.SAPMaterialPlaceholder")}
                            disableFuzzySearch
                            options={materials}
                            labelSelector={labelSelector}
                            idSelector={idSelector}
                            formControl={valmetMaterialFormControl}
                            isClearSelectionAvailable={false}
                        />
                    </ConditionalRender>
                    <ConditionalRender if={isOtherManufacturer}>
                        <FormFieldTextInput
                            formControl={otherManufacturerFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Manufacturer")}
                            placeholder={translate("REQUESTS.INFOANDFORM.ManufacturerPlaceholder")}
                        />
                        <FormFieldTextInput
                            formControl={otherManufacturerMaterialFormControl}
                            label={translate("SAMPLES.INFOANDFORM.Material") + " (" + translate("COMMON.Optional") + ")"}
                            placeholder={translate("REQUESTS.INFOANDFORM.MaterialPlaceholder")}
                        />
                    </ConditionalRender>

                    <FormFieldTextInput
                        formControl={batchNumberFormControl}
                        label={translate("SAMPLES.INFOANDFORM.Batch") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
                    />

                    <FormFieldTextInput
                        formControl={positionFormControl}
                        label={translate("REQUESTS.INFOANDFORM.Position")}
                        placeholder={translate("REQUESTS.INFOANDFORM.PositionPlaceholder")}
                    />
                    <div className="separator">{translate("REQUESTS.INFOANDFORM.RunningTime")}</div>
                    <FormFieldDatePicker
                        formControl={installedDateFormControl}
                        label={translate("REQUESTS.INFOANDFORM.InstalledDate") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("REQUESTS.INFOANDFORM.InstalledDatePlaceholder")}
                        maxDate={removedDateFormControl.value}

                    />
                    <FormFieldDatePicker
                        formControl={removedDateFormControl}
                        label={translate("REQUESTS.INFOANDFORM.RemovedDate") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("REQUESTS.INFOANDFORM.RemovedDatePlaceholder")}
                        minDate={installedDateFormControl.value}
                    />
                    <FormFieldTextInput
                        formControl={runningTimeFormControl}
                        label={translate("REQUESTS.INFOANDFORM.Duration") + " (" + translate("COMMON.Optional") + ")"}
                        placeholder={translate("REQUESTS.INFOANDFORM.DurationPlaceholder")}
                    />
                    <div className="separator">{translate("REQUESTS.OtherData")}</div>
                    <FormFieldTextArea
                        formControl={removalReasonFormControl}
                        label={translate("REQUESTS.INFOANDFORM.RemovalReason")}
                        placeholder={translate("REQUESTS.INFOANDFORM.RemovalReasonPlaceholder")}
                    />

                </FormContainer>

                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Add"),
                            type: "primary",
                            onClick: props.cloneSampleID ? handleCloneSampleClicked : handleSaveButtonClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
