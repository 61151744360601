import "./SamplesHomePage.css";

import { CardButton } from "../../../lib/components/buttons/CardButton";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ReactComponent as DeliverSampleIcon } from "../../../lib/assets/icons/deliver-samples.svg";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../models/api/gate/Permission";
import { ReactComponent as ViewSamplesIcon } from "../../../lib/assets/icons/view-samples.svg";
import { ReactComponent as PowerBiIcon } from "../../../lib/assets/icons/powerbi-light-no-margin.svg";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useNavigate } from "react-router-dom";

/***** ICONS ******/
const deliverSamplePermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_REGISTER_SAMPLES];

const viewSamplesPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_ACCESS,
  Permission.LOCATION_REGISTER_SAMPLES];


export function SamplesHomePage() {
  const navigate = useNavigate();

  const { isLoadingPermissions: isLoadingDeliverSamples, hasPermission: canDeliverSample } = useHasPermissions(deliverSamplePermissions);
  const { isLoadingPermissions: isLoadingCanViewSamples, hasPermission: canViewSamples } = useHasPermissions(viewSamplesPermissions);

  return (
    <PageLayout
      tabTitle={`laMP - ${translate("SAMPLES.Samples")}`}
      pageTitle={translate("SAMPLES.Samples")}
      className="samples-home"
      showBreadcrumb
    >


      
      <ConditionalRender if={!isLoadingDeliverSamples && canDeliverSample}>
        <CardButton
          text={translate("SAMPLES.DeliverSample")}
          icon={<DeliverSampleIcon />}
          onClick={() => {
            navigate("delivered");
          }}
        />
      </ConditionalRender>



      <ConditionalRender if={!isLoadingCanViewSamples && canViewSamples} >
        <CardButton
          text={translate("SAMPLES.ViewSamples")}
          icon={<ViewSamplesIcon />}
          onClick={() => { navigate("view") }}
        /></ConditionalRender>



      <CardButton
        text={translate("SAMPLES.ViewSamplesOnHold")}
        icon={<PowerBiIcon />}
        onClick={() => window.open("https://app.powerbi.com/reportEmbed?reportId=beb9acb3-131e-4151-853d-3d5c5f4f1b92&autoAuth=true&ctid=65f8dbd7-eb30-4ddc-88b3-f1f6fbea6ba2", "_blank")}
      />
    </PageLayout >
  );
}
