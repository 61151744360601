import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { regexOnlyPositiveIntegers, validateRegex } from "../../../../../../lib/validators/ValidateRegex";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../../../lib/components/popup/ErrorPopup";
import { FacilitiesQueryOptions } from "../../../../../../models/api/facilities/FacilitiesQueryOptions";
import { FacilityDTO } from "../../../../../../models/api/facilities/FacilitiesResponseDTO";
import { FacilityService } from "../../../../../../services/FacilityService";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { IGenericSampleFormsProps } from "../EditSampleDetailsPopup";
import { Material } from "../../../../../../models/domain/materials/Material";
import React from "react";
import { Sample } from "../../../../models/domain/Sample";
import { SampleForm } from "../../../../models/domain/SampleForm";
import { hasOneElementSelected } from "../../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../../../lib/infrastructure/location/LocationServices";
import { usePopup } from "../../../../../../lib/infrastructure/ui/UIServices";
import { useSearchParams } from "react-router-dom";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";

const materialLabelSelector = (item: Material) => item.name + " - " + item.description;
const materialIdSelector = (item: Material) => item.id;

const facilityLabelSelector = (item: FacilityDTO) => item.name;
const facilityIdSelector = (item: FacilityDTO) => item.facilityId;


export interface IThreadsSampleFormsProps {
    sample: Sample | undefined;
    materials: Material[];
    facilities: FacilityDTO[];
}


export const ThreadsSampleForm = forwardRef(
    function ThreadsSampleForms(props: IThreadsSampleFormsProps, ref: React.ForwardedRef<SampleForm>) {

        const materialFormControl = useFormControl<Material>({
            validators: [hasOneElementSelected()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const supplierFormControl = useFormControl<string>({
            isDisabled: false,
        });

        const batchFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const numSpoolsFormControl = useFormControl<string>({
            validators: [validateRegex(regexOnlyPositiveIntegers, "COMMON.FORM.VALIDATIONS.PositiveNumber")],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const facilityFormControl = useFormControl<FacilityDTO>({
            isDisabled: false,
        });

        const waybillFormControl = useFormControl<string>({
            isDisabled: false,
        });

        const observationsFormControl = useFormControl<string>({
            isDisabled: false,
        });





        useEffect(() => {
            if (!props.materials && !props.sample && !props.facilities)
                return;

            batchFormControl.setValue(props.sample?.batch);
            supplierFormControl.setValue(props.sample?.supplier);
            numSpoolsFormControl.setValue("" + props.sample?.numSpools);
            waybillFormControl.setValue(props.sample?.waybill);
            observationsFormControl.setValue(props.sample?.observations);
            materialFormControl.setValue(props.materials.find((m) => m.id === props.sample?.materialId));
            facilityFormControl.setValue(props.facilities.find((f) => f.facilityId === props.sample?.facilityId));

        }, [props.materials, props.sample, props.facilities]);





        useImperativeHandle(ref, () => {
            return {
                validate() {

                    return AllValid(materialFormControl.validate(), supplierFormControl.validate(),
                        batchFormControl.validate(), numSpoolsFormControl.validate(),
                        facilityFormControl.validate(), waybillFormControl.validate(),
                        observationsFormControl.validate())

                },
                value() {
                    return {
                        materialId: materialFormControl.value?.id ?? -1,
                        supplier: supplierFormControl.value,
                        batch: batchFormControl.value,
                        numberOfSpools: numSpoolsFormControl.value ? parseInt(numSpoolsFormControl.value) : 0,
                        facilityId: facilityFormControl.value?.facilityId,
                        waybill: waybillFormControl.value,
                        observations: observationsFormControl.value,
                    }
                }
            }
        }, [materialFormControl.validate, supplierFormControl.validate, batchFormControl.validate,
        numSpoolsFormControl.validate, facilityFormControl.validate, waybillFormControl.validate, observationsFormControl.validate,
        materialFormControl.value, supplierFormControl.value, batchFormControl.value, numSpoolsFormControl.value, facilityFormControl.value,
        waybillFormControl.value, observationsFormControl.value, AllValid]);





        return (
            <>
                <FormFieldSelectSingle
                    label={translate("SAMPLES.INFOANDFORM.Facility")}
                    placeholder={translate("SAMPLES.INFOANDFORM.FacilityPlaceholder")}
                    options={props.facilities}
                    labelSelector={facilityLabelSelector}
                    idSelector={facilityIdSelector}
                    formControl={facilityFormControl}
                    isClearSelectionAvailable={false}
                />
                <FormFieldSelectSingle
                    label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
                    placeholder={translate(
                        "SAMPLES.INFOANDFORM.SAPMaterialPlaceholder"
                    )}
                    disableFuzzySearch
                    options={props.materials}
                    labelSelector={materialLabelSelector}
                    idSelector={materialIdSelector}
                    formControl={materialFormControl}
                    isClearSelectionAvailable={false}
                />
                <FormFieldTextInput
                    formControl={batchFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Batch")}
                    placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
                />
                <FormFieldTextInput
                    formControl={supplierFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Supplier") + " (" + translate("COMMON.Optional") + ")"}
                    placeholder={translate("SAMPLES.INFOANDFORM.SupplierPlaceholder")}
                />
                <FormFieldTextInput
                    formControl={numSpoolsFormControl}
                    label={translate("SAMPLES.INFOANDFORM.NumSpools") + " (" + translate("COMMON.Optional") + ")"}
                    placeholder={translate("SAMPLES.INFOANDFORM.NumSpoolsPlaceholder")}
                />
                <FormFieldTextInput
                    formControl={waybillFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Waybill")}
                    placeholder={translate("SAMPLES.INFOANDFORM.WaybillPlaceholder")}
                />
                <FormFieldTextArea
                    formControl={observationsFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Observations") + " (" + translate("COMMON.Optional") + ")"}
                    placeholder={translate("SAMPLES.INFOANDFORM.ObservationsPlaceholder")}
                />
            </>
        );
    });
