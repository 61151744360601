import { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import React from "react";
import { Sample } from "../../../../models/domain/Sample";
import { SampleForm } from "../../../../models/domain/SampleForm";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { GetPackageAreaTypesArray, PackageAreaTypesAsEnum } from "../../../../models/domain/PackageAreaTypesEnum";
import { hasOneElementSelected } from "../../../../../../lib/validators/ValidateSelectSingle";
import { SelectOption } from "../../../../../requests/models/domain/SelectOption";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";

export interface IOthersSampleFormsProps {
    sample: Sample | undefined;
}

const areaTypeLabelSelector = (item: SelectOption) => item.description;
const areaTypeIdSelector = (item: SelectOption) => item.id;

export const PackageSampleForm = forwardRef(
    function PackageSampleForm(props: IOthersSampleFormsProps, ref: React.ForwardedRef<SampleForm>) {

        const packageAreaTypes = useMemo(() => {
            return GetPackageAreaTypesArray();
        }, [GetPackageAreaTypesArray]);

        const waybillFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const supplierFormControl = useFormControl<string>({
            isDisabled: false,
        });

        const sourceFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const areaTypeControl = useFormControl<SelectOption>({
            validators: [hasOneElementSelected()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const batchFormControl = useFormControl<string>({
            isDisabled: false,
        });


        useEffect(() => {

            if (!props.sample) return;

            sourceFormControl.setValue(props.sample?.source);
            waybillFormControl.setValue(props.sample?.waybill);
            supplierFormControl.setValue(props.sample.supplier);
            batchFormControl.setValue(props.sample.batch);
            areaTypeControl.setValue(packageAreaTypes.find((a) => a.id === (props.sample?.area)?.toString()))
        }, [props.sample]);



        useImperativeHandle(ref, () => {
            return {
                validate() {
                    return AllValid(sourceFormControl.validate(), waybillFormControl.validate(), areaTypeControl.validate())
                },
                value() {
                    return {
                        supplier: trimString(supplierFormControl.value),
                        waybill: trimString(waybillFormControl.value),
                        origin: trimString(sourceFormControl.value),
                        area: areaTypeControl.value?.id ? PackageAreaTypesAsEnum[areaTypeControl.value.id] : undefined,
                        batch: trimString(batchFormControl.value)
                    }
                }
            }
        }, [sourceFormControl.validate, waybillFormControl.validate, areaTypeControl.validate, areaTypeControl.value,
        sourceFormControl.value, waybillFormControl.value, supplierFormControl.value, batchFormControl.value]);



        return (
            <>
                <FormFieldSelectSingle
                    label={translate("SAMPLES.INFOANDFORM.Area")}
                    placeholder={translate("SAMPLES.INFOANDFORM.AreaPlaceholder")}
                    options={packageAreaTypes}
                    labelSelector={areaTypeLabelSelector}
                    idSelector={areaTypeIdSelector}
                    formControl={areaTypeControl}
                    isClearSelectionAvailable={false}
                />
                <FormFieldTextInput
                    formControl={batchFormControl}
                    label={
                        translate("SAMPLES.INFOANDFORM.Batch") +
                        " (" +
                        translate("COMMON.Optional") +
                        ")"
                    }
                    placeholder={translate("SAMPLES.INFOANDFORM.BatchPlaceholder")}
                />

                <FormFieldTextInput
                    formControl={supplierFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Supplier") + " (" + translate("COMMON.Optional") + ")"}
                    placeholder={translate("SAMPLES.INFOANDFORM.SupplierPlaceholder")}
                />

                <FormFieldTextInput
                    formControl={waybillFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Waybill")}
                    placeholder={translate(
                        "SAMPLES.INFOANDFORM.WaybillPlaceholder"
                    )}
                />

                <FormFieldTextArea
                    formControl={sourceFormControl}
                    label={translate("SAMPLES.INFOANDFORM.Source")}
                    placeholder={translate("SAMPLES.INFOANDFORM.SourcePlaceholder")}
                />

            </>
        );
    });
