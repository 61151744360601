import { Route, Routes } from "react-router-dom";
import { DeliverSamplesOverviewPage } from "../features/samples/pages/deliver-samples/DeliverSamplesOverviewPage";
import { DeliverSamplesTypePage } from "../features/samples/pages/deliver-samples/DeliverSamplesTypePage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from "../models/api/gate/Permission";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { SampleDetailsPage } from "../features/samples/pages/sample-details/SampleDetailsPage";
import { SamplesHomePage } from "../features/samples/pages/SamplesHomePage";
import { ViewSamplesPage } from "../features/samples/pages/ViewSamplesPage";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";


const moduleDefinition: ModuleDefinition = {
  basePath: "/samples",
  routes: [
    {
      name: () => translate("SAMPLES.Samples"),
      pathPattern: "/",
    },
    {
      name: () => translate("SAMPLES.ViewSamples"),
      pathPattern: "/view",
    },
    {
      name: () => "##SAMPLE##",
      pathPattern: "/view/:id",
    },
    {
      name: () => translate("SAMPLES.LastSamplesDelivered"),
      pathPattern: "/delivered",
    },
    {
      name: () => translate("SAMPLES.DeliverSample"),
      pathPattern: "/new",
    },
    {
      name: () => "##SAMPLE##",
      pathPattern: "/delivered/:id",
    },
  ],
};

const topPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_ACCESS
];

const basePermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_ACCESS,
  Permission.LOCATION_REGISTER_SAMPLES
];




export function SamplesModule() {
  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);





  //TODO: Para as permissões, dividir em sub-módulos
  return (
    <>
      <RouteGuard permissions={basePermissions}>
        <Routes>
          <Route path="/" element={<SamplesHomePage />} />
          <Route path="/delivered" element={<DeliverSamplesOverviewPage />} />
          <Route path="/new" element={<DeliverSamplesTypePage />} />
          <Route path="/new/:sampleType/:cloneId" element={<DeliverSamplesTypePage />} />
          <Route path="/new/:sampleType" element={<DeliverSamplesTypePage />} />
          <Route path="/delivered/:id" element={<SampleDetailsPage />} />
          <Route path="/view/:id" element={<SampleDetailsPage />} />
          <Route path="/view" element={<ViewSamplesPage />} />
        </Routes>
      </RouteGuard>
    </>
  );
}