import "./AssReqCardSample.css";

import { FormSection } from "../../../../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../../../../lib/components/info-block/InfoBlock";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { AnalysisRequestSampleDetails } from "../../../../../requests/models/domain/AnalysisRequestSampleDetails";
import { DateTimeHelper } from "../../../../../../lib/utils/DateTimeHelper";


interface IAssReqCardSampleDRYERProps {
    data: AnalysisRequestSampleDetails | null;
}

export function AssReqCardSampleDRYER(props: IAssReqCardSampleDRYERProps) {

    return <div className="request-sample-info">
        <FormSection>
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.Manufacturer")}
                value={props.data?.manufacturerIsValmet ? "Valmet" : props.data?.otherManufacturer || "-"}
            />
            <InfoBlock
                label={translate("REQUESTS.Used")}
                value={props.data?.isUsed ? translate("COMMON.Yes") : translate("COMMON.No")}
            />
        </FormSection>
        <FormSection title={translate("REQUESTS.INFOANDFORM.Material")}>
            <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Material")}
                value={props.data?.material?.number ? props.data?.material.number + " - " + props.data?.material.name : props.data?.otherMaterial || "-"}
            />
            <InfoBlock label={translate("SAMPLES.INFOANDFORM.Batch")}
                value={props.data?.batchNumber ?? "-"} />
        </FormSection>
        <FormSection title={translate("REQUESTS.INFOANDFORM.RunningTime")}>
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.InstalledDate")}
                value={props.data?.installationDate ?
                    DateTimeHelper.formatDateTime("" + props.data?.installationDate)
                    : "-"}
            />
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.RemovedDate")}
                value={props.data?.removalDate ?
                    DateTimeHelper.formatDateTime("" + props.data?.removalDate)
                    : "-"}
            />
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.Duration")}
                value={props.data?.runningTime || "-"}
            />
        </FormSection>
        <FormSection title={translate("REQUESTS.OtherData")}>
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.RemovalReason")}
                value={props.data?.reasonForRemoval || "-"}
            />
            <InfoBlock
                label={translate("REQUESTS.INFOANDFORM.PerformanceComments")}
                value={props.data?.otherInfo || "-"}
            />
        </FormSection>
    </div>;
}