import "./CreateRequestDetails.css";

import { ClientRegistrationEnum, GetClientRegistrationEnumByKey, GetClientRegistrationsArray } from "../../../models/domain/ClientRegistrationEnum";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { AnalysisRequestService } from "../../../services/AnalysisRequestService";
import { AnalysisRequestTypeEnum } from "../../../models/domain/AnalysisRequestTypeEnum";
import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { IGenericRequestDetailsFormsProps } from "../CreateRequestPage";
import { Permission } from "../../../../../models/api/gate/Permission";
import { RequestDetails } from "../../../models/domain/RequestDetails";
import { RequestForm } from "../../../models/domain/RequestForm";
import { SelectOption } from "../../../models/domain/SelectOption";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useHasPermissions } from "../../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";
import { validateClientRegistrationNumber } from "../../../validators/ValidateClientRegistrationNumber";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

var analysisRequestSvc = new AnalysisRequestService();

const dateNow = DateTime.now();

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];
const labelClientRegistrationSelector = (item: SelectOption) => item.description;
const idClientRegistrationSelector = (item: SelectOption) => item.id;



export const CreateRequestDetailsDryer = forwardRef(
    function CreateRequestDetailsDryer(props: IGenericRequestDetailsFormsProps, ref: React.ForwardedRef<RequestForm>) {
        const openPopup = usePopup();

        const [requestDetails, setRequestDetails] = useState<RequestDetails>();

        const clientRegistrationTypes = useMemo(() => { return GetClientRegistrationsArray(); }, [GetClientRegistrationsArray]);

        const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);
        const [isRegistedOnCompass, setIsRegistedOnCompass] = useState<boolean>(false);

        const requestNameFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const companyFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });


        const factoryFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const creationRequestDateFormControl = useFormControl<DateTime>({
            validators: [validateDateTime()],
            enableAutoValidate: false,
            initialValue: dateNow,
        });

        const clientRegistrationControl = useFormControl<SelectOption>({
            validators: [hasOneElementSelected()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const clientNumberControl = useFormControl<string>({
            validators: [validateClientRegistrationNumber(clientRegistrationControl)],
            enableAutoValidate: true,
            isDisabled: false,
        });


        const positionMachineNumberFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const positionMachineFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });




        /*****************
         * SERVICE CALLS 
        ****************/


        const [getAnalysisRequestByIdCall, getAnalysisRequestByIdCallIsLoading] = useServiceCallPro2(analysisRequestSvc, analysisRequestSvc.getAnalysisRequestById);





        /****************************
         * DATA REQUESTS
        *****************************/



        const getAnalysisRequestById = useCallback(() => {

            if (!props.locationId || !props.cloneId) return;

            getAnalysisRequestByIdCall(`${props.locationId}`, `${props.cloneId}`)
                .then((data) => {
                    if (!data) return;
                    setRequestDetails(data);
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
                });
        }, [props.locationId, props.cloneId, getAnalysisRequestByIdCall, setRequestDetails, openPopup]);




        /****************************
         * DATA MANIPULATION EFFECTS
        *****************************/




        useEffect(() => {
            getAnalysisRequestById();
        }, []);



        useEffect(() => {
            if (!props.locationId || !props.cloneId) return;

            if (requestDetails?.isRegisteredInCompass) setIsRegistedOnCompass(requestDetails.isRegisteredInCompass);

            requestNameFormControl.setValue(requestDetails?.name);
            companyFormControl.setValue(requestDetails?.company);
            factoryFormControl.setValue(requestDetails?.factory);
            if (requestDetails?.createdDate)
                creationRequestDateFormControl.setValue(DateTime.fromISO(requestDetails?.createdDate));

            clientRegistrationControl.setValue(clientRegistrationTypes.find((a) => a.id === (requestDetails?.clientRegistration)?.toString()))
            clientNumberControl.setValue(requestDetails?.clientRegistrationDescription);

            positionMachineNumberFormControl.setValue(requestDetails?.pmNumber);
            positionMachineFormControl.setValue(requestDetails?.position);

        }, [props.locationId, props.cloneId, requestDetails]);




        useEffect(() => {
            if (!isLoadingPermissions && !hasPermission)
                creationRequestDateFormControl.setIsDisabled(true);
        }, [isLoadingPermissions, hasPermission]);




        useEffect(() => {
            if (clientRegistrationControl.value?.id === (ClientRegistrationEnum.NOTREGISTERED).toString())
                clientNumberControl.setValue(undefined);
        }, [clientRegistrationControl.value?.id]);





        useEffect(() => {
            if (clientRegistrationControl.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString())
                clientNumberControl.setIsDisabled(false);
            else
                clientNumberControl.setIsDisabled(true);
        }, [clientRegistrationControl.value?.id]);






        useImperativeHandle(ref, () => {
            return {
                validate() {
                    return AllValid(
                        clientNumberControl.validate(),
                        clientRegistrationControl.validate(), requestNameFormControl.validate(),
                        companyFormControl.validate(), factoryFormControl.validate(),
                        creationRequestDateFormControl.validate(), positionMachineNumberFormControl.validate(),
                        positionMachineFormControl.validate());
                },
                value() {
                    return {
                        requestType: AnalysisRequestTypeEnum.DRYER,
                        name: requestNameFormControl.value,
                        createdDate: creationRequestDateFormControl.value,
                        company: companyFormControl.value,
                        factory: factoryFormControl.value,
                        clientRegistration: clientRegistrationControl.value?.id ? GetClientRegistrationEnumByKey[parseInt(clientRegistrationControl.value.id)] : ClientRegistrationEnum.NOTREGISTERED,
                        clientRegistrationDescription: clientNumberControl.value,
                        pmNumber: positionMachineNumberFormControl.value,
                        position: positionMachineFormControl.value
                    }
                }
            }
        }, [requestNameFormControl.validate, companyFormControl.validate, clientRegistrationControl.validate, clientNumberControl.validate,
        factoryFormControl.validate, creationRequestDateFormControl.validate, positionMachineNumberFormControl.validate,
        positionMachineFormControl.validate, requestNameFormControl.value, creationRequestDateFormControl.value,
        companyFormControl.value, factoryFormControl.value, isRegistedOnCompass, clientNumberControl.value, clientRegistrationControl.value?.id,
        positionMachineNumberFormControl.value, positionMachineFormControl.value]);




        /****************************
         * CSS & HTML
        *****************************/



        return (
            <CardContainer className="create-request-form-container">
                <div className="subtitle">{translate("REQUESTS.RequestDetails")}</div>
                <div className="create-request-form">
                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            formControl={requestNameFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Name")}
                            placeholder={translate("REQUESTS.INFOANDFORM.NamePlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldDatePicker
                            formControl={creationRequestDateFormControl}
                            label={translate("REQUESTS.INFOANDFORM.CreationDate")}
                            maxDate={dateNow}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.Client")}</div>

                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            formControl={companyFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Company")}
                            placeholder={translate("REQUESTS.INFOANDFORM.CompanyPlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldTextInput
                            formControl={factoryFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Factory")}
                            placeholder={translate("REQUESTS.INFOANDFORM.FactoryPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="request-info-inputs">
                        <FormFieldSelectSingle
                            label={translate("REQUESTS.INFOANDFORM_2.ClientRegistration")}
                            placeholder={translate("COMMON.SelectOneOptionPlaceholders")}
                            options={clientRegistrationTypes || []}
                            labelSelector={labelClientRegistrationSelector}
                            idSelector={idClientRegistrationSelector}
                            formControl={clientRegistrationControl}
                            isClearSelectionAvailable={false}
                            alternativeStyle={true}
                        />

                        <ConditionalRender if={clientRegistrationControl.value?.id && clientRegistrationControl.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString() ? true : false}>
                            <FormFieldTextInput
                                formControl={clientNumberControl}
                                label={translate("REQUESTS.INFOANDFORM.ClientNumber")}
                                placeholder={translate("REQUESTS.INFOANDFORM.ClientNumberPlaceholder")}
                                alternativeStyle={true}
                            />
                        </ConditionalRender>
                    </div>


                    <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.MachineInformation")}</div>
                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            label={translate("REQUESTS.INFOANDFORM.PositionMachine")}
                            formControl={positionMachineNumberFormControl}
                            placeholder={translate("REQUESTS.INFOANDFORM.PositionMachinePlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldTextInput
                            label={translate("REQUESTS.INFOANDFORM.Position")}
                            formControl={positionMachineFormControl}
                            placeholder={translate("REQUESTS.INFOANDFORM.PositionPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>
                </div>
            </CardContainer>
        );
    }); 