import "./LargeButton.css";

import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";

export interface ILargeButtonProps {
  text: string;
  type: "primary" | "secondary" | "tertiary";
  isDisabled?: boolean;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function LargeButton(props: ILargeButtonProps) {
  const largeButtonCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("large-button")
      .addConditional(props.type, props.type)
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className, props.type, props.isDisabled]);

  const handleClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation();
      if (props.isDisabled) return;
      props.onClick && props.onClick(ev);
    },
    [props.onClick, props.isDisabled]
  );

  return (
    <div className={largeButtonCss} onClick={handleClicked}>
      {props.text}
    </div>
  );
}
