import "./RequestDetailsPage.css";

import { AddAttachments, Attachment } from "./AddAttachments";
import { ContextualMenu, ContextualMenuItems } from "../../../../lib/components/contextual-menu/ContextualMenu";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { AddContacts } from "./AddContacts";
import { AddSamples } from "./AddSamples";
import { AnalysisRequestService } from "../../services/AnalysisRequestService";
import { AnalysisRequestStatusEnum } from "../../models/domain/AnalysisRequestStatusEnum";
import { AssociateTestsSamplesCopy } from "./AssociateTestsSamplesCopy";
import { AssociationTestsSampleService } from "../../services/AssociationTestsSampleService";
import { Button } from "../../../../lib/components/buttons/Button";
import { CancelAnalysisRequestDTO } from "../../models/api/CancelAnalysisRequestDTO";
import { ReactComponent as CancelIcon } from "../../../../lib/assets/icons/reject-dark.svg";
import { CancelRequestPopup } from "./popup/CancelRequestPopup";
import { ChangeAnalysisRequestStatusDTO } from "../../models/api/ChangeAnalysisRequestStatusDTO";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { Contact } from "../../../samples/models/domain/Contact";
import { ReactComponent as DoneIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { ReactComponent as EditIcon } from "../../../../lib/assets/icons/editar.svg";
import { EditRequestDetailsPopup } from "./popup/EditRequestDetailsPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { LabTest } from "../../../../models/domain/lab-tests/LabTest";
import { ReactComponent as MoreActions } from "../../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { RequestAttachmentsService } from "../../services/RequestAttachmentsService";
import { RequestContactsService } from "../../services/RequestContactsService";
import { RequestDetails } from "../../models/domain/RequestDetails";
import { RequestDetailsCard } from "./RequestDetailsCard";
import { RequestSampleSummaryDTO } from "../../models/api/RequestSampleSummaryDTO";
import { RequestSamplesService } from "../../services/RequestSamplesService";
import { RequestTypeTranslationTerms } from "../../models/domain/AnalysisRequestTypeEnum";
import { SampleTestsAssociationsSummary } from "../../models/domain/SampleTestsAssociationsSummary";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { TestsService } from "../../../admin/testes/services/TestsService";
import { TitledCardContainer } from "../../../../lib/layouts/containers/card/TitledCardContainer";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useIdentityUserDisplayName } from "../../../../lib/infrastructure/identity/hooks/useIdentityUserDisplayName";
import { useUpdatePageData } from "../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";

var samplesSvc = new RequestSamplesService();
var analysisRequestSvc = new AnalysisRequestService();
var sampleTestsAssociationsSvc = new AssociationTestsSampleService();
var testsService = new TestsService();
var contactsService = new RequestContactsService();
var attachmentsSvc = new RequestAttachmentsService();


const permissionsToCancel = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];
const permissionsToEdit = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];

const permissionToCloseRequest = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];

const permissionToReopenRequest = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];



export function RequestDetailsPage() {
  const { requestId } = useParams();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const createNotification = useCreateNotification();
  const locationID = useGlobalLocation();
  const updateRouteData = useUpdatePageData();
  const useIdentity = useIdentityUserDisplayName();
  const navigate = useNavigate();
  const windowResize = useWindowResize();

  const [contextualMenuItems, setContextualMenuItems] = useState<ContextualMenuItems[]>([]);
  const [activeLaboratoryTests, setActiveLaboratoryTests] = useState<LabTest[]>([]);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [requestSamples, setSamples] = useState<RequestSampleSummaryDTO[]>([]);
  const [samplesTestsAssociations, setSamplesTestsAssociations] = useState<SampleTestsAssociationsSummary>();

  const [requestDetails, setRequestDetails] = useState<RequestDetails>();

  const { isLoadingPermissions: isLoadingPermissionsToCloseRequest, hasPermission: hasPermissionToCloseRequest } = useHasPermissions(permissionToCloseRequest);
  const { isLoadingPermissions: isLoadingPermissionsToReopenRequest, hasPermission: hasPermissionToReopenRequest } = useHasPermissions(permissionToReopenRequest);
  const { isLoadingPermissions: isLoadingPermissionsToCancel, hasPermission: hasPermissionToCancel } = useHasPermissions(permissionsToCancel);
  const { isLoadingPermissions: isLoadingPermissionsToEdit, hasPermission: hasPermissionToEdit } = useHasPermissions(permissionsToEdit);

  /* LOADING STATES */
  const [isLoadingRequestDetails, setIsLoadingRequestDetails] = useState<boolean>(true);
  const [isLoadingContacts, setIsLoadingContacts] = useState<boolean>(true);
  const [isLoadingSamples, setIsLoadingSamples] = useState<boolean>(true);
  const [isLoadingAssociations, setIsLoadingAssociations] = useState<boolean>(true);
  const [isLoadingAttachments, setIsLoadingAttachments] = useState<boolean>(true);

  const [isShowFullScreenLoader, setIsShowFullScreenLoader] = useState<boolean>(false);


  useEffect(() => {
    if (!requestId || !locationID) return;

    getRequestData();
    getActiveLabTests();
    getRequestSamples();
    getRequestContacts();
    getSampleTestsAssociations();
    getRequestAttachments();
  }, [requestId, locationID]);



  const getActiveLabTests = useCallback(() => {
    if (!requestId || !locationID) return;

    testsService//TODO
      .getLabTestsForAnalysisRequests(locationID, { filterByIsActive: false }) // TODO: ATTENTION to this "Filter By Is Active".
      .then((response) => {
        setActiveLaboratoryTests(response.labTests);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, requestId, setActiveLaboratoryTests, openPopup]);



  const getRequestData = useCallback(() => {
    if (!requestId || !locationID) return;
    setIsLoadingRequestDetails(true);

    analysisRequestSvc
      .getAnalysisRequestById(locationID, requestId)
      .then((data) => {
        if (!data) return;
        setRequestDetails(data);
        setIsLoadingRequestDetails(false);
        updateRouteData(["##REQUEST##", data.name || ""]);
      })
      .catch((error) => {
        setIsLoadingRequestDetails(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [requestId, locationID, setRequestDetails, setIsLoadingRequestDetails, updateRouteData, openPopup]);



  const getRequestSamples = useCallback(() => {
    if (!locationID || !requestId) return;

    setIsLoadingSamples(true);

    samplesSvc.getRequestSamples(`${locationID}`, requestId)
      .then((response) => {
        setSamples(response);
        setIsLoadingSamples(false);
      })
      .catch((error) => {
        setIsLoadingSamples(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })
  }, [setSamples, locationID, requestId, openPopup, setIsLoadingSamples]);



  const getSampleTestsAssociations = useCallback(() => {

    if (!locationID || !requestId) return;
    setIsLoadingAssociations(true);

    sampleTestsAssociationsSvc.getSampleTestAssociations(`${locationID}`, requestId)
      .then((data) => {
        setSamplesTestsAssociations(data);
        setIsLoadingAssociations(false);
      })
      .catch((error) => {
        setIsLoadingAssociations(false);
        setSamplesTestsAssociations(undefined);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, requestId, setSamplesTestsAssociations, openPopup, setIsLoadingAssociations]);



  const getRequestContacts = useCallback(() => {

    if (!locationID || !requestId) return;
    setIsLoadingContacts(true);
    contactsService.getRequestContacts(`${locationID}`, requestId)
      .then((contacts) => {
        setContacts(contacts);
        setIsLoadingContacts(false);
      }).catch(error => {
        setIsLoadingContacts(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, requestId, setContacts, openPopup, setIsLoadingContacts]);



  const getRequestAttachments = useCallback(() => {

    if (!locationID || !requestId) return;
    setIsLoadingAttachments(true);

    attachmentsSvc.getRequestsAttachments(`${locationID}`, requestId)
      .then((attachs) => {
        setAttachments(attachs);
        setIsLoadingAttachments(false);
      }).catch(error => {
        setIsLoadingAttachments(false)
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, requestId, setAttachments, setIsLoadingAttachments, openPopup]);




  const cancelAnalysisRequest = useCallback((cancelReason: CancelAnalysisRequestDTO, changeRoute: boolean) => {

    setIsShowFullScreenLoader(true);

    analysisRequestSvc.cancelAnalysisRequest("" + locationID, "" + requestId, cancelReason)
      .then((_) => {
        setIsShowFullScreenLoader(false);
        closePopup();
        createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate("REQUESTS.POPUPS.CancelRequestSuccess")}></Notification>);

        if (changeRoute) {
          navigate("/requests/view");
          return;
        }
        getRequestData();
      })
      .catch((error) => {
        setIsShowFullScreenLoader(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, setIsShowFullScreenLoader, requestId, getRequestData, closePopup, createNotification, openPopup, navigate]);



  const submitAnalysisRequest = useCallback((changeStatus: ChangeAnalysisRequestStatusDTO, changeRoute: boolean, confirmTextSuccess: string) => {

    setIsShowFullScreenLoader(true);
    analysisRequestSvc.changeAnalysisRequestStatus("" + locationID, "" + requestId, changeStatus)
      .then((_) => {
        setIsShowFullScreenLoader(false);
        closePopup();
        createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate(confirmTextSuccess)}
        ></Notification>);

        if (changeRoute) {
          navigate("/requests/view");
          return;
        }
        getRequestData();
      })
      .catch((error) => {
        setIsShowFullScreenLoader(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });

  }, [locationID, requestId, getRequestData, closePopup, createNotification, openPopup, navigate, setIsShowFullScreenLoader]);


  const handleEditButtonClicked = useCallback(() => {
    if (!requestDetails) return;
    if (requestDetails.requestId && requestDetails.requestType)
      openPopup(
        <EditRequestDetailsPopup
          locationId={locationID ? locationID : 0}
          requestId={requestDetails.requestId}
          typeId={requestDetails.requestType}
          onOperationsCompleted={() => { getRequestData(); }}
        />
      );
  }, [requestDetails, locationID, openPopup, getRequestData]);



  const renderAnalysisReasonAndWaybillCardContainer = useMemo(() => {
    return (
      <div className="form-waybill-analysis-reason-container">
        <TitledCardContainer
          isLoading={isLoadingRequestDetails}
          title={translate("REQUESTS.INFOANDFORM.AnalysisReason")}
          className="item analysis-reason"
        >

          {requestDetails?.analysisRequestReasonType ?
            <InfoBlock
              label={translate("REQUESTS.ANALYSISREASONTYPE.AnalysisReasonType")}
              value={requestDetails?.analysisRequestReasonType}
            ></InfoBlock> : null}
          {requestDetails?.analysisReason ?
            <InfoBlock
              label={translate("COMMON.Description")}
              value={requestDetails?.analysisReason || ""}
            ></InfoBlock>
            : null}

        </TitledCardContainer>

        <TitledCardContainer
          isLoading={isLoadingRequestDetails}
          title={translate("REQUESTS.INFOANDFORM.Transport")}
          className="item waybill"
        >
          <div className="waybill-info">
            <InfoBlock
              label={translate("REQUESTS.INFOANDFORM.ShippingMethod")}
              value={
                requestDetails?.shippingByCourier
                  ? translate("REQUESTS.INFOANDFORM.DeliveredByCourier")
                  : translate("REQUESTS.INFOANDFORM.DeliveredPersonally")
              }
            />
            <InfoBlock
              label={translate("REQUESTS.INFOANDFORM.Waybill")}
              value={requestDetails?.waybill || "-"}
            ></InfoBlock>
          </div>
        </TitledCardContainer>
      </div>
    );
  }, [requestDetails?.analysisReason, isLoadingRequestDetails, requestDetails?.waybill, requestDetails?.shippingByCourier]);



  const renderCancelRequestButton = useMemo(() => {
    if (requestDetails?.requestStatus === AnalysisRequestStatusEnum.DRAFT || requestDetails?.requestStatus === AnalysisRequestStatusEnum.REGISTERED) {

      if (isLoadingPermissionsToCancel) return null;

      if (hasPermissionToCancel || requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()) {
        const btnInfo = {
          label: translate("COMMON.Cancel"),
          icon: <CancelIcon />,
          navigateTo: "",
          onClick: () => {
            if (!locationID || !requestDetails?.requestId) return;
            if (requestDetails.requestStatus === AnalysisRequestStatusEnum.DRAFT) {
              openPopup(
                <WarningPopup
                  className="cancel-request-warning-popup"
                  onDismissClicked={() => { closePopup(); }}
                  onConfirmClicked={() => { cancelAnalysisRequest({ cancelReason: "", status: AnalysisRequestStatusEnum.DRAFT }, true) }}
                  dismissText={translate("COMMON.Cancel")}
                  confirmText={translate("REQUESTS.POPUPS.CancelReq")}
                >
                  {translate("REQUESTS.POPUPS.CancelTestConfirmText")}
                </WarningPopup>
              );
            }
            else
              openPopup(<CancelRequestPopup onChange={(dto) => cancelAnalysisRequest(dto, false)}></CancelRequestPopup>)
          }
        };

        if (windowResize > 768)
          return <Button
            text={btnInfo.label}
            type="secondary"
            onClick={btnInfo.onClick}
            className="reject-btn"
          />;
        else {
          if (!contextualMenuItems.some(item => item.label === btnInfo.label))
            setContextualMenuItems(prevItems => [...prevItems, btnInfo]);
        }
      }
    }
    return null;
  }, [isLoadingPermissionsToCancel, locationID, requestDetails?.requestId, requestDetails?.requestStatus, cancelAnalysisRequest,
    hasPermissionToCancel, requestDetails?.createdBy?.email, useIdentity.userDisplayEmail, closePopup, openPopup, windowResize, contextualMenuItems]);


  //CHECKED
  const renderEditRequestButton = useMemo(() => {

    if (isLoadingPermissionsToEdit || !requestDetails?.requestStatus) return null;

    var authorHasPermission = requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase()
      && (requestDetails.requestStatus === AnalysisRequestStatusEnum.DRAFT || requestDetails.requestStatus === AnalysisRequestStatusEnum.REGISTERED)

    var analystHasPermission = hasPermissionToEdit && (requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE);

    if (authorHasPermission || analystHasPermission) {
      const btnInfo = {
        label: translate("COMMON.Edit"),
        icon: <EditIcon />,
        navigateTo: "",
        onClick: handleEditButtonClicked
      };

      if (windowResize > 768)
        return (<Button
          type="secondary"
          text={btnInfo.label}
          onClick={btnInfo.onClick}
        />);
      else {
        if (!contextualMenuItems.some(item => item.label === btnInfo.label))
          setContextualMenuItems(prevItems => [...prevItems, btnInfo]);
      }
    }
  }, [requestDetails?.requestStatus, hasPermissionToEdit, requestDetails?.createdBy?.email, useIdentity.userDisplayEmail, isLoadingPermissionsToEdit, handleEditButtonClicked, windowResize, contextualMenuItems]);




  const isAuthorRequestAuthenticated = useMemo(() => {

    return requestDetails?.createdBy?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase();

  }, [requestDetails?.createdBy?.email, useIdentity.userDisplayEmail]);





  //CHECKED
  const renderSubmitRequestButton = useMemo(() => {
    if (requestDetails?.requestStatus === AnalysisRequestStatusEnum.DRAFT && isAuthorRequestAuthenticated) {
      if (windowResize > 768)
        return <Button
          text={translate("REQUESTS.SubmitRequest")}
          type="primary"
          onClick={() => { submitAnalysisRequest({ status: AnalysisRequestStatusEnum.REGISTERED }, true, "REQUESTS.POPUPS.SubmitRequestSuccess") }}
        />;
      else
        return (<IconButton
          icon={<DoneIcon />}
          type="primary"
          onClick={() => { submitAnalysisRequest({ status: AnalysisRequestStatusEnum.REGISTERED }, true, "REQUESTS.POPUPS.SubmitRequestSuccess") }}
        />);
    }
  }, [requestDetails?.requestStatus, submitAnalysisRequest, windowResize, isAuthorRequestAuthenticated]);


  //CHECKED
  const renderCloseRequestButton = useMemo(() => {
    if (requestDetails?.requestStatus === AnalysisRequestStatusEnum.READYTOCLOSE && hasPermissionToCloseRequest && !isLoadingPermissionsToCloseRequest) {
      if (windowResize > 768)
        return <Button
          text={translate("REQUESTS.CloseRequest")}
          type="primary"
          onClick={() => { submitAnalysisRequest({ status: AnalysisRequestStatusEnum.DONE }, false, "REQUESTS.RequestClosed") }}
        />;
      else
        return (<IconButton
          icon={<DoneIcon />}
          type="primary"
          onClick={() => { submitAnalysisRequest({ status: AnalysisRequestStatusEnum.DONE }, false, "REQUESTS.RequestClosed") }}
        />);
    }
  }, [requestDetails?.requestStatus, submitAnalysisRequest, hasPermissionToCloseRequest, isLoadingPermissionsToCloseRequest, windowResize]);



  const renderReopenRequestButton = useMemo(() => {
    if ((requestDetails?.requestStatus === AnalysisRequestStatusEnum.DONE || requestDetails?.requestStatus === AnalysisRequestStatusEnum.CANCELED) && hasPermissionToReopenRequest && !isLoadingPermissionsToReopenRequest) {
      if (windowResize > 768)
        return <Button
          text={translate("REQUESTS.ReopenRequest")}
          type="primary"
          onClick={() => { submitAnalysisRequest({ status: AnalysisRequestStatusEnum.REGISTERED }, false, "REQUESTS.RequestReopened") }}
        />;
      else
        return (<IconButton
          icon={<DoneIcon />}
          type="primary"
          onClick={() => { submitAnalysisRequest({ status: AnalysisRequestStatusEnum.REGISTERED }, false, "REQUESTS.RequestReopened") }}
        />);
    }
  }, [requestDetails?.requestStatus, submitAnalysisRequest, hasPermissionToReopenRequest, isLoadingPermissionsToReopenRequest, windowResize]);



  const renderActionButtons = useMemo(() => {
    if (windowResize > 768)
      return <>{renderEditRequestButton}
        {renderCancelRequestButton}
        {renderSubmitRequestButton}
        {renderCloseRequestButton}
        {renderReopenRequestButton}</>
    else if (requestDetails?.requestStatus !== AnalysisRequestStatusEnum.CANCELED && requestDetails?.requestStatus !== AnalysisRequestStatusEnum.DONE)
      return <><ContextualMenu items={contextualMenuItems}>
        <ScalableIconButton size={30} icon={<MoreActions />} />
      </ContextualMenu>
        {renderSubmitRequestButton}
        {renderCloseRequestButton}</>
  }, [renderCancelRequestButton, renderCloseRequestButton, renderEditRequestButton, renderSubmitRequestButton, windowResize, contextualMenuItems, requestDetails?.requestStatus, renderReopenRequestButton])

  return (
    <PageLayout
      tabTitle={"laMP - " + translate("REQUESTS.RequestData")}
      pageTitle={requestDetails?.requestType ? translate(RequestTypeTranslationTerms[requestDetails?.requestType]) : ""}
      className={"request-details"}
      showBreadcrumb
      actions={renderActionButtons}
    >
      {isShowFullScreenLoader ? <FullScreenLoader /> : null}

      <RequestDetailsCard
        info={requestDetails}
        isLoading={isLoadingRequestDetails} />

      {renderAnalysisReasonAndWaybillCardContainer}

      <AddContacts
        isLoading={isLoadingContacts}
        contacts={contacts}
        onCompletedOperations={getRequestContacts}
        locationId={`${locationID}`}
        requestId={requestId}
        requestDetails={requestDetails}
      />

      <AddSamples
        isLoading={isLoadingSamples}
        onCompletedOperations={() => { getRequestSamples(); getRequestData(); getSampleTestsAssociations(); }}
        requestDetails={requestDetails}
        info={requestSamples}
        requestId={requestId}
        locationID={"" + locationID}
      />

      <ConditionalRender if={requestSamples.length > 0} >
        <AssociateTestsSamplesCopy
          isLoading={isLoadingAssociations}
          onCompletedOperations={getSampleTestsAssociations}
          requestSamples={requestSamples}
          associations={samplesTestsAssociations}
          locationId={`${locationID}`}
          requestId={`${requestId}`}
          labTests={activeLaboratoryTests}
          requestDetails={requestDetails} />
      </ConditionalRender>

      <AddAttachments
        onCompletedOperations={getRequestAttachments}
        isLoading={isLoadingAttachments}
        attachmentsInfo={attachments}
        contactsInfo={contacts}
        locationId={`${locationID}`}
        requestId={requestId}
        requestDetails={requestDetails}
      />
    </PageLayout>
  );
}