export enum LabOperatorStatus{
    ENABLE = 1,
    DISABLE = 2
}

export const LabOperatorStatusTranslationTerms: { [key: string]: string } = {
    1: "GLOBALADMIN.EMPLOYEES.INFO.Enabled",
    2: "GLOBALADMIN.EMPLOYEES.INFO.Disabled"
}

export const LabOperatorStatusAsEnum: { [key: string]: LabOperatorStatus } = {
    "1": LabOperatorStatus.ENABLE,
    "2": LabOperatorStatus.DISABLE
};

