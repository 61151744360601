import "./NavBar.css";

import { AccountMenu, IAccountMenuProps } from "../account-menu/AccountMenu";
import {
  ContextualMenu,
  ContextualMenuItems,
} from "../contextual-menu/ContextualMenu";

import {
  InternationalizationService,
  translate,
} from "../../infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";

import { ReactComponent as Logo } from "../../assets/logo/valmet-logo-white.svg";
import { useNavigate } from "react-router-dom";
import { ConfigurationProvider } from "../../infrastructure/configuration/ConfigurationProvider";


export function getAppVersion() {
  return ConfigurationProvider.getConfiguration().App.AppVersion;
}


export interface AccountInfo {
  info: IAccountMenuProps;
  menuItems?: ContextualMenuItems[];
}

interface INavBar {
  additionalOptions?: React.ReactNode;
  accountInfo?: AccountInfo;
  languages?: ContextualMenuItems[];
}

export function NavBar(props: INavBar) {
  const navigate = useNavigate();

  const renderAdditionalOptions = useMemo(() => {
    if (props.additionalOptions) {
      return (
        <div className="additional-options">{props.additionalOptions}</div>
      );
    }
  }, [props.additionalOptions]);




  const renderOptions = useMemo(() => {
    if (props.accountInfo || props.languages) {
      return (
        <>
          <div className="options">
            {props.accountInfo ? (
              <ContextualMenu items={props.accountInfo.menuItems || []}>
                <AccountMenu
                  name={props.accountInfo.info.name}
                  email={props.accountInfo.info.email}
                  personaStatus={props.accountInfo.info.personaStatus}
                />
              </ContextualMenu>
            ) : null}
          </div>
          {props.languages ? (
            <div className="language">
              <ContextualMenu items={props.languages}>
                {InternationalizationService.getLocale().split("-")[1]}
              </ContextualMenu>
            </div>
          ) : null}
        </>
      );
    }
  }, [props.accountInfo, props.languages]);

  return (
    <div className="navbar">
      <div className="navbar-logo-project">
        <div className="navbar-logo" onClick={() => navigate("/")}>
          <Logo />
        </div>
        <div className="subtitle project">
          {translate("COMMON.ProjectName") + " "}
          <div className="app-version">{getAppVersion()}</div>
        </div>
      </div>
      <div className="navbar-options">
        {renderAdditionalOptions}
        {renderOptions}
      </div>
    </div>
  );
}
