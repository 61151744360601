import "./EmptyState.css";
import { ReactComponent as NoResultsIcon } from "../../assets/icons/search-empty-icon.svg";
import React from "react";
import { translate } from "../../infrastructure/i18n/InternationalizationService";

interface IEmptyStateProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export function EmptyState(props: IEmptyStateProps) {
  return (
    <div className="no-results">
      <NoResultsIcon />
      <div className="subtitle no-results-title">
        {props.title ? props.title : translate("COMMON.TABLE.NoResults")}
      </div>
      <div className="small-copy no-results-subtitle">
        {props.subtitle
          ? props.subtitle
          : translate("COMMON.TABLE.NoResultsText")}
      </div>
      {props.children}
    </div>
  );
}
