import "./AddInfo.css";

import {
  Button,
  IButtonProps,
} from "../../../../lib/components/buttons/Button";
import {
  IResponsiveDataTableProps,
  ResponsiveDataTable,
} from "../../../../lib/components/table/ResponsiveDataTable";

import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { CssClassnameBuilder } from "../../../../lib/utils/CssClassnameBuilder";
import { IFileUploadButtonProps } from "../../../../lib/components/file/FileUploadButton";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { Loader } from "../../../../lib/components/loader/Loader";
import { useMemo } from "react";

interface IAddInfoProps<T> {
  className?: string;
  title?: string;
  button: IButtonProps /* | IFileUploadButtonProps; */
  tableInfo?: IResponsiveDataTableProps<T>;
  buttonType?: "normal" | "file-upload";
  isLoading?: boolean;
}


export function AddInfo<T>(props: IAddInfoProps<T>) {
  const renderTableOrButton = useMemo(() => {
    if (props.tableInfo?.items.length) {
      return (
        <ResponsiveDataTable
          className=""
          items={props.tableInfo.items}
          totalitems={props.tableInfo.totalitems}
          columnDefinitions={props.tableInfo.columnDefinitions}
          isLoading={props.tableInfo.isLoading}
          onClickRow={props.tableInfo.onClickRow}
        />
      );
    } else {
      return (
        <div className="add-info-btn">
          <Button
            text={props.button.text}
            type={props.button.type}
            onClick={props.button.onClick}
            icon={props.button.icon}
            className={props.button.className}
            isDisabled={props.button.isDisabled}
          />
        </div>
      );
    }
  }, [props]);

  const renderHeaderButton = useMemo(() => {
    if (props.tableInfo?.items.length) {
      return (
        <IconButton
          type={
            props.button.type !== "tertiary" ? props.button.type : "secondary"
          }
          icon={props.button.icon}
          onClick={props.button.onClick}
          isDisabled={props.button.isDisabled}
        />
      );
    }
  }, [props.tableInfo?.items, props.button]);

  const addInfoCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("add-info")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  return (
    <CardContainer className={addInfoCss}>
      <div className="add-info-header">
        <div className="subtitle">{props.title || ""}</div>
        {renderHeaderButton}
      </div>
      {props.isLoading ? <Loader /> :
        <div className="add-info-content">{renderTableOrButton}</div>}
    </CardContainer>
  );
}
