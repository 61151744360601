import { AnalysisRequestSampleDetails } from "../../../../models/domain/AnalysisRequestSampleDetails";
import { DateTimeHelper } from "../../../../../../lib/utils/DateTimeHelper";
import { FormContainer } from "../../../../../../lib/layouts/containers/form/FormContainer";
import { FormSection } from "../../../../../../lib/components/form/section/FormSection";
import { InfoBlock } from "../../../../../../lib/components/info-block/InfoBlock";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";

export interface IRequestSampleDetailsContainer {
    data?: AnalysisRequestSampleDetails;
}


export function SampleWFDetailsContainer(props: IRequestSampleDetailsContainer) {

    return (
        <FormContainer>
            <FormSection unsetFlexGrow>
                <InfoBlock label={translate("REQUESTS.INFOANDFORM.Manufacturer")}
                    value={props.data?.manufacturerIsValmet ? "Valmet" : props.data?.otherManufacturer || "-"} />
                <InfoBlock label={translate("REQUESTS.Used")}
                    value={props.data?.isUsed ? translate("COMMON.Yes") : translate("COMMON.No")} />
            </FormSection>

            <FormSection title={translate("REQUESTS.INFOANDFORM.Material")} unsetFlexGrow>
                <InfoBlock label={translate("REQUESTS.INFOANDFORM.Material")}
                    value={props.data?.material?.id ? `${props.data.material.number} - ${props.data.material.name}` : props.data?.otherMaterial || "-"} />
                <InfoBlock label={translate("SAMPLES.INFOANDFORM.Batch")}
                    value={props.data?.batchNumber ?? "-"} />
                <InfoBlock label={translate("REQUESTS.INFOANDFORM.Position")}
                    value={props.data?.position ?? "-"} />
            </FormSection>

            <FormSection title={translate("REQUESTS.INFOANDFORM.RunningTime")} unsetFlexGrow>
                <InfoBlock
                    label={translate("REQUESTS.INFOANDFORM.InstalledDate")}
                    value={props.data?.installationDate ? DateTimeHelper.formatDateTime("" + props.data?.installationDate) : "-"}
                />
                <InfoBlock
                    label={translate("REQUESTS.INFOANDFORM.RemovedDate")}
                    value={props.data?.removalDate ? DateTimeHelper.formatDateTime("" + props.data?.removalDate) : "-"}
                />
                <InfoBlock
                    label={translate("REQUESTS.INFOANDFORM.Duration")}
                    value={props.data?.runningTime || "-"}
                />
            </FormSection>
            <FormSection title={translate("REQUESTS.OtherData")} unsetFlexGrow>
                <InfoBlock
                    label={translate("REQUESTS.INFOANDFORM.RemovalReason")}
                    value={props.data?.reasonForRemoval || "-"}
                />
            </FormSection>
        </FormContainer>)
}