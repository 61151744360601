import "./CreateRequestPage.css";

import {
  DeliverTypeEnum,
  GetDeliverTypeArray,
} from "../../models/domain/DeliverTypeEnum";
import {
  IFormControl,
  Optional,
  ValidatorFunction,
  useFormControl,
} from "../../../../lib/components/form/Form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCreateNotification, usePopup, useWindowResize, } from "../../../../lib/infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { AnalysisRequestService } from "../../services/AnalysisRequestService";
import { AnalysisRequestToAnalysisRequestDTOMapper } from "../../services/mappers/AnalysisRequestToAnalysisRequestDTOMapper";
import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { CreateRequestDetailsAR } from "./create-request-details-container/CreateRequestDetailsAR";
import { CreateRequestDetailsDF } from "./create-request-details-container/CreateRequestDetailsDF";
import { CreateRequestDetailsDryer } from "./create-request-details-container/CreateRequestDetailsDryer";
import { CreateRequestDetailsPP } from "./create-request-details-container/CreateRequestDetailsPP";
import { CreateRequestDetailsWF } from "./create-request-details-container/CreateRequestDetailsWF";
import { ReactComponent as DoneIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { RequestDetails } from "../../models/domain/RequestDetails";
import { RequestForm } from "../../models/domain/RequestForm";
import { SelectOption } from "../../models/domain/SelectOption";
import { hasOneElementSelected } from "../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useServiceCallPro2 } from "../../../../services/UseServiceCall";
import { AnalysisRequestReasonTypeService } from "../../services/AnalysisRequestReasonTypeService";
import { AnalysisRequestReasonType } from "../../models/domain/AnalysisRequestReasonType";
import { validateAnalysisRequestReasonRequiredIfOthers } from "../../validators/validateAnalysisRequestReasonRequiredIfOthers";


var analysisRequestService = new AnalysisRequestService();
var reasonTypeSvc = new AnalysisRequestReasonTypeService();

const labelDeliverTypeSelector = (item: SelectOption) => item.description;
const idDeliverTypeSelector = (item: SelectOption) => item.id;

const labelAnalysisReasonTypeSelector = (item: AnalysisRequestReasonType) => item.reasonTypeName;
const idAnalysisReasonTypeSelector = (item: AnalysisRequestReasonType) => item.id;

export interface IGenericRequestDetailsFormsProps {
  cloneId?: string | undefined;
  locationId?: number | undefined;
}



export function validateWaybillFormIfCourierShippingMethod(deliverTypeRequestFormControl: IFormControl<SelectOption>): ValidatorFunction<Optional<string>> {
  return (value: string | undefined) => {
    if (deliverTypeRequestFormControl.value?.id === `${DeliverTypeEnum.Courier}` && (!value || value?.trim() === ""))
      return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
    return null;
  };
}

export function CreateRequestPage() {
  const requestDetailsRef = useRef<RequestForm>(null);


  const createNotification = useCreateNotification();
  const locationID = useGlobalLocation();
  const navigate = useNavigate();
  const openPopup = usePopup();
  const { type, cloneId } = useParams();
  const windowResize = useWindowResize();

  const [requestDetails, setRequestDetails] = useState<RequestDetails>();
  const [requestReasonTypes, setRequestReasonTypes] = useState<AnalysisRequestReasonType[] | null>();

  const [isShowFullScreenLoader, setIsShowFullScreenLoader] = useState<boolean>(false);

  const analysisReasonTypeFormControl = useFormControl<AnalysisRequestReasonType>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false
  });

  const analysisReasonFormControl = useFormControl<string>({
    validators: [validateAnalysisRequestReasonRequiredIfOthers(analysisReasonTypeFormControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const deliverTypeRequestFormControl = useFormControl<SelectOption>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const waybillFormControl = useFormControl<string>({
    validators: [validateWaybillFormIfCourierShippingMethod(deliverTypeRequestFormControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });



  /*****************
   * SERVICE CALLS 
  ****************/

  const [getAnalysisRequestByIdCall, getAnalysisRequestByIdCallIsLoading] = useServiceCallPro2(analysisRequestService, analysisRequestService.getAnalysisRequestById);
  const [getAnalysisReasonTypesCall, getAnalysisReasonTypesCallIsLoading] = useServiceCallPro2(reasonTypeSvc, reasonTypeSvc.getAnalysisRequestReasonTypes);



  /****************************
   * DATA REQUESTS
  *****************************/


  useEffect(() => {
    getAnalysisRequestReasonTypes();
  }, []);



  const getAnalysisRequestById = useCallback(() => {

    if (!locationID || !cloneId) return;

    getAnalysisRequestByIdCall(`${locationID}`, `${cloneId}`)
      .then((data) => {
        if (!data) return;
        setRequestDetails(data);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, cloneId, getAnalysisRequestByIdCall, setRequestDetails, openPopup]);




  const getAnalysisRequestReasonTypes = useCallback(() => {

    getAnalysisReasonTypesCall().then((data) => {

      if (!data) return;
      setRequestReasonTypes(data);

    }).catch((error) => {

      if (!error) return;
      openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
    });
  }, [setRequestReasonTypes, getAnalysisReasonTypesCall, openPopup]);




  useEffect(() => {
    if (!requestReasonTypes || requestReasonTypes?.length < 1) return;
    if (!requestDetails?.analysisRequestReasonTypeId) return;

    var foundReasonType = requestReasonTypes.find((t) => t.id === requestDetails?.analysisRequestReasonTypeId);
    analysisReasonTypeFormControl.setValue(foundReasonType);

  }, [requestDetails?.analysisRequestReasonTypeId, requestReasonTypes]);



  const handleCreateRequestClicked = useCallback(() => {
    if (!locationID) {
      openPopup(
        <ErrorPopup>
          {translate("COMMON.FORM.VALIDATIONS.InvalidLocationId")}
        </ErrorPopup>
      );
      return;
    }

    if (!requestDetailsRef.current) return;

    if (AllValid(analysisReasonFormControl.validate(), deliverTypeRequestFormControl.validate(), requestDetailsRef.current.validate(), waybillFormControl.validate(), analysisReasonTypeFormControl.validate())) {

      setIsShowFullScreenLoader(true);

      var shippedByCourier = deliverTypeRequestFormControl.value?.id === DeliverTypeEnum.Courier.toString() ? true : false;

      var mapped = AnalysisRequestToAnalysisRequestDTOMapper(
        requestDetailsRef.current.value(),
        analysisReasonFormControl.value ?? "",
        waybillFormControl.value ?? "",
        shippedByCourier,
        analysisReasonTypeFormControl.value?.id ?? -1
      );

      analysisRequestService.addAnalysisRequest(locationID, mapped).then((response) => {
        setIsShowFullScreenLoader(false);
        createNotification(<Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={translate("REQUESTS.POPUPS.CreateRequestSuccess")}
        />
        );

        navigate("/requests/view/" + `${response?.requestId}`);

      })
        .catch((error) => {
          setIsShowFullScreenLoader(false);
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [requestDetailsRef.current, deliverTypeRequestFormControl.value, deliverTypeRequestFormControl.value?.id, requestDetailsRef.current?.validate,
  analysisReasonFormControl.validate, deliverTypeRequestFormControl.validate, requestDetailsRef.current?.value,
  analysisReasonFormControl.value, analysisReasonTypeFormControl.validate, analysisReasonTypeFormControl.value,
  waybillFormControl.value, waybillFormControl.validate, navigate, setIsShowFullScreenLoader]);



  /****************************
   * DATA MANIPULATION EFFECTS
  *****************************/


  useEffect(() => {
    getAnalysisRequestById();
  }, []);



  useEffect(() => {
    if (!locationID || !cloneId || !requestDetails) return;

    analysisReasonFormControl.setValue(requestDetails?.analysisReason);

    if (requestDetails?.shippingByCourier)
      deliverTypeRequestFormControl.setValue(deliverRequestTypes.find((f) => f.id === (DeliverTypeEnum.Courier).toString()));
    else
      deliverTypeRequestFormControl.setValue(deliverRequestTypes.find((f) => f.id === (DeliverTypeEnum.Personally).toString()))

    if (requestDetails?.waybill)
      waybillFormControl.setValue(requestDetails.waybill)

  }, [locationID, cloneId, requestDetails]);


  useEffect(() => {
    analysisReasonFormControl.validate();
  }, [analysisReasonTypeFormControl.value]);



  useEffect(() => {
    if (!deliverTypeRequestFormControl.value) {
      waybillFormControl.setIsDisabled(true);
      return;
    }

    if (parseInt(deliverTypeRequestFormControl.value.id) === DeliverTypeEnum.Personally || !deliverTypeRequestFormControl.value)
      waybillFormControl.setIsDisabled(true);
    else waybillFormControl.setIsDisabled(false);

  }, [deliverTypeRequestFormControl.value]);






  const renderRequestDetails = useMemo(() => {
    switch (type) {
      case "ar":
        return <CreateRequestDetailsAR ref={requestDetailsRef} cloneId={cloneId} locationId={locationID} />;
      case "wf":
        return <CreateRequestDetailsWF ref={requestDetailsRef} cloneId={cloneId} locationId={locationID} />;
      case "pp":
        return <CreateRequestDetailsPP ref={requestDetailsRef} cloneId={cloneId} locationId={locationID} />;
      case "df":
        return <CreateRequestDetailsDF ref={requestDetailsRef} cloneId={cloneId} locationId={locationID} />;
      case "dryer":
        return <CreateRequestDetailsDryer ref={requestDetailsRef} cloneId={cloneId} locationId={locationID} />;
      default:
        return <CreateRequestDetailsAR ref={requestDetailsRef} cloneId={cloneId} locationId={locationID} />;
    }
  }, [type, cloneId, locationID]);




  const renderActionButtons = useMemo(() => {
    if (windowResize > 768)
      return <Button
        type="primary"
        text={translate("COMMON.Continue")}
        onClick={handleCreateRequestClicked}
      />
    else
      return <IconButton
        icon={<DoneIcon />}
        type="primary"
        onClick={handleCreateRequestClicked}
      />
  }, [windowResize, handleCreateRequestClicked])



  const deliverRequestTypes = useMemo(() => { return GetDeliverTypeArray(); }, [GetDeliverTypeArray]);


  /****************************
   * CSS & HTML
  *****************************/



  return (
    <PageLayout
      tabTitle={`laMP - ${translate("REQUESTS.TYPES." + type?.toUpperCase())}`}
      pageTitle={type ? translate("REQUESTS.TYPES." + type.toUpperCase()) : ""}
      className={"create-request"}
      showBreadcrumb
      actions={renderActionButtons}
    >
      {isShowFullScreenLoader || getAnalysisReasonTypesCallIsLoading ? <FullScreenLoader /> : null}
      {renderRequestDetails}

      <div className="form-waybill-analysis-reason">
        <CardContainer className="item analysis-reason">
          <div className="subtitle">
            {translate("REQUESTS.INFOANDFORM.AnalysisReason")}
          </div>
          <FormFieldSelectSingle
            label={translate("REQUESTS.ANALYSISREASONTYPE.AnalysisReasonType")}
            placeholder={translate("COMMON.FORM.VALIDATIONS.SelectOneOption")}
            options={requestReasonTypes || []}
            labelSelector={labelAnalysisReasonTypeSelector}
            idSelector={idAnalysisReasonTypeSelector}
            formControl={analysisReasonTypeFormControl}
            isClearSelectionAvailable={false}
            alternativeStyle={true}
          />
          <FormFieldTextArea
            formControl={analysisReasonFormControl}
            placeholder={translate("REQUESTS.INFOANDFORM.AnalysisReasonPlaceholder")}
            alternativeStyle={true}
          />
        </CardContainer>

        <CardContainer className="item waybill">
          <div className="subtitle">
            {translate("REQUESTS.INFOANDFORM.Shipping")}
          </div>
          <FormFieldSelectSingle
            label={translate("REQUESTS.INFOANDFORM.ShippingMethod")}
            placeholder={translate("COMMON.FORM.VALIDATIONS.SelectOneOption")}
            options={deliverRequestTypes || []}
            labelSelector={labelDeliverTypeSelector}
            idSelector={idDeliverTypeSelector}
            formControl={deliverTypeRequestFormControl}
            isClearSelectionAvailable={false}
            alternativeStyle={true}
          />
          <FormFieldTextInput
            className="waybill"
            formControl={waybillFormControl}
            label={translate("REQUESTS.INFOANDFORM.Waybill")}
            placeholder={translate("REQUESTS.INFOANDFORM.WaybillPlaceholder")}
            alternativeStyle={true}
          />
        </CardContainer>
      </div>
    </PageLayout>
  );
}
