import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { Contact } from "../../samples/models/domain/Contact";
import { ContactsCreateDTO } from "../models/api/ContactsCreateDTO";
import { ContactsResponseDTO } from "../models/api/ContactsResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class RequestContactsService {

    addRequestContacts(locationId: string, requestId: string, /* name: string */ email: string): Promise<void> {

        var requestDto: ContactsCreateDTO = {
/*             name: name, */
            email: email
        };

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Contacts`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getRequestContacts(locationId: string, requestId: string): Promise<Contact[]> {
        return HttpClient.sessionRequest<ContactsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Contacts`)
        })
            .then((response) => {

                var mappedContacts = response.data.contacts.map(ob => ({
                    id: ob.id,
                    email: ob.email,
                    name: ob.name
                }));

                return mappedContacts;
            })
            .catch((error) => { throw error; });
    }



    deleteRequestContact(locationId: string, requestId: string, contactId: number): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/AnalysisRequests/${requestId}/Contacts/${contactId}`),
        })
            .then((_) => { })
            .catch((error) => { throw error; });
    }
}