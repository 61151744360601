import "./SampleDetailsPage.css";

import {
  InternationalizationService,
  translate,
} from "../../../../lib/infrastructure/i18n/InternationalizationService";
import {
  SampleStatusEnum,
  SampleStatusTranslationTerms,
} from "../../models/domain/SamplesStatusEnum";
import {
  SampleTypeEnum,
  SampleTypeTranslationTerms,
} from "../../models/domain/SampleTypeEnum";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useClosePopup,
  useCreateNotification,
  usePopup,
  useWindowResize,
} from "../../../../lib/infrastructure/ui/UIServices";
import { useLocation, useParams } from "react-router-dom";

import { ReactComponent as AddC04 } from "../../../../lib/assets/icons/c04-add.svg";
import { ReactComponent as AnalyseDarkIcon } from "../../../../lib/assets/icons/teste-dark.svg";
import { ReactComponent as AnalyseIcon } from "../../../../lib/assets/icons/teste.svg";
import { ReactComponent as ArrowIcon } from "../../../../lib/assets/icons/arrowRight.svg";
import { AssociatedRequestCard } from "./cards/AssociatedRequestCard";
import { BuildSampleDetailsCellInfo } from "../../../../common/helpers/BuildSampleDetailsCellInfo";
import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ReactComponent as CheckIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { ContextualMenu } from "../../../../lib/components/contextual-menu/ContextualMenu";
import { CorrelatedRequestSampleSummary } from "../../models/domain/CorrelatedRequestSampleSummary";
import { CountdownTimer } from "../../../../lib/components/countdown-timer/CountDownTimer";
import { DateTime } from "luxon";
import { DateTimeHelper } from "../../../../lib/helpers/datetime/DateTime";
import { ReactComponent as DoneDarkIcon } from "../../../../lib/assets/icons/finish.svg";
import { ReactComponent as DoneIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { EditSampleDetailsPopup } from "./popups/EditSampleDetailsPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { GateService } from "../../../../services/GateService";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { LabSampleService } from "../../services/LabSampleService";
import { Loader } from "../../../../lib/components/loader/Loader";
import { MaterialsService } from "../../../../services/MaterialsService";
import { ReactComponent as MoreActions } from "../../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { OnHoldSamplePopup } from "./popups/OnHoldSamplePopup";
import { PackageAreaTypesTranslationTermsPlural } from "../../models/domain/PackageAreaTypesEnum";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { ReactComponent as PauseIcon } from "../../../../lib/assets/icons/pause-dark-margin.svg";
import { Permission } from "../../../../models/api/gate/Permission";
import { ReactComponent as RejectDarkIcon } from "../../../../lib/assets/icons/reject-dark.svg";
import { ReactComponent as RejectIcon } from "../../../../lib/assets/icons/reject.svg";
import { RejectSamplePopup } from "./popups/RejectSamplePopup";
import { ReactComponent as RemoveC04 } from "../../../../lib/assets/icons/c04-remove.svg";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as RevertIcon } from "../../../../lib/assets/icons/revert.svg";
import { Sample } from "../../models/domain/Sample";
import { SampleAllData } from "../../models/domain/SampleAllData";
import { SampleOtherTypeTranslationTerms } from "../../models/domain/OtherTypesEnum";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Tag } from "../../../../lib/components/tag/Tag";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { UpdateStatusRequestDTO } from "../../models/api/UpdateSampleStatusRequestDTO";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { getSampleStatusCss } from "../../../../common/helpers/GetSampleStatusCss";
import { getSampleStatusLabel } from "../../../../common/helpers/GetSampleStatusLabel";
import { replaceSampleOnURL } from "../../../../common/helpers/ReplaceSampleOnURL";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useIdentityUserDisplayName } from "../../../../lib/infrastructure/identity/hooks/useIdentityUserDisplayName";
import { useServiceCallPro2 } from "../../../../services/UseServiceCall";
import { useUpdatePageData } from "../../../../lib/infrastructure/navigation/hooks/useUpdatePageData";
import { validateDateTime } from "../../../../lib/validators/ValidateDateTime";
import { ReactComponent as CorrelateIcon } from "../../../../lib/assets/icons/correlacionar.svg";
import { CorrelateLabSamplesPopup } from "../popups/CorrelateLabSamplesPopup";

/* ICONS */

const permittedTimeToEdit = 5;
export const gateSvc = new GateService();
export const labSampleSvc = new LabSampleService();
var materialsSvc = new MaterialsService();

const adminPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
];

const adminAndAccessPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_ACCESS
];

const requisitonDatePermission = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.LOCATION_ANALISTS,
  Permission.LOCATION_ACCESS,
  Permission.LOCATION_REGISTER_SAMPLES
];

const permissionToRevert = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN];

export function SampleDetailsPage() {
  const location = useLocation();

  const currentPageURL = useMemo(() => {
    const baseUrl = ConfigurationProvider.getConfiguration().App.BaseUrl;
    return baseUrl + location.pathname;
  }, [location.pathname]);

  const { isLoadingPermissions: isLoadingAdminPermissions, hasPermission: hasAdminPermission } = useHasPermissions(adminPermissions);
  const { isLoadingPermissions: isLoadingAdminWithAccessPermissions, hasPermission: hasAdminAndAccessPermission } = useHasPermissions(adminAndAccessPermissions);
  const { isLoadingPermissions: isLoadingRequistionDatePermission, hasPermission: hasRequisitionDatePermission } = useHasPermissions(requisitonDatePermission);

  const { isLoadingPermissions, hasPermission: canRevertSampleStatus } = useHasPermissions(permissionToRevert);


  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const createNotification = useCreateNotification();
  const updateRouteData = useUpdatePageData();
  const windowResize = useWindowResize();
  const locationID = useGlobalLocation();
  const { id: sampleId } = useParams();

  const useIdentity = useIdentityUserDisplayName();

  const [sampleData, setSampleData] = useState<SampleAllData>();

  const [pageTitle, setPageTitle] = useState("");

  const [correlatedSampleInfo, setCorrelatedSampleInfo] = useState<CorrelatedRequestSampleSummary>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [onSubmitRequistionDate, setOnSubmitRequistionDate] = useState<boolean>(false);
  const [isEditTimeOver, setIsEditTimeOver] = useState<boolean>(true);


  const [updateMaterialC04Call, updateMaterialC04CallIsLoading] = useServiceCallPro2(materialsSvc, materialsSvc.updateMaterialControl04);




  useEffect(() => {
    if (!sampleData?.sample.createdDate) return;
    const currentTime = DateTime.now().toUTC();
    const targetDateTime = DateTime.fromISO(sampleData?.sample.createdDate)
      .plus({ minutes: permittedTimeToEdit })
      .toUTC();
    if (targetDateTime > currentTime) {
      setIsEditTimeOver(false);
    }
  }, [sampleData?.sample.createdDate]);

  const requestDatePickerFormControl = useFormControl<DateTime>({
    validators: [validateDateTime()],
    enableAutoValidate: false,
    isDisabled: false,
  });

  /****************************
   * DATA REQUESTS
   *****************************/
  const getSampleData = useCallback(() => {
    if (!locationID || !sampleId) return;
    labSampleSvc
      .getLabSampleById(locationID, sampleId)
      .then((res) => {
        setSampleData(res);
        setIsLoading(false);
        setPageTitle(BuildSampleDetailsCellInfo(res.sample));
        if (res.sample.sapNumber && res.sample.materialDescription) {
          updateRouteData(["##SAMPLE##", "" + translate(SampleTypeTranslationTerms[res.sample.typeId]).toUpperCase() + " - " + res.sample.sapNumber + " - " + res.sample.materialDescription,
          ]);
        } else if (res.sample.typeId === SampleTypeEnum.OTHERS) {
          updateRouteData(["##SAMPLE##", translate(SampleTypeTranslationTerms[res.sample.typeId]).toUpperCase() + " - " + res.sample.othersMaterialDescription || "",]);
        } else
          updateRouteData(["##SAMPLE##", translate(SampleTypeTranslationTerms[res.sample.typeId]).toUpperCase() + " - " + res.sample.waybill]);

        if (res.sample.requestedDate) {
          requestDatePickerFormControl.setValue(
            DateTime.fromISO(res.sample.requestedDate)
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [
    locationID,
    sampleId,
    setSampleData,
    setIsLoading,
    openPopup,
    updateRouteData,
    requestDatePickerFormControl.setValue,
  ]);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  useEffect(() => {
    getSampleData();
    getCorrelatedSample();

  }, []);



  const getCorrelatedSample = useCallback(() => {

    if (!locationID || !sampleId) return;

    labSampleSvc.getRequestSampleCorrelatedBySampleId(`${locationID}`, sampleId)
      .then((data) => {
        setCorrelatedSampleInfo(data);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      })
  }, [locationID, sampleId, setCorrelatedSampleInfo, openPopup]);



  useEffect(() => {
    if (!sampleData) return;
    if (sampleData.sample.statusId === SampleStatusEnum.REJECTED || sampleData.sample.statusId === SampleStatusEnum.DONE || hasRequisitionDatePermission === false)
      requestDatePickerFormControl.setIsDisabled(true);
    else
      requestDatePickerFormControl.setIsDisabled(false);

  }, [sampleData?.sample.statusId, hasRequisitionDatePermission]);



  const disableAddRequisitionDateButton = useMemo(() => {
    if (!sampleData?.sample.statusId) return true;
    return (sampleData.sample.statusId === SampleStatusEnum.DONE || sampleData.sample.statusId === SampleStatusEnum.REJECTED || hasRequisitionDatePermission === false);
  }, [sampleData?.sample.statusId, hasRequisitionDatePermission]);


  /****************************
   * USER ACTIONS
   *****************************/
  const handleUpdateRequisitionDateButtonClicked = useCallback(() => {
    if (!requestDatePickerFormControl.validate()) return;
    if (!locationID || !sampleId || !requestDatePickerFormControl.value) return;

    setOnSubmitRequistionDate(true);

    labSampleSvc
      .updateRequisitionDate(locationID, sampleId, requestDatePickerFormControl.value.toISO() || "")
      .then((response) => {
        if (!sampleData) return;

        var cloneSample = { ...sampleData };

        cloneSample.sample.requestedDate = response.requestDate;
        cloneSample.sample.modifiedDateRequestDate =
          response.modifiedDateRequestDate;
        cloneSample.sample.requestedBy = response.requestedBy;

        setSampleData(cloneSample);

        if (!response.requestDate) return;
        requestDatePickerFormControl.setValue(DateTime.fromISO(response.requestDate));
        requestDatePickerFormControl.setValue(undefined);
        setOnSubmitRequistionDate(false);
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("SAMPLES.POPUPS.UpdateRequisitionDateSuccess")}
          ></Notification>
        );
      })
      .catch((error) => {
        setOnSubmitRequistionDate(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, sampleId, createNotification, openPopup, requestDatePickerFormControl.validate,
    requestDatePickerFormControl.value, sampleData, setSampleData, setOnSubmitRequistionDate]);



  const updateSampleStatus = useCallback((updateSampleDTO: UpdateStatusRequestDTO) => {
    setIsLoading(true);

    labSampleSvc
      .updateLabSampleStatus("" + locationID, "" + sampleId, updateSampleDTO)
      .then(() => {
        getSampleData();
        getCorrelatedSample();
        createNotification(
          <Notification
            type="success"
            title={translate("COMMON.SYSTEMPOPUPS.Success")}
            text={translate("SAMPLES.POPUPS.StatusChangedSuccess")}
          ></Notification>
        );
      })
      .catch((error) => {
        setIsLoading(false);
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        getSampleData();
      });
  },
    [locationID, sampleId,
      getSampleData, setIsLoading,
      createNotification, openPopup, getCorrelatedSample]);



  const handleAnalyseButtonClicked = useCallback(() => {
    openPopup(
      <WarningPopup
        className="analyse-sample-popup"
        onDismissClicked={() => { closePopup(); }}
        onConfirmClicked={() => { updateSampleStatus({ nextStatus: SampleStatusEnum.UNDERANALYSIS }); }}
        dismissText={translate("COMMON.Cancel")}
        confirmText={translate("SAMPLES.ACTIONS.Analyse")}
      >
        {translate("SAMPLES.POPUPS.ChangeSampleStatusConfirmText") + " " + translate(SampleStatusTranslationTerms[SampleStatusEnum.UNDERANALYSIS]) + "?"}
      </WarningPopup>
    );
  }, [closePopup, openPopup, updateSampleStatus]);



  const handleFinishButtonClicked = useCallback(() => {
    openPopup(
      <WarningPopup
        onDismissClicked={() => { closePopup(); }}
        onConfirmClicked={() => { updateSampleStatus({ nextStatus: SampleStatusEnum.DONE }); }}
        dismissText={translate("COMMON.Cancel")}
        confirmText={translate("SAMPLES.ACTIONS.Finish")}
      >
        {translate("SAMPLES.POPUPS.ChangeSampleStatusConfirmText") + " " + translate(SampleStatusTranslationTerms[SampleStatusEnum.DONE]) + "?"}
      </WarningPopup>
    );
  }, [closePopup, openPopup, updateSampleStatus]);



  const handledRevertButtonClicked = useCallback(() => {
    openPopup(
      <WarningPopup
        onConfirmClicked={() => {
          updateSampleStatus({ nextStatus: SampleStatusEnum.REGISTERED });
        }}
        dismissText={translate("COMMON.Cancel")}
        confirmText={translate("COMMON.Change")}
      >
        {translate("SAMPLES.POPUPS.RevertSampleStatusConfirmText")}
      </WarningPopup>
    );
  }, [openPopup, updateSampleStatus]);



  const handleEditButtonClicked = useCallback(() => {
    if (!sampleData) return;
    openPopup(<EditSampleDetailsPopup
      sampleId={sampleData.sample.sampleId}
      typeId={sampleData.sample.typeId}
      onOperationsCompleted={getSampleData}
    />
    );
  }, [sampleData, getSampleData, openPopup]);



  const isRevertEnabled = useMemo(() => {
    if (!sampleData) return;
    return (sampleData.sample.analyst?.email?.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase() || canRevertSampleStatus);
  }, [sampleData, canRevertSampleStatus, useIdentity.userDisplayEmail]);



  /****************************
   * CSS & HTML *
   *****************************/

  /*   const renderCorrelateIcon = useCallback((labSample: Sample) => {
      if (labSample.correlatedRequestId) return null;
  
      if (!labSample.sampleId || (!hasTopPermission && isLoadingPremiumPermissions)) return null;
  
      return <Tooltip text={translate("REQUESTS.POPUPS.CorrelateSample")}>
        <ScalableIconButton size={24} icon={<CorrelateIcon />}
          onClick={() =>
            openPopup(<CorrelateLabSamplesPopup
              batch={labSample.batch}
              labSampleID={"" + labSample.sampleId}
              locationID={"" + locationID}
              waybill={labSample.waybill}
              materialReference={labSample.materialId ?
                "" + labSample.materialId :
                (labSample.materialDescription ? labSample.materialDescription : labSample.othersMaterialDescription)}
              onCompletedOperations={() => { }}
            />)}
        />
      </Tooltip>
    }, [locationID, hasTopPermission, isLoadingPremiumPermissions, openPopup]) */



  const renderRejectButton = useMemo(() => {
    if (!sampleData) return;
    if (windowResize > 768)
      return (
        <Button
          text={translate("SAMPLES.ACTIONS.Reject")}
          type="secondary"
          onClick={() => {
            openPopup(<RejectSamplePopup
              onChange={updateSampleStatus}
              typeId={sampleData.sample.typeId}
            />);
          }}
          className="reject-btn"
        />
      );
    return (
      <IconButton
        icon={<RejectIcon />}
        type="secondary"
        onClick={() => {
          openPopup(<RejectSamplePopup
            onChange={updateSampleStatus}
            typeId={sampleData.sample.typeId}
          />);
        }}
        className="reject-btn"
      />
    );
  }, [openPopup, updateSampleStatus, windowResize, sampleData]);



  const renderOnHoldButton = useMemo(() => {
    if (!sampleData) return;
    if (windowResize > 768)
      return (
        <Button
          text={translate("SAMPLES.ACTIONS.OnHold")}
          type="secondary"
          onClick={() => {
            openPopup(<OnHoldSamplePopup
              onChange={updateSampleStatus}
              typeId={sampleData.sample.typeId}
            />);
          }}
          className="onhold-btn"
        />
      );
    else
      return (
        <IconButton
          icon={<PauseIcon />}
          type="secondary"
          onClick={() => {
            openPopup(<OnHoldSamplePopup
              onChange={updateSampleStatus}
              typeId={sampleData.sample.typeId}
            />);
          }}
          className="onhold-btn"
        />
      );
  }, [openPopup, updateSampleStatus, windowResize, sampleData]);



  const renderAnalyseButton = useMemo(() => {
    if (!sampleData) return;
    if (windowResize > 768)
      return (
        <Button
          text={translate("SAMPLES.ACTIONS.Analyse")}
          type="secondary"
          onClick={handleAnalyseButtonClicked}
          className="analyse-btn"
        />
      );
    else
      return (<IconButton
        icon={<AnalyseIcon />}
        type="secondary"
        onClick={handleAnalyseButtonClicked}
        className="analyse-btn"
      />);
  }, [handleAnalyseButtonClicked, windowResize, sampleData]);


  //TODO: Verificar o porquê de isto não estar a ser usado.
  const renderRevertButton = useMemo(() => {
    if (!sampleData) return;
    return (
      <Button
        text={translate("SAMPLES.ACTIONS.Revert")}
        type="secondary"
        onClick={handledRevertButtonClicked}
        className="revert-btn"
      />
    );
  }, [handledRevertButtonClicked, sampleData]);



  const renderFinishButton = useMemo(() => {
    if (!sampleData) return;
    if (windowResize > 768)
      return (<Button
        text={translate("SAMPLES.ACTIONS.Finish")}
        type="secondary"
        onClick={handleFinishButtonClicked}
        className="conclude-btn"
      />);
    else
      return (<IconButton
        icon={<DoneIcon />}
        type="secondary"
        onClick={handleFinishButtonClicked}
        className="conclude-btn"
      />);
  }, [handleFinishButtonClicked, sampleData, windowResize]);



  const renderRequisitionDateInfo = useMemo(() => {
    if (!sampleData || !sampleData.sample.requestedBy?.email || !sampleData.sample.modifiedDateRequestDate)
      return null;

    const modifiedRequisitionDate = DateTime.fromISO(sampleData.sample.modifiedDateRequestDate).toFormat("dd/MM/yyyy HH'h'mm");

    return `${translate("SAMPLES.INFOANDFORM.RequestedBy")}: ${sampleData.sample.requestedBy.name}${translate("COMMON.PrepositionForDateTime")}${modifiedRequisitionDate}`;

  }, [sampleData]);



  const renderJustification = useMemo(() => {
    if (sampleData?.sample.statusId !== SampleStatusEnum.ONHOLD && sampleData?.sample.statusId !== SampleStatusEnum.REJECTED)
      return;

    if (!sampleData?.sample.rejectedReason) return;

    var reasonTitle = "";

    if (sampleData?.sample.statusId === SampleStatusEnum.REJECTED)
      reasonTitle = translate("SAMPLES.INFOANDFORM.RejectReason");

    if (sampleData?.sample.statusId === SampleStatusEnum.ONHOLD)
      reasonTitle = translate("SAMPLES.INFOANDFORM.OnHoldReason");

    return (
      <div className="justification">
        <InfoBlock
          label={reasonTitle}
          value={sampleData?.sample.rejectedJustification ? `${sampleData.sample.rejectedReason}` + ": " + sampleData.sample.rejectedJustification : `${sampleData?.sample.rejectedReason}`}
        />
      </div>
    );
  }, [sampleData?.sample]);



  const isEditButtonEnabled = useMemo(() => {

    if (isLoadingAdminPermissions) return false;

    if (hasAdminPermission && sampleData?.sample.statusId === SampleStatusEnum.REGISTERED)
      return true;

    //TODO verificar validação do createdBy.email
    if (!hasAdminPermission && !isEditTimeOver && sampleData?.sample.createdBy.email && sampleData?.sample?.createdBy?.email.toLowerCase() === useIdentity.userDisplayEmail?.toLowerCase())
      return true;

    return false;
  }, [isLoadingAdminPermissions, hasAdminPermission, isEditTimeOver, sampleData, useIdentity.userDisplayEmail]);



  const control04Columns: ColumnDefinition<Sample>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => (
          <Tag
            className="pending-item-status"
            text={getSampleStatusLabel(v.statusId)}
            backgroundColor={getSampleStatusCss(v.statusId)}
            isTiny={true}
          />
        ),
        columnKey: "status",
        width: "7.1429rem",
        isStatusCell: true
      },
      {
        cellRenderProp: (v) => v.createdDate ? DateTime.fromISO(v.createdDate).toFormat("dd/MM/yyyy HH'h'mm") : "",
        columnKey: "creationDate",
        isMobilePrimaryCell: true
      },
      {
        cellRenderProp: (v) => (<ScalableIconButton size={30} icon={<ArrowIcon />} />),
        columnKey: "status",
        width: "2.1429rem",
        isMobileHeaderIcon: true
      },
    ],
    []
  );

  const renderPendingControl04s = useMemo(() => {

    if (sampleData?.sample.statusId === SampleStatusEnum.REJECTED) return;

    if (!sampleData?.lastLabSamplesControl04.incompletedSampleControl04)
      return null;

    if (sampleData?.lastLabSamplesControl04.incompletedSampleControl04.length > 0 && sampleData.sample.isControl04 && sampleData.sample.materialIsSubjectToC04) {
      return (
        <div className="controls04-pending">
          <div className="controls04-pending-title">
            {translate("SAMPLES.INFOANDFORM.PendingControls04")}{" "}
          </div>
          <>
            <ResponsiveDataTable
              className="control-table"
              columnDefinitions={control04Columns}
              items={sampleData?.lastLabSamplesControl04.incompletedSampleControl04 || []}
              totalitems={sampleData?.lastLabSamplesControl04.incompletedSampleControl04.length}
              onClickRow={(v) => {
                window.open(replaceSampleOnURL(currentPageURL, `${v.sampleId}`), "_blank");
              }}
            />
          </>
        </div>
      );
    }
  }, [sampleData?.lastLabSamplesControl04.incompletedSampleControl04, sampleData?.sample.isControl04, sampleData?.sample.materialIsSubjectToC04, sampleData?.sample.statusId, control04Columns, currentPageURL]);



  const renderMaterialInfoBlock = useMemo(() => {
    if (sampleData?.sample.othersMaterialDescription) {
      return (
        <InfoBlock
          label={translate("SAMPLES.INFOANDFORM.Material")}
          value={sampleData?.sample.othersMaterialDescription}
        />
      );
    }
    if (sampleData?.sample.materialDescription && sampleData.sample.sapNumber) {
      return (
        <InfoBlock
          label={translate("SAMPLES.INFOANDFORM.SAPMaterial")}
          value={sampleData?.sample.materialDescription && sampleData.sample.sapNumber ? sampleData.sample.sapNumber + " " + sampleData.sample.materialDescription : sampleData?.sample.sapNumber}
        />
      );
    }
    return;
  }, [sampleData]);

  const renderSampleDetailSubtitle = useMemo(() => {
    if (!sampleData?.sample.createdDate) return;

    return (
      <div className="sub-header-sample-info">
        <div className="small-copy created-date">
          {sampleData.sample.labOperator ? (
            <>
              {translate("COMMON.CreatedBy")}: {sampleData.sample.labOperator}
              {sampleData.sample.operatorSection && (
                <> &lt;{sampleData.sample.operatorSection}&gt;</>
              )}
              {translate("COMMON.PrepositionForDateTime")}{" "}
              {DateTime.fromISO(sampleData.sample.createdDate).toFormat(
                "dd LLL yyyy HH'h'mm",
                { locale: InternationalizationService.getLocale() }
              )}
            </>
          ) : (
            <>
              {translate("COMMON.CreatedBy")}: {sampleData.sample.createdBy.name}{" "}
              {translate("COMMON.PrepositionForDateTime")}{" "}
              {DateTime.fromISO(sampleData.sample.createdDate).toFormat(
                "dd LLL yyyy HH'h'mm",
                { locale: InternationalizationService.getLocale() }
              )}
            </>
          )}
        </div>
      </div>
    );
  }, [sampleData?.sample.createdDate, sampleData?.sample.createdBy.name, sampleData?.sample.labOperator, sampleData?.sample.operatorSection]);



  const renderRequestDateCardContainer = useMemo(() => {
    return (
      <CardContainer className="sample-request-date">
        <div className="subtitle">
          {translate("SAMPLES.INFOANDFORM.RequestDate")}
        </div>
        {sampleData && sampleData.sample.requestedDate ?
          <div className="request-date-info">
            <div className="request-date">
              {DateTimeHelper.formatDate(sampleData.sample.requestedDate)}
            </div>
            <div className="small-copy request-date-timestamp">
              {renderRequisitionDateInfo}
            </div>
          </div>
          : null}
        <div className="request-date-form">
          <FormFieldDatePicker
            formControl={requestDatePickerFormControl}
            placeholder={translate("COMMON.FORM.RequestDatePlaceholder")}
            className="request-date-datepicker"
            minDate={DateTime.now()}
            alternativeStyle={true}
          />
          <IconButton
            className="request-date-form-btn"
            icon={<CheckIcon />}
            isDisabled={disableAddRequisitionDateButton || isLoadingRequistionDatePermission || !hasRequisitionDatePermission}
            type="secondary"
            onClick={handleUpdateRequisitionDateButtonClicked}
          />
        </div>
      </CardContainer>
    );
  }, [sampleData, renderRequisitionDateInfo, requestDatePickerFormControl, disableAddRequisitionDateButton,
    isLoadingRequistionDatePermission, hasRequisitionDatePermission, handleUpdateRequisitionDateButtonClicked]);




  const renderLastControl04Executed = useMemo(() => {
    if (sampleData?.sample.statusId !== SampleStatusEnum.DONE && sampleData?.sample.statusId !== SampleStatusEnum.REJECTED && sampleData?.sample.isControl04 && sampleData.lastLabSamplesControl04.lastSampleControl04?.createdDate)
      return (
        <div className="last-control04-executed">
          <div className="last-control04-executed-title">
            {translate("SAMPLES.INFOANDFORM.LastControl04")}{" "}
          </div>
          <>
            <ResponsiveDataTable
              className="control-table"
              columnDefinitions={control04Columns}
              items={[sampleData?.lastLabSamplesControl04?.lastSampleControl04] || []}
              totalitems={[sampleData?.lastLabSamplesControl04?.lastSampleControl04].length}
              onClickRow={(v) => {
                window.open(replaceSampleOnURL(currentPageURL, `${v.sampleId}`), "_blank");
              }}
            />
          </>
        </div>
      );
    return null;
  }, [sampleData?.sample.statusId, sampleData?.sample.isControl04, control04Columns, currentPageURL, sampleData?.lastLabSamplesControl04?.lastSampleControl04,]);





  const renderControl04Executed = useMemo(() => {
    if (sampleData?.sample.isControl04 && sampleData.sample.statusId === SampleStatusEnum.DONE)
      return (<div className="executed-on-control">
        {sampleData && sampleData.sample.modifiedDate ? `${translate("SAMPLES.INFOANDFORM.ExecutedOn")} ${DateTime.fromISO(sampleData?.sample.modifiedDate).toFormat("dd/MM/yyyy HH'h'mm")}` : ""}
      </div>);
    return null;
  }, [sampleData]);



  const renderNoControl04Found = useMemo(() => {
    if (sampleData?.sample.isControl04 && !sampleData.lastLabSamplesControl04 && sampleData.sample.materialIsSubjectToC04)
      return (
        <div className="first-control04">
          {translate("SAMPLES.INFOANDFORM.NoControls04Found")}
        </div>
      );
    return null;
  }, [sampleData?.sample.isControl04, sampleData?.lastLabSamplesControl04, sampleData?.sample.materialIsSubjectToC04]);



  const renderSampleDetailHeader = useMemo(() => {
    return (
      <div className="main">
        <div className="subtitle">{translate("SAMPLES.SampleData")}</div>
        <div className="status-info">
          {sampleData?.sample.isRepeated ? (
            <div className="repeated">
              <Tooltip text={translate("SAMPLES.INFOANDFORM.Repeated")}>
                <div className="caption repeated-sample">R</div>
              </Tooltip>
            </div>
          ) : null}
          {sampleData?.sample.statusId !== SampleStatusEnum.DONE && sampleData?.sample.statusId !== SampleStatusEnum.REJECTED && sampleData?.sample.needControl04 && !sampleData?.sample.isControl04 && sampleData?.sample.materialIsSubjectToC04 ? <Tooltip text={translate("SAMPLES.INFOANDFORM.Control04NeededTooltip")}>
            <Tag
              text={windowResize > 425 ? translate("SAMPLES.INFOANDFORM.Control04Needed") : "C04"}
              backgroundColor="status-yellow"
              isTiny={windowResize < 1024}
            />
          </Tooltip> : (sampleData?.sample.statusId !== SampleStatusEnum.DONE &&
            sampleData?.sample.statusId !== SampleStatusEnum.REJECTED &&
            sampleData?.sample.isControl04 && sampleData?.sample.materialIsSubjectToC04 ? (
            <Tooltip text={translate("SAMPLES.INFOANDFORM.Control04ActiveTooltip")}>
              <Tag
                text={translate("SAMPLES.INFOANDFORM.Control04Active")}
                backgroundColor="status-blue"
                isTiny={windowResize < 1024}
              />
            </Tooltip>
          ) : null)}
          <Tag
            text={sampleData ? getSampleStatusLabel(sampleData.sample.statusId) : ""}
            backgroundColor={sampleData ? getSampleStatusCss(sampleData.sample.statusId) : undefined}
            isTiny={windowResize < 1024}
          />
        </div>
      </div>
    );
  }, [sampleData, windowResize]);




  const handleSubjectMaterialToC04Clicked = useCallback((subjectToC04: boolean) => {

    if (!locationID || !sampleData?.sample.materialId) return;

    updateMaterialC04Call("" + locationID, sampleData.sample.materialId, subjectToC04)
      .then(() => {
        getSampleData();
        createNotification(<Notification
          type="success"
          title={translate("COMMON.SYSTEMPOPUPS.Success")}
          text={subjectToC04 ?
            translate("GLOBALADMIN.MATERIALS.INFO.SubjectMaterialToC04SuccessMessage") :
            translate("GLOBALADMIN.MATERIALS.INFO.RemoveSubjectMaterialToC04SuccessMessage")}
        ></Notification>
        );
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);

      });

  }, [updateMaterialC04Call, locationID, sampleData?.sample.materialId, getSampleData, openPopup]);



  const renderActionButtons = useMemo(() => {
    if (hasAdminPermission) {
      switch (sampleData?.sample.statusId) {
        case SampleStatusEnum.REGISTERED:
          return (
            <>
              {windowResize > 768 ?
                <>
                  <ContextualMenu
                    items={[
                      {
                        label: sampleData.sample.materialIsSubjectToC04 ? translate("GLOBALADMIN.MATERIALS.INFO.RemoveMaterialToC04") : translate("GLOBALADMIN.MATERIALS.INFO.SubjectMaterialToC04"),
                        icon: sampleData.sample.materialIsSubjectToC04 ? <RemoveC04 /> : <AddC04 />,
                        navigateTo: "",
                        onClick: () => {
                          openPopup(<WarningPopup
                            className={sampleData.sample.materialIsSubjectToC04 ? "remove-c04-button" : ""}
                            onDismissClicked={() => { closePopup(); }}
                            onConfirmClicked={() => { handleSubjectMaterialToC04Clicked(sampleData.sample.materialIsSubjectToC04 ? false : true); }}
                            dismissText={translate("COMMON.Cancel")}
                            confirmText={sampleData.sample.materialIsSubjectToC04 ?
                              translate("GLOBALADMIN.MATERIALS.INFO.RemoveSubjection")
                              : translate("GLOBALADMIN.MATERIALS.INFO.Subject")}
                          >
                            {sampleData.sample.materialIsSubjectToC04 ?
                              translate("GLOBALADMIN.MATERIALS.INFO.RemoveSubjectMaterialToC04ConfirmMessage")
                              : translate("GLOBALADMIN.MATERIALS.INFO.SubjectMaterialToC04ConfirmMessage")}
                          </WarningPopup>);
                        },
                      },
                      {
                        label: translate("REQUESTS.POPUPS.CorrelateSample"),
                        icon: <CorrelateIcon />,
                        navigateTo: "",
                        isVisible: !hasAdminPermission || correlatedSampleInfo?.requestSummary?.analysisRequestId ? false : true,
                        onClick: () => {
                          openPopup(<CorrelateLabSamplesPopup
                            batch={sampleData.sample.batch}
                            labSampleID={"" + sampleData.sample.sampleId}
                            locationID={"" + locationID}
                            waybill={sampleData.sample.waybill}
                            materialReference={sampleData.sample.sapNumber ?
                              "" + sampleData.sample.sapNumber :
                              (sampleData.sample.materialDescription ? sampleData.sample.materialDescription : sampleData.sample.othersMaterialDescription)}
                            onCompletedOperations={() => {
                              getSampleData();
                              getCorrelatedSample();
                            }}
                          />)
                        },
                      },
                    ]}
                  >
                    <ScalableIconButton size={30} icon={<MoreActions />} />
                  </ContextualMenu >
                  {renderAnalyseButton}
                  {renderRejectButton}</> :
                <ContextualMenu
                  items={[
                    {
                      label: translate("SAMPLES.ACTIONS.Analyse"),
                      icon: <AnalyseDarkIcon />,
                      navigateTo: "",
                      onClick: handleAnalyseButtonClicked,
                    },
                    {
                      label: translate("SAMPLES.ACTIONS.Reject"),
                      icon: <RejectDarkIcon />,
                      navigateTo: "",
                      onClick: () => {
                        openPopup(<RejectSamplePopup
                          onChange={updateSampleStatus}
                          typeId={sampleData.sample.typeId}
                        />);
                      },
                    },
                  ]}
                >
                  <ScalableIconButton size={30} icon={<MoreActions />} />
                </ContextualMenu >}
            </>
          );
        case SampleStatusEnum.UNDERANALYSIS: {
          return (
            <>
              {windowResize > 768 ?
                <><ContextualMenu
                  items={[
                    {
                      label: translate("SAMPLES.ACTIONS.Reject"),
                      icon: <RejectDarkIcon />,
                      navigateTo: "",
                      onClick: () => {
                        openPopup(
                          <RejectSamplePopup
                            onChange={updateSampleStatus}
                            typeId={sampleData.sample.typeId}
                          />
                        );
                      },
                    },
                    {
                      label: translate("SAMPLES.ACTIONS.Revert"),
                      icon: <RevertIcon />,
                      navigateTo: "",
                      onClick: handledRevertButtonClicked,
                    },
                    {
                      label: sampleData.sample.materialIsSubjectToC04 ? translate("GLOBALADMIN.MATERIALS.INFO.RemoveMaterialToC04") : translate("GLOBALADMIN.MATERIALS.INFO.SubjectMaterialToC04"),
                      icon: sampleData.sample.materialIsSubjectToC04 ? <RemoveC04 /> : <AddC04 />,
                      navigateTo: "",
                      onClick: () => {
                        openPopup(<WarningPopup
                          className={sampleData.sample.materialIsSubjectToC04 ? "" : "remove-c04"}
                          onDismissClicked={() => { closePopup(); }}
                          onConfirmClicked={() => { handleSubjectMaterialToC04Clicked(sampleData.sample.materialIsSubjectToC04 ? false : true); }}
                          dismissText={translate("COMMON.Cancel")}
                          confirmText={sampleData.sample.materialIsSubjectToC04 ?
                            translate("GLOBALADMIN.MATERIALS.INFO.RemoveSubjection")
                            : translate("GLOBALADMIN.MATERIALS.INFO.Subject")}
                        >
                          {sampleData.sample.materialIsSubjectToC04 ?
                            translate("GLOBALADMIN.MATERIALS.INFO.RemoveSubjectMaterialToC04ConfirmMessage")
                            : translate("GLOBALADMIN.MATERIALS.INFO.SubjectMaterialToC04ConfirmMessage")}
                        </WarningPopup>);
                      },
                    },
                    {
                      label: translate("REQUESTS.POPUPS.CorrelateSample"),
                      icon: <CorrelateIcon />,
                      navigateTo: "",
                      isVisible: !hasAdminPermission || correlatedSampleInfo?.requestSummary?.analysisRequestId ? false : true,
                      onClick: () => {
                        openPopup(<CorrelateLabSamplesPopup
                          batch={sampleData.sample.batch}
                          labSampleID={"" + sampleData.sample.sampleId}
                          locationID={"" + locationID}
                          waybill={sampleData.sample.waybill}
                          materialReference={sampleData.sample.sapNumber ?
                            "" + sampleData.sample.sapNumber :
                            (sampleData.sample.materialDescription ? sampleData.sample.materialDescription : sampleData.sample.othersMaterialDescription)}
                          onCompletedOperations={() => {
                            getSampleData();
                            getCorrelatedSample();
                          }}
                        />)
                      },
                    },
                  ]}
                >
                  <ScalableIconButton size={30} icon={<MoreActions />} />
                </ContextualMenu>
                  {renderOnHoldButton}
                  {renderFinishButton}
                </> : <ContextualMenu
                  items={[
                    {
                      label: translate("SAMPLES.ACTIONS.Finish"),
                      icon: <DoneDarkIcon />,
                      navigateTo: "",
                      onClick: handleFinishButtonClicked,
                    },
                    {
                      label: translate("SAMPLES.ACTIONS.OnHold"),
                      icon: <PauseIcon />,
                      navigateTo: "",
                      onClick: () => {
                        openPopup(<OnHoldSamplePopup
                          onChange={updateSampleStatus}
                          typeId={sampleData.sample.typeId}
                        />);
                      },
                    },
                    {
                      label: translate("SAMPLES.ACTIONS.Reject"),
                      icon: <RejectDarkIcon />,
                      navigateTo: "",
                      onClick: () => {
                        openPopup(<RejectSamplePopup
                          onChange={updateSampleStatus}
                          typeId={sampleData.sample.typeId}
                        />
                        );
                      },
                    },
                    {
                      label: translate("SAMPLES.ACTIONS.Revert"),
                      icon: <RevertIcon />,
                      navigateTo: "",
                      onClick: handledRevertButtonClicked,
                    },
                  ]}
                >
                  <ScalableIconButton size={30} icon={<MoreActions />} />
                </ContextualMenu>}</>
          );
        }
        case SampleStatusEnum.ONHOLD:
          return (
            <><ContextualMenu
              items={[
                {
                  label: sampleData.sample.materialIsSubjectToC04 ? translate("GLOBALADMIN.MATERIALS.INFO.RemoveMaterialToC04") : translate("GLOBALADMIN.MATERIALS.INFO.SubjectMaterialToC04"),
                  icon: sampleData.sample.materialIsSubjectToC04 ? <RemoveC04 /> : <AddC04 />,
                  navigateTo: "",
                  onClick: () => {
                    openPopup(<WarningPopup
                      className={sampleData.sample.materialIsSubjectToC04 ? "" : "remove-c04"}
                      onDismissClicked={() => { closePopup(); }}
                      onConfirmClicked={() => { handleSubjectMaterialToC04Clicked(sampleData.sample.materialIsSubjectToC04 ? false : true); }}
                      dismissText={translate("COMMON.Cancel")}
                      confirmText={sampleData.sample.materialIsSubjectToC04 ?
                        translate("GLOBALADMIN.MATERIALS.INFO.RemoveSubjection")
                        : translate("GLOBALADMIN.MATERIALS.INFO.Subject")}
                    >
                      {sampleData.sample.materialIsSubjectToC04 ?
                        translate("GLOBALADMIN.MATERIALS.INFO.RemoveSubjectMaterialToC04ConfirmMessage")
                        : translate("GLOBALADMIN.MATERIALS.INFO.SubjectMaterialToC04ConfirmMessage")}
                    </WarningPopup>);
                  },
                },
                {
                  label: translate("REQUESTS.POPUPS.CorrelateSample"),
                  icon: <CorrelateIcon />,
                  navigateTo: "",
                  isVisible: !hasAdminPermission || correlatedSampleInfo?.requestSummary?.analysisRequestId ? false : true,
                  onClick: () => {
                    openPopup(<CorrelateLabSamplesPopup
                      batch={sampleData.sample.batch}
                      labSampleID={"" + sampleData.sample.sampleId}
                      locationID={"" + locationID}
                      waybill={sampleData.sample.waybill}
                      materialReference={sampleData.sample.sapNumber ?
                        "" + sampleData.sample.sapNumber :
                        (sampleData.sample.materialDescription ? sampleData.sample.materialDescription : sampleData.sample.othersMaterialDescription)}
                      onCompletedOperations={() => {
                        getSampleData();
                        getCorrelatedSample();
                      }}
                    />)
                  },
                },
              ]}
            >
              <ScalableIconButton size={30} icon={<MoreActions />} />
            </ContextualMenu>
              {renderAnalyseButton}
              {renderRejectButton}
            </>
          );
        case SampleStatusEnum.DONE:
          return (
            <ContextualMenu
              items={[
                {
                  label: translate("REQUESTS.POPUPS.CorrelateSample"),
                  icon: <CorrelateIcon />,
                  navigateTo: "",
                  isVisible: !hasAdminPermission || correlatedSampleInfo?.requestSummary?.analysisRequestId ? false : true,
                  onClick: () => {
                    openPopup(<CorrelateLabSamplesPopup
                      batch={sampleData.sample.batch}
                      labSampleID={"" + sampleData.sample.sampleId}
                      locationID={"" + locationID}
                      waybill={sampleData.sample.waybill}
                      materialReference={sampleData.sample.sapNumber ?
                        "" + sampleData.sample.sapNumber :
                        (sampleData.sample.materialDescription ? sampleData.sample.materialDescription : sampleData.sample.othersMaterialDescription)}
                      onCompletedOperations={() => {
                        getSampleData();
                        getCorrelatedSample();
                      }}
                    />)
                  },
                },
              ]}
            >
              <ScalableIconButton size={30} icon={<MoreActions />} />
            </ContextualMenu>
          );
        case SampleStatusEnum.REJECTED:
          return (
            <ContextualMenu
              items={[
                {
                  label: translate("REQUESTS.POPUPS.CorrelateSample"),
                  icon: <CorrelateIcon />,
                  navigateTo: "",
                  isVisible: !hasAdminPermission || correlatedSampleInfo?.requestSummary?.analysisRequestId ? false : true,
                  onClick: () => {
                    openPopup(<CorrelateLabSamplesPopup
                      batch={sampleData.sample.batch}
                      labSampleID={"" + sampleData.sample.sampleId}
                      locationID={"" + locationID}
                      waybill={sampleData.sample.waybill}
                      materialReference={sampleData.sample.sapNumber ? "" + sampleData.sample.sapNumber :
                        (sampleData.sample.materialDescription ? sampleData.sample.materialDescription : sampleData.sample.othersMaterialDescription)}
                      onCompletedOperations={() => {
                        getSampleData();
                        getCorrelatedSample();
                      }}
                    />)
                  },
                },
              ]}
            >
              <ScalableIconButton size={30} icon={<MoreActions />} />
            </ContextualMenu>
          );
      }
    }
    return null;
  }, [getSampleData, getCorrelatedSample,
    handleAnalyseButtonClicked, handleFinishButtonClicked, windowResize,
    sampleData?.sample.statusId, sampleData?.sample.typeId, hasAdminPermission,
    renderOnHoldButton, renderRejectButton, renderAnalyseButton,
    renderFinishButton, openPopup, handledRevertButtonClicked,
    updateSampleStatus, sampleData?.sample.materialIsSubjectToC04,
    closePopup, handleSubjectMaterialToC04Clicked, hasAdminPermission,
    correlatedSampleInfo?.requestSummary?.analysisRequestId, getCorrelatedSample, getSampleData, sampleData?.sample, locationID
  ]);



  const renderSampleData = useMemo(() => {
    if (sampleData) {
      switch (sampleData.sample.typeId) {
        case SampleTypeEnum.PRODUCTION:
          return (
            <>
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.ProductionOrder")}
                value={sampleData.sample.op || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Batch")}
                value={sampleData.sample.batch || "-"}
              />
            </>
          );
        case SampleTypeEnum.FIBERS:
          return (
            <>              <InfoBlock
              label={translate("SAMPLES.INFOANDFORM.Batch")}
              value={sampleData.sample.batch || "-"}
            />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Supplier")}
                value={sampleData.sample.supplier || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Waybill")}
                value={sampleData.sample.waybill || "-"}
              />
            </>
          );
        case SampleTypeEnum.ACCESSORIES:
          return (
            <>
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Batch")}
                value={sampleData.sample.batch || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Waybill")}
                value={sampleData.sample.waybill || "-"}
              />
            </>
          );
        case SampleTypeEnum.THREADS:
          return (
            <>
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Batch")}
                value={sampleData.sample.batch || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Supplier")}
                value={sampleData.sample.supplier || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.NumSpools")}
                value={sampleData.sample.numSpools ? "" + sampleData.sample.numSpools : "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Facility")}
                value={sampleData.sample.facilityName || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Waybill")}
                value={sampleData.sample.waybill || "-"}
              />
            </>
          );
        case SampleTypeEnum.PRODUCED:
          return (
            <>
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.ProductionOrder")}
                value={sampleData.sample.op || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Batch")}
                value={sampleData.sample.batch || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Waybill")}
                value={sampleData.sample.waybill || "-"}
              />
            </>
          );
        case SampleTypeEnum.OTHERS:
          return (
            <>
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Type")}
                value={sampleData.sample.othersType ? translate(SampleOtherTypeTranslationTerms[sampleData.sample.othersType]) : ""}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Batch")}
                value={sampleData.sample.batch || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Waybill")}
                value={sampleData.sample.waybill || "-"}
              />
            </>
          );
        case SampleTypeEnum.PACKAGE:
          return (
            <>
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Area")}
                value={sampleData.sample.area ? translate(PackageAreaTypesTranslationTermsPlural[sampleData.sample.area]) : "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Batch")}
                value={sampleData.sample.batch || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Supplier")}
                value={sampleData.sample.supplier || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Source")}
                value={sampleData.sample.source || "-"}
              />
              <InfoBlock
                label={translate("SAMPLES.INFOANDFORM.Waybill")}
                value={sampleData.sample.waybill || "-"}
              />
            </>
          );
        default:
          return <></>;
      }
    }
  }, [sampleData]);


  return (
    <PageLayout
      tabTitle={`laMP - ${translate("SAMPLES.SampleDetails")}`}
      pageTitle={pageTitle}
      className="sample-details"
      showBreadcrumb
      actions={renderActionButtons}
    >
      {isLoadingAdminPermissions || isLoadingAdminWithAccessPermissions ? (<FullScreenLoader />) : null}
      {onSubmitRequistionDate || updateMaterialC04CallIsLoading ? <FullScreenLoader /> : null}
      {isLoading ? (<Loader text="Loading sample data, please wait..." />) :
        (
          <>
            <div className="sample-data-and-control">
              <CardContainer className="sample-data">
                <div className="sample-data-header">
                  {renderSampleDetailHeader}
                  {renderSampleDetailSubtitle}
                </div>

                <div className="sample-data-content">
                  <div className="sample-data-content-info">
                    {renderJustification}

                    <div>
                      <InfoBlock
                        label={translate("SAMPLES.INFOANDFORM.Type")}
                        value={sampleData ? translate(SampleTypeTranslationTerms[sampleData.sample.typeId]) : ""}
                      />
                      {renderMaterialInfoBlock}
                      {renderSampleData}
                    </div>

                    {sampleData?.sample.observations ?
                      (<div className="observations">
                        <InfoBlock
                          label={translate("SAMPLES.INFOANDFORM.Observations")}
                          value={sampleData && sampleData.sample.observations ? sampleData.sample.observations : ""}
                        />
                      </div>
                      ) : null}
                  </div>
                  <div className="sample-data-action-buttons">
                    <ConditionalRender if={isEditButtonEnabled}>
                      <div className="edit-time">
                        {sampleData?.sample.createdDate && !hasAdminPermission
                          ? (<CountdownTimer creationDateTime={DateTime.fromISO(sampleData?.sample.createdDate)}
                            durationMinutes={permittedTimeToEdit}
                            label={translate("SAMPLES.INFOANDFORM.TimeToEdit")}
                            timeFinished={() => setIsEditTimeOver(true)}
                          />
                          ) : null}
                      </div>
                      <Button
                        text={translate("COMMON.Edit")}
                        type="secondary"
                        isDisabled={sampleData?.sample.statusId === SampleStatusEnum.DONE || sampleData?.sample.statusId === SampleStatusEnum.REJECTED
                        }
                        onClick={handleEditButtonClicked}
                      />
                    </ConditionalRender>
                  </div>
                </div>
              </CardContainer>
              <ConditionalRender if={hasAdminAndAccessPermission}>
                <CardContainer className="control-card">
                  <div className="subtitle">
                    {translate("SAMPLES.INFOANDFORM.Control04")}
                  </div>


                  <div className="control-info">
                    {renderNoControl04Found}
                    {renderControl04Executed}
                    {renderPendingControl04s}
                    {renderLastControl04Executed}
                  </div>
                </CardContainer>
              </ConditionalRender>

              {correlatedSampleInfo?.info && (!isLoadingAdminWithAccessPermissions && hasAdminAndAccessPermission) ?
                <AssociatedRequestCard
                  hasTopPermissions={hasAdminPermission}
                  isLoadingTopPermissions={isLoadingAdminPermissions}
                  locationID={"" + locationID}
                  onCompletedOperations={() => { getSampleData(); getCorrelatedSample(); }}
                  sampleID={"" + sampleId}
                  data={correlatedSampleInfo} /> : null}
            </div>
            {renderRequestDateCardContainer}
          </>
        )}
    </PageLayout>
  );
}