import { SelectOption } from "../../../requests/models/domain/SelectOption";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";

export enum MeasurementEnum {
    AREA = 1,
    LENGTH = 2
}

export const MeasurementEnumTranslationTerms: { [key: string]: string } = {
    1: "GLOBALADMIN.TESTS.INFO.Area",
    2: "GLOBALADMIN.TESTS.INFO.Length"
}

export const MeasurementAsEnum: { [key: string]: MeasurementEnum } = {
    "1": MeasurementEnum.AREA,
    "2": MeasurementEnum.LENGTH
};



export function GetMeasurementEnumAsArray() {
    const measurementsArray: SelectOption[] = Object.values(MeasurementEnum)
        .filter(value => typeof value === "number")
        .map((enumValue) => {
            var mappedSampleStatus: SelectOption = {
                id: `${enumValue}`,
                description: translate(MeasurementEnumTranslationTerms[enumValue]) || "-unknown-"
            };
            return mappedSampleStatus;
        });

    return measurementsArray;
}