import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";
import { LocationGroupType, } from "../models/enums/LocationGroupType";
import { LocationGroupsResponseDTO } from "../models/api/access-groups/LocationGroupsResponseDTO";
import { UpdateLocationGroupAccessRequestDTO } from "../models/api/access-groups/UpdateLocationGroupAccessRequestDTO";
import { Employee } from "../features/samples/models/domain/Employee";
import { UserDTO } from "../features/samples/models/api/UserDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

interface LocationGroupItem {
    name: string;
    objectId: string;
}
export interface LocationGroups {
    admin: LocationGroupItem,
    access: LocationGroupItem,
    analyst: LocationGroupItem,
    requestsGlobal: LocationGroupItem,
    registerSamples: LocationGroupItem,
    ar: LocationGroupItem,
    dryer: LocationGroupItem,
    df: LocationGroupItem,
    pp: LocationGroupItem,
    wf: LocationGroupItem
}

export interface UpdateLocationGroupItem {
    type: LocationGroupType;
    objectId: string;
}
export interface UpdateLocationGroups {
    admin: UpdateLocationGroupItem,
    access: UpdateLocationGroupItem,
    analyst: UpdateLocationGroupItem,
    requestsGlobal: UpdateLocationGroupItem,
    registerSamples: UpdateLocationGroupItem,
    ar: UpdateLocationGroupItem,
    dryer: UpdateLocationGroupItem,
    df: UpdateLocationGroupItem,
    pp: UpdateLocationGroupItem,
    wf: UpdateLocationGroupItem
}


export class AccessGroupsService {

    getLocationGroups(locationId: string): Promise<LocationGroups> {
        return HttpClient.sessionRequest<LocationGroupsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/LocationGroups`),
        })
            .then((response) => {

                const mapGroup = (type: LocationGroupType): LocationGroupItem => {
                    let res = response.data.locationGroups.find(p => p.type === type);
                    if (res) {
                        return ({ name: res.aadName, objectId: res.aadGroupId });
                    }
                    return ({ name: "", objectId: "" })
                }

                var lg: LocationGroups = {
                    admin: mapGroup(LocationGroupType.ADMIN),
                    access: mapGroup(LocationGroupType.ACCESS),
                    analyst: mapGroup(LocationGroupType.ANALISTS),
                    requestsGlobal: mapGroup(LocationGroupType.ANALISYS_REQUEST_GLOBAL),
                    registerSamples: mapGroup(LocationGroupType.REGISTER_SAMPLES),
                    ar: mapGroup(LocationGroupType.AR),
                    dryer: mapGroup(LocationGroupType.DRYER),
                    df: mapGroup(LocationGroupType.DF),
                    pp: mapGroup(LocationGroupType.PP),
                    wf: mapGroup(LocationGroupType.WF)
                }

                return lg;

            }).catch((error) => {
                throw error;
            });
    }


    updateLocationGroup(locationId: string, accessGroups: UpdateLocationGroups): Promise<void> {

        var request: UpdateLocationGroupAccessRequestDTO = {
            locationGroupAccesses: []
        };

        const handleGroup = (g: UpdateLocationGroupItem) => {
            if (g.objectId) request.locationGroupAccesses.push({ type: g.type, AADGroupId: g.objectId })
        }

        handleGroup(accessGroups.admin);
        handleGroup(accessGroups.access);
        handleGroup(accessGroups.analyst);
        handleGroup(accessGroups.registerSamples);
        handleGroup(accessGroups.requestsGlobal);
        handleGroup(accessGroups.ar);
        handleGroup(accessGroups.df);
        handleGroup(accessGroups.dryer);
        handleGroup(accessGroups.pp);
        handleGroup(accessGroups.wf);

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/LocationGroups`),
            data: request
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }


    
  getLocationAnalysts(locationID: string): Promise<Employee[]> {
    return HttpClient.sessionRequest<UserDTO[]>({
      method: "GET",
      url: Route(`/api/v1/Locations/${locationID}/LocationGroups/Analysts`),
    })
      .then((response) => {

          if(!response.data) return new Array<Employee>(); 

          var mappedEmployees = response?.data?.map(
            (e): Employee => ({
              email: e.userEmail ?? null,
              name: e.userDisplayName ?? null
            })
        );
       return mappedEmployees; 
      })
      .catch((error) => {
        throw error;
      });
  }
}
