import { Navigate } from "react-router-dom";
import { Permission } from "../../../models/api/gate/Permission";
import { useHasPermissions } from "./useHasLocationPermissions";



interface IPermissionNavigateProps {

    permissions: Permission[],
    to: string;
    fallbackTo: string;

}

export function PermissionNavigate(props: IPermissionNavigateProps) {


    const {isLoadingPermissions, hasPermission} = useHasPermissions(props.permissions);


    if(isLoadingPermissions) return null;

    if(hasPermission) return <Navigate to={props.to} />

    return <Navigate to={props.fallbackTo} />
}
