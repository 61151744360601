import { AnalysisRequestIndustrialSectorDTO } from "../models/api/AnalysisRequestIndustrialSectorDTO";
import { AnalysisRequestIndustrialSectorResponseDTO } from "../models/api/AnalysisRequestIndustrialSectorResponseDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { IndustrialSector } from "../models/domain/AnalysisRequestIndustrialSector";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class AnalysisRequestIndustrialSectorService {
  getAnalysisRequestIndustrialSectors(): Promise<IndustrialSector[]> {

    return HttpClient.sessionRequest<AnalysisRequestIndustrialSectorResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/AnalysisRequestIndustrialSectors`),
    })
      .then((response) => {

        var mapped = response.data.analysisRequestIndustrialSectors.map((p): IndustrialSector => ({
          id: p.analysisRequestIndustrialSectorId,
          description: p.analysisRequestIndustrialSector,
          isDescriptionRequired: p.isDescriptionRequired
        }));

        return mapped;
      })
      .catch((error) => {
        throw error;
      });
  }
}