import "./CreateRequestDetails.css";

import { ClientRegistrationEnum, GetClientRegistrationEnumByKey, GetClientRegistrationsArray } from "../../../models/domain/ClientRegistrationEnum";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { AnalysisRequestFilterTypeService } from "../../../services/AnalysisRequestFilterTypeService";
import { AnalysisRequestIndustrialSectorService } from "../../../services/AnalysisRequestIndustrialSectorService";
import { AnalysisRequestService } from "../../../services/AnalysisRequestService";
import { AnalysisRequestTypeEnum } from "../../../models/domain/AnalysisRequestTypeEnum";
import { CardContainer } from "../../../../../lib/layouts/containers/card/CardContainer";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FilterType } from "../../../models/domain/AnalysisRequestFilterType";
import { FormFieldDatePicker } from "../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { IGenericRequestDetailsFormsProps } from "../CreateRequestPage";
import { IndustrialSector } from "../../../models/domain/AnalysisRequestIndustrialSector";
import { Permission } from "../../../../../models/api/gate/Permission";
import { RequestDetails } from "../../../models/domain/RequestDetails";
import { RequestForm } from "../../../models/domain/RequestForm";
import { SelectOption } from "../../../models/domain/SelectOption";
import { hasOneElementSelected } from "../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useHasPermissions } from "../../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro2 } from "../../../../../services/UseServiceCall";
import { validateClientRegistrationNumber } from "../../../validators/ValidateClientRegistrationNumber";
import { validateDateTime } from "../../../../../lib/validators/ValidateDateTime";
import { validateRequiredIfOthers } from "../../../validators/ValidateRequiredIfOthers";
import { validateStringIfConditional } from "../../../validators/ValidateIfConditional";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

var analysisRequestIndustrialSectorService = new AnalysisRequestIndustrialSectorService();
var analysisRequestFilterTypeService = new AnalysisRequestFilterTypeService();
var analysisRequestSvc = new AnalysisRequestService();

const dateNow = DateTime.now();
const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.LOCATION_ANALISTS];


const labelClientRegistrationSelector = (item: SelectOption) => item.description;
const idClientRegistrationSelector = (item: SelectOption) => item.id;

const labelIndustrialSectorSelector = (item: IndustrialSector) => item.description;
const idIndustrialSectorSelector = (item: IndustrialSector) => item.id;

const labelFilterTypeSelector = (item: FilterType) => item.description;
const idFilterTypeSelector = (item: FilterType) => item.id;



export const CreateRequestDetailsPP = forwardRef(
    function CreateRequestDetailsPP(props: IGenericRequestDetailsFormsProps, ref: React.ForwardedRef<RequestForm>) {

        const openPopup = usePopup();
        const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);
        const clientRegistrationTypes = useMemo(() => { return GetClientRegistrationsArray(); }, [GetClientRegistrationsArray]);

        const [requestDetails, setRequestDetails] = useState<RequestDetails>();

        const [industrialSectors, setIndustrialSectors] = useState<IndustrialSector[]>([]);
        const [filterTypes, setFilterTypes] = useState<FilterType[]>([]);
        const [containsCarcinogens, setContainsCarcinogens] = useState<boolean>(false);
        const [containsHazardousMaterial, setContainsHazardousMaterial] = useState<boolean>(false);


        const requestNameFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const companyFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const factoryFormControl = useFormControl<string>({
            validators: [validateWhiteSpaceStrings()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const creationRequestDateFormControl = useFormControl<DateTime>({
            validators: [validateDateTime()],
            enableAutoValidate: false,
            initialValue: dateNow,
        });

        const customerCommentsFormControl = useFormControl<string>({
            isDisabled: false
        });

        const clientRegistrationControl = useFormControl<SelectOption>({
            validators: [hasOneElementSelected()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const clientNumberControl = useFormControl<string>({
            validators: [validateClientRegistrationNumber(clientRegistrationControl)],
            enableAutoValidate: true,
            isDisabled: false,
        });


        const industrialSectorFormControl = useFormControl<IndustrialSector>({
            validators: [hasOneElementSelected()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const industrialSectorOptionOthersFormControl = useFormControl<string>({
            validators: [validateRequiredIfOthers(industrialSectorFormControl)],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const filterTypeFormControl = useFormControl<FilterType>({
            validators: [hasOneElementSelected()],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const filterTypeOptionOthersFormControl = useFormControl<string>({
            validators: [validateRequiredIfOthers(filterTypeFormControl)],
            enableAutoValidate: true,
            isDisabled: false,
        });

        const slurryFormControl = useFormControl<string>({ isDisabled: false });

        const hazardousMaterialFormControl = useFormControl<string>({
            validators: [validateStringIfConditional(containsHazardousMaterial)],
            enableAutoValidate: true,
            isDisabled: false
        });

        const carcinogenicsFormControl = useFormControl<string>({
            validators: [validateStringIfConditional(containsCarcinogens)],
            enableAutoValidate: true,
            isDisabled: false
        });

        const otherEquipmentInformationsFormControl = useFormControl<string>({
            isDisabled: false
        });




        /*****************
         * SERVICE CALLS 
        ****************/


        const [getAnalysisRequestByIdCall, getAnalysisRequestByIdCallIsLoading] = useServiceCallPro2(analysisRequestSvc, analysisRequestSvc.getAnalysisRequestById);





        /****************************
         * DATA REQUESTS
        *****************************/



        const getAnalysisRequestById = useCallback(() => {

            if (!props.locationId || !props.cloneId) return;

            getAnalysisRequestByIdCall(`${props.locationId}`, `${props.cloneId}`)
                .then((data) => {
                    if (!data) return;
                    setRequestDetails(data);
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup> {error.response.data.message}</ErrorPopup>);
                });
        }, [props.locationId, props.cloneId, getAnalysisRequestByIdCall, setRequestDetails, openPopup]);




        /****************************
         * DATA MANIPULATION EFFECTS
        *****************************/




        useEffect(() => {
            getAnalysisRequestById();
        }, []);



        useEffect(() => {
            if (!props.locationId || !props.cloneId) return;

            if (requestDetails?.hasCarcinogenicSubstances) setContainsCarcinogens(requestDetails.hasCarcinogenicSubstances);
            if (requestDetails?.hasHazardousMaterial) setContainsHazardousMaterial(requestDetails.hasHazardousMaterial);

            requestNameFormControl.setValue(requestDetails?.name);
            companyFormControl.setValue(requestDetails?.company);
            factoryFormControl.setValue(requestDetails?.factory);
            if (requestDetails?.createdDate)
                creationRequestDateFormControl.setValue(DateTime.fromISO(requestDetails?.createdDate));

            customerCommentsFormControl.setValue(requestDetails?.customerComments);
            clientRegistrationControl.setValue(clientRegistrationTypes.find((a) => a.id === (requestDetails?.clientRegistration)?.toString()))
            clientNumberControl.setValue(requestDetails?.clientRegistrationDescription);

            industrialSectorFormControl.setValue(industrialSectors.find((f) => f.id === requestDetails?.industrialSectorId));
            industrialSectorOptionOthersFormControl.setValue(requestDetails?.industrialSectorOthersDescription);
            filterTypeFormControl.setValue(filterTypes.find((f) => f.id === requestDetails?.filterTypeId));
            filterTypeOptionOthersFormControl.setValue(requestDetails?.filterTypeOthersDescription);

            slurryFormControl.setValue(requestDetails?.slurry);
            hazardousMaterialFormControl.setValue(requestDetails?.hazardousMaterialDescription);
            carcinogenicsFormControl.setValue(requestDetails?.carcinogenicSubstancesDescription);
            otherEquipmentInformationsFormControl.setValue(requestDetails?.otherInformations);

        }, [props.locationId, props.cloneId, requestDetails]);






        useEffect(() => {
            if (clientRegistrationControl.value?.id === (ClientRegistrationEnum.NOTREGISTERED).toString())
                clientNumberControl.setValue(undefined);
        }, [clientRegistrationControl.value?.id]);




        useEffect(() => {
            if (clientRegistrationControl.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString())
                clientNumberControl.setIsDisabled(false);
            else
                clientNumberControl.setIsDisabled(true);
        }, [clientRegistrationControl.value?.id]);




        useEffect(() => {
            industrialSectorOptionOthersFormControl.validate();
        }, [industrialSectorFormControl.value]);




        useEffect(() => {
            filterTypeOptionOthersFormControl.validate();
        }, [filterTypeFormControl.value]);




        useEffect(() => {
            analysisRequestIndustrialSectorService
                .getAnalysisRequestIndustrialSectors()
                .then((response) => {
                    setIndustrialSectors(response);
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
        }, []);




        useEffect(() => {
            analysisRequestFilterTypeService
                .getAnalysisRequestFilterTypes()
                .then((response) => {
                    setFilterTypes(response);
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
        }, []);



        useEffect(() => {
            if (!isLoadingPermissions && !hasPermission)
                creationRequestDateFormControl.setIsDisabled(true);
        }, [isLoadingPermissions, hasPermission]);




        useEffect(() => {
            if (containsCarcinogens)
                carcinogenicsFormControl.setIsDisabled(false);
            else
                carcinogenicsFormControl.setIsDisabled(true);
        }, [containsCarcinogens]);




        useEffect(() => {
            if (containsHazardousMaterial)
                hazardousMaterialFormControl.setIsDisabled(false);
            else
                hazardousMaterialFormControl.setIsDisabled(true);
        }, [containsHazardousMaterial]);





        useEffect(() => {
            if (!industrialSectorFormControl.value || !industrialSectorFormControl.value?.isDescriptionRequired)
                industrialSectorOptionOthersFormControl.setIsDisabled(true);
            else
                industrialSectorOptionOthersFormControl.setIsDisabled(false);
        }, [industrialSectorFormControl.value?.isDescriptionRequired, industrialSectorFormControl.value]);




        useEffect(() => {
            if (!filterTypeFormControl.value || !filterTypeFormControl.value?.isDescriptionRequired)
                filterTypeOptionOthersFormControl.setIsDisabled(true);
            else
                filterTypeOptionOthersFormControl.setIsDisabled(false);
        }, [filterTypeFormControl.value?.isDescriptionRequired, filterTypeFormControl.value]);





        useImperativeHandle(ref, () => {
            return {
                validate() {
                    return AllValid(requestNameFormControl.validate(), companyFormControl.validate(), factoryFormControl.validate(),
                        creationRequestDateFormControl.validate(), customerCommentsFormControl.validate(),
                        industrialSectorFormControl.validate(), industrialSectorOptionOthersFormControl.validate(), filterTypeFormControl.validate(),
                        filterTypeOptionOthersFormControl.validate(), slurryFormControl.validate(), hazardousMaterialFormControl.validate(),
                        carcinogenicsFormControl.validate(), otherEquipmentInformationsFormControl.validate(),
                        clientNumberControl.validate(), clientRegistrationControl.validate()
                    );
                },
                value() {
                    return {
                        requestType: AnalysisRequestTypeEnum.PP,
                        name: requestNameFormControl.value,
                        createdDate: creationRequestDateFormControl.value,
                        company: companyFormControl.value,
                        factory: factoryFormControl.value,
                        customerComments: customerCommentsFormControl.value,
                        clientRegistration: clientRegistrationControl.value?.id ?
                            GetClientRegistrationEnumByKey[parseInt(clientRegistrationControl.value.id)] : ClientRegistrationEnum.NOTREGISTERED,
                        clientRegistrationDescription: clientNumberControl.value,
                        industrialSectorId: industrialSectorFormControl.value?.id,
                        industrialSectorDescription: industrialSectorOptionOthersFormControl.value,
                        filterTypeId: filterTypeFormControl.value?.id,
                        filterTypeDescription: filterTypeOptionOthersFormControl.value,
                        slurry: slurryFormControl.value,
                        hasCarcinogenicSubstances: containsCarcinogens,
                        hasHazardousMaterial: containsHazardousMaterial,
                        hazardousMaterialDescription: hazardousMaterialFormControl.value,
                        carcinogenicSubstancesDescription: carcinogenicsFormControl.value,
                        otherInformations: otherEquipmentInformationsFormControl.value
                    }
                }
            }
        }, [requestNameFormControl.validate, companyFormControl.validate, factoryFormControl.validate,
        creationRequestDateFormControl.validate, customerCommentsFormControl.validate, clientRegistrationControl.validate, clientNumberControl.validate,
        industrialSectorFormControl.validate, industrialSectorOptionOthersFormControl.validate, filterTypeFormControl.validate,
        filterTypeOptionOthersFormControl.validate, slurryFormControl.validate, hazardousMaterialFormControl.validate,
        carcinogenicsFormControl.validate, otherEquipmentInformationsFormControl.validate,
        requestNameFormControl.value, creationRequestDateFormControl.value, companyFormControl.value, factoryFormControl.value,
        customerCommentsFormControl.value, clientNumberControl.value, clientRegistrationControl.value?.id, industrialSectorFormControl.value?.id,
        filterTypeOptionOthersFormControl.value, slurryFormControl.value, containsCarcinogens, containsHazardousMaterial,
        hazardousMaterialFormControl.value, carcinogenicsFormControl.value, otherEquipmentInformationsFormControl.value]);





        /****************************
         * CSS & HTML
        *****************************/


        return (

            <CardContainer className="create-request-form-container">
                <div className="subtitle">{translate("REQUESTS.RequestDetails")}</div>
                <div className="create-request-form">
                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            formControl={requestNameFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Name")}
                            placeholder={translate("REQUESTS.INFOANDFORM.NamePlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldDatePicker
                            formControl={creationRequestDateFormControl}
                            label={translate("REQUESTS.INFOANDFORM.CreationDate")}
                            maxDate={dateNow}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.Client")}</div>

                    <div className="request-info-inputs">
                        <FormFieldTextInput
                            formControl={companyFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Company")}
                            placeholder={translate("REQUESTS.INFOANDFORM.CompanyPlaceholder")}
                            alternativeStyle={true}
                        />
                        <FormFieldTextInput
                            formControl={factoryFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Factory")}
                            placeholder={translate("REQUESTS.INFOANDFORM.FactoryPlaceholder")}
                            alternativeStyle={true}
                        />

                    </div>

                    <div className="request-info-inputs">
                        <FormFieldSelectSingle
                            label={translate("REQUESTS.INFOANDFORM_2.ClientRegistration")}
                            placeholder={translate("COMMON.SelectOneOptionPlaceholders")}
                            options={clientRegistrationTypes || []}
                            labelSelector={labelClientRegistrationSelector}
                            idSelector={idClientRegistrationSelector}
                            formControl={clientRegistrationControl}
                            isClearSelectionAvailable={false}
                            alternativeStyle={true}
                        />

                        <ConditionalRender if={clientRegistrationControl.value?.id && clientRegistrationControl.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString() ? true : false}>
                            <FormFieldTextInput
                                formControl={clientNumberControl}
                                label={translate("REQUESTS.INFOANDFORM.ClientNumber")}
                                placeholder={translate("REQUESTS.INFOANDFORM.ClientNumberPlaceholder")}
                                alternativeStyle={true}
                            />
                        </ConditionalRender>
                    </div>

                    <div className="request-info-inputs">

                        <FormFieldTextArea
                            formControl={customerCommentsFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Comments") + " (" + translate("COMMON.Optional") + ")"}
                            placeholder={translate("REQUESTS.INFOANDFORM.CommentsPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="small-copy separator">{translate("REQUESTS.INFOANDFORM.EquipmentInformation")}</div>
                    <div className="request-info-inputs">
                        <FormFieldSelectSingle
                            label={translate("REQUESTS.INFOANDFORM.IndustrialSector")}
                            placeholder={translate("REQUESTS.INFOANDFORM.IndustrialSector")}
                            options={industrialSectors || []}
                            labelSelector={labelIndustrialSectorSelector}
                            idSelector={idIndustrialSectorSelector}
                            formControl={industrialSectorFormControl}
                            isClearSelectionAvailable={false}
                            alternativeStyle={true}
                        />
                        <FormFieldTextInput
                            formControl={industrialSectorOptionOthersFormControl}
                            label={translate("REQUESTS.INFOANDFORM.IndustrialSectorDescription")}
                            placeholder={translate("REQUESTS.INFOANDFORM.IndustrialSectorDescription")}
                            alternativeStyle={true}
                        />

                        <FormFieldSelectSingle
                            label={translate("REQUESTS.INFOANDFORM.FilterType")}
                            placeholder={translate("REQUESTS.INFOANDFORM.FilterType")}
                            options={filterTypes || []}
                            labelSelector={labelFilterTypeSelector}
                            idSelector={idFilterTypeSelector}
                            formControl={filterTypeFormControl}
                            isClearSelectionAvailable={false}
                            alternativeStyle={true}
                        />
                        <FormFieldTextInput
                            formControl={filterTypeOptionOthersFormControl}
                            label={translate("REQUESTS.INFOANDFORM.FilterTypeDescription")}
                            placeholder={translate("REQUESTS.INFOANDFORM.FilterTypeDescription")}
                            alternativeStyle={true}
                        />

                        <FormFieldTextInput
                            formControl={slurryFormControl}
                            label={translate("REQUESTS.INFOANDFORM.Slurry") + " (" + translate("COMMON.Optional") + ")"}
                            placeholder={translate("REQUESTS.INFOANDFORM.SlurryPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="request-info-inputs">
                        <Checkbox
                            text={translate("REQUESTS.INFOANDFORM.ContainsHazardMaterial") + "?"}
                            isChecked={containsHazardousMaterial}
                            onClick={() => { setContainsHazardousMaterial(!containsHazardousMaterial) }}
                        />
                        <FormFieldTextInput
                            formControl={hazardousMaterialFormControl}
                            placeholder={translate("REQUESTS.INFOANDFORM.ContainsHazardMaterialPlaceholder")}
                            alternativeStyle={true}
                        />
                        <Checkbox
                            text={translate("REQUESTS.INFOANDFORM.ContainsCarcinogenicSubstances") + "?"}
                            isChecked={containsCarcinogens}
                            onClick={() => { setContainsCarcinogens(!containsCarcinogens) }}
                        />
                        <FormFieldTextInput
                            formControl={carcinogenicsFormControl}
                            placeholder={translate("REQUESTS.INFOANDFORM.ContainsCarcinogenicSubstancesPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="request-info-inputs">
                        <FormFieldTextArea
                            formControl={otherEquipmentInformationsFormControl}
                            label={translate("REQUESTS.INFOANDFORM.FurtherInformation") + " (" + translate("COMMON.Optional") + ")"}
                            placeholder={translate("REQUESTS.INFOANDFORM.FurtherInformationPlaceholder")}
                            alternativeStyle={true}
                        />
                    </div>

                    <div className="create-request-action-btns-wf-pp">
                        <div className="slurry-data-sheet-info">
                            {translate("REQUESTS.INFOANDFORM.ProvideSlurrySafetySheet")}
                        </div>
                    </div>
                </div>
            </CardContainer>
        );
    }
);
