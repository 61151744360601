import { useCallback, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { TestTypesService } from "../../services/TestTypesService";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

export interface IAddTestTypeProps {
    locationId: string;
    onChangesMade: () => void;
}

var testTypesSvc = new TestTypesService();

export function AddTestTypePopup(props: IAddTestTypeProps) {
    const createNotification = useCreateNotification();
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);


    const nameEnFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });
    const namePtFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });



    const onClickAddTestType = useCallback(() => {
        if (!props.locationId) return;

        if (!AllValid(namePtFormControl.validate(), nameEnFormControl.validate())) return;

        setOnClickSubmit(true);

        testTypesSvc
            .addTestType(props.locationId, { nameEn: trimString(nameEnFormControl.value), namePt: trimString(namePtFormControl.value) })
            .then((_) => {
                props.onChangesMade();
                setOnClickSubmit(false);
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("GLOBALADMIN.TESTTYPES.POPUP.CreateTestTypeSuccess")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                setOnClickSubmit(false);
                openPopup(
                    <ErrorPopup>{error.response.data.message}</ErrorPopup>,
                    false
                );
            });

    }, [
        namePtFormControl.value,
        nameEnFormControl.value,
        namePtFormControl.validate,
        nameEnFormControl.validate,
        createNotification,
        closePopup,
        openPopup,
        setOnClickSubmit,
        props.onChangesMade,
    ]);

    return (
        <div className="add-test-type-wrapper">
            <PopupContainer className="popup-add-test-type">
                {onClickSubmit ? <FullScreenLoader /> : null}

                <PopupHeader
                    title={translate("GLOBALADMIN.TESTTYPES.INFOANDFORM.CreateTestType")}
                />
                <PopupContent>
                    <FormContainer>
                        <FormFieldTextInput
                            formControl={nameEnFormControl}
                            label={translate("GLOBALADMIN.TESTTYPES.INFOANDFORM.Name") + " (en-EN)"}
                            placeholder="Felts"
                        />
                        <FormFieldTextInput
                            formControl={namePtFormControl}
                            label={translate("GLOBALADMIN.TESTTYPES.INFOANDFORM.Name") + " (pt-PT)"}
                            placeholder="Ex: Feltros"
                        />
                    </FormContainer>
                    <Spacer mode={"vertical"} px="30"></Spacer>
                    <PopupActionButtons
                        buttons={[
                            {
                                text: translate("COMMON.Cancel"),
                                type: "tertiary",
                                onClick: () => closePopup(),
                            },
                            {
                                text: translate("COMMON.Create"),
                                type: "primary",
                                onClick: onClickAddTestType
                            },
                        ]}
                    />
                </PopupContent>
            </PopupContainer>
        </div>
    );
}
