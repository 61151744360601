import { IFormControl, Optional, ValidatorFunction } from "../../../lib/components/form/Form";

import { ClientRegistrationEnum } from "../models/domain/ClientRegistrationEnum";
import { SelectOption } from "../models/domain/SelectOption";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";

export function validateClientRegistrationNumber(clientRegistrationControl?: IFormControl<SelectOption>): ValidatorFunction<Optional<string>> {
    return (value: string | undefined) => {
        if (clientRegistrationControl?.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString()
            && (!value || value?.trim() === "")) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}