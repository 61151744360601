import "./SamplesPageFilters.css";

import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { ReactComponent as ArrowDown } from "../../../../lib/assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../lib/assets/icons/arrowUp.svg";
import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { ReactComponent as CleanIcon } from "../../../../lib/assets/icons/clean.svg";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { Employee } from "../../models/domain/Employee";
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectMultipleSimple } from "../../../../lib/components/form/form-field/FormFieldSelectMultipleSimple";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { GetSampleTypes } from "../../models/domain/SampleTypeEnum";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { SampleStatus } from "../../models/domain/SampleStatus";
import {
  SampleStatusEnum,
} from "../../models/domain/SamplesStatusEnum";
import { SampleType } from "../../models/domain/SampleType";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Tooltip } from "../../../../lib/components/tooltip/Tooltip";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { validateDateTime } from "../../../../lib/validators/ValidateDateTime";

const labelSampleTypeSelector = (item: SampleType) => item.description;
const idSampleTypeSelector = (item: SampleType) => item.id;

const labelSampleStatusSelector = (item: SampleStatus) => item.description;
const idSampleStatusSelector = (item: SampleStatus) => item.id;

const labelAnalystSelector = (item: Employee) => "" + item.name;
const idAnalystSelector = (item: Employee) => "" + item.email;

export interface SamplesFilters {
  sampleType: SampleType | undefined;
  samplesStatus: SampleStatus[];
  isRequestDateSelected: boolean;
  searchWord: string | undefined;
  initialCreationDate: DateTime | undefined;
  finalCreationDate: DateTime | undefined;
  byAnalist: boolean;
  analystEmail: string | undefined;
}

interface ISetOptions {
  clearFilters?: boolean;
  defaultFilters?: boolean;
}

export interface FiltersForm {
  cleanFilters: () => void;
  setStatus: (status: SampleStatusEnum[], options: ISetOptions | undefined) => void;
  setRequestDate: (options: ISetOptions | undefined) => void;
}

export interface IFiltersProps {
  defaultStatusAsEnum: SampleStatusEnum[];
  sampleStatusOptions: SampleStatus[];
  analysts: Employee[];
  isLoadingAnalystsData: boolean;
  onFiltersChanged: (values: SamplesFilters) => void;
}

export const SamplesPageFilters = forwardRef(function SamplesPageFilters(
  props: IFiltersProps,
  ref: React.ForwardedRef<FiltersForm>
) {

  const windowResize = useWindowResize();
  const upperRowRef = useRef<HTMLDivElement>(null);
  const bottomRowRef = useRef<HTMLDivElement>(null);

  const samplesTypes = useMemo(() => {
    return GetSampleTypes();
  }, []);



  const defaultSampleStatuses = useMemo(() => {
    if (!props.defaultStatusAsEnum && !props.sampleStatusOptions) return;
    const sampleStatusKeysAsString = props.defaultStatusAsEnum.map((key) => key.toString()
    );
    return props.sampleStatusOptions.filter((obj) => sampleStatusKeysAsString.includes(obj.id));
  }, [props.sampleStatusOptions, props.defaultStatusAsEnum]);


  var searchBoxFormControl = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
    initialValue: "",
  });

  const sampleStatusFormControl = useFormControl<SampleStatus[]>({
    isDisabled: false,
    initialValue: defaultSampleStatuses,
  });

  const typeFormControl = useFormControl<SampleType>({ isDisabled: false });

  const initialDatePickerControl = useFormControl<DateTime>({
    validators: [validateDateTime()],
    enableAutoValidate: false,
    isDisabled: false,
  });

  const finalDatePickerControl = useFormControl<DateTime>({
    validators: [validateDateTime()],
    enableAutoValidate: false,
    isDisabled: false,
  });


  const analystsFormControl = useFormControl<Employee>({
    isDisabled: false,
  });



  
  useEffect(() => {
    if (props.isLoadingAnalystsData)
      analystsFormControl.setIsDisabled(true)
    else
      analystsFormControl.setIsDisabled(false)

  }, [props.isLoadingAnalystsData]);




  useEffect(() => {
    if (props.analysts.length < 1)
      analystsFormControl.setIsDisabled(true)
    else
      analystsFormControl.setIsDisabled(false)

  }, [props.analysts]);



  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();

  const [isRequestDateSelected, setIsRequestDateSelected] = useState<boolean>(false);
  const [isByAnalistSelected, setIsByAnalistSelected] = useState<boolean>(false);
  const [isFiltersPanelExpanded, setIsFiltersPanelExpanded] = useState<boolean>(false);

  const handleClearFilters = useCallback(() => {
    searchBoxFormControl.setValue("");
    typeFormControl.setValue({ id: "", description: "" });
    setIsRequestDateSelected(false);
    setIsByAnalistSelected(false);
    sampleStatusFormControl.setValue([]);
    initialDatePickerControl.setValue(undefined);
    finalDatePickerControl.setValue(undefined);
  }, [searchBoxFormControl, setIsByAnalistSelected, typeFormControl, setIsRequestDateSelected, sampleStatusFormControl, initialDatePickerControl, finalDatePickerControl]);




  useImperativeHandle(
    ref,
    () => {
      return {
        cleanFilters: () => {
          handleClearFilters();
        },
        setStatus: (
          values: SampleStatusEnum[],
          options: ISetOptions | undefined
        ) => {
          if (options && options.clearFilters) handleClearFilters();
          if (options && options.defaultFilters)
            sampleStatusFormControl.setValue(defaultSampleStatuses);

          if (values && values.length && !options?.defaultFilters) {
            var mappedStatus = values
              .map((v) => {
                return props.sampleStatusOptions.find((s) => s.id === `${v}`);
              })
              .filter((s) => s) as SampleStatus[];

            sampleStatusFormControl.setValue(mappedStatus);
          }
        },
        setRequestDate: (options: ISetOptions | undefined) => {
          handleClearFilters();
          sampleStatusFormControl.setValue(defaultSampleStatuses);
          setIsRequestDateSelected(true);
        },
      };
    },
    [handleClearFilters, props.sampleStatusOptions, sampleStatusFormControl.setValue, setIsRequestDateSelected, props.defaultStatusAsEnum, defaultSampleStatuses]);


  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    var responseTimeOut = setTimeout(() => {
      if (timer) {
        setSearchWord(searchBoxFormControl.value);
      }
    }, 1000);
    setTimer(responseTimeOut);
  }, [searchBoxFormControl.value]);





  useEffect(() => {
    props.onFiltersChanged({
      isRequestDateSelected: isRequestDateSelected,
      samplesStatus: sampleStatusFormControl.value || [],
      sampleType: typeFormControl.value,
      searchWord: searchWord,
      initialCreationDate: initialDatePickerControl.value,
      finalCreationDate: finalDatePickerControl.value,
      byAnalist: isByAnalistSelected,
      analystEmail: analystsFormControl.value?.email ?? undefined
    });
  }, [searchWord, isByAnalistSelected, typeFormControl.value, sampleStatusFormControl.value,
    initialDatePickerControl.value, finalDatePickerControl.value, isRequestDateSelected, analystsFormControl.value]);




  const handleResetFiltersClicked = useCallback(() => {
    sampleStatusFormControl.setValue(defaultSampleStatuses);
    searchBoxFormControl.setValue("");
    typeFormControl.setValue({ id: "", description: "" });
    setIsRequestDateSelected(false);
    setIsByAnalistSelected(false);
    initialDatePickerControl.setValue(undefined);
    finalDatePickerControl.setValue(undefined);
    analystsFormControl.setValue({ email: "", name: "" });
  }, [searchBoxFormControl, typeFormControl, setIsRequestDateSelected, sampleStatusFormControl, finalDatePickerControl, initialDatePickerControl, defaultSampleStatuses, analystsFormControl, setIsByAnalistSelected]);



  useEffect(() => {
    const upperRowNode = upperRowRef.current;
    const bottomRowNode = bottomRowRef.current;

    if (upperRowNode && bottomRowNode) {
      let hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
        return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
      });

      while (hasWrapOccurred) {
        const lastChild = upperRowNode.lastElementChild;
        if (lastChild) {
          upperRowNode.removeChild(lastChild);
          bottomRowNode.appendChild(lastChild);
        }

        hasWrapOccurred = Array.from(upperRowNode.children, child => child as HTMLElement).some((child, i, arr) => {
          return i > 0 && child.offsetTop > arr[i - 1].offsetTop;
        });
      }
    }
  }, [windowResize]);


  return (
    <div className="view-samples-page-filters">
      <div className="filters">
        <div className="upper-row-filters" ref={upperRowRef}>
          <FormFieldTextInput
            formControl={searchBoxFormControl}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
          />
          <FormFieldSelectSingle
            placeholder={translate("SAMPLES.INFOANDFORM.TypePlaceholder")}
            options={samplesTypes}
            labelSelector={labelSampleTypeSelector}
            idSelector={idSampleTypeSelector}
            formControl={typeFormControl}
            isClearSelectionAvailable={true}
          />
          <FormFieldSelectMultipleSimple<SampleStatus>
            placeholder={translate("COMMON.FORM.SelectStatusPlaceholder")}
            options={props.sampleStatusOptions}
            labelSelector={labelSampleStatusSelector}
            idSelector={idSampleStatusSelector}
            formControl={sampleStatusFormControl}
            maxHeightOptions={"16rem"}
          />
          {/*           <ConditionalRender if={props.analysts.length > 0}> */}
          <FormFieldSelectSingle
            placeholder={translate("SAMPLES.INFOANDFORM.SelectAnalyst")}
            options={props.analysts || []}
            labelSelector={labelAnalystSelector}
            idSelector={idAnalystSelector}
            formControl={analystsFormControl}
            isClearSelectionAvailable={true}
          />
          {/*           </ConditionalRender> */}
          <Checkbox
            text={translate("SAMPLES.STATUS.WithRequestDateExtended")}
            isChecked={isRequestDateSelected}
            onClick={() => setIsRequestDateSelected(!isRequestDateSelected)}
          />
        </div>
        <div className="action-button-filters">
          <IconButton
            icon={isFiltersPanelExpanded ? <ArrowUp /> : <ArrowDown />}
            type="tertiary"
            onClick={() => setIsFiltersPanelExpanded(!isFiltersPanelExpanded)}
            className="view-more-filters-btn"
          />
          <Tooltip text={translate("COMMON.FORM.CleanFilters")}>
            <IconButton
              icon={<CleanIcon />}
              type="secondary"
              onClick={handleResetFiltersClicked}
              className="reset-filters-btn"
            />
          </Tooltip>
        </div>
        <div className={"bottom-row-filters" + (isFiltersPanelExpanded ? " expanded" : "")} ref={bottomRowRef}>
          <FormFieldDatePicker
            className="date-picker-width"
            formControl={initialDatePickerControl}
            placeholder={translate("COMMON.FORM.InitialCreationDatePlaceholder")}
            maxDate={finalDatePickerControl.value}
          />
          <FormFieldDatePicker
            className="date-picker-width"
            formControl={finalDatePickerControl}
            placeholder={translate("COMMON.FORM.FinalCreationDatePlaceholder")}
            minDate={initialDatePickerControl.value}
          />
        </div>
      </div>
    </div>
  );
});
