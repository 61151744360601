import "./EditRequestForm.css";

import { ClientRegistrationEnum, GetClientRegistrationEnumByKey, GetClientRegistrationsArray } from "../../../../models/domain/ClientRegistrationEnum";
import { DeliverTypeEnum, GetDeliverTypeArray } from "../../../../models/domain/DeliverTypeEnum";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import { AllValid } from "../../../../../../common/validators/ValidateFormControls";
import { AnalysisRequestFilterTypeService } from "../../../../services/AnalysisRequestFilterTypeService";
import { AnalysisRequestIndustrialSectorService } from "../../../../services/AnalysisRequestIndustrialSectorService";
import { Checkbox } from "../../../../../../lib/components/checkbox/Checkbox";
import { ConditionalRender } from "../../../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { EditRequestForm } from "../../../../models/domain/EditRequestForm";
import { ErrorPopup } from "../../../../../../lib/components/popup/ErrorPopup";
import { FilterType } from "../../../../models/domain/AnalysisRequestFilterType";
import { FormFieldDatePicker } from "../../../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectSingle } from "../../../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../../../lib/components/form/form-field/FormFieldTextArea";
import { FormFieldTextInput } from "../../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../../../lib/components/form/section/FormSection";
import { IndustrialSector } from "../../../../models/domain/AnalysisRequestIndustrialSector";
import { Permission } from "../../../../../../models/api/gate/Permission";
import React from "react";
import { RequestDetails } from "../../../../models/domain/RequestDetails";
import { SelectOption } from "../../../../models/domain/SelectOption";
import { hasOneElementSelected } from "../../../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../../../lib/components/form/Form";
import { usePopup } from "../../../../../../lib/infrastructure/ui/UIServices";
import { validateClientRegistrationNumber } from "../../../../validators/ValidateClientRegistrationNumber";
import { validateRequiredIfOthers } from "../../../../validators/ValidateRequiredIfOthers";
import { validateStringIfConditional } from "../../../../validators/ValidateIfConditional";
import { validateWaybillFormIfCourierShippingMethod } from "../../../create-request-page/CreateRequestPage";
import { validateWhiteSpaceStrings } from "../../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { AnalysisRequestReasonType } from "../../../../models/domain/AnalysisRequestReasonType";
import { validateAnalysisRequestReasonRequiredIfOthers } from "../../../../validators/validateAnalysisRequestReasonRequiredIfOthers";


const labelDeliverTypeSelector = (item: SelectOption) => item.description;
const idDeliverTypeSelector = (item: SelectOption) => item.id;

const labelIndustrialSectorSelector = (item: IndustrialSector) => item.description;
const idIndustrialSectorSelector = (item: IndustrialSector) => item.id;

const labelFilterTypeSelector = (item: FilterType) => item.description;
const idFilterTypeSelector = (item: FilterType) => item.id;

const labelClientRegistrationSelector = (item: SelectOption) => item.description;
const idClientRegistrationSelector = (item: SelectOption) => item.id;

const labelAnalysisReasonTypeSelector = (item: AnalysisRequestReasonType) => item.reasonTypeName;
const idAnalysisReasonTypeSelector = (item: AnalysisRequestReasonType) => item.id;


var analysisRequestIndustrialSectorService =  new AnalysisRequestIndustrialSectorService();
var analysisRequestFilterTypeService = new AnalysisRequestFilterTypeService();

export interface IGenericEditRequestFormsProps {
  info: RequestDetails;
  analysisReasonTypes: AnalysisRequestReasonType[];
}

export const EditRequestWFPPForm = forwardRef(function EditRequestWFPPForm(
  props: IGenericEditRequestFormsProps,
  ref: React.ForwardedRef<EditRequestForm>
) {

  const openPopup = usePopup();

  const [industrialSectors, setIndustrialSectors] = useState<IndustrialSector[]>([]);
  const [containsHazardousMaterial, setContainsHazardousMaterial] = useState<boolean>(false);
  const [containsCarcinogens, setContainsCarcinogens] = useState<boolean>(false);
  const [filterTypes, setFilterTypes] = useState<FilterType[]>([]);

  const clientRegistrationTypes = useMemo(() => {
    return GetClientRegistrationsArray();
  }, [GetClientRegistrationsArray]);

  const deliverRequestTypes = useMemo(() => {
    return GetDeliverTypeArray();
  }, [GetDeliverTypeArray]);



  useEffect(() => {
    analysisRequestIndustrialSectorService
      .getAnalysisRequestIndustrialSectors()
      .then((response) => {
        setIndustrialSectors(response);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, []);



  useEffect(() => {
    analysisRequestFilterTypeService
      .getAnalysisRequestFilterTypes()
      .then((response) => {
        setFilterTypes(response);
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, []);



  const requestNameFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const companyFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const factoryFormControl = useFormControl<string>({
    validators: [validateWhiteSpaceStrings()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const customerCommentsFormControl = useFormControl<string>({
    isDisabled: false,
  });

  const industrialSectorFormControl = useFormControl<IndustrialSector>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const industrialSectorOptionOthersFormControl = useFormControl<string>({
    validators: [validateRequiredIfOthers(industrialSectorFormControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const filterTypeFormControl = useFormControl<FilterType>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const filterTypeOptionOthersFormControl = useFormControl<string>({
    validators: [validateRequiredIfOthers(filterTypeFormControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const slurryFormControl = useFormControl<string>({ isDisabled: false });

  const hazardousMaterialFormControl = useFormControl<string>({
    validators: [validateStringIfConditional(containsHazardousMaterial)],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const carcinogenicsFormControl = useFormControl<string>({
    validators: [validateStringIfConditional(containsCarcinogens)],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const otherEquipmentInformationsFormControl = useFormControl<string>({
    isDisabled: false,
  });

  const analysisReasonTypeFormControl = useFormControl<AnalysisRequestReasonType>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false
  });

  const analysisReasonFormControl = useFormControl<string>({
    validators: [validateAnalysisRequestReasonRequiredIfOthers(analysisReasonTypeFormControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const deliverTypeRequestFormControl = useFormControl<SelectOption>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const waybillFormControl = useFormControl<string>({
    validators: [validateWaybillFormIfCourierShippingMethod(deliverTypeRequestFormControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const clientRegistrationControl = useFormControl<SelectOption>({
    validators: [hasOneElementSelected()],
    enableAutoValidate: true,
    isDisabled: false,
  });

  const clientNumberControl = useFormControl<string>({
    validators: [validateClientRegistrationNumber(clientRegistrationControl)],
    enableAutoValidate: true,
    isDisabled: false,
  });





  useEffect(() => {
    if (!props.info) return;

    if (props.info.hasHazardousMaterial) setContainsHazardousMaterial(props.info.hasHazardousMaterial);
    if (props.info.hasCarcinogenicSubstances) setContainsCarcinogens(props.info.hasCarcinogenicSubstances);
    clientRegistrationControl.setValue(clientRegistrationTypes.find((a) => a.id === (props.info.clientRegistration)?.toString()))
    clientNumberControl.setValue(props.info.clientRegistrationDescription);

    requestNameFormControl.setValue(props.info.name);
    companyFormControl.setValue(props.info.company);
    factoryFormControl.setValue(props.info.factory);
    waybillFormControl.setValue(props.info.waybill);
    customerCommentsFormControl.setValue(props.info.customerComments);
    analysisReasonFormControl.setValue(props.info.analysisReason);

    slurryFormControl.setValue(props.info.slurry);
    carcinogenicsFormControl.setValue(props.info.carcinogenicSubstancesDescription);
    hazardousMaterialFormControl.setValue(props.info.hazardousMaterialDescription);
    otherEquipmentInformationsFormControl.setValue(props.info.otherInformations);
    industrialSectorFormControl.setValue(industrialSectors.find((f) => f.id === props.info.industrialSectorId));
    industrialSectorOptionOthersFormControl.setValue(props.info.industrialSectorOthersDescription);
    filterTypeFormControl.setValue(filterTypes.find((f) => f.id === props.info.filterTypeId));
    filterTypeOptionOthersFormControl.setValue(props.info.filterTypeOthersDescription);

    if (props.info.shippingByCourier)
      deliverTypeRequestFormControl.setValue(deliverRequestTypes.find((f) => f.id === (DeliverTypeEnum.Courier).toString()));
    else
      deliverTypeRequestFormControl.setValue(deliverRequestTypes.find((f) => f.id === (DeliverTypeEnum.Personally).toString()))
  }, [props.info, industrialSectors, filterTypes]);





  useImperativeHandle(
    ref,
    () => {
      return {
        validate() {
          return AllValid(
            requestNameFormControl.validate(),
            companyFormControl.validate(),
            factoryFormControl.validate(),
            customerCommentsFormControl.validate(),
            industrialSectorFormControl.validate(),
            industrialSectorOptionOthersFormControl.validate(),
            filterTypeFormControl.validate(),
            filterTypeOptionOthersFormControl.validate(),
            slurryFormControl.validate(),
            hazardousMaterialFormControl.validate(),
            carcinogenicsFormControl.validate(),
            otherEquipmentInformationsFormControl.validate(),
            analysisReasonFormControl.validate(),
            clientRegistrationControl.validate(),
            clientNumberControl.validate(),
            waybillFormControl.validate(),
            deliverTypeRequestFormControl.validate(),
            analysisReasonTypeFormControl.validate()
          );
        },
        value() {
          return {
            name: requestNameFormControl.value,
            company: companyFormControl.value,
            factory: factoryFormControl.value,
            customerComments: customerCommentsFormControl.value,
            industrialSectorId: industrialSectorFormControl.value?.id,

            filterTypeId: filterTypeFormControl.value?.id,

            slurry: slurryFormControl.value,
            hasCarcinogenicSubstances: containsCarcinogens,
            hasHazardousMaterial: containsHazardousMaterial,

            otherInformations: otherEquipmentInformationsFormControl.value,
            analysisReason: analysisReasonFormControl.value,
            analysisRequestReasonTypeId: analysisReasonTypeFormControl.value?.id ?? -1,

            shippingByCourier: deliverTypeRequestFormControl.value?.id === DeliverTypeEnum.Courier.toString() ? true : false,
            waybill: deliverTypeRequestFormControl.value?.id === `${DeliverTypeEnum.Personally}` ?
              undefined : waybillFormControl.value,

            clientRegistration: clientRegistrationControl.value?.id ?
              GetClientRegistrationEnumByKey[parseInt(clientRegistrationControl.value.id)] : ClientRegistrationEnum.NOTREGISTERED,

            clientRegistrationDescription: clientRegistrationControl.value?.id === (ClientRegistrationEnum.NOTREGISTERED).toString() ?
              undefined : clientNumberControl.value,

            industrialSectorOthersDescription: industrialSectorFormControl.value?.isDescriptionRequired ?
              industrialSectorOptionOthersFormControl.value : undefined,

            filterTypeOthersDescription: filterTypeFormControl.value?.isDescriptionRequired ?
              filterTypeOptionOthersFormControl.value : undefined,

            hazardousMaterialDescription: containsHazardousMaterial ? hazardousMaterialFormControl.value : undefined,

            carcinogenicSubstancesDescription: containsCarcinogens ? carcinogenicsFormControl.value : undefined,

          };
        },
      };
    },
    [
      requestNameFormControl.validate,
      companyFormControl.validate,
      factoryFormControl.validate,
      customerCommentsFormControl.validate,
      industrialSectorFormControl.validate,
      industrialSectorOptionOthersFormControl.validate,
      filterTypeFormControl.validate,
      filterTypeOptionOthersFormControl.validate,
      slurryFormControl.validate,
      hazardousMaterialFormControl.validate,
      carcinogenicsFormControl.validate,
      otherEquipmentInformationsFormControl.validate,
      analysisReasonFormControl.validate,
      clientRegistrationControl.validate,
      clientNumberControl.validate,
      waybillFormControl.validate,
      deliverTypeRequestFormControl.validate,
      analysisReasonTypeFormControl.validate,
      analysisReasonTypeFormControl.value,
      waybillFormControl.value,
      deliverTypeRequestFormControl.value,
      requestNameFormControl.value,
      companyFormControl.value,
      factoryFormControl.value,
      customerCommentsFormControl.value,
      industrialSectorFormControl.value?.id,
      industrialSectorOptionOthersFormControl.value,
      filterTypeOptionOthersFormControl.value,
      slurryFormControl.value,
      containsCarcinogens,
      containsHazardousMaterial,
      hazardousMaterialFormControl.value,
      carcinogenicsFormControl.value,
      otherEquipmentInformationsFormControl.value,
      analysisReasonFormControl.value,
      clientRegistrationControl.value,
      clientNumberControl.value
    ]
  );


  useEffect(() => {
    if (containsCarcinogens) carcinogenicsFormControl.setIsDisabled(false);
    else carcinogenicsFormControl.setIsDisabled(true);
  }, [containsCarcinogens]);

  useEffect(() => {

    if (!props.info || !props.analysisReasonTypes || props.analysisReasonTypes.length < 1) return;
    var foundReasonType = props.analysisReasonTypes.find((t) => t.id === props.info.analysisRequestReasonTypeId);
    analysisReasonTypeFormControl.setValue(foundReasonType);

  }, [props.info, props.analysisReasonTypes]);


  useEffect(() => {
    if (containsHazardousMaterial)
      hazardousMaterialFormControl.setIsDisabled(false);
    else hazardousMaterialFormControl.setIsDisabled(true);
  }, [containsHazardousMaterial]);


  useEffect(() => {
    if (!industrialSectorFormControl.value || !industrialSectorFormControl.value?.isDescriptionRequired)
      industrialSectorOptionOthersFormControl.setIsDisabled(true);
    else industrialSectorOptionOthersFormControl.setIsDisabled(false);
  }, [industrialSectorFormControl.value?.isDescriptionRequired, industrialSectorFormControl.value]);


  useEffect(() => {
    if (!filterTypeFormControl.value || !filterTypeFormControl.value?.isDescriptionRequired)
      filterTypeOptionOthersFormControl.setIsDisabled(true);
    else filterTypeOptionOthersFormControl.setIsDisabled(false);
  }, [filterTypeFormControl.value?.isDescriptionRequired, filterTypeFormControl.value]);


  useEffect(() => {
    if (!deliverTypeRequestFormControl.value) {
      waybillFormControl.setIsDisabled(true); return;
    }
    if (parseInt(deliverTypeRequestFormControl.value.id) === DeliverTypeEnum.Personally || !deliverTypeRequestFormControl.value)
      waybillFormControl.setIsDisabled(true);
    else waybillFormControl.setIsDisabled(false);

  }, [deliverTypeRequestFormControl.value]);


  useEffect(() => {
    if (clientRegistrationControl.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString())
      clientNumberControl.setIsDisabled(false);
    else
      clientNumberControl.setIsDisabled(true);
  }, [clientRegistrationControl.value?.id]);



  return (
    <>
      <div className="edit-request-details">
        <div className="subtitle">{translate("REQUESTS.RequestData")}</div>
        <FormSection>
          <FormFieldTextInput
            formControl={requestNameFormControl}
            label={translate("REQUESTS.INFOANDFORM.Name")}
            placeholder={translate("REQUESTS.INFOANDFORM.NamePlaceholder")}
          />
        </FormSection>

        <FormSection
          title={translate("REQUESTS.INFOANDFORM.Client")}
          childrenFlexDirColumn={true}
        >
          <div className="flex-row">
            <FormFieldTextInput
              formControl={companyFormControl}
              label={translate("REQUESTS.INFOANDFORM.Company")}
              placeholder={translate("REQUESTS.INFOANDFORM.CompanyPlaceholder")}
            />
            <FormFieldTextInput
              formControl={factoryFormControl}
              label={translate("REQUESTS.INFOANDFORM.Factory")}
              placeholder={translate("REQUESTS.INFOANDFORM.FactoryPlaceholder")}
            />
          </div>
          <div className="flex-row">
            <FormFieldSelectSingle
              label={translate("REQUESTS.INFOANDFORM_2.ClientRegistration")}
              placeholder={translate("COMMON.SelectOneOptionPlaceholders")}
              options={clientRegistrationTypes || []}
              labelSelector={labelClientRegistrationSelector}
              idSelector={idClientRegistrationSelector}
              formControl={clientRegistrationControl}
              isClearSelectionAvailable={false}
            />

            <ConditionalRender if={clientRegistrationControl.value?.id && clientRegistrationControl.value?.id !== (ClientRegistrationEnum.NOTREGISTERED).toString() ? true : false}>
              <FormFieldTextInput
                formControl={clientNumberControl}
                label={translate("REQUESTS.INFOANDFORM.ClientNumber")}
                placeholder={translate("REQUESTS.INFOANDFORM.ClientNumberPlaceholder")}
              />
            </ConditionalRender>
          </div>
          <div className="flex-row">
            <FormFieldTextArea
              formControl={customerCommentsFormControl}
              label={
                translate("REQUESTS.INFOANDFORM.Comments") +
                " (" +
                translate("COMMON.Optional") +
                ")"
              }
              placeholder={translate(
                "REQUESTS.INFOANDFORM.CommentsPlaceholder"
              )}
            />
          </div>
        </FormSection>

        <FormSection
          title={translate("REQUESTS.INFOANDFORM.EquipmentInformation")}
          childrenFlexDirColumn={true}
        >
          <div className="flex-row">
            <FormFieldSelectSingle
              label={translate("REQUESTS.INFOANDFORM.IndustrialSector")}
              placeholder={translate("REQUESTS.INFOANDFORM.IndustrialSector")}
              options={industrialSectors || []}
              labelSelector={labelIndustrialSectorSelector}
              idSelector={idIndustrialSectorSelector}
              formControl={industrialSectorFormControl}
              isClearSelectionAvailable={false}
            />
            <FormFieldTextInput
              formControl={industrialSectorOptionOthersFormControl}
              label={translate(
                "REQUESTS.INFOANDFORM.IndustrialSectorDescription"
              )}
              placeholder={translate(
                "REQUESTS.INFOANDFORM.IndustrialSectorDescription"
              )}
            />
          </div>
          <div className="flex-row">
            <FormFieldSelectSingle
              label={translate("REQUESTS.INFOANDFORM.FilterType")}
              placeholder={translate("REQUESTS.INFOANDFORM.FilterType")}
              options={filterTypes || []}
              labelSelector={labelFilterTypeSelector}
              idSelector={idFilterTypeSelector}
              formControl={filterTypeFormControl}
              isClearSelectionAvailable={false}
            />
            <FormFieldTextInput
              formControl={filterTypeOptionOthersFormControl}
              label={translate("REQUESTS.INFOANDFORM.FilterTypeDescription")}
              placeholder={translate(
                "REQUESTS.INFOANDFORM.FilterTypeDescription"
              )}
            />
          </div>
          <div className="flex-row one-column">
            <FormFieldTextInput
              formControl={slurryFormControl}
              label={
                translate("REQUESTS.INFOANDFORM.Slurry") +
                " (" +
                translate("COMMON.Optional") +
                ")"
              }
              placeholder={translate("REQUESTS.INFOANDFORM.SlurryPlaceholder")}
            />
          </div>
          <div className="flex-row">
            <Checkbox
              text={
                translate("REQUESTS.INFOANDFORM.ContainsHazardMaterial") + "?"
              }
              isChecked={containsHazardousMaterial}
              onClick={() => {
                setContainsHazardousMaterial(!containsHazardousMaterial);
              }}
            />
            <FormFieldTextInput
              formControl={hazardousMaterialFormControl}
              placeholder={translate(
                "REQUESTS.INFOANDFORM.ContainsHazardMaterialPlaceholder"
              )}
            />
          </div>
          <div className="flex-row">
            <Checkbox
              text={
                translate(
                  "REQUESTS.INFOANDFORM.ContainsCarcinogenicSubstances"
                ) + "?"
              }
              isChecked={containsCarcinogens}
              onClick={() => {
                setContainsCarcinogens(!containsCarcinogens);
              }}
            />
            <FormFieldTextInput
              formControl={carcinogenicsFormControl}
              placeholder={translate(
                "REQUESTS.INFOANDFORM.ContainsCarcinogenicSubstancesPlaceholder"
              )}
            />
          </div>
          <div className="flex-row">
            <FormFieldTextArea
              formControl={otherEquipmentInformationsFormControl}
              label={
                translate("REQUESTS.INFOANDFORM.FurtherInformation") +
                " (" +
                translate("COMMON.Optional") +
                ")"
              }
              placeholder={translate(
                "REQUESTS.INFOANDFORM.FurtherInformationPlaceholder"
              )}
            />
          </div>
        </FormSection>
      </div>
      <div className="edit-request-ar-transport">
        <div className="edit-request-ar">
          <div className="subtitle">
            {translate("REQUESTS.INFOANDFORM.AnalysisReason")}
          </div>
          <FormFieldSelectSingle
            label={translate("REQUESTS.ANALYSISREASONTYPE.AnalysisReasonType")}
            placeholder={translate("COMMON.FORM.VALIDATIONS.SelectOneOption")}
            options={props.analysisReasonTypes || []}
            labelSelector={labelAnalysisReasonTypeSelector}
            idSelector={idAnalysisReasonTypeSelector}
            formControl={analysisReasonTypeFormControl}
            isClearSelectionAvailable={false}
          />
          <FormFieldTextArea
            formControl={analysisReasonFormControl}
            placeholder={translate(
              "REQUESTS.INFOANDFORM.AnalysisReasonPlaceholder"
            )}
          />
        </div>

        <div className="edit-request-transport">
          <div className="subtitle">
            {translate("REQUESTS.INFOANDFORM.Shipping")}
          </div>
          <FormFieldSelectSingle
            label={translate("REQUESTS.INFOANDFORM.ShippingMethod")}
            placeholder={translate("COMMON.FORM.VALIDATIONS.SelectOneOption")}
            options={deliverRequestTypes || []}
            labelSelector={labelDeliverTypeSelector}
            idSelector={idDeliverTypeSelector}
            formControl={deliverTypeRequestFormControl}
            isClearSelectionAvailable={false}
          />
          <FormFieldTextInput
            className="waybill"
            formControl={waybillFormControl}
            label={translate("REQUESTS.INFOANDFORM.Waybill")}
            placeholder={translate("REQUESTS.INFOANDFORM.WaybillPlaceholder")}
          />
        </div>
      </div>
    </>
  );
});
